import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const ProfileIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon
      sx={{ width: '24px', height: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 14.5264 5.17108 16.7793 7 18.2454V17.8571C7 16.1725 7.85766 14.6882 9.16016 13.817C8.44301 13.094 8 12.0988 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 12.0988 15.557 13.094 14.8398 13.817C16.1423 14.6882 17 16.1725 17 17.8571V18.2454C18.8289 16.7793 20 14.5264 20 12C20 7.58172 16.4183 4 12 4ZM15 19.4185V17.8571C15 16.2792 13.7208 15 12.1429 15H12H11.8571C10.2792 15 9 16.2792 9 17.8571V19.4185C9.92643 19.7935 10.9391 20 12 20C13.0609 20 14.0736 19.7935 15 19.4185ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
        fill={isLight ? '#5275EF' : color}
      />
    </SvgIcon>
  );
};

export default ProfileIcon;
