import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminTransactionsIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 24 20'} width={'24'} height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16536 16.0007C6.16536 16.645 6.68769 17.1673 7.33203 17.1673H20.1654C20.8097 17.1673 21.332 16.645 21.332 16.0007V7.83398C21.332 7.18963 20.8097 6.66732 20.1654 6.66732V12.5007C20.1654 14.4337 18.5984 16.0007 16.6654 16.0007H6.16536ZM20.1654 4.33398C20.1654 2.40099 18.5984 0.833984 16.6654 0.833984H3.83203C1.89904 0.833984 0.332031 2.40099 0.332031 4.33398V12.5007C0.332031 14.4337 1.89904 16.0007 3.83203 16.0007C3.83203 17.9337 5.39904 19.5007 7.33203 19.5007H20.1654C22.0984 19.5007 23.6654 17.9337 23.6654 16.0007V7.83398C23.6654 5.90099 22.0984 4.33398 20.1654 4.33398ZM16.6654 13.6673C17.3097 13.6673 17.832 13.145 17.832 12.5007V4.33398C17.832 3.68966 17.3097 3.16732 16.6654 3.16732H3.83203C3.1877 3.16732 2.66536 3.68966 2.66536 4.33398V12.5007C2.66536 13.145 3.1877 13.6673 3.83203 13.6673H16.6654ZM16.6654 11.334C16.6654 10.6896 16.143 10.1673 15.4987 10.1673C14.8543 10.1673 14.332 10.6896 14.332 11.334C14.332 11.9783 14.8543 12.5007 15.4987 12.5007C16.143 12.5007 16.6654 11.9783 16.6654 11.334ZM4.9987 4.33398C5.64302 4.33398 6.16536 4.85632 6.16536 5.50065C6.16536 6.14499 5.64302 6.66732 4.9987 6.66732C4.35437 6.66732 3.83203 6.14499 3.83203 5.50065C3.83203 4.85632 4.35437 4.33398 4.9987 4.33398ZM13.1654 8.41732C13.1654 7.12862 12.1207 6.08398 10.832 6.08398C9.54337 6.08398 8.4987 7.12862 8.4987 8.41732C8.4987 9.70602 9.54337 10.7507 10.832 10.7507C12.1207 10.7507 13.1654 9.70602 13.1654 8.41732Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminTransactionsIcon;
