/* eslint-disable prettier/prettier */
import { authRoutes } from '../../layouts/public/AuthLayout/layoutRoutes';
// inject:import
import { AppRoute } from '../../setup/typedefs';
import { commonRoutes } from '../common/routes';

export const publicRoutes: AppRoute<any>[] = [
  ...authRoutes,
  ...commonRoutes
];
