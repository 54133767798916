import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AchievementsIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 24 20'} width={'24'} height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51861 1.04314C3.68671 0.806701 3.96042 0.66603 4.25238 0.66603H19.7479C20.0398 0.66603 20.3135 0.806701 20.4816 1.04314L23.5031 5.2931C23.696 5.56446 23.7203 5.91968 23.5659 6.21427C23.4116 6.50887 23.1044 6.69377 22.7693 6.69377H1.2309C0.89582 6.69377 0.588647 6.50887 0.434318 6.21427C0.279989 5.91968 0.304212 5.56446 0.497135 5.2931L3.51861 1.04314ZM4.71804 2.44381L2.96046 4.91599H21.0398L19.2822 2.44381H4.71804Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.417018 5.43033C0.564169 5.11665 0.881673 4.916 1.23089 4.916H22.7693C23.1186 4.916 23.4361 5.11668 23.5832 5.43041C23.7304 5.74413 23.6804 6.11391 23.4551 6.37825L12.6838 19.0172C12.5133 19.2173 12.2623 19.3327 11.9979 19.3327C11.7336 19.3327 11.4826 19.2172 11.3121 19.017L0.545002 6.37812C0.319796 6.11376 0.269867 5.74402 0.417018 5.43033ZM3.16237 6.69377L11.9982 17.0656L20.8374 6.69377H3.16237Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12693 5.28371C7.2957 5.05275 7.56608 4.916 7.85393 4.916H16.1421C16.43 4.916 16.7003 5.05275 16.8691 5.28371C17.0379 5.51466 17.0846 5.81179 16.9946 6.08262L13.0801 17.8707C12.9618 18.2269 12.6305 18.4713 12.2521 18.4815L11.7972 18.4939C11.4007 18.5046 11.0441 18.2563 10.9202 17.8831L7.00142 6.08262C6.91148 5.81179 6.95816 5.51467 7.12693 5.28371ZM9.09385 6.69377L11.9981 15.4392L14.9022 6.69377H9.09385Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6754 0.874085C4.05503 0.55847 4.6211 0.607436 4.93975 0.983453L7.90066 4.47743L11.3525 0.937413C11.5216 0.763982 11.7546 0.666036 11.998 0.666036C12.2415 0.666036 12.4744 0.763983 12.6435 0.937413L16.0958 4.47783L19.0608 0.983064C19.3797 0.607227 19.9458 0.558582 20.3252 0.874411C20.7047 1.19024 20.7538 1.75095 20.4349 2.12678L16.8292 6.37675C16.6656 6.56952 16.4272 6.68454 16.1731 6.69324C15.9191 6.70195 15.6732 6.60351 15.4966 6.4224L11.998 2.83445L8.49945 6.4224C8.32278 6.60358 8.0768 6.70201 7.82265 6.69323C7.5685 6.68445 7.33004 6.56929 7.16654 6.37636L3.56498 2.1264C3.24633 1.75038 3.29577 1.1897 3.6754 0.874085Z"
        fill={color}
      />
      <path
        d="M0.966654 4.83403C1.05161 4.81129 1.14032 4.79933 1.23089 4.79933H2.90025L4.16711 3.01739L3.47598 2.20182C3.33465 2.03505 3.25732 1.83617 3.24136 1.63447L0.966654 4.83403Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AchievementsIcon;
