import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const NewsIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77842 3.25016C4.77842 2.92842 5.02675 2.66732 5.33398 2.66732H16.2638C16.0238 3.19772 15.8895 3.79105 15.8895 4.41732V6.16732H18.1118H15.8895V13.1673V14.0747V18.5206C15.8895 20.0744 14.6899 21.334 13.2101 21.334C11.7303 21.334 10.5308 20.0744 10.5308 18.5206V16.7251C10.5308 16.0489 10.0087 15.5007 9.36464 15.5007H4.77842V12.2674V3.25016ZM18.1118 14.0747V18.5206C18.1118 21.363 15.9173 23.6673 13.2101 23.6673C13.1772 23.6673 13.1445 23.667 13.1118 23.6663V23.6673H7.00066C3.36355 23.6673 0.333984 20.5742 0.333984 16.7251C0.333984 16.0489 0.856091 15.5007 1.5001 15.5007H2.5562V12.2674V3.25016C2.5562 1.6389 3.80027 0.333984 5.33398 0.333984H19.7785C21.9262 0.333984 23.6673 2.16215 23.6673 4.41732V7.27623C23.6673 7.95246 23.1452 8.50065 22.5012 8.50065H18.1118V13.1673V14.0747ZM18.1118 6.16732V4.41732C18.1118 3.45082 18.858 2.66732 19.7785 2.66732C20.6989 2.66732 21.445 3.45082 21.445 4.41732V6.16732H18.1118ZM8.30852 18.5206C8.30852 19.5589 8.60135 20.5254 9.105 21.334H7.00066C4.92995 21.334 3.17133 19.8354 2.68779 17.834H3.66731H8.30852V18.5206ZM7.00066 9.66732C6.387 9.66732 5.88953 10.1896 5.88953 10.834C5.88953 11.4783 6.387 12.0007 7.00066 12.0007H13.6674C14.281 12.0007 14.7785 11.4783 14.7785 10.834C14.7785 10.1896 14.281 9.66732 13.6674 9.66732H7.00066ZM5.88953 6.16732C5.88953 5.52299 6.387 5.00065 7.00066 5.00065H13.6674C14.281 5.00065 14.7785 5.52299 14.7785 6.16732C14.7785 6.81164 14.281 7.33398 13.6674 7.33398H7.00066C6.387 7.33398 5.88953 6.81164 5.88953 6.16732Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default NewsIcon;
