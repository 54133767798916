import { apply, call, put } from 'redux-saga/effects';
import { todoActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import {
  ChangeTodoActionPayload,
  UpdateTodoActionPayload,
} from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* updateTodo({
  payload,
}: PayloadAction<UpdateTodoActionPayload>) {
  try {
    yield put(todoActions.startFetching());
    const body: string = yield call(JSON.stringify, payload);
    const response: Response = yield apply(api, api.put, [
      { endpoint: `todo/${payload.id}/`, body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: ChangeTodoActionPayload = yield call([response, 'json']);
    yield put(todoActions.changeTodo(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(todoActions.stopFetching());
  }
}
