import React from 'react';
import { SvgIcon } from '@mui/material';

const CheckedIconLT = ({ color = '#37E6FF' }) => {
  return (
    <SvgIcon
      sx={{ fontSize: '20px', width: '20px', height: '20px' }}
      width={20}
      height={20}
      viewBox={'0 0 20 20'}>
      <rect
        width="20"
        height="20"
        rx="5"
        fill="url(#paint0_linear_14410_22996)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1328 6.18971C15.3962 6.44695 15.4012 6.86903 15.144 7.13245L8.63355 13.7991C8.50237 13.9335 8.32062 14.0062 8.13297 13.9996C7.94533 13.9929 7.76919 13.9075 7.64785 13.7642L4.82493 10.4309C4.58698 10.1499 4.62186 9.7292 4.90283 9.49127C5.1838 9.25333 5.60447 9.2882 5.84242 9.56913L8.19169 12.3432L14.1901 6.20089C14.4473 5.93747 14.8694 5.93246 15.1328 6.18971Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14410_22996"
          x1="20"
          y1="15.3"
          x2="0.689251"
          y2="9.7268"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default CheckedIconLT;
