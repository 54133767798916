import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { Avatar } from '../../../ui-kit/Avatar';
import MercurioIcon from '../../../ui-kit/Icons/MercurioIcon';
import AdvcashIcon from '../../../ui-kit/Icons/AdvcashIcon';
import BalanceIcon from '../../../ui-kit/Icons/BalanceIcon';
import { Accordion } from '../../../ui-kit/Accordion';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../../../bus/ui/actions';
import { getBalanceWalletsWithStats } from '../../../../bus/balance/selectors';
import { getCurrencyCourse } from '../../../../bus/options/selectors';
import { paymentActions } from '../../../../bus/payment/actions';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';
import BalanceIconLT from '../../../ui-kit/Icons/lightTeme/BalanceIconLT';

type PaymentChoiceProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-2, 73],
    blur: 50,
  }),
  (colors) => ({
    color: colors.violet,
    position: [100, 30],
    blur: 50,
  }),
];

export const PaymentChoice: React.FC<PaymentChoiceProps> = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();
  const balanceCardList: any = useSelector(getBalanceWalletsWithStats);
  const id = balanceCardList.filter((item: any) => {
    return item.currency === 1;
  })[0]?.id;

  const rateFzs = useSelector(getCurrencyCourse(3));

  const paymentMercurio = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        paymentActions.fetchMercurio({
          values: {
            type: 1,
            callback_url: window.location.href,
          },
          resolve,
          reject,
        }),
      );
    })
      .then((res) => {
        dispatch(
          uiActions.modal({
            modalName: 'ModalIframe',
            hideUi: true,
            modalPayload: {
              isOutSideClicked: true,
              titlePosition: 'self-start',
              src: res,
            },
          }),
        );
      })
      .catch((errors) => {
        console.log('errors => ', errors);
      });
  };

  const paymentAdvcash = () => {
    dispatch(
      uiActions.modal({
        modalName: 'PaymentAdvcash',
        modalPayload: {
          title: 'Advcash',
          isOutSideClicked: true,
          titlePosition: 'self-start',
        },
      }),
    );
  };

  const useYourBalance = () => {
    dispatch(
      uiActions.modal({
        modalName: 'Convert',
        modalPayload: {
          titlePosition: 'baseline',
          title: 'Convert',
          isOutSideClicked: true,
          id: id,
        },
      }),
    );
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box
        m={'12px 0 30px 0'}
        display={'flex'}
        justifyContent={'space-between'}
        gap={'7px'}>
        <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
          <Button
            onClick={paymentMercurio}
            startIcon={<Avatar variant={'rounded'} svgIcon={MercurioIcon} />}
            variant={'todo'}>
            <Typography variant={'h4'}>Mercuryo</Typography>
          </Button>
          <Button
            onClick={paymentAdvcash}
            startIcon={<Avatar variant={'rounded'} svgIcon={AdvcashIcon} />}
            variant={'todo'}>
            <Typography variant={'h4'}>Advcash</Typography>
          </Button>
          <Button
            onClick={useYourBalance}
            sx={styles.payButton}
            startIcon={
              <Avatar
                variant={'rounded'}
                svgIcon={isLightTheme ? BalanceIconLT : BalanceIcon}
              />
            }
            variant={'todo'}>
            <Typography lineHeight={'19px'} variant={'h4'}>
              Use your BUSD balance
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={styles.accordion}>
        <Accordion title={'Disclaimer'}>
          <Typography sx={{ lineHeight: '20px' }} variant={'blockSubtitle'}>
            You will now leave{' '}
            <a
              href="https://Fozeus.fr"
              style={{ color: theme.palette.common.mainColorFirst }}>
              Fozeus.fr
            </a>{' '}
            and be taken to third-party payment platform. Services related to
            credit card payments are provided and is a separate platform owned
            by a third party. Please read and agree to the Terms of Use of
            third-party payment platform before using their service. For any
            questions relating to credit card payments, please contact
            third-party payment platform via their support email, Fozeus
            Franchise does not assume any responsibility for any loss or damage
            caused by the use of the credit card payment service. By proceeding,
            you consent to Fozeus Franchise sharing your personal information on
            your Fozeus Franchise account with third-party payment platform if
            this is your first time using this platform in accordance with our
            Terms and Privacy. To your attention, after the purchase, all BUSD
            are automatically converted into FZS at the average exchange price
            for the last 24 hours high/low and are credited to your franchisee
            balance with getting into the pool. Current FZS exchange price $
            {rateFzs} To avoid market speculations, the price will be taken as
            the average between buys and sells on PancakeSwap over the last 24
            hours.
          </Typography>
        </Accordion>
      </Box>
    </GeneralCardHolder>
  );
};
