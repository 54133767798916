import { createAction } from '@reduxjs/toolkit';
import teamSlice from './slice';

export const teamActions = {
  ...teamSlice.actions,
  fetchTeamUser: createAction('team/fetchTeamUser', (payload) => {
    return {
      payload,
    };
  }),
  fetchTeam: createAction('team/fetchTeam'),
};
