import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '400px',
    padding: '19px 8px 19px 19px',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.notifications.border}`,
    background: theme.palette.common.notifications.background,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '335px',
      padding: '20px 5px 20px 20px',
    },
  }),
};
