import { Components, Theme } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsOptions } from '../breakpoints';

const breakpoints = createBreakpoints(breakpointsOptions);

export const MuiListItemButton: Partial<Components<Theme>> = {
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontFamily: 'Work Sans, sans-serif',
        fontWeight: 400,
        letterSpacing: '-0.8px',
        height: 50,
        paddingLeft: 46,
        marginBottom: 11.5,
        '&:hover, &.hover': {
          backgroundColor: 'transparent',
          '&:after': {
            content: '""',
            width: 174,
            height: 50,
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: '0px 8px 8px 0px',
            background: theme.palette.common.navbar.hover,
          },
        },
        '&.Mui-selected': {
          '& .MuiTypography-root': {
            fontWeight: 600,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          background: 'transparent',
          letterSpacing: '-0.2px',
          '&:before': {
            content: '""',
            width: 6,
            height: 50,
            position: 'absolute',
            left: 0,
            top: 0,
            background: theme.palette.common.pink,
            borderRadius: '0px 8px 8px 0px',
          },
          '&:after': {
            content: '""',
            width: 174,
            height: 50,
            position: 'absolute',
            left: 0,
            top: 0,
            background: theme.palette.common.navbar.selected,
          },
        },
      }),
    },
    variants: [
      {
        props: { dense: true },
        style: ({ theme }) => {
          return {
            [breakpoints.down('lg')]: {
              paddingLeft: '25px',
            },
            margin: 0,
            flexGrow: 0,
            paddingLeft: '30px',
            '& .MuiTypography-root': {
              fontWeight: 500,
              color: theme.palette.common.white50,
            },
            '& .MuiListItemText-root': {
              width: 'initial',
            },
            letterSpacing: '-0.2px',
            '& .MuiListItemIcon-root': {
              '& .MuiSvgIcon-root': {
                '& path': {
                  fill: theme.palette.common.white50,
                },
              },
            },
            '&.Mui-selected': {
              ...theme.mixins.menuItemDense,
              background: 'none',
              backdropFilter: 'none',
              letterSpacing: '-0.2px',
              '& .MuiTypography-root': {
                fontWeight: 500,
                color: theme.palette.common.white,
              },
              '& .MuiListItemIcon-root': {
                '& .MuiSvgIcon-root': {
                  '& path': {
                    fill: theme.palette.common.birch,
                  },
                },
              },
              '&:after': {
                ...theme.mixins.cardBefore,
                background: 'none',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              },
              '&:before': {
                content: 'unset',
              },
            },
          };
        },
      },
    ],
  },
};
