// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  RoyaltyFeesState,
  FillRoyaltyFeesActionPayload,
  IRoyaltyFeesItem,
} from './typedefs';
import { emptyPaginationState, WithPagination } from '../../setup/typedefs';

const initialState: RoyaltyFeesState = {
  isFetching: false,
  isInitialized: true,
  royaltyFees: emptyPaginationState as WithPagination<IRoyaltyFeesItem[]>,
};

const royaltyFeesSlice = createSlice({
  name: 'royaltyFees',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillRoyaltyFees(
      state,
      action: PayloadAction<FillRoyaltyFeesActionPayload>,
    ) {
      state.royaltyFees = action.payload;
    },
    // inject
  },
});

export default royaltyFeesSlice;
