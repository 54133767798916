import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import { styles } from './styles';

const HeaderLinkIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#A3AED0',
}) => {
  return (
    <SvgIcon sx={styles.link} viewBox={'0 0 18 18'} width={'18'} height={'18'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6573 3.34352C13.4857 2.17195 11.5862 2.17195 10.4146 3.34352L9.00036 4.75774C8.60986 5.14826 7.97666 5.14826 7.58616 4.75774C7.19566 4.36721 7.19566 3.73405 7.58616 3.34352L9.00036 1.92931C10.953 -0.0233113 14.1189 -0.0233113 16.0715 1.92931C18.0241 3.88193 18.0241 7.04772 16.0715 9.00042L14.6573 10.4146C14.2668 10.8051 13.6336 10.8051 13.2431 10.4146C12.8525 10.024 12.8525 9.39092 13.2431 9.00042L14.6573 7.58612C15.8288 6.41459 15.8288 4.51509 14.6573 3.34352ZM3.34353 14.6572C2.17196 13.4856 2.17196 11.5861 3.34353 10.4146L4.75774 9.00042C5.14827 8.60982 5.14827 7.97672 4.75774 7.58612C4.36722 7.19562 3.73405 7.19562 3.34353 7.58612L1.92931 9.00042C-0.0233113 10.953 -0.0233113 14.1188 1.92931 16.0714C3.88194 18.024 7.04776 18.024 9.00036 16.0714L10.4146 14.6572C10.8052 14.2667 10.8052 13.6335 10.4146 13.243C10.0241 12.8525 9.39086 12.8525 9.00036 13.243L7.58616 14.6572C6.4146 15.8288 4.5151 15.8288 3.34353 14.6572ZM12.536 6.87906C12.9265 6.48853 12.9265 5.85537 12.536 5.46484C12.1454 5.07432 11.5123 5.07432 11.1217 5.46484L5.46485 11.1217C5.07432 11.5122 5.07432 12.1454 5.46485 12.5359C5.85537 12.9264 6.48854 12.9264 6.87906 12.5359L12.536 6.87906Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default HeaderLinkIcon;
