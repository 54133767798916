import { Theme } from '@mui/material';

export const styles = {
  wrapper: (theme: Theme) => ({
    ...theme.mixins.card,
    [theme.breakpoints.down('xxl')]: {
      padding: '20px 20px 15px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px 15px 20px',
    },
    backdropFilter: 'none',
    '&:before': {
      ...theme.mixins.cardBefore,
    },
  }),
};
