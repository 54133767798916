export const styles = {
  root: {
    paddingTop: '26px',
    '@media (max-width: 1920px)': {
      paddingTop: '26px',
    },
  },
  background: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: -1,
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
};
