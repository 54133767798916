import { Components, Theme } from '@mui/material';

export const MuiTypography: Partial<Components<Theme>> = {
  MuiTypography: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
    variants: [
      {
        props: { variant: 'title' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '32px',
          lineHeight: '38px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h1' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '32px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h2' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h3' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '32px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h4' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h5' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '22px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h6' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h7' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'h8' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '14x',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'headerTitle' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '32px',
          lineHeight: '38px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'profile1' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 700,
          letterSpacing: '0.15px',
          fontSize: '16px',
          lineHeight: '21px',
        },
      },
      {
        props: { variant: 'profile2' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '-0.3px',
          fontSize: '14px',
          lineHeight: '14px',
          color: theme.palette.common.buttons.textDisabled,
        }),
      },
      {
        props: { variant: 'royalty' },
        style: ({ theme }) => ({
          color: theme.palette.common.buttons.pink,
        }),
      },
      {
        props: { variant: 'inherit' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '50px',
          lineHeight: '100%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'blockTitle' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '100%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'blockSubtitle' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '14px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.notifications.dateColor,
        }),
      },
      {
        props: { variant: 'blockSubtitle2' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '14px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.notifications.dateColor,
        }),
      },
      {
        props: { variant: 'blockSubtitle3' },
        style: ({ theme }) => ({
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '14px',
          letterSpacing: '0.007em',
          color: theme.palette.common.white,
        }),
      },
      {
        props: { variant: 'blockSubtitle4' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '25.4039px',
          lineHeight: '23px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.white,
        }),
      },
      {
        props: { variant: 'blockSubtitle5' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '10px',
          lineHeight: '12px',
          letterSpacing: '-0.02em',
          textTransform: 'uppercase',
          opacity: '0.8',
          color: theme.palette.common.white,
        }),
      },
      {
        props: { variant: 'blockSubtitle6' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '15px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.notifications.dateColor,
        }),
      },
      {
        props: { variant: 'blockSubtitle7' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.white,
        }),
      },
      {
        props: { variant: 'blockSubtitle8' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.notifications.dateColor,
        }),
      },
      {
        props: { variant: 'blockSubtitle9' },
        style: ({ theme }) => ({
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '0.005em',
          color: theme.palette.common.notifications.dateColor,
        }),
      },
      {
        props: { variant: 'blockRank' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '14px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.rank,
          textShadow: `0px 0px 7px ${theme.palette.common.rankShadow}`,
        }),
      },
      {
        props: { variant: 'smallText' },
        style: ({ theme }) => ({
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '16px',
          letterSpacing: '-0.02em',
          color: theme.palette.common.notifications.dateColor,
        }),
      },
      {
        props: { variant: 'textRegular' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '140%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'level' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '100%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'rankInCircle' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '140px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'availableSum' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '40px',
          lineHeight: '40px',
          letterSpacing: '-2%',
        },
      },
      {
        props: { variant: 'subText' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '24px',
          letterSpacing: '-2%',
        },
      },
      {
        props: { variant: 'miniCardTitle' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '-2%',
        },
      },
      {
        props: { variant: 'compareDiagram' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '16px',
        },
      },
      {
        props: { variant: 'mobileH2' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
        },
      },
      // updated
      {
        props: { variant: 'Headlines/H7' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Body/Table/Basic 15' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '16px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Body/Table/Basic 13' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '16px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H5' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '23px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H6' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '21px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Button/Button 16' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Body/Table/Basic 14' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H4' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '120%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H9' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H10' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H11' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '13px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Button/Button 14' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Input/Input 15' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '130%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Body/Paragraph/Basic 16' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '140%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Body/Paragraph/Basic 14 Text' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '140%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H8' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H3' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '32px',
          lineHeight: '120%',
          letterSpacing: '-0.02em',
        },
      },
      // {
      //   props: { variant: 'Body/Table/Basic 13' },
      //   style: {
      //     fontFamily: 'Work Sans, sans-serif',
      //     fontStyle: 'normal',
      //     fontWeight: 400,
      //     fontSize: '13px',
      //     lineHeight: '16px',
      //     letterSpacing: '-2%',
      //   },
      // },
      {
        props: { variant: 'Tag/ Uppercase 12' },
        style: {
          textTransform: 'uppercase',
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
      {
        props: { variant: 'Body/Form UPPERCASE 14' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '16px',
          textTransform: 'uppercase',
          opacity: 0.5,
        },
      },
      {
        props: { variant: 'Input/Title 12' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '100%',
          // textTransform: 'uppercase',
          opacity: 0.5,
        },
      },
      {
        props: { variant: 'poppins20' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '1',
        },
      },
      {
        props: { variant: 'Headlines/H2' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '40px',
          lineHeight: '120%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Headlines/H1' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '50px',
          lineHeight: '100%',
          letterSpacing: '-0.02em',
        },
      },
      {
        props: { variant: 'Poppins' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
        },
      },
      {
        props: { variant: 'Poppins600' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '16px',
        },
      },
      {
        props: { variant: 'Poppins400' },
        style: ({ theme }) => ({
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '14.4px',
          },
        }),
      },
      {
        props: { variant: 'Poppins600/2' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
      {
        props: { variant: 'Poppins400/small' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14.4px',
        },
      },
      {
        props: { variant: 'Poppins600/2/small' },
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '14.4px',
        },
      },
      {
        props: { variant: 'Button/Link' },
        style: {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.02em',
        },
      },
    ],
  },
};
