import React from 'react';
import AddNewMerchantForm from '../../../../Forms/AddNewMerchantForm';
import { schema } from '../../../../Forms/AddNewMerchantForm/schema';
import FinalForm from '../../../FinalForm';
import { useDispatch } from 'react-redux';
import { merchantsActions } from '../../../../bus/merchants/actions';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-2, 42],
    blur: 40,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [97, 88],
    size: 140,
    blur: 50,
  }),
];

export const AddNewMerchant = () => {
  const dispatch = useDispatch();

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={AddNewMerchantForm}
        sagaAction={merchantsActions.createNewMerchant}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(merchantsActions.fetchNotConfirmMerchants({ status: 0 }));
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
