import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { CheckboxAndLabel } from '../../../components/ui-kit/inputs/CheckboxAndLabel';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderDirectDepositField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <CheckboxAndLabel
      label={'Direct deposit'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
      input={input}
    />
  );
};
