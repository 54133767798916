import { Theme } from '@mui/material';

export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 44,
    background: (theme: Theme) =>
      theme.palette.common.header.themeSwitcherBackground,
    backdropFilter: 'blur(130px)',
    border: '1px solid transparent',
    borderRadius: '16px',
    position: 'relative',
  },
  tabs: {
    '&.MuiTabs-root': {
      minHeight: 'auto',
    },
    '& .MuiTabs-indicator': {
      height: 36,
      width: 36,
      zIndex: -1,
      borderRadius: '13px',
      background: ({ palette }: Theme) =>
        palette.common.header.themeSwitcherIconActiveBackground,
    },
  },
  tab: {
    '&.MuiTab-root': {
      padding: '0px',
      minWidth: 36,
      minHeight: 36,
    },
  },
};
