import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { uiActions } from '../../../../bus/ui/actions';
import { schema } from '../../../../Forms/EditResourceForm/schema';
import FinalForm from '../../../FinalForm';
import { EditResourceForm } from '../../../../Forms/EditResourceForm';
import { resourcesActions } from '../../../../bus/resources/actions';
import { getOneResource } from '../../../../bus/resources/selectors';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type CreateNewResourceProps = {
  slug: string;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-3, 23],
  }),
];

export const EditResource: React.FC<CreateNewResourceProps> = ({ slug }) => {
  const dispatch = useDispatch();

  const { resource } = useSelector(getOneResource(slug));

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    dispatch(
      resourcesActions.editResource({
        ...payload,
        values: {
          content: payload.values.content,
          slug: payload.values.slug,
          title: payload.values.title,
          external_url: payload.values.external_url,
          attachment: 1,
          thumbnail: payload.values.thumbnail,
        },
      }),
    );
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={resource ? resource : {}}
        component={EditResourceForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
