import { apply, call, put } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* twoFactorAuthSettings({
  payload,
}: PayloadAction<{
  enabled: boolean;
}>) {
  try {
    yield put(authActions.startFetching());

    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `users/two-factor-auth-settings/`, body },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(authActions.stopFetching());
  }
}
