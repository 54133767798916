export const useScrollTo = (): {
  scrollTop: (to: number) => void;
  scrollToBottom: () => void;
} => {
  const scrollTop = (to = 0) => {
    window.scroll({
      top: to,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    window.scroll({
      top: window.document.body.offsetHeight,
      behavior: 'smooth',
    });
  };

  return { scrollTop, scrollToBottom };
};
