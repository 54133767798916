import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const FaqIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color, fontSize }) => {
  return (
    <SvgIcon
      sx={{ fontSize: fontSize, width: '24px', height: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        d="M10.834 19.0007H13.1673V16.6673H10.834V19.0007ZM12.0007 0.333984C5.56065 0.333984 0.333984 5.56065 0.333984 12.0007C0.333984 18.4407 5.56065 23.6673 12.0007 23.6673C18.4407 23.6673 23.6673 18.4407 23.6673 12.0007C23.6673 5.56065 18.4407 0.333984 12.0007 0.333984ZM12.0007 21.334C6.85565 21.334 2.66732 17.1457 2.66732 12.0007C2.66732 6.85565 6.85565 2.66732 12.0007 2.66732C17.1457 2.66732 21.334 6.85565 21.334 12.0007C21.334 17.1457 17.1457 21.334 12.0007 21.334ZM12.0007 5.00065C9.42232 5.00065 7.33398 7.08898 7.33398 9.66732H9.66732C9.66732 8.38398 10.7173 7.33398 12.0007 7.33398C13.284 7.33398 14.334 8.38398 14.334 9.66732C14.334 12.0007 10.834 11.709 10.834 15.5007H13.1673C13.1673 12.8757 16.6673 12.584 16.6673 9.66732C16.6673 7.08898 14.579 5.00065 12.0007 5.00065Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default FaqIcon;
