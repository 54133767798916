import {
  WEBSOCKET_BROKEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_OPEN,
  WEBSOCKET_SEND,
  DEFAULT_PREFIX,
} from '@giantmachines/redux-websocket';

export const types = {
  OPEN: `${DEFAULT_PREFIX}::${WEBSOCKET_OPEN}`,
  CLOSED: `${DEFAULT_PREFIX}::${WEBSOCKET_CLOSED}`,
  SEND: `${DEFAULT_PREFIX}::${WEBSOCKET_SEND}`,
  DISCONNECT: `${DEFAULT_PREFIX}::${WEBSOCKET_DISCONNECT}`,
  BROKEN: `${DEFAULT_PREFIX}::${WEBSOCKET_BROKEN}`,
  MESSAGE: `${DEFAULT_PREFIX}::${WEBSOCKET_MESSAGE}`,
  CONNECT: `${DEFAULT_PREFIX}::${WEBSOCKET_CONNECT}`,
};
