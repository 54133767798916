import { number, object, string } from 'yup';

export const schema = object().shape({
  owner_id: string(),
  currency: string().required('Currency is required field'),
  amount: string().required('Amount is required field'),
  password: string().required('Transaction password is required field'),
  card: string().required('Wallet address is required field'),
  available: string(),
  percent: number(),
  network: string().required('Network is required field'),
});
