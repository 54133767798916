import { Box, TextField } from '@mui/material';
import React from 'react';
import DatePickerIcon from '../../Icons/DatePicerIcon';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { styles } from './styles';
import LeftArrowCalendarIcon from '../../Icons/LeftArrowCalendarIcon';
import RightArrowCalendarIcon from '../../Icons/RightArrowCalendarIcon';
import { Placement } from '@popperjs/core/lib/enums';
import moment from 'moment';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';
import DatePickerIconLT from '../../Icons/lightTeme/DatePicerIconLT';

type DatePickerProps = {
  label: string;
  onChange: (date: moment.Moment | null) => void;
  helperText?: string;
  value: any;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  placement?: Placement;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  defaultValue?: moment.Moment;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  disabled = false,
  error = false,
  label,
  helperText,
  fullWidth = false,
  placement = 'bottom-start', // 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end'
  minDate,
  maxDate,
  defaultValue = moment(),
  ...rest
}) => {
  const isLightTheme = useIsLightTheme();
  const CalendarIcon = () =>
    isLightTheme ? (
      <DatePickerIconLT
        onClick={() => {
          !value && onChange(defaultValue || null);
        }}
      />
    ) : (
      <DatePickerIcon
        onClick={() => {
          !value && onChange(defaultValue || null);
        }}
      />
    );

  return (
    <Box sx={styles.root}>
      <MuiDatePicker
        disabled={disabled}
        minDate={minDate && moment(minDate)}
        maxDate={maxDate && moment(maxDate)}
        mask={'__.__.____'}
        views={['year', 'month', 'day']}
        label={label}
        PopperProps={{
          placement,
          disablePortal: Boolean(process.env.IS_STORYBOOK),
          sx: styles.paper,
        }}
        value={value}
        showDaysOutsideCurrentMonth
        inputFormat={'DD.MM.YYYY'}
        openTo={'year'}
        components={{
          OpenPickerIcon: CalendarIcon,
          LeftArrowIcon: LeftArrowCalendarIcon,
          RightArrowIcon: RightArrowCalendarIcon,
        }}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            error={error}
            fullWidth={fullWidth}
            {...rest}
          />
        )}
      />
    </Box>
  );
};
