import { Box, FormHelperText, Typography, useTheme } from '@mui/material';
import { Link as UiLink } from '../../components/ui-kit/typography/Link';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { RenderPasswordField } from './fields/RenderPasswordField';
import { Button } from '../../components/ui-kit/Button';
import { RenderEmailField } from './fields/RenderEmailField';
import { RenderRepeatPasswordField } from './fields/RenderRepeatPasswordField';
import { RenderFirstNameField } from './fields/RenderFirstNameField';
import { RenderLastNameField } from './fields/RenderLastNameField';
import { RenderReferralCodeField } from './fields/RenderReferralCodeField';
import { RenderCountryField } from './fields/RenderCountryField';
import { RenderDateOfBirthField } from './fields/RenderDateOfBirthField';
import { book } from '../../routes/book';
import { useSelector } from 'react-redux';
import { getCountriesOptions } from '../../bus/options/selectors';
import RenderContactPhoneField from '../ApplyMembership/fields/RenderContactPhoneField';
import moment from 'moment';
import { styles } from './styles';

export const SignUp = (props: FormRenderProps) => {
  const theme = useTheme();
  const countries = useSelector(getCountriesOptions);

  return (
    <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
      <Box sx={styles.root}>
        <Box sx={styles.row}>
          <Field name={'first_name'} render={RenderFirstNameField} />
          <Field name={'last_name'} render={RenderLastNameField} />
        </Box>
        <Field name={'email'} render={RenderEmailField} />
        <Field name={'contact'} render={RenderContactPhoneField} />
        <Box sx={styles.rowWrap}>
          <Field
            name={'date_of_birth'}
            render={RenderDateOfBirthField}
            parse={(value) => moment(value).format('YYYY-MM-DD')}
            format={(value) => (value ? moment(value) : null)}
          />
          <Field
            name={'country'}
            render={RenderCountryField}
            parse={(value) => value?.code || ''}
            format={(value) =>
              countries.find((option: { code: any }) => option.code === value)
                ?.label || ''
            }
          />
        </Box>
        <Field name={'password'} render={RenderPasswordField} />
        <Field name={'password_repeat'} render={RenderRepeatPasswordField} />
        <Field name={'referrer_code'} render={RenderReferralCodeField} />
        <Box>
          <UiLink
            to={book.applyForMembership}
            color={theme.palette.common.birch}
            fontSize={12}>
            Apply for membership
          </UiLink>
        </Box>
        <Box mt={4} mb={1}>
          <Button
            fullWidth
            type={'submit'}
            variant={'primary'}
            color={'green'}
            role={'submit'}>
            Sign Up
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'wrap'}>
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'textRegular'}
            color={theme.palette.grey['50']}>
            By signing up, you agree to our&nbsp;
          </Typography>
          <UiLink
            to={book.generalTerms}
            color={theme.palette.common.neonPink}
            fontSize={12}>
            Terms
          </UiLink>
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'textRegular'}
            color={theme.palette.grey['50']}>
            {','}&nbsp;
          </Typography>
          <UiLink
            to={book.privacy}
            color={theme.palette.common.neonPink}
            fontSize={12}>
            Privacy
          </UiLink>
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'textRegular'}
            color={theme.palette.grey['50']}>
            {', '}and&nbsp;
          </Typography>
          <UiLink
            to={book.cookiePolicy}
            color={theme.palette.common.neonPink}
            fontSize={12}>
            Cookie Policy
          </UiLink>
        </Box>
      </Box>
    </form>
  );
};
export default SignUp;
