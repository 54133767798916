import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

type ArrowIconProps = {
  color?: string;
};

const PencilIcon: React.FC<SVGProps<SVGSVGElement> & ArrowIconProps> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon
      sx={{ fontSize: '21px', width: '21px', height: '21px' }}
      viewBox={'0 0 21 21'}
      width={'21'}
      height={'21'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8358 0.801003L1.8759 13.7609C0.390331 15.2465 0.390321 17.6551 1.87589 19.1406C3.36146 20.6262 5.77004 20.6262 7.25561 19.1406L20.2155 6.18072C21.0752 5.32105 21.0752 3.92725 20.2155 3.06758L17.9489 0.801003C17.0892 -0.0586675 15.6954 -0.0586675 14.8358 0.801003ZM12.5418 5.92345L16.25 2.21521C16.3286 2.13659 16.4561 2.13659 16.5347 2.21521L18.8013 4.48179C18.8799 4.56041 18.8799 4.68788 18.8013 4.7665L15.093 8.47475L12.5418 5.92345ZM11.1275 7.33766L3.29011 15.1751C2.58559 15.8797 2.58559 17.0219 3.29011 17.7264C3.99463 18.431 5.13688 18.431 5.8414 17.7264L13.6788 9.88895L11.1275 7.33766Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default PencilIcon;
