import { apply, call, put } from 'redux-saga/effects';
import { merchantsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillMerchantsActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchMerchants({ payload }: PayloadAction<FetchListWorkers>) {
  try {
    yield put(merchantsActions.startFetching());
    yield put(merchantsActions.clearMerchants());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `merchants/`, query: payload.query },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillMerchantsActionPayload = yield call([response, 'json']);

    yield put(merchantsActions.fillMerchants(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(merchantsActions.stopFetching());
  }
}
