import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../../../bus/ui/actions';
import { merchantsActions } from '../../../../bus/merchants/actions';
import FinalForm from '../../../FinalForm';
import EditMerchantForm from '../../../../Forms/EditMerchantForm';
import { schema } from '../../../../Forms/EditMerchantForm/schema';
import { getMerchant } from '../../../../bus/merchants/selectors';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type EditMerchantProps = {
  id: number;
  onSuccess: any;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-2, 42],
    blur: 40,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [97, 88],
    size: 140,
    blur: 50,
  }),
];

export const EditMerchant: React.FC<EditMerchantProps> = ({
  id,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const single = useSelector(getMerchant(id));

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      {single ? (
        <FinalForm
          initialValues={{ ...single, id }}
          component={EditMerchantForm}
          onSubmitSuccess={(showSnackbar) => {
            onSuccess();
            showSnackbar('Success');
            dispatch(uiActions.closeModal());
          }}
          sagaAction={merchantsActions.updateMerchant}
          schema={schema}
        />
      ) : (
        <></>
      )}
    </GeneralCardHolder>
  );
};
