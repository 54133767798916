import { apply, call, put } from 'redux-saga/effects';
import { optionsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { WithPagination } from '../../../../setup/typedefs';
import { FillRoyaltyCategoriesPayload } from '../../typedefs';

export function* fetchRoyaltyCategories() {
  try {
    yield put(optionsActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `royalty-categories/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const { results }: WithPagination<FillRoyaltyCategoriesPayload> =
      yield call([response, 'json']);

    yield put(optionsActions.fillRoyaltyCategories(results));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(optionsActions.stopFetching());
  }
}
