import { Box, List, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { EffectFunc, GeneralCardHolder } from '../../ui-kit/GeneralCardHolder';
import { styles } from './styles';
import { NewsItemType } from '../../../bus/news/typedefs';
import { SearchInput } from '../../ui-kit/inputs/SearchInput';
import { NewsItem } from '../NewsItem';
import moment from 'moment';
import { useParams } from 'react-router-dom';

type NewsListProps = {
  newsList: NewsItemType[];
  nextNews: () => void;
  handlerSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const effects: EffectFunc[] = [
  (colors) => ({
    position: ['-80px', 50],
    color: colors.secondary,
  }),
];
export const NewsList: React.FC<NewsListProps> = ({
  newsList,
  nextNews,
  handlerSearchChange,
}) => {
  const params = useParams();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      nextNews();
    }
  };

  return (
    <GeneralCardHolder effects={effects} sx={styles.root}>
      <SearchInput onChange={handlerSearchChange} fullwidth />
      <List
        onScroll={handleScroll}
        sx={[
          styles.list,
          {
            overflowY: isSM ? 'hidden' : 'scroll',
            overflowX: isSM ? 'scroll' : 'hidden',
          },
        ]}>
        <Box marginRight={isSM ? 0 : '26px'} sx={styles.listWrapper}>
          {newsList?.map((news) => (
            <NewsItem
              key={news.id}
              title={news.title}
              date={moment(news.created_at).format('DD MMM YYYY')}
              image={news.thumbnail}
              excerptBody={news.excerpt}
              isSeen={news.is_read}
              isChosen={params.slug === news.slug}
              slug={news.slug}
            />
          ))}
        </Box>
      </List>
    </GeneralCardHolder>
  );
};
