import { Theme } from '@mui/material';

export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'max-content',
    background: (theme: Theme) => theme.palette.common.tabs.background,
    backdropFilter: 'blur(130px)',
    border: `1px solid ${(theme: Theme) =>
      theme.palette.common.inputs.defaultBorder}`,
    borderRadius: '12px',
    padding: '2px',
  },
  tabs: {
    '&.MuiTabs-root': {
      minHeight: 'auto',
    },
    '& .MuiTabs-indicator': {
      height: 36,
      width: '100%',
      zIndex: -1,
      borderRadius: '9px',
      background: (theme: Theme) =>
        theme.palette.common.apexChart.activeTabBackground,
    },
  },
  tab: (theme: Theme) => ({
    '&.MuiTab-root': {
      color: theme.palette.common.apexChart.labelColor,
      padding: '0px 17px 0 17px',
      minWidth: 36,
      minHeight: 36,
      textTransform: 'initial',
    },
    '&.Mui-selected': {
      color: theme.palette.common.apexChart.activeLabelColor,
    },
  }),
};
