import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const DatePickerIconLT: React.FC<SVGProps<SVGSVGElement>> = ({ onClick }) => {
  return (
    <SvgIcon
      onClick={onClick}
      sx={{ fontSize: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C7.44772 2 7 2.44772 7 3V4H6C4.34315 4 3 5.34315 3 7V9V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9V7C21 5.34314 19.6569 4 18 4H17V3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3C9 2.44772 8.55228 2 8 2ZM16 6H8H6C5.44772 6 5 6.44772 5 7V8H19V7C19 6.44772 18.5523 6 18 6H16ZM5 19V10H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19Z"
        fill="url(#paint0_linear_14410_10087)"
      />
      <path d="M9 11H7V13H9V11Z" fill="url(#paint1_linear_14410_10087)" />
      <path d="M9 14H7V16H9V14Z" fill="url(#paint2_linear_14410_10087)" />
      <path d="M9 17H7V19H9V17Z" fill="url(#paint3_linear_14410_10087)" />
      <path d="M13 17H11V19H13V17Z" fill="url(#paint4_linear_14410_10087)" />
      <path d="M13 14H11V16H13V14Z" fill="url(#paint5_linear_14410_10087)" />
      <path d="M13 11H11V13H13V11Z" fill="url(#paint6_linear_14410_10087)" />
      <path d="M17 17H15V19H17V17Z" fill="url(#paint7_linear_14410_10087)" />
      <path d="M17 14H15V16H17V14Z" fill="url(#paint8_linear_14410_10087)" />
      <path d="M17 11H15V13H17V11Z" fill="url(#paint9_linear_14410_10087)" />
      <defs>
        <linearGradient
          id="paint0_linear_14410_10087"
          x1="21"
          y1="17.3"
          x2="3.36266"
          y2="12.7188"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14410_10087"
          x1="9"
          y1="12.53"
          x2="7.06893"
          y2="11.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14410_10087"
          x1="9"
          y1="15.53"
          x2="7.06893"
          y2="14.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14410_10087"
          x1="9"
          y1="18.53"
          x2="7.06893"
          y2="17.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14410_10087"
          x1="13"
          y1="18.53"
          x2="11.0689"
          y2="17.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_14410_10087"
          x1="13"
          y1="15.53"
          x2="11.0689"
          y2="14.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_14410_10087"
          x1="13"
          y1="12.53"
          x2="11.0689"
          y2="11.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_14410_10087"
          x1="17"
          y1="18.53"
          x2="15.0689"
          y2="17.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_14410_10087"
          x1="17"
          y1="15.53"
          x2="15.0689"
          y2="14.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_14410_10087"
          x1="17"
          y1="12.53"
          x2="15.0689"
          y2="11.9727"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default DatePickerIconLT;
