import { apply, call, put } from 'redux-saga/effects';
import { royaltyFeesActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers, WithPagination } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import { IRoyaltyFeesItem } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchRoyaltyFees({
  payload,
}: PayloadAction<FetchListWorkers>) {
  try {
    yield put(royaltyFeesActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `transactions/`, query: payload.query },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: WithPagination<IRoyaltyFeesItem[]> = yield call([
      response,
      'json',
    ]);

    yield put(royaltyFeesActions.fillRoyaltyFees(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(royaltyFeesActions.stopFetching());
  }
}
