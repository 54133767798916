import { Theme } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsOptions } from '../../../themes/dark/breakpoints';

const breakpoints = createBreakpoints(breakpointsOptions);

export const styles = {
  root: (theme: Theme) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    flexShrink: 0,
    height: '100%',
    overflow: 'hidden',
    zIndex: 30101010,
    width: '300px',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    border: '1px solid transparent',
    borderImageSlice: 1,
    background: theme.palette.common.sidebar.background,
    borderImageSource: theme.palette.common.sidebar.border,
    backdropFilter: 'blur(130px)',
    transition: 'all 0.6s cubic-bezier(0,0,0.2,1)',
  }),
  logoWrapper: {
    padding: '20px',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
    '& svg': {
      width: '140px',
      height: '45px',
    },
  },
  closeButton: {
    height: '36px',
    minWidth: 'initial',
    maxHeight: 'initial',
    position: 'absolute',
    top: '26px',
    right: '13px',
    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: '24px',
    },
  },
  logoWrapperSmall: {
    paddingLeft: '10px',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  collapseButtonWrapper: {
    display: 'flex',
  },
  menu: {
    overflow: 'hidden',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
    width: '298px',
  },
  menuCollapsed: {
    width: '117px',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  scrollbar: {
    overflowY: 'auto',
    height: 'calc(100% - 68px)',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  collapsed: {
    left: '-300px',
    [breakpoints.down('sm')]: {
      left: '-100%',
    },
  },
  menuWrapper: {
    height: '100%',
    '&: div': {
      overflowX: 'hidden !important',
    },
    '& .ps__rail-x': {
      display: 'none',
    },
    '& .ps__rail-y': {
      display: 'none',
    },
  },
};
