import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import CopyrightFooter from '../../../components/ui-kit/CopyrightFooter';
import { styles } from './styles';

export type CommonPagesLayoutProps = {};

const CommonPagesLayout: React.FC<CommonPagesLayoutProps> = () => {
  const themes = useTheme();
  const isMD = useMediaQuery(themes.breakpoints.down('md'));

  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={styles.root}
        overflow={'hidden'}
        boxSizing={'border-box'}
        // pb={'28px'}
        zIndex={1}
        minHeight={'100vh'}
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        position={'relative'}
        alignItems={'center'}>
        <Box p={isMD ? 2.5 : 3.75}>
          <Outlet />
        </Box>
        <CopyrightFooter />
      </Box>
    </Container>
  );
};

export default CommonPagesLayout;
