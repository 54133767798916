import React, { lazy } from 'react';
// imports
import { book } from '../../../routes/book';
import { AppRoute } from '../../../setup/typedefs';
import SettingsLayout, { SettingsLayoutProps } from './index';
const SettingsVerify = lazy(
  () => import('../../../pages/private/SettingsVerify'),
);
const SettingsPayment = lazy(
  () => import('../../../pages/private/SettingsPayment'),
);
const SettingsSecurity = lazy(
  () => import('../../../pages/private/SettingsSecurity'),
);
const SettingsProfile = lazy(
  () => import('../../../pages/private/SettingsProfile'),
);
const SettingsCompany = lazy(
  () => import('../../../pages/private/SettingsCompany'),
);

export const settingsRoutes: AppRoute<SettingsLayoutProps>[] = [
  // inject
  {
    path: book.settingsProfile,
    Component: <SettingsProfile />,
    Layout: SettingsLayout,
    layoutProps: {
      title: 'Settings',
    },
  },
  {
    path: book.settingsCompany,
    Component: <SettingsCompany />,
    Layout: SettingsLayout,
    layoutProps: {
      title: 'Settings',
    },
  },
  {
    path: book.settingsPayment,
    Component: <SettingsPayment />,
    Layout: SettingsLayout,
    layoutProps: {
      title: 'Settings',
    },
  },
  {
    path: book.settingsSecurity,
    Component: <SettingsSecurity />,
    Layout: SettingsLayout,
    layoutProps: {
      title: 'Settings',
    },
  },
  {
    path: book.settingsVerify,
    Component: <SettingsVerify />,
    Layout: SettingsLayout,
    layoutProps: {
      title: 'Settings',
    },
  },
];
