import { Theme } from '@mui/material';

export const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    flexShrink: 0,
    zIndex: 3,
    width: '300px',
    border: '1px solid transparent',
    borderImageSlice: 1,
    background: ({ palette }: Theme) => palette.common.sidebar.background,
    borderImageSource: ({ palette }: Theme) => palette.common.sidebar.border,
    backdropFilter: 'blur(130px)',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  logoWrapper: {
    position: 'relative',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  logoWrapperSmall: {
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  collapseButtonWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    transform: 'translate(50%, 50%)',
    zIndex: 1,
  },
  collapseButtonWrapperLight: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(50%, 50%)',
    zIndex: 1,
  },
  menu: {
    overflow: 'hidden',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
    width: '298px',
    paddingBottom: '40px',
  },
  menuCollapsed: {
    width: '117px',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  scrollbar: {
    overflowY: 'auto',
    height: 'calc(100vh - 100px)',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
  collapsed: {
    width: '117px',
  },
  menuWrapper: {
    position: 'relative',
    '&: div': {
      overflowX: 'hidden !important',
    },
    '& .ps__rail-x': {
      display: 'none',
    },
    '& .ps__rail-y': {
      display: 'none',
    },
  },
};
