import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    '& > div': {
      '&:nth-of-type(2)': {
        margin: '12px 0 30px 0',
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '60px 24px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 20px',
      width: 'calc(100% - 40px)',
    },
  }),
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > button': {
      minWidth: '103px',
    },
  },
  amount: (theme: Theme) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '3fr 1fr',
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      gridTemplateColumns: '3fr 2fr',
    },
    border: (theme: Theme) => `1px solid ${theme.palette.common.white20}`,
    borderRadius: '8px',
    background: (theme: Theme) => theme.palette.common.buttons.todoBackground05,
    '&:hover': {
      border: (theme: Theme) => theme.palette.common.buttons.whiteHover,
    },
    '& .MuiOutlinedInput-root': {
      background: 'unset',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0!important',
      },
    },
    '& .MuiSelect-outlined': {
      paddingTop: '16px',
    },
    '& .MuiSvgIcon-root': {
      top: 'calc(50% - 2px)',
    },
  }),
  focus: (theme: Theme) => ({
    color: theme.palette.common.birch,
    background: theme.palette.common.buttons.birchBackground,
    '&:before': {
      background: theme.palette.common.buttons.birchOpacity,
      opacity: 1,
    },
    '&:after': {
      background: theme.palette.common.birch,
      opacity: 0,
    },
  }),
};
