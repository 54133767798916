import { call, put } from 'redux-saga/effects';
import { authActions } from '../../../auth/actions';
import { book } from '../../../../routes/book';
import * as Sentry from '@sentry/react';
import { history } from '../../../../setup/configureStore';

export function* handleError(error: any) {
  console.log(error);
  process.env.NODE_ENV !== 'development' && Sentry.captureException(error);
  if (error.cause?.status_code === 401 && error.cause?.details.code) {
    yield put(authActions.clearData());
    localStorage.setItem('snackbar_message', error.cause.details.detail);
    yield call(history.push, book.signIn);
  }
}
