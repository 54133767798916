import { apply, call, put } from 'redux-saga/effects';
import { dashboardActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillStatisticsChartActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchStatisticsChart({
  payload,
}: PayloadAction<'daily' | 'weekly' | 'monthly'>) {
  try {
    yield put(dashboardActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `dashboard/statistics-chart/`, query: `period=${payload}` },
    ]);
    const data: FillStatisticsChartActionPayload = yield call([
      response,
      'json',
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    yield put(dashboardActions.fillStatisticsChart(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(dashboardActions.stopFetching());
  }
}
