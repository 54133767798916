import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminFeesIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0095 5.55651C22.6929 6.23993 22.6929 7.34796 22.0095 8.03138L8.00501 22.0358C7.3216 22.7192 6.21356 22.7192 5.53014 22.0358C4.84672 21.3524 4.84672 20.2444 5.53014 19.561L19.5346 5.55651C20.218 4.87309 21.326 4.87309 22.0095 5.55651Z"
        fill="url(#paint0_linear_14271_87565)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7597 19.2953C23.694 20.26 23.6776 21.7969 22.7229 22.7414L22.1335 22.1456L22.7229 22.7414C21.7683 23.6859 20.2313 23.6859 19.2767 22.7414L20.2612 21.7463L19.2767 22.7414C18.322 21.7969 18.3056 20.26 19.2399 19.2953C19.7014 18.8188 20.3364 18.5498 20.9998 18.5498C21.6632 18.5498 22.2982 18.8188 22.7597 19.2953Z"
        fill="url(#paint1_linear_14271_87565)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75972 5.29534C9.69398 6.25999 9.67758 7.79694 8.72295 8.74143C7.76832 9.68593 6.23129 9.68593 5.27666 8.74143C4.32203 7.79694 4.30564 6.25999 5.23989 5.29534C5.7014 4.81881 6.33643 4.5498 6.99981 4.5498C7.66318 4.5498 8.29821 4.81881 8.75972 5.29534Z"
        fill="url(#paint2_linear_14271_87565)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87565"
          x1="22.522"
          y1="18.4349"
          x2="5.62083"
          y2="13.5571"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14271_87565"
          x1="23.4498"
          y1="22.2983"
          x2="18.7187"
          y2="20.9329"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14271_87565"
          x1="9.44981"
          y1="8.29831"
          x2="4.71867"
          y2="6.93287"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminFeesIconLT;
