import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getIsSidebarOpen } from '../../../../bus/ui/selectors';
import { Modals } from '../../../../components/Modal';
import { styles } from './styles';
import { Navbar } from '../Navbar';
import { MobileTitle } from '../../AppLayout/MobileTitle';
import { Grid } from '../../../../components/ui-kit/Grid';
import { Header } from '../../../../components/ui-kit/Header';
import CopyrightFooter from '../../../../components/ui-kit/CopyrightFooter';

type ContentProps = {
  title: string;
};

export const Content: React.FC<ContentProps> = ({ title }) => {
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  return (
    <Box sx={[styles.root, !isSidebarOpen && styles.sidebarCollapsed]}>
      <Header title={title} />
      {isSM && <MobileTitle title={title} />}
      <Box
        p={isSM ? 2.5 : 3.75}
        minHeight={`calc(100vh - ${isSM ? '250px' : '155px'})`}>
        <Navbar />
        <Grid container>
          <Grid item xl={5} lg={7} md={12} xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
      <Modals />
      <CopyrightFooter />
    </Box>
  );
};
