export const book = {
  technicalPage: '/technical',
  adminApplications: '/admin-applications',
  achievements: '/achievements',
  home: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  applyForMembership: '/apply-for-membership',
  almostReady: '/almost-ready',
  verificationCode: '/verification-code',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  setPassword: '/set-password',
  checkYouEmail: '/check-you-email',
  successSignUp: '/success-sign-up',
  terms: '/terms',
  privacy: '/privacy',
  cookiePolicy: '/cookie-policy',
  generalTerms: '/general-terms',
  termsOfUse: '/terms-of-use',
  franchiseTerms: '/franchise-terms',
  confirmEmail: '/confirm-email',
  thankYou: '/thank-you',
  dashboard: '/dashboard',
  team: '/team',
  profileSettings: '/profile-settings',
  news: '/news',
  pools: '/pools',
  products: '/products',
  balance: '/balance',
  merchants: '/merchants',
  resources: '/resources',
  faq: '/faq',
  ventureCapital: '/ventureCapital',
  royaltyFees: '/royalty-fees',
  settings: '/settings',
  settingsProfile: '/settings-profile',
  settingsCompany: '/settings-company',
  settingsPayment: '/settings-payment',
  settingsSecurity: '/settings-security',
  settingsVerify: '/settings-verify',
  adminUsers: '/admin-users',
  adminTransactions: '/admin-transactions',
  adminWithdrawals: '/admin-withdrawals',
  adminMerchants: '/admin-merchants',
  adminFees: '/admin-fees',
  adminFaq: '/admin-faq',
  adminResources: '/admin-resources',
  adminProducts: '/admin-products',
  adminNews: '/admin-news',
  addNews: '/admin-news/add',
  editNews: '/admin-news/edit',
};
