import React from 'react';
import { getFieldError } from '../../../helpers/getFieldError';
import { FieldRenderProps } from 'react-final-form';
import { InputWithIcon } from '../../../components/ui-kit/inputs/InputWithIcon';
import Convert from '../../../components/ui-kit/Icons/Convert';
import { useTheme } from '@mui/material';
import { styles } from '../styles';

export const RenderNetworkField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const theme = useTheme();

  return (
    <InputWithIcon
      fullWidth
      label={'Network'}
      sx={styles.root}
      disabled
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
      icon={<Convert color={theme.palette.common.inputs.iconColor} />}
      {...input}
    />
  );
};
