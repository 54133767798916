import { date, object, ref, string } from 'yup';
import moment from 'moment';

export const schema = object().shape({
  first_name: string()
    .min(2, 'Minimum 2 symbol')
    .required('First Name is required field'),
  last_name: string()
    .min(2, 'Minimum 2 symbol')
    .required('Last Name is required field'),
  email: string().email('Wrong email').required('Email is required field'),
  date_of_birth: date()
    .max(
      moment().subtract(18, 'years').add(1, 'hour').toDate(),
      'Your age should be more than 18',
    )
    .typeError('Invalid Date')
    .required('Date of birth is required field'),
  country: string().required('Country is required field'),
  contact: string().required('Contact phone is required field'),
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .required(`Password is required field`)
    .matches(/(?=.*\d)/, {
      message: 'Password must contain at least one number',
    })
    .matches(/(?=.*[a-z])/, {
      message: 'Password must contain at least one lower case letter',
    })
    .matches(/(?=.*[A-Z])/, {
      message: 'Password must contain at least one upper case letter',
    }),
  password_repeat: string()
    .oneOf([ref('password'), null], 'Passwords do not match')
    .required(`Repeat password is required field`),
  referrer_code: string().required(`Referral Code is required field`),
});
