import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import FinalForm from '../../../FinalForm';
import { WithdrawalForm } from '../../../../Forms/WithdrawalForm';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceForWithdrawal } from '../../../../bus/balance/selectors';
import { getCurrentUserWalletSettings } from '../../../../bus/auth/selectors';
import { schema } from '../../../../Forms/WithdrawalForm/schema';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { transactionsActions } from '../../../../bus/transactions/actions';
import { formatToUSLocale } from '../../../../helpers/formating';
import { uiActions } from '../../../../bus/ui/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type WithdrawalProps = {
  id: number;
  onSuccess: () => void;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-3, 70],
    size: 120,
    blur: 40,
  }),
  (colors) => ({
    color: colors.violet,
    position: [104, 25],
    blur: 40,
  }),
];

export const Withdrawal: React.FC<WithdrawalProps> = ({ id, onSuccess }) => {
  const dispatch = useDispatch();
  const wallets = useSelector(getBalanceForWithdrawal);
  const walletSettings = useSelector(getCurrentUserWalletSettings);
  const [walletData, setWalletData] = useState<any>();

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    const values = {
      transaction: {
        owner_id: payload.values.owner_id,
        currency: payload.values.currency,
        amount: payload.values.amount,
      },
      password: payload.values.password,
      card: payload.values.card,
    };
    dispatch(transactionsActions.postUserWithdraw({ ...payload, values }));
  };
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  useEffect(() => {
    if (id) {
      const tmp = wallets.find((item) => item.id === id);
      setWalletData(tmp);
    }
  }, [id]);

  const onSubmitSuccess = () => {
    dispatch(
      uiActions.modal({
        modalName: 'ThankYou',
        modalPayload: {
          isOutSideClicked: true,
        },
      }),
    );
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      {walletData && (
        <>
          <Box
            marginBottom={'30px'}
            display={'flex'}
            justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                sx={{ marginBottom: '4px' }}
                variant={'Body/Table/Basic 13'}
                color={'common.gray100'}>
                Available funds
              </Typography>
              <Typography
                sx={{ color: 'common.neonPink', marginBottom: '4px' }}
                variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}>
                {formatToUSLocale(walletData.available)}{' '}
                {walletData.currencyLabel}
              </Typography>
              <Typography
                variant={'Body/Table/Basic 13'}
                color={'common.gray100'}>
                ${formatToUSLocale(walletData.subAvailable)}
              </Typography>
            </Box>
          </Box>
          <FinalForm
            initialValues={{
              available: walletData?.available,
              card: walletSettings.defaultWalletAddress,
              network: walletSettings.defaultWalletNetwork,
              currency: walletData.currency,
              owner_id: walletData.owner_id,
            }}
            component={WithdrawalForm}
            onSubmit={onSubmitHandler}
            closeModalAfterSubmitting={false}
            onSubmitSuccess={() => {
              onSuccess();
              onSubmitSuccess();
            }}
            schema={schema}
          />
        </>
      )}
    </GeneralCardHolder>
  );
};
