import { Box } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getCountriesOptions } from '../../../bus/options/selectors';
import { useSelector } from 'react-redux';
import { Autocomplete } from '../../../components/ui-kit/inputs/Autocomplete';

export const RenderCountryField: React.FC<FieldRenderProps<string>> = ({
  input,
}) => {
  const countries = useSelector(getCountriesOptions);

  return (
    <Box my={1}>
      <Autocomplete options={countries} label={'Country'} input={input} />
    </Box>
  );
};
