import { apply, put } from 'redux-saga/effects';
import { productsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { RemoveProductActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* deleteProduct({
  payload,
}: PayloadAction<RemoveProductActionPayload>) {
  try {
    yield put(productsActions.startFetching());

    const response: Response = yield apply(api, api.delete, [
      { endpoint: `products/${payload}/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    yield put(productsActions.removeProduct(payload));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(productsActions.stopFetching());
  }
}
