import { Box, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderTransactionPassField } from './fields/RenderTransactionPassField';
import { RenderAmountField } from './fields/RenderAmountField';
import { RenderPercentField } from './fields/RenderPercentField';

export const InvestPoolForm = (props: FormRenderProps) => {
  const [percent, setPercent] = useState<number | string>(0);

  useEffect(() => {
    if (props.values.percent) {
      const val = +props.values.available;
      const fixedVal = Number((val / 100) * +props.values.percent).toFixed(2);
      setPercent(fixedVal);
    }
  }, [props.values]);

  return (
    <form onSubmit={props.handleSubmit}>
      <Box sx={styles.root} width={'100%'} margin={'31px 0 0 0'}>
        <Field
          name={'amount'}
          valuePercent={percent}
          render={RenderAmountField}
          type={'number'}
        />
        <Field name={'percent'} render={RenderPercentField} />
        <Field name={'password'} render={RenderTransactionPassField} />
        <Box mt={'30px'} display={'flex'} justifyContent={'space-between'}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Invest
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
