import { Box, ClickAwayListener, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentUserProfile } from '../../../bus/auth/selectors';
import { Button } from '../../ui-kit/Button';
import ProfileArrow from '../../ui-kit/Button/ProfileArrow';
import { ProfileAction } from '../ProfileAction';
import { styles } from './styles';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

type ProfileProps = {};

export const Profile: React.FC<ProfileProps> = () => {
  const [isActive, setIsActive] = useState(false);
  const profile = useSelector(getCurrentUserProfile);
  const { pathname } = useLocation();
  const isLight = useIsLightTheme();

  useEffect(() => {
    setIsActive(false);
  }, [pathname]);

  return profile ? (
    <ClickAwayListener
      onClickAway={() => {
        setIsActive(false);
      }}>
      <Box sx={styles.root} onClick={() => setIsActive(!isActive)}>
        {profile.rank.level.image && (
          <Box position={'relative'} width={'56.47px'} height={'56.47px'}>
            {isLight && <Box sx={styles.rankImageBackground} />}
            <Box
              width={'100%'}
              component={'img'}
              alt={'profile image'}
              src={profile.rank.level.image}
            />
          </Box>
        )}
        <Box sx={styles.right}>
          {isActive && <ProfileAction />}
          <Typography sx={{ color: '#FFFFFF' }} variant={'profile1'}>
            {`${profile.first_name} ${profile.last_name}`}
          </Typography>
          <Typography variant={'profile2'}>
            {profile.rank.level.rank}
          </Typography>
        </Box>
        <Button
          variant={'square'}
          color={'none'}
          startIcon={<ProfileArrow direction={isActive} />}
        />
      </Box>
    </ClickAwayListener>
  ) : null;
};
