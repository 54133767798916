// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  VentureState,
  FillVentureActionPayload,
} from './typedefs';

const initialState: VentureState = {
  isFetching: false,
  isInitialized: true,
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const ventureSlice = createSlice({
  name: 'venture',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillVenture(state, action: PayloadAction<FillVentureActionPayload>) {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    },
    // inject
  },
});

export default ventureSlice;
