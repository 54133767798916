import React from 'react';
import FinalForm from '../../../FinalForm';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { schema } from '../../../../Forms/WithdrawApproveForm/schema';
import WithdrawApproveForm from '../../../../Forms/WithdrawApproveForm';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { styles } from './styles';
import { adminActions } from '../../../../bus/admin/actions';

type UserActivationProps = {
  id: number;
  onSuccess: () => void;
};

const effects: EffectFunc[] = [
  (colors, isSM) => ({
    color: colors.tertiary,
    position: isSM ? [105, 55] : [110, 88],
    size: isSM ? 130 : 100,
    blur: isSM ? 50 : 40,
  }),
  (colors, isSM) => ({
    color: colors.secondary,
    position: isSM ? [-10, 55] : [0, 12],
    size: 100,
    blur: 50,
  }),
];

export const WithdrawApprove: React.FC<UserActivationProps> = ({
  id,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    dispatch(
      adminActions.approveWithdrawRequest({
        ...payload,
        values: {
          password: payload.values.password,
          id,
        },
      }),
    );
  };

  const onSuccessHandler = () => {
    onSuccess();
    dispatch(uiActions.closeModal());
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={WithdrawApproveForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={onSuccessHandler}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
