import { createAction } from '@reduxjs/toolkit';
import resourcesSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const resourcesActions = {
  ...resourcesSlice.actions,
  loadMoreResources: createAction(
    'resources/loadMoreResources',
    (nextPageUrl) => {
      return {
        payload: { nextPageUrl },
      };
    },
  ),
  postResource: createAction(
    'resources/postResource',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  editResource: createAction(
    'resources/editResource',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  deleteResources: createAction('resources/deleteResources', (slug) => {
    return {
      payload: slug,
    };
  }),
  fetchResourcesCategories: createAction('resources/fetchResourcesCategories'),
  fetchResources: createAction(
    'resources/fetchResources',
    ({ categories, order, is_active, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: order === 'desc' ? '-created_at' : 'created_at',
              is_active: is_active.toString(),
            },
            new URLSearchParams(),
          ),
          isActive: is_active,
          categories,
        },
      };
    },
  ),
};
