import { apply, call, put, select } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { IProfile } from '../../typedefs';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { getCurrentUserId } from '../../selectors';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* updateProfile({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<Partial<IProfile>>) {
  try {
    yield put(authActions.startFetching());
    const body: string = yield call(JSON.stringify, payload);
    const id: number = yield select(getCurrentUserId);
    const response: Response = yield apply(api, api.patch, [
      {
        endpoint: `users/${id}/`,
        body,
      },
    ]);
    const data: ServerFormErrors | IProfile = yield call([response, 'json']);

    if (!response.ok) {
      const formErrors: FormErrors = yield call(
        getServerFormErrors,
        data as ServerFormErrors,
      );
      reject(formErrors);
      yield throwError(response);
    }

    yield put(authActions.fillProfile(data as IProfile));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(authActions.stopFetching());
  }
}
