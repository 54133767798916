import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import DeleteIcon from '../../../ui-kit/Icons/DeleteIcon';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { productsActions } from '../../../../bus/products/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { useSnackbar } from 'notistack';

type ConfirmDeleteProductProps = {
  slug: string;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-25, 66],
  }),
  (colors) => ({
    color: colors.primary,
    position: [120, 50],
    blur: 50,
  }),
];

export const ConfirmDeleteProduct: React.FC<ConfirmDeleteProductProps> = ({
  slug,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  const deleteProduct = () => {
    dispatch(productsActions.deleteProduct(slug));
    enqueueSnackbar('Successfully', { variant: 'infoSnackbar' });
    dispatch(uiActions.closeModal());
  };

  return (
    // @ts-ignore
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box maxWidth={'306px'}>
        <Typography variant={'h1'}>
          Are you really want to delete the product?
        </Typography>
      </Box>
      <Box display={'flex'} gap={'10px'}>
        <Button variant={'secondary'} onClick={closeModal}>
          <Typography variant={'h4'}>Cancel</Typography>
        </Button>
        <Button
          onClick={deleteProduct}
          startIcon={<DeleteIcon />}
          variant={'secondary'}
          color={'pink'}>
          <Typography variant={'h4'}>Delete</Typography>
        </Button>
      </Box>
    </GeneralCardHolder>
  );
};
