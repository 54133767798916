import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { NavMenuItem } from '../menu';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../../../bus/ui/actions';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';

type MenuItemProps = {
  item: NavMenuItem;
  collapsed?: boolean;
  className?: string;
  activeLink: boolean;
  dense?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  item: { icon, iconLT, label, link, onClick },
  collapsed = false,
  className,
  activeLink,
  dense,
}) => {
  const theme = useTheme();
  const isLight = useIsLightTheme();
  const dispatch = useDispatch();

  const onItemClick = () => {
    dispatch(uiActions.toggleMobileSidebar());
    onClick && label === 'Support' && onClick(dispatch, uiActions.modal);
  };

  return (
    <ListItemButton
      sx={styles.root}
      dense={dense}
      className={className}
      component={onClick ? 'button' : Link}
      to={link}
      onClick={onItemClick}
      selected={activeLink}>
      <ListItemIcon>
        {React.createElement(isLight ? iconLT || icon : icon, {
          color: theme.palette.common.birch,
        })}
      </ListItemIcon>
      <ListItemText primary={label} sx={styles.text} />
    </ListItemButton>
  );
};
