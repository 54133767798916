import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

const uiSelector = (state: RootState) => state.ui;

export const getIsUiFetching = createSelector([uiSelector], (result) => {
  return { isFetching: result.isFetching };
});
export const getTheme = createSelector([uiSelector], (result) => {
  return result.theme;
});
export const getIsSidebarOpen = createSelector([uiSelector], (result) => {
  return result.isSidebarOpen;
});
export const getIsMobileSidebarOpen = createSelector([uiSelector], (result) => {
  return result.isMobileSidebarOpen;
});
export const getIsModalOpen = createSelector([uiSelector], (result) => {
  return result.isModalOpen;
});
export const getModalData = createSelector([uiSelector], ({ modalData }) => {
  return modalData;
});
export const getTableFilter = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter;
  },
);
export const getTableFilterActivities = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.activities || [];
  },
);
export const getIsActivityChecked = (id: number) =>
  createSelector([uiSelector], ({ tableFilter }) => {
    return tableFilter?.activities.includes(id);
  });
export const getTableFilterTransactionStatuses = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.status || [];
  },
);
export const getTableFilterApplicationSources = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.source || [];
  },
);
export const getTableFilterApplicationStatuses = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.applicationStatus || [];
  },
);
export const getTableFilterTransactionTypes = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.type || [];
  },
);
export const getTableFilterCountries = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.country || [];
  },
);
export const getTableFilterCurrencies = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.currency || [];
  },
);
export const getTableFilterDate = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.dateRange || [];
  },
);
export const getTableFilterLeader = createSelector(
  [uiSelector],
  ({ tableFilter }) => {
    return tableFilter?.leader || [];
  },
);
