import React, { useEffect } from 'react';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { useSelector } from 'react-redux';
import { getMercurioData } from '../../../../bus/payment/selectors';

type PaymentChoiceProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-2, 73],
    blur: 50,
  }),
  (colors) => ({
    color: colors.violet,
    position: [100, 30],
    blur: 50,
  }),
];

export const ModalIframe: React.FC<PaymentChoiceProps> = () => {
  const data = useSelector(getMercurioData);

  useEffect(() => {
    // @ts-ignore
    if (data && !!window?.mercuryoWidget) {
      // @ts-ignore
      window.mercuryoWidget.run({
        host: document.getElementById('mercuryo-widget'),
        theme: 'xzen',
        widgetId: data.widget_id,
        merchantTransactionId: data.merchant_transaction_id,
        address: data.address,
        hideAddress: true,
        currency: 'BUSD',
        signature: data.signature,
        fixCurrency: true,
      });
    }
  }, []);

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <div style={{ height: '90vh' }} id="mercuryo-widget" />
    </GeneralCardHolder>
  );
};
