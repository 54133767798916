import { Theme } from '@mui/material';

export const styles = {
  paper: (theme: Theme) => ({
    '& .MuiPaper-root': {
      marginTop: '6px',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.common.inputs.defaultBorder}`,
      maxHeight: '239px',
      '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.common.mainColorFirst,
        borderRadius: '8px',
      },
      '.MuiList-root': {
        boxSizing: 'border-box',
        '.MuiMenuItem-root': {
          backgroundColor: 'transparent',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: 15,
          lineHeight: '100%',
          padding: '15px',
          '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.common.inputs.defaultBorder}`,
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.common.mainColorFirst,
          },
        },
        '.MuiDivider-root': {
          margin: 0,
        },
        '.Mui-selected': {
          backgroundColor: 'transparent',
          color: theme.palette.common.mainColorFirst,
          borderColor: theme.palette.common.mainColorFirst,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.common.mainColorFirst,
          },
        },
      },
    },
  }),
  icon: {
    fontSize: '14px',
    right: '21px!important',
  },
};
