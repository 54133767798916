import { createTheme, ThemeOptions } from '@mui/material/styles';
import { palette } from './palette';
import { MuiDivider } from './components/MuiDivider';
import { MuiButton } from './components/MuiButton';
import { typography } from './typography';
import { MuiTypography } from './components/MuiTypography';
import { MuiListItemButton } from './components/MuiListItemButton';
import { MuiListItemIcon } from './components/MuiListItemIcon';
import { MuiSwitch } from './components/MuiSwitch';
import { MuiOutlinedInput } from './components/MuiOutlinedInput';
import { MuiInputLabel } from './components/MuiInputLabel';
import { MuiSelect } from './components/MuiSelect';
import { MuiFormHelperText } from './components/MuiFormHelperText';
import { MuiChip } from './components/MuiChip';
import { MuiAvatar } from './components/MuiAvatar';
import { MuiLink } from './components/MuiLink';
import { MuiGrid } from './components/MuiGrid';
import { MuiTooltip } from './components/MuiTooltip';
import { breakpoints } from './breakpoints';
import { mixins } from './mixins';
import { MuiAutocomplete } from './components/MuiAutocomplete';
import { MuiTableContainer } from './components/MuiTableContainer';
import { MuiSvgIcon } from './components/MuiSvgIcon';
import { MuiDialog } from '../light/components/MuiDialog';

const themeOptions: Partial<ThemeOptions> = {
  mixins,
  breakpoints,
  palette,
  typography,
  components: {
    ...MuiButton,
    ...MuiDivider,
    ...MuiTypography,
    ...MuiListItemButton,
    ...MuiListItemIcon,
    ...MuiSwitch,
    ...MuiOutlinedInput,
    ...MuiInputLabel,
    ...MuiSelect,
    ...MuiFormHelperText,
    ...MuiChip,
    ...MuiLink,
    ...MuiAvatar,
    ...MuiGrid,
    ...MuiTooltip,
    ...MuiTableContainer,
    ...MuiAutocomplete,
    ...MuiSvgIcon,
    ...MuiDialog,
  },
};

export const dark = createTheme(themeOptions);
