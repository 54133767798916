import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Box } from '@mui/material';
import { PhoneInput } from '../../../components/ui-kit/inputs/PhoneInput';
import { getFieldError } from '../../../helpers/getFieldError';

const RenderContactPhoneField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={1}>
      <PhoneInput
        {...input}
        fullWidth
        id={'contact_phone'}
        label={'Contact Phone'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};

export default RenderContactPhoneField;
