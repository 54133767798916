import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    background: theme.palette.common.hoverInfoBackground,
    padding: '14px 16px',
    width: '167px',
    borderRadius: '12px',
    backdropFilter: 'blur(5px)',
    boxSizing: 'border-box',
    textAlign: 'center',
  }),
};
