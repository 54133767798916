import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import FinalForm from '../../../components/FinalForm';
import { schema } from '../../../Forms/SignUp/schema';
import { authActions } from '../../../bus/auth/actions';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Link as UiLink } from '../../../components/ui-kit/typography/Link';
import SignUp from '../../../Forms/SignUp';
import { book } from '../../../routes/book';
import { useNavigate, useParams } from 'react-router-dom';
import { styles } from './styles';
import { EffectFunc } from '../../../components/ui-kit/GeneralCardHolder';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [3, 94],
    size: 100,
    blur: 60,
  }),
  (colors) => ({
    color: colors.violet,
    position: [95, 45],
    size: 100,
    blur: 60,
  }),
];

const SignUpPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { referral } = useParams();

  const onSubmitSuccess = () => {
    navigate(book.checkYouEmail);
  };

  return (
    <>
      <FormBackgroundHolder effects={effects}>
        <Box
          sx={styles.root}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}>
          <Typography sx={{ zIndex: 1 }} variant={'title'}>
            Create your account
          </Typography>
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'h5'}
            color={theme.palette.grey['50']}>
            Fill out the form below
          </Typography>
          <FinalForm
            component={SignUp}
            onSubmitSuccess={onSubmitSuccess}
            sagaAction={authActions.signUp}
            initialValues={{
              referrer_code: referral,
            }}
            schema={schema}
          />
        </Box>
      </FormBackgroundHolder>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={4}
        mb={10}>
        <Typography
          mb={1}
          sx={{ opacity: 0.5 }}
          variant={'h8'}
          color={theme.palette.grey['50']}>
          Already have an account?
        </Typography>
        <UiLink to={book.signIn}>Sign in</UiLink>
      </Box>
    </>
  );
};

export default SignUpPage;
