import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as UiLink } from '../typography/Link';
import { book } from '../../../routes/book';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const CopyrightFooter = () => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const isLight = useIsLightTheme();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      mt={'auto'}
      mb={0}
      px={'20px'}
      textAlign={'center'}
      alignItems={'center'}>
      <Typography
        sx={{ opacity: 0.5 }}
        mb={'8px'}
        variant={'Body/Table/Basic 15'}
        color={!isLight ? theme.palette.grey['50'] : ''}>
        Copyright {new Date().getFullYear()} © Fozeus
      </Typography>
      <Typography
        sx={{
          opacity: 0.5,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        width={isSM ? '300px' : 'initial'}
        variant={'Body/Table/Basic 14'}
        color={isLight ? '#A29FA9' : theme.palette.grey['50']}>
        <UiLink
          tag={'h3'}
          to={book.generalTerms}
          color={isLight ? '#A29FA9' : theme.palette.grey['50']}
          onClick={() => {
            localStorage.setItem('general_terms_url', window.location.pathname);
          }}>
          General Terms&nbsp;
        </UiLink>
        {' | '}
        <UiLink
          tag={'h3'}
          to={book.privacy}
          color={isLight ? '#A29FA9' : theme.palette.grey['50']}
          onClick={() => {
            localStorage.setItem('privacy_url', window.location.pathname);
          }}>
          &nbsp;Privacy&nbsp;
        </UiLink>
        {' | '}
        <UiLink
          tag={'h3'}
          to={book.termsOfUse}
          color={isLight ? '#A29FA9' : theme.palette.grey['50']}
          onClick={() => {
            localStorage.setItem('terms_of_use_url', window.location.pathname);
          }}>
          &nbsp;Terms Of Use&nbsp;
        </UiLink>
        {!isSM && ' | '}
        <UiLink
          tag={'h3'}
          to={book.cookiePolicy}
          color={isLight ? '#A29FA9' : theme.palette.grey['50']}
          onClick={() => {
            localStorage.setItem('cookie_policy_url', window.location.pathname);
          }}>
          &nbsp;Cookie Policy&nbsp;
        </UiLink>
        {' | '}
        <UiLink
          tag={'h3'}
          to={book.franchiseTerms}
          color={isLight ? '#A29FA9' : theme.palette.grey['50']}
          onClick={() => {
            localStorage.setItem(
              'franchise_terms_url',
              window.location.pathname,
            );
          }}>
          &nbsp;Franchise Terms
        </UiLink>
      </Typography>
    </Box>
  );
};

export default CopyrightFooter;
