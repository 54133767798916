import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
      },
    }),
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-15px',
      width: 'max-content',
    },
  },
  wrapper: (theme: Theme) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '3fr 1fr',
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      gridTemplateColumns: '3fr 2fr',
    },
    border: (theme: Theme) =>
      `1px solid ${theme.palette.common.inputs.defaultBorder}`,
    borderRadius: '8px',
    background: (theme: Theme) => theme.palette.common.todoBackground05,
    '&:hover': {
      border: (theme: Theme) =>
        `1px solid ${theme.palette.common.buttons.whiteHover}`,
    },
    '& .MuiOutlinedInput-root': {
      background: 'unset',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0!important',
      },
    },
    '& .MuiSelect-outlined': {
      paddingTop: '16px',
    },
    '& .MuiSvgIcon-root': {
      top: 'calc(50% - 2px)',
    },
  }),
  button: {
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  },
};
