export const styles = {
  full: {
    fontSize: '22px',
    width: '20px',
    height: '22px',
  },
  qr: {
    fontSize: '20px',
  },
  message: {
    fontSize: '20px',
    width: '16px',
    height: '20px',
  },
  link: {
    fontSize: '18px',
    width: '18px',
    height: '18px',
  },
};
