import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      width: '100%',
      marginTop: '25px',
    },
  }),
  row: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    '& > div': {
      width: 'calc(50% - 10px)',
    },
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      gap: '10px',
    },
  }),
  rowWrap: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    flexWrap: 'wrap',
    '& > div': {
      width: 'calc(50% - 10px)',
    },
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      gap: '0',
      '& > div': {
        width: '100%',
      },
    },
  }),
};
