// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  AuthState,
  FillProfileActionPayload,
  ProfilePayment,
  FillTwoFactorQrCodeActionPayload,
} from './typedefs';

const initialState: AuthState = {
  isFetching: false,
  isInitialized: true,
  profile: null,
  isAuthenticated: false,
  allowCountdown: false,
  isInitialised: false,
  twoFactor: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    isAuthenticatedTrue(state) {
      state.isAuthenticated = true;
    },
    isAuthenticatedFalse(state) {
      state.isAuthenticated = false;
    },
    fillProfile(state, action: PayloadAction<FillProfileActionPayload>) {
      state.profile = action.payload;
    },
    allowCountdown(state, action: PayloadAction<boolean>) {
      state.allowCountdown = action.payload;
    },
    clearData(state) {
      state.profile = null;
      state.isAuthenticated = false;
      state.allowCountdown = false;
      localStorage.clear();
      sessionStorage.clear();
    },
    initialize(state) {
      state.isInitialised = true;
    },
    // fillSupport(state, action: PayloadAction<FillSupportActionPayload>) {},
    changePaymentDetails(state, action: PayloadAction<ProfilePayment>) {
      if (state.profile) {
        state.profile.payment = action.payload;
      }
    },
    fillTwoFactorQrCode(
      state,
      action: PayloadAction<FillTwoFactorQrCodeActionPayload>,
    ) {
      state.twoFactor = action.payload;
    },
    // inject
  },
});

export default authSlice;
