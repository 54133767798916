import { apply, call, put } from 'redux-saga/effects';
import { merchantsActions } from '../../actions';
import { FillSingleMerchantActionPayload } from '../../typedefs';
import { api } from '../../../../REST/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* getSingleMerchant({ payload }: PayloadAction<string>) {
  try {
    yield put(merchantsActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `merchants/${payload}/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillSingleMerchantActionPayload = yield call([
      response,
      'json',
    ]);

    yield put(merchantsActions.fillSingleMerchant(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(merchantsActions.stopFetching());
  }
}
