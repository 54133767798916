import { apply, call, put } from 'redux-saga/effects';
import { transactionsActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* convert({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(transactionsActions.startFetching());

    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `transactions/convert/`, body },
    ]);

    if (!response.ok) {
      const data: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(
        getServerFormErrors,
        data as ServerFormErrors,
      );
      reject(formErrors);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(transactionsActions.stopFetching());
  }
}
