import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { ThemeVariant } from '../../../bus/ui/typedefs';
import DarkIcon from './DarkIcon';
import LightIcon from './LightIcon';

type ThemeSwitcherProps = {
  onChange: (theme: ThemeVariant) => void;
  value: ThemeVariant;
};

function a11yProps(index: number) {
  return {
    id: `switch-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({
  onChange,
  value,
}) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(value === ThemeVariant.dark ? 0 : 1);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    onChange(tabValue === 0 ? ThemeVariant.dark : ThemeVariant.light);
  }, [tabValue]);

  return (
    <Box sx={styles.root}>
      <Tabs
        sx={styles.tabs}
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example">
        <Tab
          sx={styles.tab}
          icon={
            <DarkIcon
              color={
                tabValue === 1
                  ? theme.palette.common.header.themeSwitcherIconActive
                  : theme.palette.common.header.themeSwitcherIconRegular
              }
            />
          }
          {...a11yProps(0)}
          disableRipple
        />
        <Tab
          sx={styles.tab}
          icon={
            <LightIcon
              color={
                tabValue === 0
                  ? theme.palette.common.mildBlueYonder
                  : theme.palette.common.white
              }
            />
          }
          {...a11yProps(1)}
          disableRipple
        />
      </Tabs>
    </Box>
  );
};
