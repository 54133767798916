import { apply, call, put } from 'redux-saga/effects';
import { newsActions } from '../../actions';
import { NewsItemType } from '../../typedefs';
import { api } from '../../../../REST/api';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchOneNews({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<string>) {
  try {
    yield put(newsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `news/${payload}/` },
    ]);
    if (!response.ok) {
      reject('Post not founds');
      yield throwError(response);
    }
    const data: NewsItemType = yield call([response, 'json']);
    yield put(newsActions.fillOneNews(data));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(newsActions.stopFetching());
  }
}
