import { all, call, takeEvery } from 'redux-saga/effects';
import { newsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchNews,
  loadMoreNews,
  readNews,
  deleteNews,
  fetchOneNews,
  editNews,
  postNews,
} from './workers';

// imports
function* watchFetchNews() {
  yield takeEvery(newsActions.fetchNews.type, fetchNews);
}

function* watchLoadMoreNews() {
  yield takeEvery(newsActions.loadMoreNews.type, loadMoreNews);
}

function* watchReadNews() {
  yield takeEvery(newsActions.readNews.type, readNews);
}

function* watchDeleteNews() {
  yield takeEvery(newsActions.deleteNews.type, deleteNews);
}

function* watchFetchOneNews() {
  yield takeEvery(newsActions.fetchOneNews.type, fetchOneNews);
}

function* watchEditNews() {
  yield takeEvery(newsActions.editNews.type, editNews);
}

function* watchPostNews() {
  yield takeEvery(newsActions.postNews.type, postNews);
}
// watchers
export function* watchNews() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchPostNews),
    call(watchEditNews),
    call(watchFetchOneNews),
    call(watchDeleteNews),
    call(watchReadNews),
    call(watchLoadMoreNews),
    call(watchFetchNews),
  ]);
}
