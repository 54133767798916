import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Box, TextField } from '@mui/material';
import { getFieldError } from '../../../helpers/getFieldError';

const RenderPromotionField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={2.5}>
      <TextField
        {...input}
        fullWidth
        label={'Promotion %'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};

export default RenderPromotionField;
