import React, { SVGProps } from 'react';
import { SvgIcon, useTheme } from '@mui/material';

const RightArrowCalendarIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      sx={{ fontSize: '26px' }}
      viewBox={'0 0 26 16'}
      width={'26'}
      height={'16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 8.0011C0.75 7.76904 0.842187 7.54648 1.00628 7.38239C1.17038 7.21829 1.39294 7.1261 1.625 7.1261H22.2628L16.7555 1.62061C16.5912 1.4563 16.4989 1.23346 16.4989 1.00111C16.4989 0.768748 16.5912 0.545907 16.7555 0.381605C16.9198 0.217303 17.1426 0.125 17.375 0.125C17.6074 0.125 17.8302 0.217303 17.9945 0.381605L24.9945 7.3816C25.076 7.46288 25.1406 7.55944 25.1847 7.66575C25.2289 7.77205 25.2516 7.88601 25.2516 8.0011C25.2516 8.1162 25.2289 8.23016 25.1847 8.33646C25.1406 8.44277 25.076 8.53932 24.9945 8.6206L17.9945 15.6206C17.8302 15.7849 17.6074 15.8772 17.375 15.8772C17.1426 15.8772 16.9198 15.7849 16.7555 15.6206C16.5912 15.4563 16.4989 15.2335 16.4989 15.0011C16.4989 14.7687 16.5912 14.5459 16.7555 14.3816L22.2628 8.8761H1.625C1.39294 8.8761 1.17038 8.78392 1.00628 8.61982C0.842187 8.45573 0.75 8.23317 0.75 8.0011Z"
        fill={theme.palette.common.calendar.monthYear}
      />
    </SvgIcon>
  );
};

export default RightArrowCalendarIcon;
