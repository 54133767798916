import { all, call, takeEvery } from 'redux-saga/effects';
import { uiActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchTheme,
  modal,
  removeModal,
} from './workers';

// imports
function* watchFetchTheme() {
  yield takeEvery(uiActions.fetchTheme.type, fetchTheme);
}
function* watchModal() {
  yield takeEvery(uiActions.modal.type, modal);
}
function* watchRemoveModal() {
  yield takeEvery(uiActions.closeModal.type, removeModal);
}
// watchers
export function* watchUi() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchTheme),
    call(watchModal),
    call(watchRemoveModal),
  ]);
}
