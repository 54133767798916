import { Theme } from '@mui/material';

export const styles = {
  root: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    background: (theme: Theme) => theme.palette.common.sidebar.collapseButton,
    border: (theme: Theme) =>
      `1px solid ${theme.palette.common.sidebar.collapseButtonBorder}`,
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
  },
};
