import { apply, call, put } from 'redux-saga/effects';
import { notificationsActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';

export function* setSeen({ payload }: PayloadAction<any>) {
  try {
    yield put(notificationsActions.startFetching());
    const body: string = payload.id
      ? yield call(JSON.stringify, { items: [payload.id] })
      : yield call(JSON.stringify, { items: [] });

    const response: Response = yield apply(api, api.post, [
      {
        endpoint: `notifications/set_seen/`,
        body,
      },
    ]);

    if (response.status !== 204) {
      yield throwError(response);
    }

    yield put(notificationsActions.changeNotificationsList(payload.id));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(notificationsActions.stopFetching());
  }
}
