import { CreateNewTask } from './CreateNewTask';
import { ConfirmDeleteAllTodo } from './ConfirmDeleteAllTodo';
import { PaymentChoice } from './PaymentChoice';
import { InvestInPool } from './InvestInPool';
import { PoolsReinvestWithdraw } from './PoolsReinvestWithdraw';
import { UserDeactivation } from './UserDeactivation';
import { UserActivation } from './UserActivation';
import { UserSend } from './UserSend';
import { AddNewMerchant } from './AddNewMerchant';
import { Support } from './Support';
import { ConfirmDeleteNews } from './ConfirmDeleteNews';
import { EditFaq } from './EditFaq';
import { UserDelete } from './UserDelete';
import { CreateNewResource } from './CreateNewResource';
import { EditResource } from './EditResource';
import { ConfirmDeleteResources } from './ConfirmDeleteResources';
import { ConfirmOrCancel } from './ConfirmOrCancel';
import { CreateFaq } from './CreateFaq';
import { EditMerchant } from './EditMerchant';
import { UserDeduct } from './UserDeduct';
import { Withdrawal } from './Withdrawal';
import { ModalIframe } from './ModalIframe';
import { Transfer } from './Transfer';
import { Convert } from './Convert';
import { EditProduct } from './EditProduct';
import { ConfirmDeleteProduct } from './ConfirmDeleteProduct';
import { CreateNewProduct } from './CreateNewProduct';
import { QrCode } from './QrCode';
import { TeamUserInformation } from './TeamUserInformation';
import { ThankYou } from './ThankYou';
import { WithdrawApprove } from './WithdrawApprove';
import { WithdrawDecline } from './WithdrawDecline';
import { TwoFactorQrCode } from './TwoFactorQrCode';
import { PaymentAdvcash } from './PaymentAdvcash';
import { EnterCodeForResetTransactionPassword } from './EnterCodeForResetTransactionPassword';
import React from 'react';

export const registeredModals: Record<string, React.FC<any>> = {
  CreateNewTask,
  Withdrawal,
  ModalIframe,
  ConfirmDeleteAllTodo,
  PaymentChoice,
  PaymentAdvcash,
  InvestInPool,
  PoolsReinvestWithdraw,
  AddNewMerchant,
  EditMerchant,
  UserDeactivation,
  UserActivation,
  UserSend,
  Support,
  ConfirmDeleteNews,
  UserDelete,
  CreateNewResource,
  EditResource,
  ConfirmDeleteResources,
  EditFaq,
  ConfirmOrCancel,
  CreateFaq,
  UserDeduct,
  Transfer,
  Convert,
  EditProduct,
  ConfirmDeleteProduct,
  CreateNewProduct,
  QrCode,
  TeamUserInformation,
  ThankYou,
  WithdrawApprove,
  WithdrawDecline,
  TwoFactorQrCode,
  EnterCodeForResetTransactionPassword,
};
