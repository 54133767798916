import { createAction } from '@reduxjs/toolkit';
import dashboardSlice from './slice';

export const dashboardActions = {
  ...dashboardSlice.actions,
  fetchRoyaltyEvents: createAction('dashboard/fetchRoyaltyEvents'),
  fetchPhysicalPin: createAction('dashboard/fetchPhysicalPin'),
  fetchRanks: createAction('dashboard/fetchRanks'),
  fetchVentureInfo: createAction('dashboard/fetchVentureInfo'),
  fetchVentureTimer: createAction('dashboard/fetchVentureTimer'),
  fetchStatisticsChart: createAction(
    'dashboard/fetchStatisticsChart',
    (payload) => {
      return {
        payload,
      };
    },
  ),
  fetchWorkflowChart: createAction('dashboard/fetchWorkflowChart'),
  fetchTopCountries: createAction('dashboard/fetchTopCountries', (payload) => {
    return {
      payload,
    };
  }),
  fetchRecentActivity: createAction(
    'dashboard/fetchRecentActivity',
    (payload) => {
      return {
        payload,
      };
    },
  ),
  loadMoreActivity: createAction('dashboard/loadMoreAction', (nextPageUrl) => {
    return {
      payload: { nextPageUrl },
    };
  }),
};
