import React, { useEffect, useState } from 'react';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Box, Typography } from '@mui/material';
import { Link as UiLink } from '../../../components/ui-kit/typography/Link';
import { book } from '../../../routes/book';
import { dark } from '../../../themes/dark';

const ThankYouPage = () => {
  const sessionVar = window.sessionStorage.getItem('email');
  const [email, setEmail] = useState<string>('');
  useEffect(() => {
    sessionVar && setEmail(sessionVar.replace(/^.|.$/g, ''));
  }, [sessionVar]);

  return (
    <>
      <FormBackgroundHolder>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          {email ? (
            <>
              <Typography variant={'title'} zIndex={1}>
                Thank You
              </Typography>
              <Typography
                mt={1.5}
                align={'center'}
                sx={{ opacity: 0.5 }}
                variant={'h5'}
                color={dark.palette.grey['50']}
                zIndex={1}>
                Check you email
              </Typography>
              <Typography mt={2} variant={'h7'} zIndex={1}>
                {email}
              </Typography>
            </>
          ) : (
            <Typography variant={'title'} textAlign={'center'} zIndex={1}>
              First you need <br /> <UiLink to={book.signUp}>Sign Up</UiLink>
            </Typography>
          )}
        </Box>
      </FormBackgroundHolder>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={4}
        mb={10}>
        <Typography
          mb={1}
          sx={{ opacity: 0.5 }}
          variant={'h8'}
          color={dark.palette.grey['50']}>
          Already have an account?
        </Typography>
        <UiLink to={book.signIn}>Sign In</UiLink>
      </Box>
    </>
  );
};

export default ThankYouPage;
