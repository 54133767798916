import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '420px',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      width: '100%',
      marginTop: '25px',
    },
  }),
};
