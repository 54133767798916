import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextArea } from '../../../components/ui-kit/inputs/TextArea';

export const RenderMessageField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <TextArea
      {...input}
      testId={'contact-us-textarea'}
      fullWidth={true}
      label={'Message'}
      error={(!!meta.error || !!meta.submitError) && meta.touched}
      helperText={meta.touched && (meta.error || meta.submitError)}
    />
  );
};
