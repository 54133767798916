import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { uiActions } from '../../../../bus/ui/actions';
import { schema } from '../../../../Forms/TwoFactorQrCodeForm/schema';
import FinalForm from '../../../FinalForm';
import { TwoFactorQrCodeForm } from '../../../../Forms/TwoFactorQrCodeForm';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../bus/auth/actions';
import { getTwoFactorAuthenticationQrCode } from '../../../../bus/auth/selectors';

type TwoFactorQrCodeProps = {
  setChecked: (i: boolean) => void;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-4, 73],
    size: 130,
    blur: 50,
  }),
  (colors) => ({
    color: colors.violet,
    position: [106, 25],
    blur: 40,
  }),
];

export const TwoFactorQrCode: React.FC<TwoFactorQrCodeProps> = ({
  setChecked,
}) => {
  const dispatch = useDispatch();
  const qrCode = useSelector(getTwoFactorAuthenticationQrCode);

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Typography variant={'Headlines/H8'}>
        Please scan this QR code with your mobile device
      </Typography>
      <Box
        mt={'10px'}
        mb={'30px'}
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}>
        <Box component={'img'} src={qrCode} alt={'qr code'} />
      </Box>
      <Box mb={'10px'}>
        <Typography
          variant={'Body/Paragraph/Basic 14 Text'}
          color={'common.inversText05'}>
          Enter 6 digit code from your authentication app
        </Typography>
      </Box>
      <FinalForm
        initialValues={{}}
        component={TwoFactorQrCodeForm}
        sagaAction={authActions.postTwoFactor}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
          dispatch(authActions.twoFactorAuthSettings({ enabled: true }));
          setChecked(true);
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
