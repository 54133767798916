// imports
//state type____________________________________
export type UiState = {
  isFetching: boolean;
  isInitialized: boolean;
  theme: ThemeVariant;
  isSidebarOpen: boolean;
  isMobileSidebarOpen: boolean;
  isModalOpen: boolean;
  modalData: ModalDataActionPayload | null;
  tableFilter: { [key in TableFilterKeys]?: any } | null;
};

//payload types_________________________________
export type AddTableFilterActionPayload = {
  key: string;
  value: any;
};
export type InitTableFilterActionPayload = TableFilterKeys[];

export type FillThemeActionPayload = ThemeVariant;

export type ModalDataActionPayload = {
  modalName: string;
  modalComponent?: null;
  modalPayload?: any;
  hideUi?: boolean;
};

//common types__________________________________
export enum ThemeVariant {
  dark = 'dark',
  light = 'light',
}

export enum TableFilterKeys {
  activities = 'activities',
  status = 'status',
  type = 'type',
  country = 'country',
  currency = 'currency',
  dateRange = 'dateRange',
  leader = 'leader',
  applicationStatus = 'applicationStatus',
  source = 'source',
}
