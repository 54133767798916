import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const ThankYouCheckIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      sx={{ fontSize: '40px', width: '40px', height: '30px' }}
      viewBox={'0 0 40 30'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2468 0.711402C40.2345 1.67608 40.2533 3.25888 39.2888 4.24668L14.8746 29.2467C14.3827 29.7505 13.7011 30.0232 12.9974 29.9985C12.2938 29.9735 11.6333 29.653 11.1782 29.1157L0.592279 16.6158C-0.300046 15.562 -0.169246 13.9845 0.884404 13.0923C1.93803 12.2 3.51553 12.3307 4.40786 13.3842L13.2176 23.787L35.7115 0.753327C36.676 -0.234498 38.259 -0.253273 39.2468 0.711402Z"
        fill="#F13DD8"
      />
    </SvgIcon>
  );
};

export default ThankYouCheckIcon;
