import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& > div': {
      '&:nth-of-type(2)': {
        margin: '12px 0 30px 0',
      },
    },
  },
  buttonGroup: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px',
    '& > button': {
      minWidth: '103px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
        padding: '12px 0',
      },
    },
  }),
  focus: (theme: Theme) => ({
    color: theme.palette.common.birch,
    background: theme.palette.common.buttons.birchBackground,
    '&:before': {
      background: theme.palette.common.buttons.birchOpacity,
      opacity: 1,
    },
    '&:after': {
      background: theme.palette.common.birch,
      opacity: 0,
    },
  }),
  button: (theme: Theme) => ({
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'initial',
      width: '100%',
    },
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  }),
};
