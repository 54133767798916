import { apply, call, put } from 'redux-saga/effects';
import { todoActions } from '../../actions';
import { api } from '../../../../REST/api';
import {
  LoadMoreTodoActionPayload,
  PushMoreTodoActionPayload,
} from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* loadMoreTodo({
  payload,
}: PayloadAction<LoadMoreTodoActionPayload>) {
  try {
    yield put(todoActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: payload.nextPageUrl, direct: true },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: PushMoreTodoActionPayload = yield call([response, 'json']);
    yield put(todoActions.pushMoreTodo(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(todoActions.stopFetching());
  }
}
