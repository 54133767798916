import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Button } from '../../../components/ui-kit/Button';
import { styles } from './styles';
import { EffectFunc } from '../../../components/ui-kit/GeneralCardHolder';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.violet,
    position: [10, 84],
    size: 100,
    blur: 60,
  }),
  (colors) => ({
    color: colors.violet,
    position: [95, 55],
    size: 100,
    blur: 60,
  }),
];

const AlmostReadyPage = () => {
  return (
    <Box mb={10}>
      <FormBackgroundHolder effects={effects}>
        <Box
          sx={styles.root}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}>
          <Typography
            variant={'Headlines/H3'}
            color={'common.white'}
            mb={'16px'}>
            You’re almost ready
          </Typography>
          <Typography
            variant={'Body/Table/Basic 15'}
            mb={'30px'}
            textAlign={'center'}
            color={'common.declineChip'}>
            An admin will review your request and you will get an email
            notification
          </Typography>
          <Link href={'https://fozeus.com/c/fozeusfranchise'}>
            <Button variant={'primary'} color={'green'}>
              <Typography variant={'Button/Button 16'}>
                Visit Franchise Community
              </Typography>
            </Button>
          </Link>
        </Box>
      </FormBackgroundHolder>
    </Box>
  );
};

export default AlmostReadyPage;
