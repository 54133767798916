import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import DeleteIcon from '../../../ui-kit/Icons/DeleteIcon';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import { newsActions } from '../../../../bus/news/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { useSnackbar } from 'notistack';
import { getModalData } from '../../../../bus/ui/selectors';

type ConfirmDeleteNewsProps = {
  slug: string;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-25, 66],
  }),
  (colors) => ({
    color: colors.primary,
    position: [120, 50],
    blur: 50,
  }),
];

export const ConfirmDeleteNews: React.FC<ConfirmDeleteNewsProps> = () => {
  const modalData = useSelector(getModalData);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  const deleteNews = (str: any) => {
    return new Promise((resolve, reject) => {
      str && dispatch(newsActions.deleteNews({ values: str, resolve, reject }));
    })
      .then(() => {
        enqueueSnackbar('Successfully', { variant: 'infoSnackbar' });
        dispatch(uiActions.closeModal());
        modalData?.modalPayload.onSuccess();
      })
      .catch((errors) => {
        return errors;
      });
  };

  return (
    // @ts-ignore
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box maxWidth={'230px'}>
        <Typography variant={'h1'}>
          Are you really want to delete the post?
        </Typography>
      </Box>
      <Box display={'flex'} gap={'10px'}>
        <Button variant={'secondary'} onClick={closeModal}>
          <Typography variant={'h4'}>Cancel</Typography>
        </Button>
        <Button
          onClick={() => deleteNews(modalData?.modalPayload.slug)}
          startIcon={<DeleteIcon />}
          variant={'secondary'}
          color={'pink'}>
          <Typography variant={'h4'}>Delete</Typography>
        </Button>
      </Box>
    </GeneralCardHolder>
  );
};
