import React from 'react';
import { createSvgIcon } from '@mui/material';

const DefaultIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.3327 7.91406C18.3327 11.3658 15.5344 14.1641 12.0827 14.1641C10.6397 14.1641 9.31085 13.675 8.25295 12.8536C8.21311 12.9379 8.15832 13.0169 8.08861 13.0866L3.08861 18.0866C2.76317 18.4121 2.23553 18.4121 1.91009 18.0866C1.58466 17.7612 1.58466 17.2336 1.91009 16.9081L6.91009 11.9081C6.97982 11.8384 7.05882 11.7836 7.14312 11.7438C6.32172 10.6859 5.83268 9.35706 5.83268 7.91406C5.83268 4.46228 8.63093 1.66406 12.0827 1.66406C15.5344 1.66406 18.3327 4.46228 18.3327 7.91406ZM16.666 7.91406C16.666 5.38275 14.614 3.33073 12.0827 3.33073C9.55135 3.33073 7.49935 5.38275 7.49935 7.91406C7.49935 10.4454 9.55135 12.4974 12.0827 12.4974C14.614 12.4974 16.666 10.4454 16.666 7.91406Z"
  />,
  'Search',
);

export default DefaultIcon;
