import { Box, TextField } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderVerificationCodeField: React.FC<
  FieldRenderProps<string>
> = ({ input, meta }) => {
  return (
    <Box>
      <TextField
        {...input}
        id={'code'}
        fullWidth
        label={'Code'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};
