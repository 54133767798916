import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderAnswerField } from './fields/RenderAnswerField';
import { RenderQuestionField } from './fields/RenderQuestionField';
import { styles } from './styles';

export const CreateFaqForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        sx={styles.root}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        mt={1}>
        <Field name={'title'} render={RenderQuestionField} />
        <Field name={'content'} render={RenderAnswerField} />
        <Box mt={2}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Create
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default CreateFaqForm;
