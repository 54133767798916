import { Components } from '@mui/material';

export const MuiSelect: Partial<Components> = {
  MuiSelect: {
    styleOverrides: {
      select: {
        borderRadius: '8px',
        fontFamily: 'Poppins, sans-serif',
        minWidth: '50px',
        '& + .MuiSvgIcon-root': {
          position: 'absolute',
          fontSize: '10px',
        },
      },
    },
  },
};
