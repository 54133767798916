import { createAction } from '@reduxjs/toolkit';
import usersSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const usersActions = {
  ...usersSlice.actions,
  deactivateUser: createAction(
    'users/deactivateUser',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: {
          resolve,
          reject,
        },
      };
    },
  ),
  activateUser: createAction(
    'users/activateUser',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: {
          resolve,
          reject,
        },
      };
    },
  ),
  deleteUser: createAction('users/deleteUser', (id) => {
    return {
      payload: id,
    };
  }),
  fetchUsersByString: createAction('users/fetchUsersByString', (payload) => {
    return {
      payload,
    };
  }),
  fetchUsers: createAction(
    'users/fetchUsers',
    ({ is_active, is_deleted, order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
              is_active: is_active?.toString(),
              is_deleted: is_deleted?.toString(),
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
};
