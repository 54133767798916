import moment from 'moment';
import { formatToRound } from './formating';

export const getStringActivities = (idsArr: any[], objectsArr: any[]) => {
  return idsArr
    .map((item) => {
      return objectsArr
        .filter((activity) => {
          return activity.id === item;
        })
        .map((item) => item.label);
    })
    .join(', ');
};

export const getLocationWithIcon = (
  countryCode: string,
  countryArray: any[],
) => {
  return countryArray.find((item) => {
    return item.code === countryCode;
  });
};

export const getTransactionFromTo = (
  type: number,
  direction: string,
  item: any,
  isAdmin?: boolean,
) => {
  if (type === 1) {
    return direction === 'from'
      ? item.source_user.first_name + ' ' + item.source_user.last_name
      : item.target_user.first_name + ' ' + item.target_user.last_name;
  }

  if (type === 2) {
    return direction === 'from'
      ? `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } BUSD Balance`
      : item.currency_id === 1
      ? 'BUSD Balance'
      : 'FZS Balance';
  }

  if (type === 3) {
    return direction === 'from'
      ? item.owner.first_name + ' ' + item.owner.last_name + ' ' + item.card
      : 'FZS Balance';
  }

  if (type === 4) {
    return direction === 'from'
      ? item.currency_id === 1
        ? `${
            isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
          } BUSD Balance`
        : `${
            isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
          } FZS Balance`
      : 'Default wallet';
  }

  if (type === 5) {
    return direction === 'from'
      ? 'Fozeus Franchise'
      : `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`;
  }

  if (type === 7) {
    return direction === 'from'
      ? `${item.owner.first_name} ${item.owner.last_name} (generation: ${item.generation})`
      : `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } BUSD Balance`;
  }

  if (type === 8) {
    return direction === 'from'
      ? `Pool share ${moment(item.pool.start_at).format('DD-MM/YYYY')}`
      : `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`;
  }

  if (type === 9) {
    return direction === 'from'
      ? `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`
      : `Earning Pool ${moment(item?.pool?.start_at).format('MM/YYYY')}`;
  }

  if (type === 10) {
    return direction === 'from'
      ? 'Fozeus Franchise'
      : `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`;
  }

  if (type === 11) {
    return direction === 'from'
      ? 'Fozeus Franchise'
      : `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`;
  }

  if (type === 12) {
    return direction === 'from'
      ? `${item?.source_user?.first_name || ''} ${
          item?.source_user?.last_name || ''
        }`
      : `${item?.target_user?.first_name || ''} ${
          item?.target_user?.last_name || ''
        }`;
  }

  if (type === 13) {
    return direction === 'from'
      ? item.currency_id === 1
        ? `${
            isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
          } BUSD Balance`
        : `${
            isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
          } FZS Balance`
      : 'Fozeus Franchise';
  }

  if (type === 14) {
    return direction === 'from'
      ? `${item?.source_user?.first_name || ''} ${
          item?.source_user?.last_name || ''
        }`
      : `${item?.target_user?.first_name || ''} ${
          item?.target_user?.last_name || ''
        }`;
  }

  if (type === 15) {
    return direction === 'from'
      ? `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`
      : `Earning Pool ${moment(item?.pool?.start_at).format('MM/YYYY')}`;
  }

  if (type === 21) {
    return direction === 'from'
      ? 'Fozeus Franchise'
      : `${
          isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
        } FZS Balance`;
  }

  return direction === 'from'
    ? 'Fozeus Franchise'
    : `${
        isAdmin ? item.owner.first_name + ' ' + item.owner.last_name : ''
      } FZS Balance`;
};

export const getDescription = (
  type: number,
  description: string | null,
  rate?: string,
  currency?: string,
  address?: string,
) => {
  if (type === 2 && currency === 'FZS' && rate) {
    return {
      description,
      rate: `${formatToRound(+rate, 4)} FZS/BUSD`,
    };
  }

  if (type === 3 && currency === 'FZS' && rate) {
    return {
      description,
      rate: `${formatToRound(+rate, 4)} FZS/BUSD`,
    };
  }

  if (type === 4 && currency === 'FZS' && rate) {
    return {
      address,
      description,
      rate: `${formatToRound(+rate, 4)} FZS/BUSD`,
    };
  }

  if (type === 5 && rate) {
    return {
      description,
      rate: `${formatToRound(+rate, 4)} FZS/BUSD`,
    };
  }

  if (type === 10 && rate) {
    return {
      description,
      rate: `${formatToRound(+rate, 4)} ${currency === 'FZS' && 'FZS/BUSD'}`,
    };
  }

  if (type === 11 && rate) {
    return {
      description,
      rate: `${formatToRound(+rate, 4)} ${currency === 'FZS' && 'FZS/BUSD'}`,
    };
  }

  return null;
};

export const numberToPercentInArray = (arrWithNumber: any[]) => {
  const biggestNumber = Math.ceil(
    arrWithNumber?.reduce((a, b) => {
      return a > b ? a : b;
    }),
  );

  const onePercent = biggestNumber / 100;

  return arrWithNumber.map((item) => {
    return Number((item / onePercent).toFixed(2));
  });
};

export const rankItemToCategory = (arr: any) => {
  const resultArray: any[] = [];

  for (const key in arr) {
    resultArray.push({ title: key !== 'undefined' ? key : '', list: arr[key] });
  }

  return resultArray;
};

export const convertRankToAchievement = (obj: any, level: number) => {
  return {
    category: obj.category?.title,
    id: obj.id,
    name: obj.rank,
    imageMini: obj.reward_image_mini,
    imageBackground: obj.reward_image_background,
    image: obj.reward_image,
    level: obj.level,
    title: obj.achievement_rewards,
    description: obj.description,
    gotIt: Number(obj.level) <= level,
  };
};

export const sliceRankMVT = (arr: number[], lvl: number) => {
  switch (true) {
    case [1].includes(lvl):
      return [];
    case [2, 3, 4, 5].includes(lvl):
      return arr.slice(0, 1);
    case [6, 7, 8, 9].includes(lvl):
      return arr.slice(0, 2);
    case [10, 11, 12, 13, 14, 15].includes(lvl):
      return arr.slice(0, 3);
    case [16, 17, 18].includes(lvl):
      return arr.slice(0, 4);
    case [19, 20].includes(lvl):
      return arr.slice(0, 5);
    default:
      return arr;
  }
};
