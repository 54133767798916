import {
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import RenderAmountField from './fields/RenderAmountField';
import { RenderPercentField } from './fields/RenderPercentField';
import { RenderTransactionPassField } from './fields/RenderTransactionPassField';
import RenderEmailField from './fields/RenderEmailField';
import { useSelector } from 'react-redux';
import { getBalanceForWithdrawal } from '../../bus/balance/selectors';
import { getModalData } from '../../bus/ui/selectors';
import { useEffect, useState } from 'react';
import { styles } from './styles';
import { formatToUSLocale } from '../../helpers/formating';

export const TransferForm = (props: FormRenderProps) => {
  const wallets = useSelector(getBalanceForWithdrawal);
  const modalData = useSelector(getModalData);
  const [walletData, setWalletData] = useState<any>();
  const [percent, setPercent] = useState<number | string>(0);
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  useEffect(() => {
    if (modalData?.modalPayload.id) {
      const tmp = wallets.find((item) => item.id === modalData.modalPayload.id);

      setWalletData(tmp);
    }
  }, [modalData?.modalPayload]);

  useEffect(() => {
    if (props.values.percent) {
      const val = +props.values.available;
      const fixedVal = Number((val / 100) * +props.values.percent).toFixed(2);
      setPercent(fixedVal);
    }
  }, [props.values]);

  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        marginTop={isSM ? '30px' : '0'}
        maxWidth={'447px'}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}>
        <Box mb={'30px'}>
          <Typography
            variant={'Body/Table/Basic 15'}
            color={'common.modals.subText'}>
            Send to Fozeus Franchise user. Easy, Instant,{' '}
          </Typography>
          <Typography
            variant={'Body/Table/Basic 15'}
            color={'common.modals.mainColor'}>
            0 fees.
          </Typography>
        </Box>
        <Typography
          sx={{ marginBottom: '17px', opacity: 0.5 }}
          variant={'Body/Table/Basic 15'}>
          Specify the recipients ID
        </Typography>
        <Field name={'wallet'} render={RenderEmailField} />
        <Typography
          sx={{ marginTop: '30px', marginBottom: '12px', opacity: 0.5 }}
          variant={'Body/Table/Basic 13'}>
          Available
        </Typography>
        {!!walletData && (
          <Typography
            color={'common.modals.mainColor'}
            variant={'Headlines/H4'}>
            {formatToUSLocale(walletData.available)} {walletData.currencyLabel}
          </Typography>
        )}
        {!!walletData && (
          <Typography
            sx={{ marginBottom: '25px', marginTop: '4px', opacity: 0.5 }}
            variant={'Body/Table/Basic 13'}>
            ${formatToUSLocale(walletData.subAvailable)}
          </Typography>
        )}
        <Box mt={2.5} my={1.5}>
          <Field
            name={'amount'}
            valuePercent={percent}
            currency={props.values.currency}
            render={RenderAmountField}
            type={'number'}
          />
        </Box>
        <Field name={'percent'} render={RenderPercentField} />
        <Box mt={'30px'}>
          <Field name={'password'} render={RenderTransactionPassField} />
        </Box>
      </Box>
      <Box mt={'30px'}>
        <Button
          sx={styles.button}
          type={'submit'}
          disabled={props.pristine}
          variant={'secondary'}
          color={'violet'}
          role={'submit'}>
          Transfer
        </Button>
        {props.hasSubmitErrors && (
          <Box mx={2}>
            <FormHelperText error>{props.submitError}</FormHelperText>
          </Box>
        )}
      </Box>
    </form>
  );
};
export default TransferForm;
