import { Box } from '@mui/material';
import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { PasswordInput } from '../../../components/ui-kit/inputs/PasswordInput';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderRepeatPasswordField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box my={1}>
      <PasswordInput
        fullWidth
        testId={'ResetPasswordRepeatPassword'}
        setShowPassword={setShowPassword}
        showPassword={showPassword}
        label={'Repeat Password'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
    </Box>
  );
};
