import React, { lazy } from 'react';
import { book } from '../../../routes/book';
import { AppRoute, UserRole } from '../../../setup/typedefs';
import AppLayout, { AppLayoutProps } from './index';

const Achievements = lazy(() => import('../../../pages/private/Achievements'));
const Merchants = lazy(() => import('../../../pages/private/Merchants'));
const Balance = lazy(() => import('../../../pages/private/Balance'));
const AdminWithdrawals = lazy(
  () => import('../../../pages/private/AdminWithdrawals'),
);
const AdminUsers = lazy(() => import('../../../pages/private/AdminUsers'));
const AdminTransaction = lazy(
  () => import('../../../pages/private/AdminTransaction'),
);
const AdminResources = lazy(
  () => import('../../../pages/private/AdminResources'),
);
const AdminProducts = lazy(
  () => import('../../../pages/private/AdminProducts'),
);
const AdminNews = lazy(() => import('../../../pages/private/AdminNews'));
const AdminMerchants = lazy(
  () => import('../../../pages/private/AdminMerchants'),
);
const AdminApplications = lazy(
  () => import('../../../pages/private/AdminApplications'),
);
const VentureCapital = lazy(
  () => import('../../../pages/private/VentureCapital'),
);
const Team = lazy(() => import('../../../pages/private/Team'));
const Resources = lazy(() => import('../../../pages/private/Resources'));
const Products = lazy(() => import('../../../pages/private/Products'));
const Pools = lazy(() => import('../../../pages/private/Pools'));
const FAQ = lazy(() => import('../../../pages/private/FAQ'));
const Dashboard = lazy(() => import('../../../pages/private/Dashboard'));
const AdminWithdrawalsFeePage = lazy(
  () => import('../../../pages/private/AdminWithdrawalsFeePage'),
);
const AdminRoyaltyFeesPages = lazy(
  () => import('../../../pages/private/AdminRoyaltyFeesPages'),
);
const AdminFAQ = lazy(() => import('../../../pages/private/AdminFAQ'));
const AddNews = lazy(() => import('../../../components/AdminNews/AddNews'));
const EditNews = lazy(() => import('../../../components/AdminNews/EditNews'));
// imports

export const appRoutes: AppRoute<AppLayoutProps>[] = [
  // inject
  {
    path: book.achievements,
    Component: <Achievements />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Achievements',
    },
  },
  {
    path: book.dashboard,
    Component: <Dashboard />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Dashboard',
    },
  },
  {
    path: book.balance,
    Component: <Balance />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Balance',
    },
  },
  {
    path: book.team,
    Component: <Team />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Team',
    },
  },
  {
    path: book.merchants,
    Component: <Merchants />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Merchants',
    },
  },
  {
    path: book.pools,
    Component: <Pools />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Pools',
    },
  },
  {
    path: book.ventureCapital,
    Component: <VentureCapital />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Venture capital',
    },
  },
  {
    path: book.products,
    Component: <Products />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Products',
    },
  },
  // {
  //   path: book.news,
  //   Component: <News />,
  //   Layout: AppLayout,
  //   layoutProps: {
  //     title: 'News',
  //   },
  // },
  {
    path: book.resources,
    Component: <Resources />,
    Layout: AppLayout,
    layoutProps: {
      title: 'Resources',
    },
  },
  {
    path: book.faq,
    Component: <FAQ />,
    Layout: AppLayout,
    layoutProps: {
      title: 'FAQ',
    },
  },
  {
    path: book.adminApplications,
    Component: <AdminApplications />,
    forRole: UserRole.admin,
    Layout: AppLayout,
    layoutProps: {
      title: 'Admin Applications',
    },
  },
  {
    path: book.adminUsers,
    Component: <AdminUsers />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin users',
    },
  },
  {
    path: book.adminTransactions,
    Component: <AdminTransaction />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin Transactions',
    },
  },
  {
    path: book.adminMerchants,
    Component: <AdminMerchants />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin Merchants',
    },
  },
  {
    path: book.adminWithdrawals,
    Component: <AdminWithdrawals />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin Withdrawals',
    },
  },
  {
    path: book.adminFees,
    Component: <AdminWithdrawalsFeePage />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Withdrawal fee',
    },
  },
  {
    path: book.royaltyFees,
    Component: <AdminRoyaltyFeesPages />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Royalty fees 75/25',
    },
  },
  {
    path: book.adminFaq,
    Component: <AdminFAQ />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin FAQ',
    },
  },
  {
    path: book.adminResources,
    Component: <AdminResources />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin Resources',
    },
  },
  {
    path: book.adminNews,
    Component: <AdminNews />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin News',
    },
  },
  {
    path: `${book.adminNews}/add`,
    Component: <AddNews />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Add News',
    },
  },
  {
    path: `${book.adminNews}/edit/:slug`,
    Component: <EditNews />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Edit News',
    },
  },
  {
    path: book.adminProducts,
    Component: <AdminProducts />,
    Layout: AppLayout,
    forRole: UserRole.admin,
    layoutProps: {
      title: 'Admin Products',
    },
  },
];
