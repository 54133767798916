import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { asyncValidate } from '../../helpers/asyncValidate';
import { useSnackbar, VariantType } from 'notistack';
import { OnFormSubmit, OnFormSubmitPayload } from '../../setup/typedefs';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { uiActions } from '../../bus/ui/actions';

type FinalFormProps = {
  initialValues: Record<string, any>;
  component: (props: FormRenderProps) => React.ReactNode;
  onSubmit?: OnFormSubmit;
  sagaAction?: ActionCreatorWithPayload<OnFormSubmitPayload>;
  onSubmitSuccess?: (showSnackbar: ShowSnackbar) => void;
  onSuccessMessage?: string | { message: string; variant: VariantType };
  onSubmitFailure?: (errors: any, showSnackbar: ShowSnackbar) => void;
  skipErrorMessage?: boolean;
  schema: any;
  mutators?: any;
  closeModalAfterSubmitting?: boolean;
};
export type ShowSnackbar = (message?: string, variant?: VariantType) => void;

const FinalForm: React.FC<FinalFormProps> = ({
  initialValues,
  component,
  onSubmit,
  sagaAction,
  schema,
  onSubmitSuccess,
  onSuccessMessage,
  onSubmitFailure,
  mutators,
  closeModalAfterSubmitting = true,
}) => {
  const validate = asyncValidate(schema);
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar: ShowSnackbar = (
    message = 'Form submitted successfully',
    variant = 'infoSnackbar',
  ) => {
    enqueueSnackbar(message, { variant });
  };

  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    return new Promise((resolve, reject) => {
      sagaAction && dispatch(sagaAction({ values, resolve, reject }));
      onSubmit && onSubmit({ values, resolve, reject });
    })
      .then(() => {
        onSubmitSuccess && onSubmitSuccess(showSnackbar);
        if (onSuccessMessage) {
          typeof onSuccessMessage === 'string'
            ? showSnackbar(onSuccessMessage)
            : showSnackbar(onSuccessMessage.message, onSuccessMessage.variant);
        }
        closeModalAfterSubmitting && dispatch(uiActions.closeModal());
      })
      .catch((errors) => {
        onSubmitFailure && onSubmitFailure(errors, showSnackbar);

        return errors;
      });
  };

  return (
    <Form
      mutators={{
        ...mutators,
      }}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      render={(props) => {
        return component(props);
      }}
    />
  );
};

export default FinalForm;
