import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { styles } from './styles';
import DefaultArrow from './DefaultArrow';
import { IOption } from '../../../../bus/options/typedefs';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';

type SelectProps = {
  opened?: boolean;
  disabled?: boolean;
  options: IOption[];
  label: string;
  error?: boolean;
  helperText?: string;
  value: string;
  onChange: (event: SelectChangeEvent, child: ReactNode) => void;
  fullWidth?: boolean;
  testId?: string;
  defaultValue?: any;
};

export const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  opened = false,
  disabled = false,
  options,
  label,
  error = false,
  helperText,
  fullWidth = false,
  testId,
  defaultValue,
}) => {
  const isLightTheme = useIsLightTheme();
  const theme = useTheme();

  return (
    <FormControl
      data-testid={testId}
      variant="outlined"
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        value={value}
        role={testId}
        onChange={onChange}
        defaultValue={defaultValue}
        MenuProps={{
          disablePortal: !!process.env.IS_STORYBOOK,
          sx: styles.paper,
        }}
        IconComponent={(props) => {
          return (
            <DefaultArrow
              {...props}
              sx={styles.icon}
              color={
                isLightTheme
                  ? theme.palette.common.cornflowerblue
                  : theme.palette.common.birch
              }
            />
          );
        }}
        defaultOpen={opened}>
        {options.map((item) => {
          return (
            <MenuItem key={item.id} data-testid={item.label} value={item.id}>
              {item.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
