import {
  Autocomplete as MuiAutocomplete,
  Box,
  MenuItem,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

import { FieldInputProps } from 'react-final-form';
import { TextField } from '../TextField';
import DefaultArrow from '../Select/DefaultArrow';

type AutocompleteProps = {
  options: any[];
  label: string;
  input: FieldInputProps<string>;
  equalTo?: string;
  error?: boolean;
  helperText?: string;
};

export const Autocomplete: React.FC<AutocompleteProps> = ({
  input: { onChange, value, ...input },
  options,
  label,
  error,
  helperText,
}) => {
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  return (
    <Box>
      <MuiAutocomplete
        isOptionEqualToValue={(option, value) => option.label === value}
        // sx={styles.root}
        options={options}
        value={value}
        onChange={(event, value) => {
          onChange(value);
        }}
        popupIcon={
          <DefaultArrow
            color={theme.palette.common.inputs.iconColor}
            sx={{
              fontSize: '14px',
              margin: '5px',
            }}
          />
        }
        renderOption={(props, option) => {
          return (
            <MenuItem {...props} key={option.id} data-testid={option.label}>
              {option.label}
            </MenuItem>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              label={label}
              error={error}
              helperText={helperText}
              {...params}
              {...input}
            />
          );
        }}
      />
    </Box>
  );
};
