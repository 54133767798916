export const styles = {
  root: {
    display: 'flex',
    maxWidth: '292px',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    '& span': {
      color: '#ffffff',
    },
  },
};
