import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextField } from '../../../components/ui-kit/inputs/TextField';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderAmountField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
  valuePercent,
}) => {
  useEffect(() => {
    if (valuePercent) {
      input.onChange(valuePercent);
    }
  }, [valuePercent]);

  return (
    <TextField
      {...input}
      fullWidth
      label={'Amount'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
    />
  );
};
