import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

type ItemProps = {
  value: number;
  title: string;
};

export const NotificationItem: React.FC<ItemProps> = ({ value, title }) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Typography variant={isSM ? 'Headlines/H8' : 'Headlines/H7'}>
        {value}
      </Typography>
      {!isSM && <Typography variant={'Headlines/H7'}>{title}</Typography>}
    </Box>
  );
};
