import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import {
  Box,
  FormGroup,
  Checkbox,
  Typography,
  Button,
  useTheme,
  Theme,
  FormHelperText,
} from '@mui/material';
import DefaultIcon from '../../../components/ui-kit/inputs/Checkbox/DefaultIcon';
import CheckedIcon from '../../../components/ui-kit/inputs/Checkbox/CheckedIcon';
import { getActivitiesMerchants } from '../../../bus/options/selectors';
import { useSelector } from 'react-redux';
import DefaultIconLT from '../../../components/ui-kit/inputs/Checkbox/DefaultIconLT';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';
import CheckedIconLT from '../../../components/ui-kit/inputs/Checkbox/CheckedIconLT';

const createStyles = (theme: Theme) => ({
  root: {
    border: `1px solid`,
    background: theme.palette.common.inputs.gray,
    borderRadius: '8px',
    padding: '26px 7px 0 16px',
    position: 'relative',
  },
  list: {
    overflowY: 'auto',
    maxHeight: '265px',
    minHeight: '265px',
    paddingLeft: '2px',
    '&::-webkit-scrollbar': {
      width: '2px',
      height: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.mainColorFirst,
      borderRadius: '10px',
    },
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    transform: 'translateY(100%)',
  },
});

export const RenderActivityField: React.FC<FieldRenderProps<number[]>> = ({
  input,
  meta,
  initialValues,
}) => {
  const theme = useTheme();
  const styles = createStyles(theme);
  const list = useSelector(getActivitiesMerchants(initialValues));
  const isLightTheme = useIsLightTheme();

  const [state, setState] = useState<any[]>(list);

  const clear = () => {
    const tmp = state.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });

    setState(tmp);
    input.onChange([]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tmp = state.map((item) => {
      return {
        ...item,
        checked:
          item.label === event.target.name
            ? event.target.checked
            : item.checked,
      };
    });

    setState(tmp);
    input.onChange(
      tmp
        .filter((item) => {
          return item.checked;
        })
        .map((item) => {
          return item.id;
        }),
    );
  };

  return (
    <>
      <Box
        my={1}
        sx={{
          ...styles.root,
          borderColor:
            !meta.dirtySinceLastSubmit &&
            (meta.dirty || meta.submitFailed) &&
            meta.error
              ? theme.palette.error.main
              : theme.palette.common.borderColor,
        }}>
        <Box display={'flex'} justifyContent={'space-between'} mb={2}>
          <Typography variant={'Tag/ Uppercase 12'} sx={{ opacity: 0.5 }}>
            Field of activity
          </Typography>
          <Button
            color="birch"
            variant="square"
            sx={{
              marginRight: '5px',
              color: theme.palette.common.inputs.iconColor,
            }}
            onClick={clear}>
            Clear All
          </Button>
        </Box>
        <FormGroup>
          <Box display={'flex'} flexDirection={'column'} sx={styles.list}>
            {state.map((item: any) => {
              return (
                <Box key={item.id} display={'flex'} alignItems={'center'}>
                  <Checkbox
                    checked={item.checked}
                    onChange={handleChange}
                    name={item.label}
                    icon={isLightTheme ? <DefaultIconLT /> : <DefaultIcon />}
                    checkedIcon={
                      isLightTheme ? <CheckedIconLT /> : <CheckedIcon />
                    }
                  />
                  <Typography
                    variant={'Button/Button 14'}
                    color={
                      item.checked
                        ? theme.palette.common.mainColorFirst
                        : theme.palette.common.inversText
                    }>
                    {item.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </FormGroup>
        {!!(
          !meta.dirtySinceLastSubmit &&
          (meta.dirty || meta.submitFailed)
        ) && (
          <Box sx={styles.error}>
            <FormHelperText error={true}>{meta.error}</FormHelperText>
          </Box>
        )}
      </Box>
    </>
  );
};
