export const styles = {
  root: {
    paddingLeft: '19px',
    marginBottom: '10px',
    '&.Mui-selected': {
      '&:before': {
        width: '3px',
      },
    },
  },
  text: {
    width: '300px',
    whiteSpace: 'nowrap',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
  },
};
