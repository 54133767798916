import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';
import { styles } from './styles';
import { SnackbarContent, useSnackbar } from 'notistack';
import Guy from './spaceman.svg';
import Cookie from './cookies-svgrepo-com.svg';
import Rocks from './rocks-down.svg';
import { Button } from '../../../ui-kit/Button';
import { setCookie } from 'nookies';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type CookieBannerSnackbarProps = {
  id: number;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-5, 20],
    size: 200,
    blur: 40,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [105, 80],
    size: 200,
    blur: 70,
  }),
];

const CookieBannerSnackbar: React.ForwardRefRenderFunction<
  HTMLDivElement,
  CookieBannerSnackbarProps
> = ({ id }, ref) => {
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));
  const { closeSnackbar } = useSnackbar();
  const handleCloseSnackbar = () => {
    setCookie(null, 'cookie_policy_agree_all', 'true');
    closeSnackbar(id);
  };

  return (
    <SnackbarContent ref={ref} style={{ width: '100%' }}>
      <GeneralCardHolder height={'100%'} sx={styles.root} effects={effects}>
        <Box
          component={'img'}
          alt={'cookie banner image'}
          src={Rocks}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          zIndex={-1}
        />
        <Box display={'flex'} justifyContent={'flex-end'} padding={0}>
          <Box
            component={'img'}
            src={Cookie}
            mt={'71px'}
            mr={'-6px'}
            alt={'Cookie'}
          />
          <Box component={'img'} src={Guy} mb={'31px'} alt={'Guy'} />
        </Box>
        <Box sx={styles.textWrap}>
          <Typography
            sx={styles.headline}
            variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}>
            By using Fozeus Franchise services you agree to our Cookie policy
          </Typography>
          <Typography variant={'Body/Paragraph/Basic 16'}>
            We and our partners operate globally and use cookies, including for
            analytics, personalisation, and ads
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            sx={styles.button}
            variant={'primary'}
            color={'violet'}
            onClick={handleCloseSnackbar}>
            <Typography
              variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
              Agree
            </Typography>
          </Button>
        </Box>
      </GeneralCardHolder>
    </SnackbarContent>
  );
};

export default forwardRef<HTMLDivElement, CookieBannerSnackbarProps>(
  CookieBannerSnackbar,
);
