import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const DashboardIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width="28" height="28">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 23.3296C12.25 24.6204 11.2053 25.6668 9.91667 25.6668H7C5.06701 25.6668 3.5 24.0973 3.5 22.1611V12.2993C3.5 11.3171 3.9114 10.3799 4.63406 9.71591L11.6649 3.2558C12.9641 2.06204 14.9471 2.0226 16.2926 3.1638L23.2617 9.07461C24.0471 9.74072 24.5 10.7193 24.5 11.75V22.1611C24.5 24.0973 22.9329 25.6668 21 25.6668H18.0833C16.7946 25.6668 15.75 24.6204 15.75 23.3296V17.5296H12.25V23.3296ZM14.7848 4.94742C14.3362 4.56703 13.6753 4.58017 13.2422 4.9781L6.21136 11.4382C5.97046 11.6595 5.83333 11.9719 5.83333 12.2993V22.1611C5.83333 22.8065 6.35567 23.3296 7 23.3296H9.91667V16.361C9.91667 15.7156 10.439 15.1924 11.0833 15.1924H16.9167C17.561 15.1924 18.0833 15.7156 18.0833 16.361V23.3296H21C21.6444 23.3296 22.1667 22.8065 22.1667 22.1611V11.75C22.1667 11.4065 22.0157 11.0803 21.7539 10.8582L14.7848 4.94742Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DashboardIcon;
