import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '90px 24px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '90px 20px 20px',
      width: 'calc(100% - 40px)',
    },
    '& > div:first-of-type': {
      '& .MuiButton-root': {
        maxWidth: '217px',
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: '16px',
        '& .MuiAvatar-root': {
          borderRadius: '6px',
        },
        '& .MuiButton-startIcon': {
          margin: '0 12px 0 0',
        },
      },
    },
  }),
  payButton: (theme: Theme) => ({
    maxWidth: '217px',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    '& .MuiButton-startIcon': {
      margin: '0 12px 0 0',
    },
    '& .MuiAvatar-root': {
      borderRadius: '6px',
    },
    '& .MuiSvgIcon-root': {
      fill: (theme: Theme) => theme.palette.common.birch,
    },
    '& span:nth-of-type(2)': {
      textAlign: 'left',
      maxWidth: '107px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  }),
  button: (theme: Theme) => ({
    margin: '30px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  }),
  accordion: {
    '& .MuiTypography-root.MuiTypography-h1': {
      transform: 'inherit',
    },
    '& .MuiTypography-root.MuiTypography-blockSubtitle': {
      lineHeight: '20px',
    },
  },
};
