import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { InvestPoolForm } from '../../../../Forms/InvestPoolForm';
import { schema } from '../../../../Forms/InvestPoolForm/schema';
import FinalForm from '../../../FinalForm';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceForWithdrawal } from '../../../../bus/balance/selectors';
import { poolsActions } from '../../../../bus/pools/actions';
import { formatToUSLocale } from '../../../../helpers/formating';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type InvestInPoolProps = {
  id: number;
  onSuccess: () => void;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-4, 73],
    blur: 40,
  }),
  (colors) => ({
    color: colors.violet,
    position: [103, 30],
    blur: 40,
  }),
];

export const InvestInPool: React.FC<InvestInPoolProps> = ({
  id,
  onSuccess,
}) => {
  const wallets = useSelector(getBalanceForWithdrawal);
  const [walletData, setWalletData] = useState<any>();
  const dispatch = useDispatch();
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  useEffect(() => {
    if (id) {
      const tmp = wallets.find((item) => item.id === id);

      setWalletData(tmp);
    }
  }, [id]);

  const buyFZS = () => {
    dispatch(
      uiActions.modal({
        modalName: 'PaymentChoice',
        modalPayload: {
          title: 'Choose your payment channel',
          isOutSideClicked: true,
          titlePosition: 'self-start',
        },
      }),
    );
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Typography
        variant={'Body/Table/Basic 14'}
        sx={{ opacity: 0.5, display: 'block' }}>
        The first month you determine the share in the pool, the next two months
        you receive interest according to your share in the pool.
      </Typography>
      {walletData && (
        <>
          <Box
            marginBottom={'30px'}
            marginTop={'35px'}
            display={'flex'}
            justifyContent={'space-between'}>
            <Box
              sx={styles.available}
              display={'flex'}
              flexDirection={'column'}>
              <Typography variant={'smallText'}>Available</Typography>
              <Typography
                sx={{ color: 'common.neonPink' }}
                variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}>
                {formatToUSLocale(walletData.available)}{' '}
                {walletData.currencyLabel}
              </Typography>
              <Typography variant={'smallText'}>
                ${formatToUSLocale(walletData.subAvailable)}
              </Typography>
            </Box>
            <Button
              onClick={buyFZS}
              sx={styles.buyButton}
              variant={'secondary'}
              color={'violet'}>
              <Typography
                variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
                Buy FZS
              </Typography>
            </Button>
          </Box>
          <FinalForm
            initialValues={{
              available: walletData.available,
              wallet: walletData.id,
            }}
            component={InvestPoolForm}
            sagaAction={poolsActions.poolInvest}
            onSubmitSuccess={(showSnackbar) => {
              onSuccess && onSuccess();
              showSnackbar('Success');
              dispatch(uiActions.closeModal());
            }}
            schema={schema}
          />
        </>
      )}
    </GeneralCardHolder>
  );
};
