import { PaletteOptions } from '@mui/material/styles/createPalette';
import { PaletteMode } from '@mui/material';

export const commonColors = {
  white: '#ffffff',
  effects: {
    primary: '#9B24AE',
    secondary: '#37E6FF',
    tertiary: 'linear-gradient(74.23deg, #0035BE 0.49%, #DB28DF 82.74%)',
    pinkAndPurple: 'linear-gradient(91.91deg, #2E3DF9 13.57%, #F11EAA 90.37%)',
    green: 'linear-gradient(76.75deg, #01A4FF 15.01%, #27FF97 90.12%)',
    violet: 'linear-gradient(74.3deg, #0041E4 -27.49%, #DB28DF 79.89%)',
  },
  birchBlack: '#37E6FF',
  trueBlack: '#000000',
  inversText: '#FFFFFF',
  inversText05: 'rgba(255,255,255,0.5)',
  mainShadow: '0px 2px 24px rgba(0, 0, 0, 0)',
  buttons: {
    violet: 'linear-gradient(74.3deg, #0041E4 -27.49%, #DB28DF 79.89%)',
    standardColor: '#FFFFFF',
    violetHover: 'linear-gradient(0deg, #DB28DF, #DB28DF)',
    violetPressed: 'linear-gradient(66.43deg, #3F3AE3 -8.12%, #DB28DF 126.94%)',
    green: 'linear-gradient(76.75deg, #01A4FF 15.01%, #27FF97 90.12%)',
    whiteOpacity:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0))',
    birchOpacity:
      'linear-gradient(to bottom, rgba(55, 228, 255, 0.7), rgba(55, 228, 255, 0.1))',
    activePoolBg: 'rgba(55, 230, 255, 0.1)',
    todoBackground: 'rgba(116, 107, 155, 0.1)',
    todoBackground05: 'rgba(116, 107, 155, 0.05)',
    todoBackground1: '#181630',
    birchBackground: 'rgba(87, 147, 181, 0.2)',
    greenHover: 'linear-gradient(0deg, #37E6FF, #37E6FF)',
    greenPressed: 'linear-gradient(66.43deg, #01A4FF 26.9%, #27FF97 200.08%)',
    pink: '#F13DD8',
    white: 'rgba(255, 255, 255, 0.14)',
    whiteHover: 'rgba(255, 255, 255, 1)',
    whitePressed: 'rgba(141, 125, 125, 1)',
    disabled: '#4B4B55',
    textDisabled: 'rgba(255,255,255,0.6)',
    systemGreen: '#94FF9E',
    systemGreenHover: '#35FF49',
    systemGreenFocus: '#00FF66',
    systemPink: '#F13DD8',
    systemPinkHover: '#FF76EC',
    systemPinkFocus: '#FF73EC',
    systemBlue: '#37E6FF',
    systemBlueHover: '#37FFCF',
    systemBlueFocus: '#00FFC2',
    squareDark: '#28224A',
    squareDarkHover: '#342A6D',
    backBackground: 'rgba(255, 255, 255, 0.1)',
    hoverHeader: 'rgba(255, 255, 255, 0.08)',
  },
  percentage: {
    background: 'rgba(87, 147, 181, 0.2)',
    backgroundBefore: 'rgba(55, 230, 255, 0.1)',
    backgroundAfter: 'birch',
  },
  generalCardHolder: {
    background: 'rgba(61, 52, 93, 0.3)',
    borderShadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    border: '1px solid transparent',
  },
  apexChart: {
    name: '#FFFFFF',
    value: '#FFFFFF',
    radialbarHollowBg: 'rgba(116, 107, 155, 0.05)',
    tabBackground: '#16132D',
    activeTabBackground: '#3b335d',
    labelColor: '#A8A9B1',
    activeLabelColor: '#FFFFFF',
    chartColorFirst: '#DB28DF',
    chartColorSecond: '#37E6FF',
  },
  earningPools: {
    mainText: '#37E6FF',
    chartValue: '#FFFFFF',
    chartSubText: '#A8A9B1',
    chartFillingColor: '#FF41D5',
  },
  ventureCapitalFund: {
    remainingTimeBackground: 'rgba(68, 72, 107, 0.15)',
    text: '#FFFFFF',
    checkBox: 'rgba(55, 230, 255, 0.3)',
  },
  countdown: {
    border: '#37E6FF',
    background:
      "'linear-gradient(214.25deg, rgba(255, 255, 255, 0.08) 6.73%, rgba(255, 255, 255, 0.03) 100.28%)",
    dataFirst: '#37E6FF',
    dataSecond: '#DB28DF',
  },
  recentActivity: {
    buttonText: '#FFFFFF',
  },
  map: {
    filledCountries: '#46d1eb',
    datalessRegionColor: '#201937',
  },
  todo: {
    button: '#FFFFFF',
    todoText: '#FFFFFF',
    todoTextDone: '#A8A9B1',
  },
  calendar: {
    monthYear: '#FFFFFF',
    days: '#FFFFFF',
    dateRegular: '#FFFFFF',
    dateOutsideMonth: '#A8A9B1',
    dateSelectedBackground: '#37E6FF',
    dateSelected: '#040018',
  },
  calendarPopup: {
    bg: '#16132D',
    shadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    mainColor: '#37E6FF',
    text: '#C1B5E3',
  },
  tooltipPopup: {
    text: 'rgba(255,255,255,0.6)',
    bg: 'rgba(20, 18, 45, 0.8)',
    shadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    green: '#65FF74',
    red: '#F13DD8',
  },
  wallet: {
    buttonText: '#FFFFFF',
    diagramBackground: 'rgba(236, 226, 254, .1)',
    firstColorVariant: '#EA3AD9',
    secondColorVariant: '#37E6FF',
    comparePositive: '#65FF74',
    compareNegative: '#FF7171',
  },
  table: {
    amountPositive: '#65FF74',
    amountNegative: '#FF6565',
    statusCellPaid: '#94fc9e',
    statusCellAproved: '#94fc9e',
    statusCellPending: '#FFEE94',
    statusCellLeader: '#FFEE94',
    statusCellFail: '#F13DD8',
    statusCellDecline: '#838395',
    statusCellCanceled: '#838395',
    statusCellComplete: '#94fc9e',
    statusCellDef: '#838395',
    buttonIncreaseDecrease: '#FFFFFF',
    buttonIncreaseDecreaseBorder: '#37E6FF',
    linkText: '#8f8e9a',
    filterText: '#FAFAFA',
  },
  controlTopBlock: {
    button: '#FFFFFF',
    regularTab: '#FFFFFF',
    activeTab: '#37E6FF',
  },
  teamPage: {
    statisticsBackground: 'rgba(68, 72, 107, 0.15)',
    statisticsShadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    primeContentColor: '#37E6FF',
    subContentColor: 'rgba(255, 255, 255, 0.5)',
    circularBorder: 'rgba(255, 255, 255, 0.1)',
    titleColor: 'rgba(255, 255, 255, 0.5)',
    zoomButton: '#181630',
    zoomButtonIcon: '#FFFFFF',
    userModal: {
      idBackground: 'rgba(255, 255, 255, 0.1)',
      mainFirstColor: '#37E6FF',
      mainSecondColor: '#F13DD8',
      subText: '#7A778F',
      inverseText: 'rgba(255, 255, 255, 0.3)',
      inverseText05: 'rgba(255, 255, 255, 0.5)',
      red: '#FF41D5',
      green: '#20F0A9',
    },
  },
  poolsPage: {
    mainColor: '#37E6FF',
    textColor: '#FFFFFF',
    dropDown: '#37E6FF',
    dropDownBackground: 'rgba(116, 107, 155, 0)',
    dropDownListBackground: '#22213C',
    timeDateBlockBackground: 'rgba(116, 107, 155, 0.05)',
    timeDateBlockShadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    timeDateBlockText: '#FAFAFA',
    contentText: 'rgba(255, 255, 255, 0.5)',
    amountText: '#37E6FF',
    buttonText: '#FFFFFF',
    additionalText: 'rgba(55, 230, 255, 0.5)',
    levelChartBackground: '#22213C',
    buttonPoolsSwiper: '#3C335D',
    buttonPoolsSwiperHover: '#342A6D',
    earningInformationText: 'rgba(255, 255, 255, 0.5)',
    earningInformationAmount: '#37E6FF',
    earningChartTextColor: 'rgba(255, 255, 255, 0.5)',
    earningChartAxis: 'rgba(255, 255, 255, 0.5)',
    earningChartGrid: 'rgba(255, 255, 255, 0.5)',
    borderChart: 'linear-gradient(76.75deg, #01A4FF 15.01%, #27FF97 90.12%)',
  },
  ventureCapital: {
    shadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    background: 'rgba(68, 72, 107, 0.15)',
    subText: 'rgba(255, 255, 255, 0.5)',
    conditionsIcon: '#37E6FF',
    sliderBackground: 'rgba(116, 107, 155, 0.1)',
    buttonSwiper: '#37E6FF',
    buttonSwiperBg: 'rgb(40,34,74)',
    sliderItemText: 'rgba(255, 255, 255, 0.5)',
  },
  products: {
    buttonColor: '#FFFFFF',
  },
  news: {
    text: '#FFFFFF',
    link: '#37E6FF',
    cardBg: 'rgba(116, 107, 155, 0.05)',
    cardShadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    cardChoosen: 'rgba(116, 107, 155, 0.1)',
    cardChoosenBefore: 'rgba(55, 230, 255, 0.1)',
    cardChoosenBorder: 'rgba(0, 0, 0, 0)',
    newsStatusNew: '#F13DD8',
    newsStatusSeen: '#ffffff',
  },
  resources: {
    cardBg: 'rgba(68, 72, 107, 0.15)',
    cardShadow: '0px 2px 24px rgba(0, 0, 0, 0)',
    dateColor: 'rgba(255, 255, 255, 0.5)',
    buttonText: '#040018',
    buttonIcon: '#37E6FF',
  },
  faq: {
    icon: '#37E6FF',
  },
  adminWithdrawalsFeePage: {
    titleText: 'rgba(255, 255, 255, 0.5)',
    mainColor: '#F13DD8',
    deductButton: '#FFFFFF',
  },
  adminRoyaltyFeePage: {
    titleText: 'rgba(255, 255, 255, 0.5)',
    mainColor: '#F13DD8',
    deductButton: '#FFFFFF',
  },
  adminFaq: {
    button: '#FFFFFF',
  },
  switch: {
    switchedPink: 'linear-gradient(102.8deg, #F13DD8 5.91%, #FE5FE8 83.27%)',
    defaultSwitch:
      'linear-gradient(90deg, rgba(117, 121, 231, 0.71) 0%, rgba(255, 255, 255, 0.22) 100.48%)',
  },
  inputs: {
    gray: 'rgba(116, 107, 155, 0.05)',
    defaultBorder: 'rgba(255, 255, 255, 0.1)',
    selectListsBackground: '#16132D',
    iconColor: '#37E6FF',
    mainColor: '#37E6FF',
  },
  navbar: {
    denseActiveBg: 'rgba(116, 107, 155, 0.1)',
    hover:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 86.21%)',
    selected:
      'linear-gradient(90deg, rgba(234, 58, 217, 0.6) 0%, rgba(234, 58, 217, 0) 47.41%)',
  },
  header: {
    background: '#070117',
    iconColor: '#A3AED0',
    themeSwitcherBackground: 'rgba(68, 72, 107, 0.15)',
    themeSwitcherIconRegular: '#FFFFFF',
    themeSwitcherIconActive: '#A3AED0',
    themeSwitcherIconActiveBackground: '#3C335D',
    dropdownBackground: '#16132D',
    dropdownIconColor: '#FFFFFF',
    titleColor: '#FFFFFF',
  },
  sidebar: {
    background:
      'linear-gradient(90deg, #0E1235 -61.33%, rgba(68, 72, 107, 0) 100%)',
    border:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
    itemIcon: '#37E6FF',
    itemColor: '#FFFFFF',
    collapseButton: '#433D59',
    collapseButtonBorder: '#433D59',
  },
  settings: {
    settingTabsText: 'rgba(255, 255, 255, 0.5)',
    settingTabsTextActive: 'rgba(255, 255, 255, 1)',
    companyBg: 'rgba(255, 255, 255, 0)',
  },
  tabs: {
    background: 'rgba(68, 72, 107, 0.15)',
    border:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
    indicator: '#3C335D',
  },
  notifications: {
    dateColor: '#A8A9B1',
    background: '#14122D',
    border: 'rgba(255, 255, 255, 0.12)',
    remainingBg: 'rgba(68, 72, 107, 0.15)',
    remainingTextReg: '#040018',
    markAsRead: '#37E6FF',
    whiteText: '#FFFFFF',
  },
  modals: {
    subText: '#FFFFFF',
    subText05: 'rgba(255,255,255,0.5)',
    mainColor: '#EA3AD9',
  },
  neonPink: '#F13DD8',
  levelColor: {
    novice: '#65FF74',
    casual: '#FFEE94',
    active: '#37E6FF',
    heavy: '#FFC786',
    absolute: '#F13DD8',
  },
  achievements: {
    sliderTitle: '#FFFFFF',
    backgroundOfLevel: 'rgba(61, 52, 93, 0.3)',
    levelTextColor: '#FFFFFF',
    levelProgress: '#37E6FF',
    levelNumber: 'rgba(61, 52, 93, 0.3)',
    arrowColor: '#37E6FF',
    progressBG: '#2f2551',
    activeSlideBackground: 'rgba(181, 87, 171, 0.2)',
    activeSlideBackgroundDecorate:
      'radial-gradient(circle, rgba(251, 63, 215, 0.7) 0%, rgba(255, 255, 255, 0) 70%)',
    activeSlideBorderDecorate: '#F13DD8',
    sliderTitleBackground: '#3D345D',
    sliderTitleBorder: '1px solid #706A87',
    sliderWrapperBackground: 'rgba(61,52,93,0.4)',
    borderRadius: '16px 0 0 0',
    titleOffset: '-1px',
    titlePadding: '0px 10px',
  },
  mainColorFirst: '#37E6FF',
  red: '#FF6565',
  chosenDate: '#F13DD8',
  birch: '#37E6FF',
  birchOpacity: 'rgba(55, 230, 255, 0.1)',
  birchOpacity30: 'rgba(55, 230, 255, 0.3)',
  birchOpacity50: 'rgba(55, 230, 255, 0.5)',
  hoverInfoBackground: 'rgba(20, 18, 45, 0.8)',
  pink: '#EA3AD9',
  lavender: '#DB28DF',
  razzleDazzleRose: '#FF25BE',
  failChip: '#FF7171',
  pendingChip: '#FFEE94',
  profileBackground: '#16132D',
  backgroundDark: '#1E1659',
  rank: '#3BAAE2',
  rankShadow: '#4BD5F8',
  purple200: '#C1B5E3',
  line: '#514F61',
  gray100: '#7A778F',
  gray50: '#FAFAFA',
  gray90: '#A0A5B0',
  declineChip: 'rgba(255, 255, 255, 0.5)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white09: 'rgba(255, 255, 255, 0.09)',
  white05: 'rgba(255, 255, 255, 0.05)',
  white03: 'rgba(255, 255, 255, 0.03)',
  white0: 'rgba(255, 255, 255, 0)',
  pink02: 'rgba(181, 87, 171, 0.2)',
  pink07: 'rgba(251, 63, 215, 0.7)',
  backgroundFuturePools: 'rgba(68, 72, 107, 0.03)',
  notActiveCard: 'rgba(56, 56, 62, 0.7)',
  currentPoolChartShareholder: '#22213C',
  shareholderName: 'rgba(8, 4, 28, 0.6)',
  shareholderNumber: 'rgba(8, 4, 28, 0.8)',
  gray70: '#7E7DA2',
  darkLink: '#040018',
  poolBackgroundDefault:
    'linear-gradient(180deg, rgba(68, 72, 107, 0.15) 0%, rgba(68, 72, 107, 0) 100%)',
  avatarSquareBackground:
    'linear-gradient(214.25deg, rgba(255, 255, 255, 0.12) 6.73%, rgba(255, 255, 255, 0.045) 100.28%)',
  loaderGreen: '#51BB25',
  lightGreen: '#20F0A9',
  green: '#65FF74',
  bananaYellow: '#F8FF94',
  darknessGreen: '#33cf4d',
  grayBackground: 'rgba(19, 19, 19, 0.59)',
  grayAwards: 'rgba(60, 51, 93, 0.3)',
  blackOpacity15: 'rgba(0, 0, 0, 0.15)',
  filterTab: 'linear-gradient(74.23deg, #0035BE 0.49%, #DB28DF 82.74%)',
  filterCrossClose: 'rgba(255, 255, 255, 0.5)',
  rangeDate:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.195) 0%, rgba(255, 255, 255, 0.039) 100%)',
  lightPink: '#FF41D5',
  borderColor: '#2F2B45',
  aquamarine: '#5AF1FF',
  greenAquamarine: '#50FFC3',
  yellowOrange: '#FFB342',
  cornflowerblue: '#5A9FFF',
  regentGray: '#78909C',
  richBlack: '#0a072a',
  blueberry: '#7366FF',
  shadeOfBlue: '#44486B',
  shadeOfGreen: '#2eca45',
  blueMagenta: '#8f8e9a',
  blueMagentaBg30: 'rgba(61, 52, 93, 0.3)',
  paleGreen: '#94fc9e',
  manatee: '#838395',
  darkByzantineBlue: '#3e3c52',
  byzantineBlue: '#3D345D',
  rhythm: '#706A87',
  mulberryMauveBlack: '#433D59',
  mildBlueYonder: '#A3AED0',
  hipsterSalmon: '#fd7c6e',
  tristesse: '#0c0c1f',
  forgottenPurple: '#9878f8',
  scarabaeusSacer: '#414040',
  throat: '#201937',
  whiteSmoke: '#f5f5f5',
  blueDacnis: '#46d1eb',
  purple: '#a26cf8',
  vanillaIce: '#ecd8d8',
  blueBell01: 'rgba(185, 178, 215, 0.1)',
  brightTurquoise06: 'rgba(65, 255, 232, 0.6)',
  lightYellow06: 'rgba(255, 199, 134, 0.6)',
  mainBg0: 'rgba(22, 12, 47, 0)',
  mainBg09: 'rgba(22, 12, 47, 0.9)',
  mainBg1: 'rgba(22, 12, 47, 1)',
  diagramColor01: 'rgba(236, 226, 254, .1)',
  slateGreen: 'rgba(92,36,140,1)',
  cyanBlue: 'rgba(36,76,123,1)',
  gray80: '#9097AC',
  gray60: '#3B3665',
};

export const palette: PaletteOptions = {
  mode: 'dark' as PaletteMode,
  primary: {
    main: '#37E6FF',
  },
  secondary: {
    main: '#65FF74',
  },
  text: {
    primary: '#ffffff',
  },
  error: {
    main: '#F13D89',
  },
  background: {
    default: '#040018',
  },
  common: commonColors,
};
