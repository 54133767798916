import { apply, call, put } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { TwoFactorPayload } from '../../typedefs';
import { handleError } from '../../../ui/saga/workers/handleError';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { throwError } from '../../../ui/saga/workers/throwError';

export function* postTwoFactor({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<TwoFactorPayload>) {
  try {
    yield put(authActions.startFetching());

    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/verify-code/`, body },
    ]);

    if (response.status >= 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }

    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    const { access, refresh } = yield call([response, 'json']);

    window.localStorage.setItem('accessToken', JSON.stringify(access));
    window.localStorage.setItem('refreshToken', JSON.stringify(refresh));
    window.sessionStorage.setItem('accessToken', JSON.stringify(access));
    window.sessionStorage.setItem('refreshToken', JSON.stringify(refresh));

    yield put(authActions.isAuthenticatedTrue());
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(authActions.stopFetching());
  }
}
