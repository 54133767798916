// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  NotificationsActionPayload,
  NotificationsState,
  ChangeNotificationsListActionPayload,
  INotification,
  PushMoreNotificationsActionPayload,
} from './typedefs';
import { emptyPaginationState } from '../../setup/typedefs';

const initialState: NotificationsState = {
  isFetching: false,
  isInitialized: true,
  notifications: emptyPaginationState as any,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    notifications(state, action: PayloadAction<NotificationsActionPayload>) {
      state.notifications = action.payload;
    },
    changeNotificationsList(
      state,
      action: PayloadAction<ChangeNotificationsListActionPayload>,
    ) {
      state.notifications.results = state.notifications.results.map((item) => {
        return action.payload
          ? +item.id === +action.payload
            ? {
                ...item,
                is_new: false,
              }
            : item
          : {
              ...item,
              is_new: false,
            };
      });
    },
    addNewNotificationInList(state, action: PayloadAction<INotification>) {
      state.notifications.results = [
        action.payload,
        ...state.notifications.results,
      ];
    },
    pushMoreNotifications(
      state,
      action: PayloadAction<PushMoreNotificationsActionPayload>,
    ) {
      state.notifications.count = action.payload.count;
      state.notifications.next = action.payload.next;
      state.notifications.results = [
        ...state.notifications.results,
        ...action.payload.results,
      ];
    },
    // inject
  },
});

export default notificationsSlice;
