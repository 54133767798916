import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const PoolsIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33398 24.4969C2.33398 23.8504 2.85631 23.3262 3.50065 23.3262H24.5007C25.145 23.3262 25.6673 23.8504 25.6673 24.4969C25.6673 25.1434 25.145 25.6676 24.5007 25.6676H3.50065C2.85632 25.6676 2.33398 25.1434 2.33398 24.4969Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.83784 7.9745C7.37618 8.59537 6.99604 9.29287 6.71874 10.0574L21.991 15.6352C22.033 15.6504 22.0795 15.6287 22.0947 15.5866C22.2273 15.2211 22.3317 14.8522 22.4093 14.4821C22.4872 14.1102 22.5378 13.7371 22.5625 13.3653C22.5627 13.3611 22.563 13.3568 22.5633 13.3526C22.7902 9.83719 20.686 6.43791 17.2243 5.17359C13.7478 3.90395 9.94427 5.1416 7.83784 7.9745ZM5.92069 12.2575C4.70974 11.8153 4.08537 10.4717 4.52612 9.2566C6.51463 3.77446 12.5872 0.988466 18.0223 2.97346C23.4154 4.94313 26.2606 10.9474 24.2873 16.3874C23.8313 17.6446 22.4459 18.2928 21.193 17.8353L5.92069 12.2575Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9572 9.34437C12.0253 7.55256 13.8546 5.2514 16.9162 3.09961L18.2549 5.01733C15.4843 6.96451 13.8736 9.01307 12.9596 10.5462C12.5017 11.3144 12.2171 11.956 12.0496 12.3944C11.9658 12.6135 11.9114 12.7816 11.8792 12.8889C11.8632 12.9427 11.8527 12.9811 11.8468 13.0031L11.8416 13.0238L11.8419 13.0225L11.8423 13.0209C11.8423 13.0209 11.8428 13.0188 10.7054 12.7579C9.56809 12.497 9.56836 12.4958 9.56836 12.4958L9.56865 12.4946L9.56933 12.4917L9.57098 12.4847L9.57559 12.4654C9.57927 12.4504 9.58416 12.431 9.59036 12.4073C9.60278 12.3599 9.6205 12.2957 9.64447 12.2155C9.69242 12.0553 9.76542 11.832 9.87103 11.5557C10.0822 11.0033 10.4241 10.2387 10.9572 9.34437Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2806 12.3832C19.6102 10.3216 19.683 7.37898 18.716 3.75586L16.4622 4.36152C17.3372 7.64012 17.2588 10.2483 16.9768 12.0123C16.8355 12.8961 16.6424 13.5712 16.49 14.015C16.4139 14.2369 16.3478 14.4008 16.3037 14.5038C16.2816 14.5553 16.265 14.5916 16.2553 14.6121L16.2461 14.6314L16.2468 14.6301L16.2475 14.6287C16.2475 14.6287 16.2484 14.6267 17.2868 15.1604C18.3252 15.6942 18.3257 15.6931 18.3257 15.6931L18.3277 15.6893L18.3309 15.6829L18.3397 15.6651C18.3464 15.6512 18.3552 15.6333 18.3656 15.6111C18.3863 15.5669 18.414 15.5061 18.447 15.4292C18.5129 15.2756 18.6001 15.0574 18.6961 14.7778C18.8881 14.2184 19.1161 13.4121 19.2806 12.3832Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9945 13.9596C14.6 14.1807 14.9122 14.8525 14.6918 15.4601L11.4996 24.2606C11.2793 24.8682 10.6098 25.1814 10.0043 24.9602C9.39882 24.7392 9.08663 24.0674 9.307 23.4598L12.4992 14.6593C12.7196 14.0517 13.389 13.7384 13.9945 13.9596Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default PoolsIcon;
