import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const ResourcesIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 22.1673H8.16667C8.16667 24.1004 9.73368 25.6673 11.6667 25.6673H21C22.9331 25.6673 24.5 24.1004 24.5 22.1673V9.33398C24.5 7.40099 22.9331 5.83398 21 5.83398C21 3.90099 19.433 2.33398 17.5 2.33398H7C5.06701 2.33398 3.5 3.90099 3.5 5.83398V18.6673C3.5 20.6004 5.06701 22.1673 7 22.1673ZM9.33333 19.834H9.33513H17.5C18.1443 19.834 18.6667 19.3117 18.6667 18.6673V7.00065V5.83398C18.6667 5.18966 18.1443 4.66732 17.5 4.66732H7C6.35567 4.66732 5.83333 5.18965 5.83333 5.83398V18.6673C5.83333 19.3117 6.35567 19.834 7 19.834H9.33154H9.33333ZM22.1667 9.33398C22.1667 8.68966 21.6444 8.16732 21 8.16732V18.6673C21 20.6004 19.433 22.1673 17.5 22.1673H10.5C10.5 22.8117 11.0223 23.334 11.6667 23.334H21C21.6444 23.334 22.1667 22.8117 22.1667 22.1673V9.33398ZM15.1667 7.00065C15.811 7.00065 16.3333 7.52299 16.3333 8.16732C16.3333 8.81166 15.811 9.33398 15.1667 9.33398H9.33333C8.68901 9.33398 8.16667 8.81166 8.16667 8.16732C8.16667 7.52299 8.68901 7.00065 9.33333 7.00065H15.1667ZM16.3333 12.2507C16.3333 11.6063 15.811 11.084 15.1667 11.084H9.33333C8.68901 11.084 8.16667 11.6063 8.16667 12.2507C8.16667 12.895 8.68901 13.4173 9.33333 13.4173H15.1667C15.811 13.4173 16.3333 12.895 16.3333 12.2507ZM15.1667 15.1673C15.811 15.1673 16.3333 15.6896 16.3333 16.334C16.3333 16.9783 15.811 17.5007 15.1667 17.5007H9.33333C8.68901 17.5007 8.16667 16.9783 8.16667 16.334C8.16667 15.6896 8.68901 15.1673 9.33333 15.1673H15.1667Z"
        fill="url(#paint0_linear_14271_84475)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84475"
          x1="24.5"
          y1="20.184"
          x2="3.92311"
          y2="14.8392"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default ResourcesIconLT;
