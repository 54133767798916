import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const BalanceIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4999 11.1507C24.4999 9.40438 23.0933 7.97135 21.3014 7.82557L20.5594 5.75787C19.9143 3.9604 17.828 3.03824 15.9725 3.73052L4.82489 7.8898C4.71354 7.93135 4.61266 7.98741 4.5237 8.05482C3.23946 8.54919 2.33325 9.74902 2.33325 11.1507V21.1626C2.33325 23.0058 3.90026 24.5 5.83325 24.5H20.9999C22.933 24.5 24.4999 23.0058 24.4999 21.1626V18.6402C25.1974 18.2554 25.6666 17.5364 25.6666 16.7128V14.488C25.6666 13.6645 25.1974 12.9454 24.4999 12.5608V11.1507ZM22.1666 12.2632V11.1507C22.1666 10.5363 21.6443 10.0382 20.9999 10.0382H5.83325C5.18893 10.0382 4.66659 10.5363 4.66659 11.1507V21.1626C4.66659 21.777 5.18893 22.2752 5.83325 22.2752H20.9999C21.6443 22.2752 22.1666 21.777 22.1666 21.1626V18.9378H20.9999C19.0669 18.9378 17.4999 17.4436 17.4999 15.6004C17.4999 13.7573 19.0669 12.2632 20.9999 12.2632H22.1666ZM18.3517 6.47822L18.8309 7.81335L11.4331 7.81336L16.8228 5.80243C17.4412 5.57167 18.1367 5.87905 18.3517 6.47822ZM19.8333 15.6004C19.8333 14.9861 20.3556 14.488 20.9999 14.488H23.3333V16.7128H20.9999C20.3556 16.7128 19.8333 16.2148 19.8333 15.6004Z"
        fill={isLight ? '#5275EF' : color}
      />
    </SvgIcon>
  );
};

export default BalanceIcon;
