import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrenciesRate } from '../../bus/options/selectors';
import { Typography } from '@mui/material';

const RateBlock = ({ value }: any) => {
  const optionList = useSelector(getCurrenciesRate);
  const [currentCurrency, setCurrentCurrency] = useState<any>();
  const [FZSRate, setFZSRate] = useState<number>(0);
  const [rateCalc, setRateCalc] = useState<number>(0);

  useEffect(() => {
    if (optionList.length) {
      setFZSRate(Number(optionList.find((item) => item.label === 'FZS')?.rate));
    }
  }, [optionList]);

  useEffect(() => {
    if (value) {
      setCurrentCurrency(optionList.find((item) => item.id === value));
    }
  }, [value]);

  useEffect(() => {
    if (currentCurrency && FZSRate) {
      setRateCalc(Number((currentCurrency.rate * FZSRate).toFixed(2)));
    }
  }, [currentCurrency, FZSRate]);

  if (!rateCalc) return <></>;

  return (
    <Typography mb={2.5} variant={'Input/Title 12'}>
      1 FZS ={' '}
      <>
        {currentCurrency.label === 'FZS'
          ? '1 ' + currentCurrency.label
          : rateCalc + ' ' + currentCurrency.label}
      </>
    </Typography>
  );
};

export default RateBlock;
