import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';
import { InputWithIcon } from '../../../components/ui-kit/inputs/InputWithIcon';
import PencilIcon from '../../../components/ui-kit/Icons/PencilIcon';
import { useTheme } from '@mui/material';

export const RenderWalletsAddressField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const theme = useTheme();

  return (
    <InputWithIcon
      fullWidth
      label={'Wallet Address'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
      icon={<PencilIcon color={theme.palette.common.inputs.iconColor} />}
      {...input}
    />
  );
};
