// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillResourcesActionPayload,
  FillResourcesCategoriesActionPayload,
  ICategory,
  ResourcesState,
  IResourceItem,
  RemoveResourcesActionPayload,
  UpdateResourceActionPayload,
  AddResourceActionPayload,
  PushMoreResourcesActionPayload,
} from './typedefs';
import { emptyPaginationState, WithPagination } from '../../setup/typedefs';

const initialState: ResourcesState = {
  isFetching: false,
  isInitialized: true,
  resourcesCategory: emptyPaginationState as WithPagination<ICategory[]>,
  allResources: null,
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillResources(state, action: PayloadAction<FillResourcesActionPayload>) {
      state.resourcesCategory.results = state.resourcesCategory.results?.map(
        (item) => {
          if (item.id === action.payload.id) {
            return { ...item, resources: action.payload.results };
          }

          return item;
        },
      );
    },
    fillAllResources(
      state,
      action: PayloadAction<WithPagination<IResourceItem[]>>,
    ) {
      state.allResources = action.payload;
    },
    fillResourcesCategories(
      state,
      action: PayloadAction<FillResourcesCategoriesActionPayload>,
    ) {
      state.resourcesCategory.results = action.payload.results;
    },
    removeResources(
      state,
      action: PayloadAction<RemoveResourcesActionPayload>,
    ) {
      if (state.allResources) {
        state.allResources.count = state.allResources.count - 1;
        state.allResources.results = state.allResources.results.filter(
          (resource) => resource.slug !== action.payload,
        );
      }
    },
    updateResource(state, action: PayloadAction<UpdateResourceActionPayload>) {
      if (state.allResources) {
        state.allResources.results = state.allResources.results.map(
          (resource) =>
            resource.id === action.payload.id ? action.payload : resource,
        );
      }
    },
    clearResources(state) {
      state.allResources = null;
    },
    addResource(state, action: PayloadAction<AddResourceActionPayload>) {
      if (state.allResources) {
        state.allResources.results = [
          action.payload,
          ...state.allResources.results,
        ];
      }
    },
    pushMoreResources(
      state,
      action: PayloadAction<PushMoreResourcesActionPayload>,
    ) {
      if (state.allResources) {
        state.allResources.count = action.payload.count;
        state.allResources.next = action.payload.next;
        state.allResources.results = [
          ...state.allResources.results,
          ...action.payload.results,
        ];
      }
    },
    // inject
  },
});

export default resourcesSlice;
