import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminTransactionsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16634 20.9997C8.16634 21.644 8.68867 22.1663 9.33301 22.1663H22.1663C22.8107 22.1663 23.333 21.644 23.333 20.9997V12.833C23.333 12.1887 22.8107 11.6663 22.1663 11.6663V17.4997C22.1663 19.4327 20.5994 20.9997 18.6663 20.9997H8.16634ZM22.1663 9.33301C22.1663 7.40002 20.5994 5.83301 18.6663 5.83301H5.83301C3.90002 5.83301 2.33301 7.40002 2.33301 9.33301V17.4997C2.33301 19.4327 3.90002 20.9997 5.83301 20.9997C5.83301 22.9327 7.40002 24.4997 9.33301 24.4997H22.1663C24.0994 24.4997 25.6663 22.9327 25.6663 20.9997V12.833C25.6663 10.9 24.0994 9.33301 22.1663 9.33301ZM18.6663 18.6663C19.3107 18.6663 19.833 18.144 19.833 17.4997V9.33301C19.833 8.68868 19.3107 8.16634 18.6663 8.16634H5.83301C5.18868 8.16634 4.66634 8.68868 4.66634 9.33301V17.4997C4.66634 18.144 5.18868 18.6663 5.83301 18.6663H18.6663ZM18.6663 16.333C18.6663 15.6887 18.144 15.1663 17.4997 15.1663C16.8553 15.1663 16.333 15.6887 16.333 16.333C16.333 16.9774 16.8553 17.4997 17.4997 17.4997C18.144 17.4997 18.6663 16.9774 18.6663 16.333ZM6.99967 9.33301C7.644 9.33301 8.16634 9.85535 8.16634 10.4997C8.16634 11.144 7.644 11.6663 6.99967 11.6663C6.35535 11.6663 5.83301 11.144 5.83301 10.4997C5.83301 9.85535 6.35535 9.33301 6.99967 9.33301ZM15.1663 13.4163C15.1663 12.1276 14.1217 11.083 12.833 11.083C11.5443 11.083 10.4997 12.1276 10.4997 13.4163C10.4997 14.705 11.5443 15.7497 12.833 15.7497C14.1217 15.7497 15.1663 14.705 15.1663 13.4163Z"
        fill="url(#paint0_linear_14271_87546)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87546"
          x1="25.6663"
          y1="20.113"
          x2="4.07113"
          y2="12.3224"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminTransactionsIconLT;
