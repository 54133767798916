import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminFaqIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1654 0.333984H3.83203C1.89904 0.333984 0.332031 1.90099 0.332031 3.83398V20.1673C0.332031 22.1004 1.89904 23.6673 3.83203 23.6673H20.1654C22.0984 23.6673 23.6654 22.1004 23.6654 20.1673V3.83398C23.6654 1.90099 22.0984 0.333984 20.1654 0.333984ZM2.66536 3.83398C2.66536 3.18966 3.1877 2.66732 3.83203 2.66732H20.1654C20.8097 2.66732 21.332 3.18966 21.332 3.83398V20.1673C21.332 20.8117 20.8097 21.334 20.1654 21.334H3.83203C3.1877 21.334 2.66536 20.8117 2.66536 20.1673V3.83398ZM11.7556 14.0447C12.2292 14.0447 12.5722 13.669 12.6376 13.2443L12.6866 12.9503C14.4669 12.591 15.9042 11.7417 15.9042 9.66732V9.63465C15.9042 7.46232 14.3036 6.25365 11.9516 6.25365C10.5142 6.25365 9.41989 6.71098 8.55422 7.42965C8.26022 7.67465 8.12955 8.00132 8.12955 8.31165C8.12955 8.93232 8.65222 9.45498 9.27289 9.45498C9.55055 9.45498 9.8119 9.34065 10.0079 9.19365C10.5959 8.75265 11.2002 8.54032 11.9026 8.54032C12.8499 8.54032 13.4052 8.96498 13.4052 9.69998V9.73265C13.4052 10.533 12.7519 10.974 11.3309 11.1537C10.8082 11.219 10.4979 11.5947 10.5796 12.1337L10.7429 13.2443C10.8082 13.6853 11.1512 14.0447 11.6249 14.0447H11.7556ZM10.2039 16.511V16.5436C10.2039 17.3277 10.8082 17.932 11.6249 17.932C12.4416 17.932 13.0459 17.3277 13.0459 16.5436V16.511C13.0459 15.727 12.4416 15.1227 11.6249 15.1227C10.8082 15.1227 10.2039 15.727 10.2039 16.511Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminFaqIcon;
