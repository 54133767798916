import { createAction } from '@reduxjs/toolkit';
import productsSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const productsActions = {
  ...productsSlice.actions,
  loadMoreProducts: createAction('products/loadMoreProducts', (nextPageUrl) => {
    return {
      payload: { nextPageUrl },
    };
  }),
  postProduct: createAction(
    'products/postProduct',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  deleteProduct: createAction('products/deleteProduct', (slug) => {
    return {
      payload: slug,
    };
  }),
  editProduct: createAction(
    'products/editProduct',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchProducts: createAction(
    'products/fetchProducts',
    ({ categories, order, is_active, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: order === 'desc' ? '-created_at' : 'created_at',
              is_active: is_active.toString(),
            },
            new URLSearchParams(),
          ),
          isActive: is_active,
          categories,
        },
      };
    },
  ),
};
