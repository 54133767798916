import { apply, call, put } from 'redux-saga/effects';
import { poolsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillPoolsActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchPools({ payload }: PayloadAction<string>) {
  try {
    yield put(poolsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `pools/`, query: `year=${payload}` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillPoolsActionPayload = yield call([response, 'json']);
    yield put(poolsActions.fillPools(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(poolsActions.stopFetching());
  }
}
