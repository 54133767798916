import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '80px 24px 24px',
      width: 'calc(100% - 48px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '70px 20px 20px',
      width: 'calc(100% - 40px)',
    },
  }),
  payment: {
    '& span:first-of-type': {
      margin: '0 0 12px 0',
    },
  },
  buyButton: {
    marginTop: '4px',
    minWidth: 'initial',
    height: '39px',
    maxWidth: '140px',
    width: '100%',
  },
};
