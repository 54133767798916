import { all, call, takeEvery } from 'redux-saga/effects';
import { merchantsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchMerchants,
  createNewMerchant,
  getSingleMerchant,
  fetchNotConfirmMerchants,
  updateMerchant,
  declineMerchants,
  approveMerchants,
  deleteMerchant,
} from './workers';
// imports

function* watchFetchMerchants() {
  yield takeEvery(merchantsActions.fetchMerchants.type, fetchMerchants);
}

function* watchFetchNotConfirmMerchants() {
  yield takeEvery(
    merchantsActions.fetchNotConfirmMerchants.type,
    fetchNotConfirmMerchants,
  );
}

function* watchCreateNewMerchant() {
  yield takeEvery(merchantsActions.createNewMerchant.type, createNewMerchant);
}

function* watchGetSingleMerchant() {
  yield takeEvery(merchantsActions.getSingleMerchant.type, getSingleMerchant);
}

function* watchUpdateMerchant() {
  yield takeEvery(merchantsActions.updateMerchant.type, updateMerchant);
}
function* watchDeclineMerchants() {
  yield takeEvery(merchantsActions.declineMerchants.type, declineMerchants);
}
function* watchApproveMerchants() {
  yield takeEvery(merchantsActions.approveMerchants.type, approveMerchants);
}
function* watchDeleteMerchant() {
  yield takeEvery(merchantsActions.deleteMerchant.type, deleteMerchant);
}
// watchers
export function* watchMerchants() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchDeleteMerchant),
    call(watchApproveMerchants),
    call(watchDeclineMerchants),
    call(watchUpdateMerchant),
    call(watchGetSingleMerchant),
    call(watchFetchNotConfirmMerchants),
    call(watchCreateNewMerchant),
    call(watchFetchMerchants),
  ]);
}
