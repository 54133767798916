import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { PoolsReinvestWithdrawForm } from '../../../../Forms/PoolsReinvestWithdrawForm';
import { schema } from '../../../../Forms/PoolsReinvestWithdrawForm/schema';
import FinalForm from '../../../FinalForm';
import { Switcher } from '../../../ui-kit/Switcher';
import RedInfoIcon from '../../../ui-kit/Icons/RedInfoIcon';
import { formatToUSLocale } from '../../../../helpers/formating';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { poolsActions } from '../../../../bus/pools/actions';

const labelList = [
  {
    id: 1,
    label: 'Reinvest',
  },
  {
    id: 2,
    label: 'Withdraw',
  },
];

type PoolsReinvestWithdrawProps = {
  action: string;
  id: number;
  amount: number;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-4, 73],
    blur: 40,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [103, 30],
    blur: 40,
  }),
];

export const PoolsReinvestWithdraw: React.FC<PoolsReinvestWithdrawProps> = ({
  action,
  id,
  amount,
}) => {
  const dispatch = useDispatch();

  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  const [tab, setTab] = useState(id);
  const [currentValue, setValue] = useState(action);

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    dispatch(
      poolsActions.poolReinvest({
        ...payload,
        values: {
          amount: payload.values.amount,
          is_withdraw: currentValue === 'Withdraw',
        },
      }),
    );
  };

  useEffect(() => {
    switch (tab) {
      case 0:
        setValue('Reinvest');
        break;
      case 1:
        setValue('Withdraw');
        break;
      default:
        break;
    }
  }, [tab]);

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box
        sx={styles.subtitle}
        maxWidth={'387px'}
        margin={isSM ? '0 auto 26px auto' : '0 auto 41px auto'}
        alignSelf={isSM ? 'baseline' : 'center'}
        textAlign={isSM ? 'left' : 'center'}>
        <Typography
          variant={'Body/Table/Basic 14'}
          color={'common.modals.subText05'}>
          Do you want to continue earning from pools or do you want to withdraw
          the entire deposit from the pool?
        </Typography>
      </Box>
      <Switcher labelList={labelList} value={tab} onChange={setTab} />
      <Box
        marginBottom={'30px'}
        marginTop={isSM ? '24px' : '40px'}
        display={'flex'}
        justifyContent={'space-between'}>
        <Box sx={styles.available} display={'flex'} flexDirection={'column'}>
          <Typography variant={'Body/Table/Basic 13'} color={'common.gray100'}>
            {tab ? 'Past Investment' : 'Locked Investment'}
          </Typography>
          <Typography
            color={'common.neonPink'}
            variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}>
            {formatToUSLocale(amount)} FZS
          </Typography>
        </Box>
      </Box>
      <FinalForm
        initialValues={{ available: amount, switcher: currentValue }}
        component={PoolsReinvestWithdrawForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
      <Box display={'flex'} gap={'9.5px'} marginTop={'20px'}>
        <RedInfoIcon />
        {tab ? (
          <Typography
            variant={'Headlines/H10'}
            color={'common.modals.subText05'}>
            In case of withdrawal of the deposit, at the end of the current
            month you will receive the full amount of your initial deposit,
            including interest for 2 months.
          </Typography>
        ) : (
          <Typography
            variant={'Headlines/H10'}
            color={'common.modals.subText05'}>
            Amount of the initial deposit gets into the current pool, and
            interest for 2 months goes to your FZS balance
          </Typography>
        )}
      </Box>
    </GeneralCardHolder>
  );
};
