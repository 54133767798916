import React from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const MobileLogOutIcon = () => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon
      sx={{ fontSize: '20px' }}
      viewBox={'0 0 14 20'}
      width={'14'}
      height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.89543 0 2 0H12C13.1046 0 14 0.89543 14 2V18C14 19.1046 13.1046 20 12 20H2C0.89543 20 0 19.1046 0 18V17.4835V13.1V2.72318V2ZM12 18H5.8719L7.7878 17.1789C8.5232 16.8637 9 16.1407 9 15.3406V5.58032C9 4.86261 8.6154 4.19992 7.9923 3.84384L4.76556 2H12V18ZM2 13.1V2.72318L7 5.58032V15.3406L2 17.4835V13.1ZM5 9C4.44772 9 4 9.4477 4 10C4 10.5523 4.44772 11 5 11C5.5523 11 6 10.5523 6 10C6 9.4477 5.5523 9 5 9Z"
        fill={isLight ? '#5275EF' : '#37E6FF'}
      />
    </SvgIcon>
  );
};

export default MobileLogOutIcon;
