import React, { useState } from 'react';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { useDispatch } from 'react-redux';
import { paymentActions } from '../../../../bus/payment/actions';
import { Box, Button, TextField } from '@mui/material';

type PaymentMercurioProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-2, 73],
    blur: 50,
  }),
  (colors) => ({
    color: colors.violet,
    position: [100, 30],
    blur: 50,
  }),
];

export const PaymentAdvcash: React.FC<PaymentMercurioProps> = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>();

  const advCash = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        paymentActions.fetchAdvcash({
          values: {
            type: 2,
            callback_url: window.location.href,
            transaction: { amount: value },
          },
          resolve,
          reject,
        }),
      );
    })
      .then(() => {
        localStorage.setItem('waitPaymentCallback', 'true');
      })
      .catch((errors) => {
        console.log('errors => ', errors);
      });
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box gap={2} display={'flex'} sx={{ flexDirection: 'column' }}>
        <TextField
          type={'number'}
          label={'Enter amount'}
          fullWidth
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <Button
          variant={'secondary'}
          color={'violet'}
          onClick={advCash}
          sx={styles.button}
          disabled={!value}>
          Proceed
        </Button>
      </Box>
    </GeneralCardHolder>
  );
};
