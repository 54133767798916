import { apply, call, put } from 'redux-saga/effects';
import { dashboardActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillPhysicalPinActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';

export function* fetchPhysicalPin() {
  try {
    yield put(dashboardActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `physical-pins/` },
    ]);
    const data: FillPhysicalPinActionPayload = yield call([response, 'json']);

    if (!response.ok) {
      // throw new Error('failed to fetch');
      yield throwError(response);
    }
    yield put(dashboardActions.fillPhysicalPin(data));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(dashboardActions.stopFetching());
  }
}
