import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const ProductsIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91732 0.333984H3.25065C1.63982 0.333984 0.333984 1.63982 0.333984 3.25065V7.91732C0.333984 9.52815 1.63982 10.834 3.25065 10.834H7.91732C9.52815 10.834 10.834 9.52815 10.834 7.91732V3.25065C10.834 1.63982 9.52815 0.333984 7.91732 0.333984ZM2.66732 3.25065C2.66732 2.92849 2.92849 2.66732 3.25065 2.66732H7.91732C8.23948 2.66732 8.50065 2.92849 8.50065 3.25065V7.91732C8.50065 8.23948 8.23948 8.50065 7.91732 8.50065H3.25065C2.92849 8.50065 2.66732 8.23948 2.66732 7.91732V3.25065ZM7.91732 13.1673H3.25065C1.63982 13.1673 0.333984 14.4732 0.333984 16.084V20.7507C0.333984 22.3615 1.63982 23.6673 3.25065 23.6673H7.91732C9.52815 23.6673 10.834 22.3615 10.834 20.7507V16.084C10.834 14.4732 9.52815 13.1673 7.91732 13.1673ZM2.66732 16.084C2.66732 15.7619 2.92849 15.5007 3.25065 15.5007H7.91732C8.23948 15.5007 8.50065 15.7619 8.50065 16.084V20.7507C8.50065 21.0728 8.23948 21.334 7.91732 21.334H3.25065C2.92849 21.334 2.66732 21.0728 2.66732 20.7507V16.084ZM16.084 13.1673H20.7507C22.3615 13.1673 23.6673 14.4732 23.6673 16.084V20.7507C23.6673 22.3615 22.3615 23.6673 20.7507 23.6673H16.084C14.4732 23.6673 13.1673 22.3615 13.1673 20.7507V16.084C13.1673 14.4732 14.4732 13.1673 16.084 13.1673ZM16.084 15.5007C15.7619 15.5007 15.5007 15.7619 15.5007 16.084V20.7507C15.5007 21.0728 15.7619 21.334 16.084 21.334H20.7507C21.0728 21.334 21.334 21.0728 21.334 20.7507V16.084C21.334 15.7619 21.0728 15.5007 20.7507 15.5007H16.084ZM20.7507 0.333984H16.084C14.4732 0.333984 13.1673 1.63982 13.1673 3.25065V7.91732C13.1673 9.52815 14.4732 10.834 16.084 10.834H20.7507C22.3615 10.834 23.6673 9.52815 23.6673 7.91732V3.25065C23.6673 1.63982 22.3615 0.333984 20.7507 0.333984ZM15.5007 3.25065C15.5007 2.92849 15.7619 2.66732 16.084 2.66732H20.7507C21.0728 2.66732 21.334 2.92849 21.334 3.25065V7.91732C21.334 8.23948 21.0728 8.50065 20.7507 8.50065H16.084C15.7619 8.50065 15.5007 8.23948 15.5007 7.91732V3.25065Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ProductsIcon;
