import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const SecurityIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon
      sx={{ width: '24px', height: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9V8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8V9H8ZM6 9.34505V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V9.34505C18.6016 9.7323 19 10.4079 19 11.1765V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V11.1765C5 10.4079 5.39842 9.7323 6 9.34505ZM7.17647 11C7.07901 11 7 11.079 7 11.1765V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V11.1765C17 11.079 16.921 11 16.8235 11H16H8H7.17647ZM12 13C13.1046 13 14 13.8954 14 15C14 16.1046 13.1046 17 12 17C10.8954 17 10 16.1046 10 15C10 13.8954 10.8954 13 12 13Z"
        fill={isLight ? '#5275EF' : '#37E6FF'}
      />
    </SvgIcon>
  );
};

export default SecurityIcon;
