import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import { styles } from './styles';

const MessagesIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#A3AED0',
}) => {
  return (
    <SvgIcon
      sx={styles.message}
      viewBox={'0 0 16 20'}
      width={'16'}
      height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C6.8954 0 6 0.89543 6 2V2.34141C3.66962 3.16508 2.00001 5.38756 2.00001 8V9.5V11.5996L0.382543 13.8237C-0.059917 14.432 -0.123577 15.2372 0.217783 15.9076C0.559133 16.5779 1.24776 17 2.00001 17H5.00001C5.00001 18.6569 6.3432 20 8 20C9.6569 20 11 18.6569 11 17H14C14.7523 17 15.4409 16.5779 15.7822 15.9076C16.1236 15.2372 16.0599 14.432 15.6175 13.8237L14 11.5996V9.5V8C14 5.38756 12.3304 3.16508 10 2.34141V2C10 0.89543 9.1046 0 8 0ZM9.0012 15H9H7H4.473H2.00001L3.45455 13L3.61748 12.776C3.86609 12.4341 4.00001 12.0223 4.00001 11.5996V9.5V8C4.00001 5.79086 5.79087 4 8 4C10.2091 4 12 5.79086 12 8V9.5V11.5996C12 12.0223 12.1339 12.4341 12.3825 12.776L12.5455 13L14 15H11.527H9.0012ZM8 18C7.4477 18 7 17.5523 7 17H9C9 17.5523 8.5523 18 8 18Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default MessagesIcon;
