export const styles = {
  root: {
    '& .MuiOutlinedInput-root': {
      paddingLeft: '16px',
      '& input': {
        padding: '22.5px 16px 8px 8px',
      },
      '&.Mui-disabled': {
        '& .MuiButtonBase-root': {},
      },
    },
    '& .MuiPhoneNumber-flagButton': {
      height: '17px',
      padding: 0,
      marginTop: '15px',
      width: '15px',
      '& svg': {
        width: '24px',
      },
    },
  },
};
