import { apply, call, put } from 'redux-saga/effects';
import { dashboardActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillRecentActivityActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchRecentActivity({
  payload,
}: PayloadAction<'activityFeed' | 'newsAndMedia'>) {
  try {
    yield put(dashboardActions.startRecentActivityFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `dashboard/recentActivity/`, query: `type=${payload}` },
    ]);
    const data: FillRecentActivityActionPayload = yield call([
      response,
      'json',
    ]);

    if (!response.ok) {
      yield throwError(response);
    }
    yield put(dashboardActions.fillRecentActivity(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(dashboardActions.stopRecentActivityFetching());
  }
}
