import React from 'react';
import { SvgIcon } from '@mui/material';

type ProfileArrowProps = {
  direction: boolean;
  color?: any;
};

const ProfileArrow: React.FC<ProfileArrowProps> = ({
  direction,
  color = 'white',
}) => {
  return (
    <SvgIcon
      sx={{
        transform: direction ? 'rotateZ(180deg)' : 'rotateZ(0)',
        fontSize: '14px!important',
        width: '14px',
        height: '8px',
      }}
      width={14}
      height={8}
      viewBox={'0 0 14 8'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.29289 0.29289C-0.09763 0.68342 -0.09763 1.31658 0.29289 1.70711L6.2929 7.7071C6.6834 8.0976 7.3166 8.0976 7.7071 7.7071L13.7071 1.70711C14.0976 1.31658 14.0976 0.68342 13.7071 0.29289C13.3166 -0.09763 12.6834 -0.09763 12.2929 0.29289L7 5.5858L1.70711 0.29289C1.31658 -0.09763 0.68342 -0.09763 0.29289 0.29289Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ProfileArrow;
