import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#01FFFF',
}) => {
  return (
    <SvgIcon
      sx={{ fontSize: '18px', width: '18px', height: '18px' }}
      viewBox={'0 0 18 18'}
      width={'18'}
      height={'18'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7071 1.70711C18.0976 1.31658 18.0976 0.68342 17.7071 0.29289C17.3166 -0.09763 16.6834 -0.09763 16.2929 0.29289L9 7.5858L1.70711 0.29289C1.31658 -0.09763 0.68342 -0.09763 0.29289 0.29289C-0.09763 0.68342 -0.09763 1.31658 0.29289 1.70711L7.5858 9L0.29289 16.2929C-0.09763 16.6834 -0.09763 17.3166 0.29289 17.7071C0.68342 18.0976 1.31658 18.0976 1.70711 17.7071L9 10.4142L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L10.4142 9L17.7071 1.70711Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default CloseIcon;
