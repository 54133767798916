// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillUsersActionPayload,
  UsersState,
} from './typedefs';

const initialState: UsersState = {
  isFetching: false,
  isInitialized: true,
  users: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillUsers(state, action: PayloadAction<FillUsersActionPayload>) {
      state.users = action.payload;
    },
    clearUsers(state) {
      state.users = null;
    },
    // inject
  },
});

export default usersSlice;
