import { object, ref, string } from 'yup';

export const schema = object().shape({
  reset_code: string().required('Code is required field'),
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .required(`Password is required field`)
    .matches(/(?=.*\d)/, {
      message: 'Password must contain at least one number',
    })
    .matches(/(?=.*[a-z])/, {
      message: 'Password must contain at least one lower case letter',
    })
    .matches(/(?=.*[A-Z])/, {
      message: 'Password must contain at least one upper case letter',
    }),
  password_repeat: string()
    .oneOf([ref('password'), null], 'Passwords do not match')
    .required(`Repeat password is required field`),
});
