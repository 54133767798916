import { Theme } from '@mui/material';

export const styles = {
  button: (theme: Theme) => ({
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'initial',
      width: '100%',
    },
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  }),
};
