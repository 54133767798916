import React, { lazy } from 'react';
import { book } from '../../../routes/book';
import { AppRoute } from '../../../setup/typedefs';
import NewsLayout, { NewsLayoutProps } from './index';
const News = lazy(() => import('../../../pages/private/News'));
// imports

export const newsRoutes: AppRoute<NewsLayoutProps>[] = [
  // inject
  {
    path: `${book.news}`,
    Component: <News />,
    Layout: NewsLayout,
    layoutProps: {
      title: 'News',
    },
  },
  {
    path: `${book.news}/:slug`,
    Component: <News />,
    Layout: NewsLayout,
    layoutProps: {
      title: 'News',
    },
  },
];
