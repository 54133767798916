import { apply, put } from 'redux-saga/effects';
import { newsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';

export function* deleteNews({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(newsActions.startFetching());

    const response: Response = yield apply(api, api.delete, [
      { endpoint: `news/${payload}/` },
    ]);

    if (!response.ok) {
      reject(response);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(newsActions.stopFetching());
  }
}
