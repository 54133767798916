import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const SupportIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0007 0.333984C5.55733 0.333984 0.333984 5.55733 0.333984 12.0007C0.333984 18.4439 5.55733 23.6673 12.0007 23.6673C18.4439 23.6673 23.6673 18.4439 23.6673 12.0007C23.6673 5.55733 18.4439 0.333984 12.0007 0.333984ZM21.2618 10.834C21.3095 11.2162 21.334 11.6055 21.334 12.0007C21.334 12.3958 21.3095 12.7851 21.2618 13.1673H18.9038C18.9675 12.7879 19.0007 12.3981 19.0007 12.0007C19.0007 11.6032 18.9675 11.2134 18.9038 10.834H21.2618ZM20.6556 8.50065C19.7085 6.16102 17.8403 4.29281 15.5007 3.34576V5.93712C16.5634 6.55186 17.4494 7.43796 18.0642 8.50065H20.6556ZM18.0642 15.5007H20.6556C19.7085 17.8403 17.8403 19.7085 15.5007 20.6556V18.0642C16.5634 17.4494 17.4494 16.5634 18.0642 15.5007ZM13.1673 18.9038C12.7879 18.9675 12.3981 19.0007 12.0007 19.0007C11.6032 19.0007 11.2134 18.9675 10.834 18.9038V21.2618C11.2162 21.3095 11.6055 21.334 12.0007 21.334C12.3958 21.334 12.7851 21.3095 13.1673 21.2618V18.9038ZM2.66732 12.0007C2.66732 12.3958 2.69186 12.7851 2.73952 13.1673H5.09744C5.03378 12.7879 5.00065 12.3981 5.00065 12.0007C5.00065 11.6032 5.03378 11.2134 5.09744 10.834H2.73952C2.69186 11.2162 2.66732 11.6055 2.66732 12.0007ZM3.34576 8.50065C4.29281 6.16102 6.16102 4.29281 8.50065 3.34576V5.93712C7.43796 6.55186 6.55186 7.43796 5.93712 8.50065H3.34576ZM8.50065 20.6556C6.16102 19.7085 4.29281 17.8403 3.34576 15.5007H5.93712C6.55186 16.5634 7.43796 17.4494 8.50065 18.0642V20.6556ZM13.1673 2.73952C12.7851 2.69186 12.3958 2.66732 12.0007 2.66732C11.6055 2.66732 11.2162 2.69186 10.834 2.73952V5.09744C11.2134 5.03378 11.6032 5.00065 12.0007 5.00065C12.3981 5.00065 12.7879 5.03378 13.1673 5.09744V2.73952ZM12.0007 7.33398C14.5779 7.33398 16.6673 9.42332 16.6673 12.0007C16.6673 14.5779 14.5779 16.6673 12.0007 16.6673C9.42332 16.6673 7.33398 14.5779 7.33398 12.0007C7.33398 9.42332 9.42332 7.33398 12.0007 7.33398Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default SupportIcon;
