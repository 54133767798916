import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    minHeight: '50px',
    minWidth: '50px',
    position: 'relative',
    background: theme.palette.common.generalCardHolder.background,
    boxShadow: theme.palette.common.generalCardHolder.borderShadow,
    border: theme.palette.common.generalCardHolder.border,
    mixBlendMode: 'normal',
    boxSizing: 'border-box',
    zIndex: 1,
    backdropFilter: 'blur(60px)',
    '&:before': {
      ...theme.mixins.cardBefore,
    },
  }),
  rootWithoutBefore: (theme: Theme) => ({
    minHeight: '50px',
    minWidth: '50px',
    position: 'relative',
    background: theme.palette.common.generalCardHolder.background,
    boxShadow: theme.palette.common.generalCardHolder.borderShadow,
    border: theme.palette.common.generalCardHolder.border,
    mixBlendMode: 'normal',
    boxSizing: 'border-box',
    zIndex: 1,
    backdropFilter: 'blur(60px)',
  }),
  rootActive: (theme: Theme) => ({
    background: theme.palette.common.achievements.activeSlideBackground,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: (theme: Theme) =>
        theme.palette.common.achievements.activeSlideBackgroundDecorate,
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: '115px',
    },
    '&:before': {
      ...theme.mixins.activeCardBefore,
      background: theme.palette.common.achievements.activeSlideBorderDecorate,
    },
  }),
};
