import { apply, call, put } from 'redux-saga/effects';
import { dashboardActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillWorkflowChartActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchWorkflowChart() {
  try {
    yield put(dashboardActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `dashboard/salesStats/` },
    ]);
    const data: FillWorkflowChartActionPayload = yield call([response, 'json']);
    if (!response.ok) {
      yield throwError(response);
    }

    yield put(dashboardActions.fillWorkflowChart(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(dashboardActions.stopFetching());
  }
}
