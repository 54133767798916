import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

export const newsSelector = (state: RootState) => state.news;

export const getIsNewsFetching = createSelector([newsSelector], (result) => {
  return { isFetching: result.isFetching };
});

export const getNewsList = createSelector([newsSelector], (result) => {
  return { newsList: result.news.results };
});

export const newsListNextUrl = createSelector([newsSelector], (result) => {
  return { nextUrl: result.news.next };
});

export const getOneNews = (slug?: string) =>
  createSelector([newsSelector], ({ news }) => {
    return {
      oneNews: news.results.find((item) => item.slug === slug),
    };
  });

export const getFirstNewSlug = createSelector([newsSelector], ({ news }) => {
  return news.results[0] && news.results[0].slug;
});

export const getAdminAllNewsList = createSelector(
  [newsSelector],
  ({ news }) => {
    if (!news.results) return [];

    return news.results.map((item) => {
      return {
        slug: item.slug,
        created_at: item.created_at,
        image: item.thumbnail,
        title: item.title,
        description: item.excerpt,
        active: item.is_active,
        action: '',
      };
    });
  },
);

export const getAdminAllNewsCurrentPage = createSelector(
  [newsSelector],
  ({ news }) => {
    if (!news.previous) return 1;

    return (
      Number(new URLSearchParams(news.previous.split('?')[1]).get('page')) +
        1 || 1
    );
  },
);

export const getAdminAllNewsPagination = (pageSize: number) =>
  createSelector(
    [newsSelector, getAdminAllNewsCurrentPage],
    ({ news: { count, next, previous, results } }, currentPage) => {
      if (!results) return null;

      return {
        totalRows: count,
        totalPage: Math.ceil(count / pageSize),
        currentPage: currentPage,
        rowsPerPage: pageSize,
        nextPage: next,
        prevPage: previous,
      };
    },
  );
