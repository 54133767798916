export const styles = {
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    '& svg': {
      width: '237px',
      height: '75px',
    },
  },
  smallLogo: {
    padding: '20px 10px',
    '& svg': {
      width: '100px',
      height: '75px',
    },
  },
};
