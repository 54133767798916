import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getIsSidebarOpen } from '../../../../bus/ui/selectors';
import { Modals } from '../../../../components/Modal';
import { styles } from './styles';
import { MobileTitle } from '../MobileTitle';
import {
  getIsNewsFetching,
  getNewsList,
  newsListNextUrl,
} from '../../../../bus/news/selectors';
import { newsActions } from '../../../../bus/news/actions';
import { NewsList } from '../../../../components/News/NewsList';
import { Grid } from '../../../../components/ui-kit/Grid';
import { getIsNewsInitialize } from '../../../../bus/app/selectors';
import { Preloader } from '../../../../components/ui-kit/Preloader';
import { Header } from '../../../../components/ui-kit/Header';
import CopyrightFooter from '../../../../components/ui-kit/CopyrightFooter';

type ContentProps = {
  title: string;
};

export const Content: React.FC<ContentProps> = ({ title }) => {
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const fetching = useSelector(getIsNewsInitialize);
  const { newsList } = useSelector(getNewsList);
  const { isFetching } = useSelector(getIsNewsFetching);
  const { nextUrl } = useSelector(newsListNextUrl);
  const [searchValue, setSearchValue] = useState('');

  const handlerSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    dispatch(newsActions.fetchNews({ search: searchValue, is_active: true }));
  }, [searchValue]);

  const nextNews = () => {
    !isFetching && nextUrl && dispatch(newsActions.loadMoreNews(nextUrl));
  };

  return (
    <Box sx={[styles.root, !isSidebarOpen && styles.sidebarCollapsed]}>
      <Header title={title} />
      {isSM && <MobileTitle title={title} />}
      <Box
        p={isSM ? 2.5 : 3.75}
        minHeight={`calc(100vh - ${isSM ? '250px' : '155px'})`}>
        {fetching && <Preloader fetching={fetching} />}
        <Grid container spacing={3.75}>
          <Grid item xxl={4.2} xl={4.4} xs={12}>
            <NewsList
              newsList={newsList}
              nextNews={nextNews}
              handlerSearchChange={handlerSearchChange}
            />
          </Grid>
          <Outlet />
        </Grid>
      </Box>
      <Modals />
      <CopyrightFooter />
    </Box>
  );
};
