import { apply, call, put } from 'redux-saga/effects';
import { faqActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { FAQItemType } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchFaq({ payload }: PayloadAction<string>) {
  try {
    yield put(faqActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `faqs/${payload}/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FAQItemType = yield call([response, 'json']);
    yield put(faqActions.fillFaq(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(faqActions.stopFetching());
  }
}
