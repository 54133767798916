import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

const paymentSelector = (state: RootState) => state.payment;

export const getIsPaymentFetching = createSelector(
  [paymentSelector],
  (result) => {
    return { isFetching: result.isFetching };
  },
);

export const getMercurioData = createSelector(
  [paymentSelector],
  ({ mercurioData }) => {
    return mercurioData?.params;
  },
);

export const getAdvcashData = createSelector(
  [paymentSelector],
  ({ advcashData }) => {
    return advcashData;
  },
);
