import { Components } from '@mui/material';

export const MuiButton: Partial<Components> = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '19px',
        letterSpacing: '-0.02em',
        textTransform: 'unset',
        color: 'white',
      },
      startIcon: {
        margin: 0,
      },
    },
    variants: [
      {
        props: { variant: 'primary' },
        style: ({ theme }) => ({
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          minWidth: 200,
          height: 60,
          background: 'transparent',
          borderRadius: 12,
          '&:after, &:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 0,
            transition: 'opacity 0.3s cubic-bezier(0,0,0.2,1)',
            borderRadius: 'inherit',
          },
          '&:after': {
            background: theme.palette.common.buttons.violet,
            opacity: 1,
          },
          '&:before': {
            background: theme.palette.common.buttons.violetHover,
            opacity: 0,
          },
          '&:hover, &.hover': {
            background: 'transparent',
            '&:after': {
              opacity: 0,
            },
            '&:before': {
              opacity: 1,
            },
          },
          '&:focus, &:active, &.focus': {
            backgroundImage: theme.palette.common.buttons.violetPressed,
          },
          '&.Mui-disabled': {
            color: theme.palette.common.buttons.standardColor,
            '&:before, &:after': {
              background: theme.palette.common.buttons.disabled,
            },
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'green' },
        style: ({ theme }) => ({
          '&:after': {
            background: theme.palette.common.buttons.green,
            opacity: 1,
          },
          '&:before': {
            background: theme.palette.common.buttons.greenHover,
            opacity: 0,
          },
          '&:focus, &:active, &.focus': {
            backgroundImage: theme.palette.common.buttons.greenPressed,
          },
          '&.Mui-disabled': {
            color: theme.palette.common.buttons.standardColor,
            '&:before, &:after': {
              background: theme.palette.common.buttons.disabled,
            },
          },
        }),
      },
      {
        props: { variant: 'secondary' },
        style: ({ theme }) => ({
          borderRadius: 12,
          minWidth: 200,
          minHeight: 50,
          border: 0,
          '&:before, &:after': {
            content: '""',
            boxSizing: 'border-box',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            padding: 4,
            borderRadius: 'inherit',
            mask: `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            lineHeight: 0,
            transition: 'opacity 0.3s cubic-bezier(0,0,0.2,1)',
          },
          '&:before': {
            // background: theme.palette.common.buttons.white,
            background: 'rgba(0,0,0,0.2)',
            opacity: 1,
          },
          '&:after': {
            // background: theme.palette.common.buttons.whiteHover,
            background: 'rgba(0,0,0,0.2)',
            opacity: 0,
          },
          '&:hover, &.hover': {
            background: 'transparent',
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 1,
            },
          },
          '&:focus, &:active, &.focus': {
            '&:after, &:before': {
              transition: 'none',
              background: theme.palette.common.buttons.whitePressed,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.common.buttons.standardColor,
            '&:before, &:after': {
              background: theme.palette.common.buttons.disabled,
            },
          },
        }),
      },
      {
        props: { variant: 'secondary', color: 'green' },
        style: ({ theme }) => ({
          '&:before': {
            background: theme.palette.common.buttons.green,
          },
          '&:after': {
            background: theme.palette.common.buttons.greenHover,
          },
          '&:focus, &:active, &.focus': {
            '&:after, &:before': {
              transition: 'none',
              background: theme.palette.common.buttons.greenPressed,
            },
          },
        }),
      },
      {
        props: { variant: 'secondary', color: 'violet' },
        style: ({ theme }) => ({
          color: theme.palette.common.black,
          '&:before': {
            background: theme.palette.common.buttons.violet,
          },
          '&:after': {
            background: theme.palette.common.buttons.violetHover,
          },
          '&:focus, &:active, &.focus': {
            '&:after, &:before': {
              transition: 'none',
              background: theme.palette.common.buttons.violetPressed,
            },
          },
        }),
      },
      {
        props: { variant: 'secondary', color: 'pink' },
        style: ({ theme }) => ({
          transition: 'color 0.3s cubic-bezier(0,0,0.2,1)',
          '&:before, &:after': {
            background: theme.palette.common.buttons.pink,
          },
          '&:hover, &.hover': {
            color: theme.palette.common.buttons.pink,
          },
          '&:focus, &:active, &.focus': {
            color: theme.palette.common.white,
            '&:after, &:before': {
              transition: 'none',
              background: theme.palette.common.buttons.pink,
            },
          },
        }),
      },
      {
        props: { variant: 'tertiary' },
        style: ({ theme }) => ({
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '21px',
          width: 100,
          height: 40,
          background: 'transparent',
          borderRadius: 12,
          border: `2px solid ${theme.palette.common.buttons.systemGreen}`,
          color: theme.palette.common.buttons.systemGreen,
          '&:hover, &.hover': {
            background: 'transparent',
            border: `2px solid ${theme.palette.common.buttons.systemGreenHover}`,
            color: theme.palette.common.buttons.systemGreenHover,
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid ${theme.palette.common.buttons.systemGreenFocus}`,
            color: theme.palette.common.buttons.systemGreenFocus,
          },
        }),
      },
      {
        props: { variant: 'tertiary', color: 'pink' },
        style: ({ theme }) => ({
          border: `2px solid ${theme.palette.common.buttons.systemPink}`,
          color: theme.palette.common.buttons.systemPink,
          '&:hover, &.hover': {
            border: `2px solid ${theme.palette.common.buttons.systemPinkHover}`,
            color: theme.palette.common.buttons.systemPinkHover,
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid ${theme.palette.common.buttons.systemPinkFocus}`,
            color: theme.palette.common.buttons.systemPinkFocus,
          },
        }),
      },
      {
        props: { variant: 'tertiary', color: 'blue' },
        style: ({ theme }) => ({
          border: `2px solid ${theme.palette.common.buttons.systemBlue}`,
          color: theme.palette.common.buttons.systemBlue,
          '&:hover, &.hover': {
            border: `2px solid ${theme.palette.common.buttons.systemBlueHover}`,
            color: theme.palette.common.buttons.systemBlueHover,
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid ${theme.palette.common.buttons.systemBlueFocus}`,
            color: theme.palette.common.buttons.systemBlueFocus,
          },
        }),
      },
      {
        props: { variant: 'tertiary', color: 'red' },
        style: ({ theme }) => ({
          border: `2px solid ${theme.palette.error.main}`,
          color: theme.palette.error.main,
          '&:hover, &.hover': {
            border: `2px solid ${theme.palette.common.buttons.systemBlueHover}`,
            color: theme.palette.common.buttons.systemBlueHover,
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid ${theme.palette.common.buttons.systemBlueFocus}`,
            color: theme.palette.common.buttons.systemBlueFocus,
          },
        }),
      },
      {
        props: { variant: 'tertiary', color: 'yellow' },
        style: ({ theme }) => ({
          border: `2px solid ${theme.palette.common.pendingChip}`,
          color: theme.palette.common.pendingChip,
          '&:hover, &.hover': {
            border: `2px solid ${theme.palette.common.buttons.systemBlueHover}`,
            color: theme.palette.common.buttons.systemBlueHover,
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid ${theme.palette.common.buttons.systemBlueFocus}`,
            color: theme.palette.common.buttons.systemBlueFocus,
          },
        }),
      },
      {
        props: { variant: 'tableButton' },
        style: ({ theme }) => ({
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '21px',
          width: 100,
          height: 40,
          background: 'transparent',
          borderRadius: 12,
          border: 0,
          color: '#4B5876',
          '&:before, &:after': {
            content: '""',
            boxSizing: 'border-box',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            padding: 3,
            borderRadius: 'inherit',
            mask: `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            lineHeight: 0,
            transition: 'opacity 0.3s cubic-bezier(0,0,0.2,1)',
          },
          '&:before': {
            background: theme.palette.common.buttons.white,
            opacity: 1,
          },
          '&:after': {
            background: theme.palette.common.buttons.whiteHover,
            opacity: 0,
          },
          '&:hover, &.hover': {
            background: 'transparent',
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 1,
            },
          },
          '&:focus, &:active, &.focus': {
            '&:after, &:before': {
              transition: 'none',
              background: theme.palette.common.buttons.whitePressed,
            },
          },
        }),
      },
      {
        props: { variant: 'tableButton', color: 'confirm' },
        style: ({ theme }) => ({
          '&:before': {
            background: theme.palette.common.buttons.violet,
          },
          '&:after': {
            background: theme.palette.common.buttons.violetHover,
          },
          '&:focus, &:active, &.focus': {
            '&:after, &:before': {
              transition: 'none',
              background: theme.palette.common.buttons.violetPressed,
            },
          },
        }),
      },
      {
        props: { variant: 'tableButton', color: 'cancel' },
        style: {
          border: `2px solid #F13D89`,
          color: '#F13D89',
          '&:hover, &.hover': {
            border: `2px solid rgba(241, 61, 137, 0.3)`,
            color: '#F13D89',
            background: 'rgba(241, 61, 137, 0.3)',
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 0,
            },
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid rgba(241, 61, 137, 0.3)`,
            color: '#F13D89',
            background: 'rgba(241, 61, 137, 0.3)',
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 0,
            },
          },
        },
      },
      {
        props: { variant: 'tableButton', color: 'green' },
        style: {
          border: `2px solid #3BC963`,
          color: '#3BC963',
          '&:before': {
            opacity: 0,
          },
          '&:after': {
            opacity: 0,
          },
          '&:hover, &.hover': {
            border: `2px solid #3BC963`,
            background: 'rgba(59,201,99,0.3)',
            color: '#3BC963',
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 0,
            },
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid #3BC963`,
            background: 'rgba(59,201,99,0.3)',
            color: '#3BC963',
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 0,
            },
          },
        },
      },
      {
        props: { variant: 'tableButton', color: 'yellow' },
        style: ({ theme }) => ({
          border: `2px solid ${theme.palette.common.pendingChip}`,
          color: theme.palette.common.pendingChip,
          '&:before': {
            opacity: 0,
          },
          '&:after': {
            opacity: 0,
          },
          '&:hover, &.hover': {
            border: `2px solid ${theme.palette.common.pendingChip}`,
            background: 'rgba(255,238,148,0.3)',
            color: theme.palette.common.pendingChip,
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 0,
            },
          },
          '&:focus, &:active, &.focus': {
            border: `2px solid ${theme.palette.common.pendingChip}`,
            background: 'rgba(255,238,148,0.3)',
            color: theme.palette.common.pendingChip,
            '&:before': {
              opacity: 0,
            },
            '&:after': {
              opacity: 0,
            },
          },
        }),
      },
      {
        props: { variant: 'square', color: 'dark' },
        style: ({ theme }) => ({
          minWidth: '40px',
          width: '40px',
          height: '40px',
          borderRadius: '10px',
          padding: 0,
          background: theme.palette.common.buttons.squareDark,
          color: theme.palette.common.buttons.squareDark,
          '& svg': {
            fontSize: '14px!important',
          },
          '&:hover, &.hover': {
            '& svg': {
              '& path': {
                stroke: 'white',
              },
            },
            background: theme.palette.common.buttons.squareDarkHover,
            color: theme.palette.common.buttons.squareDarkHover,
          },
          '&:focus, &:active, &.focus': {
            background: theme.palette.common.buttons.squareDark,
            color: theme.palette.common.buttons.squareDark,
            '& svg': {
              '& path': {
                stroke: theme.palette.common.white,
              },
            },
          },
          '&.Mui-disabled': {
            background: theme.palette.common.buttons.disabled,
            color: theme.palette.common.buttons.standardColor,
            '& svg': {
              '& path': {
                stroke: theme.palette.common.white,
              },
            },
          },
        }),
      },
      {
        props: { variant: 'square', color: 'blue' },
        style: ({ theme }) => ({
          minWidth: '30px',
          width: '30px',
          height: '30px',
          borderRadius: '8px',
          padding: 0,
          border: `1px solid ${theme.palette.common.birch}`,
          color: theme.palette.common.trueBlack,
          lineHeight: '22px',
          '& span': {
            bottom: '1px',
          },
          '&:hover, &.hover': {
            background: theme.palette.common.mainColorFirst,
            color: theme.palette.common.white,
          },
          '&:focus, &:active, &.focus': {
            color: theme.palette.common.white,
          },
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.common.buttons.disabled}`,
            color: theme.palette.common.trueBlack,
            opacity: '0.6',
          },
        }),
      },
      {
        props: { variant: 'square', color: 'none' },
        style: ({ theme }) => ({
          minWidth: '30px',
          width: 'max-content',
          height: '27px',
          padding: 0,
          '&:hover, &.hover': {
            background: 'inherit',
          },
          '&.Mui-disabled': {
            background: theme.palette.common.buttons.disabled,
            color: theme.palette.common.buttons.disabled,
          },
        }),
      },
      {
        props: { variant: 'square', color: 'birch' },
        style: ({ theme }) => ({
          width: 'max-content',
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
          padding: 0,
          color: theme.palette.common.birch,
          '&:hover, &.hover': {
            background: 'inherit',
          },
          '&.Mui-disabled': {
            background: theme.palette.common.buttons.disabled,
            color: theme.palette.common.buttons.disabled,
          },
        }),
      },
      {
        props: { variant: 'todo' },
        style: ({ theme }) => ({
          width: 'max-content',
          position: 'relative',
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          boxSizing: 'border-box',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '-0.02em',
          padding: '10px 16px 10px 19px',
          border: 0,
          background: theme.palette.common.buttons.todoBackground,
          color: theme.palette.common.white,
          borderRadius: '8px',
          '& .MuiButton-startIcon': {
            margin: '0 9px 0 0',
          },
          '& .MuiCheckbox-root': {
            padding: '0',
          },
          '&:before, &:after': {
            content: '""',
            boxSizing: 'border-box',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            padding: '1px',
            borderRadius: 'inherit',
            mask: `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            transition: 'opacity 0.3s cubic-bezier(0,0,0.2,1)',
          },
          '&:before': {
            background: theme.palette.common.buttons.whiteOpacity,
            opacity: 1,
          },
          '&:after': {
            background: theme.palette.common.buttons.whiteHover,
            opacity: 0,
          },
        }),
      },
      {
        props: { variant: 'topIcon' },
        style: ({ theme }) => ({
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          boxSizing: 'border-box',
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '-2%',
          padding: '18px',
          border: 0,
          background: theme.palette.common.buttons.todoBackground,
          color: theme.palette.common.white,
          minHeight: '88px',
          borderRadius: '16px',
          overflow: 'hidden',
          transition: '.3s',
          '& .MuiButton-startIcon': {
            margin: 0,
            marginBottom: '10px',
          },
          '&:before': {
            content: '""',
            boxSizing: 'border-box',
            display: 'inline-block',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            padding: '1px',
            borderRadius: 'inherit',
            mask: `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            transition: 'opacity 0.3s cubic-bezier(0,0,0.2,1)',
            background: theme.palette.common.buttons.whiteOpacity,
            opacity: 1,
          },
          '&:after': {
            content: '""',
            boxSizing: 'border-box',
            display: 'block',
            position: 'absolute',
            zIndex: 1,
            transition: '.2s',
            left: 0,
            bottom: '-120px',
            height: '100%',
            width: '100%',
            opacity: 0,
            background:
              'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,0) 50%)',
          },
          '&:hover': {
            paddingBottom: '24px',
            paddingTop: '12px',
            '&:after': {
              bottom: '-60px',
              background:
                'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,0) 50%)',
              opacity: 0.6,
            },
          },
        }),
      },
      {
        props: { variant: 'todo', color: 'birch' },
        style: ({ theme }) => ({
          '&:focus, &:active, &.focus': {
            color: theme.palette.common.birch,
            background: theme.palette.common.buttons.birchBackground,
            '&:before, &:after': {
              content: '""',
              boxSizing: 'border-box',
              display: 'inline-block',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 1,
              padding: '1px',
              borderRadius: 'inherit',
              mask: `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              transition: 'opacity 0.3s cubic-bezier(0,0,0.2,1)',
            },
            '&:before': {
              background: theme.palette.common.buttons.birchOpacity,
              opacity: 1,
            },
            '&:after': {
              background: theme.palette.common.birch,
              opacity: 0,
            },
          },
        }),
      },
      {
        props: { variant: 'back' },
        style: ({ theme }) => ({
          background: theme.palette.common.buttons.backBackground,
          borderRadius: '8px',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          padding: '8px 12px 8px 5px',
        }),
      },
      {
        props: { variant: 'darkLink' },
        style: ({ theme }) => ({
          background: theme.palette.common.darkLink,
          borderRadius: '12px',
          padding: '10.9px 24px 10.9px 26.9px',
        }),
      },
    ],
  },
};
