import { all, call, takeEvery } from 'redux-saga/effects';
import { royaltyFeesActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchRoyaltyFees,
} from './workers';
// imports
function* watchFetchRoyaltyFees() {
  yield takeEvery(royaltyFeesActions.fetchRoyaltyFees.type, fetchRoyaltyFees);
}
// watchers
export function* watchRoyaltyFees() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchRoyaltyFees),
  ]);
}
