import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderPasswordField } from './fields/RenderPasswordField';

const WithdrawApproveForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box mt={'33px'}>
        <Box marginBottom={'17px'}>
          <Typography
            variant={'Body/Table/Basic 15'}
            color={'common.inversText05'}>
            Add a transaction password as a proof of the transaction
          </Typography>
        </Box>
        <Field name={'password'} render={RenderPasswordField} />
        <Box mt={'30px'}>
          <Button
            type={'submit'}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            <Typography variant={'Button/Button 16'}>Approve</Typography>
          </Button>
          {props.hasSubmitErrors && (
            <Box>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default WithdrawApproveForm;
