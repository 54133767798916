// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillTeamActionPayload,
  TeamState,
  FillTeamUserActionPayload,
} from './typedefs';

const initialState: TeamState = {
  isFetching: false,
  isInitialized: true,
  newMembers: 0,
  totalDirectSales: 0,
  totalTeamSales: 0,
  totalDirectSalesUsd: 0,
  totalTeamSalesUsd: 0,
  referralLink: 'string',
  results: [],
  teamUser: null,
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillTeam(state, action: PayloadAction<FillTeamActionPayload>) {
      state.newMembers = action.payload.new_members;
      state.totalDirectSales = action.payload.total_direct_sales;
      state.totalTeamSales = action.payload.total_team_sales;
      state.totalDirectSalesUsd = action.payload.total_direct_sales_usd;
      state.totalTeamSalesUsd = action.payload.total_team_sales_usd;
      state.referralLink = action.payload.referral_link;
      state.results = action.payload.team;
    },
    fillTeamUser(state, action: PayloadAction<FillTeamUserActionPayload>) {
      state.teamUser = action.payload;
    },
    clearTeamUser(state) {
      state.teamUser = null;
    },
    // inject
  },
});

export default teamSlice;
