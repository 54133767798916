import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '60px 24px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 20px',
      width: 'calc(100% - 40px)',
    },
    '& > div': {
      '& img': {
        borderRadius: '12px',
        width: '100%',
      },
    },
    '& > button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
  }),
};
