import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { useDispatch, useSelector } from 'react-redux';
import { teamActions } from '../../../../bus/team/actions';
import { getTeamUser } from '../../../../bus/team/selectors';
import {
  formatToRound,
  formatToUSLocale,
  getFullNameCountryByCode,
} from '../../../../helpers/formating';
import { getNextRanks } from '../../../../bus/dashboard/selectors';
import { dashboardActions } from '../../../../bus/dashboard/actions';
import { TeamUserRank } from './TeamUserRank';
import { uiActions } from '../../../../bus/ui/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type TeamUserInformationProps = {
  id: number;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-5, 35],
    size: 150,
    blur: 40,
  }),
  (colors) => ({
    color: colors.secondary,
    position: [78, 5],
    size: 60,
    blur: 40,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [45, 105],
    size: 150,
    blur: 50,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [109, 50],
    size: 150,
  }),
];

export const TeamUserInformation: React.FC<TeamUserInformationProps> = ({
  id,
}) => {
  const dispatch = useDispatch();
  const teamUser = useSelector(getTeamUser);
  const nextRank = useSelector(getNextRanks(teamUser?.rank.level.id));
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(teamActions.fetchTeamUser(id));

    return () => {
      dispatch(teamActions.clearTeamUser());
    };
  }, []);

  useEffect(() => {
    if (!nextRank && teamUser?.rank.level.id) {
      dispatch(dashboardActions.fetchRanks());
    }
  }, [nextRank, teamUser?.rank.level.id]);

  const buyFzs = () => {
    dispatch(uiActions.closeModal());
    dispatch(
      uiActions.modal({
        modalName: 'PaymentChoice',
        modalPayload: {
          title: 'Choose your payment channel ',
          isOutSideClicked: true,
          titlePosition: 'self-start',
        },
      }),
    );
  };

  return teamUser ? (
    <>
      {teamUser.is_active && !teamUser.is_deleted ? (
        <GeneralCardHolder sx={styles.root} effects={effects}>
          <Box
            sx={styles.title}
            display={'flex'}
            gap={'10px'}
            justifyContent={'space-between'}>
            <Box display={'flex'} gap={'8px'}>
              <Box
                width={'60px'}
                height={'60px'}
                component={'img'}
                src={teamUser.rank.level.image}
                alt={'team icon'}
              />
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                gap={'8px'}>
                <Box display={'flex'} gap={'6px'} alignItems={'center'}>
                  <Typography variant={'Headlines/H5'}>
                    <Typography
                      variant={'Headlines/H5'}
                      maxWidth={'200px'}
                      width={'100%'}
                      display={'inline-block'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}>
                      {teamUser.first_name} {teamUser.last_name}
                    </Typography>
                    <Box
                      ml={'6px'}
                      component={'span'}
                      sx={styles.id}
                      display={'inline-flex'}>
                      <Typography component={'span'}>{id}</Typography>
                    </Box>
                  </Typography>
                </Box>
                <Typography
                  variant={'Body/Table/Basic 15'}
                  color={'common.teamPage.userModal.textCountry'}>
                  {getFullNameCountryByCode(teamUser.country)}
                </Typography>
              </Box>
            </Box>
            <Button
              sx={styles.button}
              variant={'secondary'}
              color={'violet'}
              onClick={buyFzs}>
              <Typography variant={'Button/Button 14'}>Buy FZS</Typography>
            </Button>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            sx={styles.central}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                variant={'Body/Table/Basic 13'}
                mb={'9px'}
                color={theme.palette.common.teamPage.userModal.subText}>
                Direct sales
              </Typography>
              <Typography
                title={
                  formatToUSLocale(
                    formatToRound(teamUser.rank.total_direct_sales || 0),
                  ) + ' FZS'
                }
                variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}
                mb={'7px'}
                color={theme.palette.common.teamPage.userModal.mainFirstColor}>
                {formatToUSLocale(
                  formatToRound(teamUser.rank.total_direct_sales || 0),
                )}
                &nbsp;FZS
              </Typography>
              <Typography
                title={
                  '$' +
                  formatToUSLocale(
                    formatToRound(teamUser.rank.total_direct_sales_usd || 0),
                  )
                }
                variant={'Body/Table/Basic 13'}
                mb={'30px'}
                color={theme.palette.common.teamPage.userModal.subText}>
                $
                {formatToUSLocale(
                  formatToRound(teamUser.rank.total_direct_sales_usd || 0),
                )}
              </Typography>
              <Typography
                variant={'Body/Table/Basic 15'}
                mb={'5px'}
                color={theme.palette.common.teamPage.userModal.subText}>
                Merchants
              </Typography>
              <Typography variant={'Headlines/H7'}>
                {teamUser.merchants_count || 0}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                variant={'Body/Table/Basic 13'}
                mb={'9px'}
                color={theme.palette.common.teamPage.userModal.subText}>
                Team sales
              </Typography>
              <Typography
                title={
                  formatToUSLocale(
                    formatToRound(teamUser.rank.total_team_sales || 0),
                  ) + ' FZS'
                }
                variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}
                mb={'7px'}
                color={theme.palette.common.teamPage.userModal.mainSecondColor}>
                {formatToUSLocale(
                  formatToRound(teamUser.rank.total_team_sales || 0),
                )}
                &nbsp;FZS
              </Typography>
              <Typography
                title={
                  '$' +
                  formatToUSLocale(
                    formatToRound(teamUser.rank.total_team_sales_usd || 0),
                  )
                }
                variant={'Body/Table/Basic 13'}
                mb={'30px'}
                color={theme.palette.common.teamPage.userModal.subText}>
                $
                {formatToUSLocale(
                  formatToRound(teamUser.rank.total_team_sales_usd || 0),
                )}
              </Typography>
              <Typography
                variant={'Body/Table/Basic 15'}
                mb={'5px'}
                color={theme.palette.common.teamPage.userModal.subText}>
                Highest Rank in the team
              </Typography>
              <Typography variant={'Headlines/H7'}>
                {teamUser.highest_team_rank}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={styles.userRanks}
            mt={'29.5px'}
            display={'flex'}
            flexDirection={'column'}
            gap={'24px'}>
            {nextRank && (
              <>
                <TeamUserRank
                  rankImage={teamUser.rank.level.image}
                  level={teamUser.rank.level.rank}
                  levelNum={teamUser.rank.level.level}
                  purchaseTotal={teamUser.rank.personal_purchase_total}
                  totalPurchaseTotal={
                    teamUser.rank.level.personal_purchase_total
                  }
                  teamsVolume={teamUser.rank.teams_total_volume || 0}
                  teamsTotalVolume={teamUser.rank.level.teams_volume_total}
                  teamsCount={teamUser.rank.teams_count}
                  minTeamsCount={teamUser.rank.level.teams_required}
                  mvtCurrent={teamUser.rank.volume_per_team_max}
                />
                <TeamUserRank
                  rankImage={nextRank.image}
                  level={nextRank.rank}
                  levelNum={nextRank.level}
                  purchaseTotal={teamUser.rank.personal_purchase_total}
                  totalPurchaseTotal={nextRank.personal_purchase_total}
                  teamsVolume={teamUser.rank.teams_total_volume || 0}
                  teamsTotalVolume={nextRank.teams_volume_total}
                  teamsCount={teamUser.rank.teams_count}
                  minTeamsCount={nextRank.teams_required}
                  mvtCurrent={teamUser.rank.volume_per_team_max}
                  isNext
                />
              </>
            )}
          </Box>
        </GeneralCardHolder>
      ) : (
        <GeneralCardHolder sx={styles.inactive} effects={effects}>
          <Typography variant={'Headlines/H4'}>
            This account is no longer active
          </Typography>
        </GeneralCardHolder>
      )}
    </>
  ) : null;
};
