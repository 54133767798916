import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const VentureCapitalIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 24 22'} width={'24'} height={'22'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4342 0.646816C11.7866 0.451061 12.215 0.451061 12.5673 0.646816L23.0673 6.48015C23.5321 6.73833 23.7635 7.27803 23.6301 7.79265C23.4968 8.30728 23.0324 8.66667 22.5008 8.66667H21.3341V19.1667H22.5008C23.1451 19.1667 23.6674 19.689 23.6674 20.3333C23.6674 20.9777 23.1451 21.5 22.5008 21.5H1.50076C0.856433 21.5 0.334093 20.9777 0.334093 20.3333C0.334093 19.689 0.856433 19.1667 1.50076 19.1667H2.66743V8.66667H1.50076C0.969145 8.66667 0.504753 8.30728 0.371392 7.79265C0.238042 7.27803 0.469462 6.73833 0.93418 6.48015L11.4342 0.646816ZM5.00076 19.1667H7.91743V8.66667H5.00076V19.1667ZM10.2508 19.1667H13.7508V8.66667H10.2508V19.1667ZM16.0841 19.1667H19.0008V8.66667H16.0841V19.1667ZM17.9985 6.33334H14.9174H9.08409H6.00308L12.0008 3.00129L17.9985 6.33334Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default VentureCapitalIcon;
