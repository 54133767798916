import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { humanFileSize } from '../../../../helpers/humanFileSize';
import { Button } from '../../Button';
import CloseIcon from '../../Icons/CloseIcon';
import { getValidToken, removeQuotes } from '../../../../REST/api';
import { getFileFromUrl } from '../../../../helpers/formating';
import { urlRegex } from '../../../../helpers/regex';

type ButtonWithoutDropProps = {
  testId: string;
  onChange: (event: any) => void;
  value: string;
};

export const UploadFileButton: React.FC<ButtonWithoutDropProps> = ({
  testId,
  onChange,
  value,
}) => {
  const theme = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const [blob, setBlob] = useState<string>('');

  const fetchImage = async (newFile: File) => {
    const tokenAccess = await getValidToken();
    const { file } = await getFileFromUrl(URL.createObjectURL(newFile));
    const formData = new FormData();
    formData.append('file', file, newFile.name);

    await fetch(`${process.env.REACT_APP_API_URL}/temporary-file/`, {
      method: 'post',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + removeQuotes(tokenAccess!),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        onChange(data.uid);
      });
  };

  const handleChange = async (e: any) => {
    setFile(e.target.files[0]);
    setBlob(URL.createObjectURL(e.target.files[0]));
    await fetchImage(e.target.files[0]);
  };

  const handleClick = () => {
    setFile(null);
    setBlob('');
    onChange(null);
  };

  useEffect(() => {
    const getUrl = async () => {
      if (value && urlRegex.test(value)) {
        const { file } = await getFileFromUrl(value);
        setBlob(URL.createObjectURL(file));
        setFile(file);
      }
    };

    getUrl().then();
  }, [value]);

  return (
    <Box sx={[styles.root, !!blob && styles.withFile]}>
      {!file && (
        <label>
          <input
            type={'file'}
            onChange={handleChange}
            data-testid={testId}
            accept={'image/*'}
          />
          <Typography variant={'Button/Button 16'}>Choose file</Typography>
        </label>
      )}
      {file && blob && (
        <Box
          sx={styles.preview}
          display={'flex'}
          gap={'10px'}
          alignItems={'center'}>
          <Box sx={styles.previewImg}>
            <Box component={'img'} alt={'preview image'} src={blob} />
          </Box>
          <Box
            sx={styles.textWrapper}
            display={'flex'}
            flexDirection={'column'}>
            <Typography sx={styles.text} variant={'Button/Button 16'}>
              {file.name}
            </Typography>
            <Typography variant={'Input/Title 12'}>
              {humanFileSize(file.size)}
            </Typography>
          </Box>
        </Box>
      )}
      {file && blob && (
        <Button
          startIcon={<CloseIcon color={theme.palette.common.white} />}
          onClick={handleClick}
        />
      )}
    </Box>
  );
};
