import React from 'react';
import { getFieldError } from '../../../helpers/getFieldError';
import { InputWithIcon } from '../../../components/ui-kit/inputs/InputWithIcon';
import { FieldRenderProps } from 'react-final-form';
import ProfileIcon from '../../../components/ui-kit/Icons/ProfileIcon';
import { useTheme } from '@mui/material';

const RenderEmailField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const theme = useTheme();

  return (
    <InputWithIcon
      fullWidth
      label={'ID'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
      icon={<ProfileIcon color={theme.palette.common.inputs.iconColor} />}
      {...input}
    />
  );
};

export default RenderEmailField;
