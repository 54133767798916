import { all, call, takeEvery } from 'redux-saga/effects';
import { dashboardActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchRecentActivity,
  fetchStatisticsChart,
  fetchTopCountries,
  fetchWorkflowChart,
  loadMoreActivity,
  fetchVentureTimer,
  fetchVentureInfo,
  fetchRanks,
  fetchPhysicalPin,
  fetchRoyaltyEvents,
} from './workers';

// imports
function* watchFetchTopCountries() {
  yield takeEvery(dashboardActions.fetchTopCountries.type, fetchTopCountries);
}
function* watchFetchRecentActivity() {
  yield takeEvery(
    dashboardActions.fetchRecentActivity.type,
    fetchRecentActivity,
  );
}
function* watchLoadMoreActivity() {
  yield takeEvery(dashboardActions.loadMoreActivity.type, loadMoreActivity);
}
function* watchFetchWorkflowChart() {
  yield takeEvery(dashboardActions.fetchWorkflowChart.type, fetchWorkflowChart);
}
function* watchFetchStatisticsChart() {
  yield takeEvery(
    dashboardActions.fetchStatisticsChart.type,
    fetchStatisticsChart,
  );
}
function* watchFetchVentureTimer() {
  yield takeEvery(dashboardActions.fetchVentureTimer.type, fetchVentureTimer);
}
function* watchFetchVentureInfo() {
  yield takeEvery(dashboardActions.fetchVentureInfo.type, fetchVentureInfo);
}
function* watchFetchRanks() {
  yield takeEvery(dashboardActions.fetchRanks.type, fetchRanks);
}
function* watchFetchPhysicalPin() {
  yield takeEvery(dashboardActions.fetchPhysicalPin.type, fetchPhysicalPin);
}
function* watchFetchRoyaltyEvents() {
  yield takeEvery(dashboardActions.fetchRoyaltyEvents.type, fetchRoyaltyEvents);
}
// watchers
export function* watchDashboard() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchRoyaltyEvents),
    call(watchFetchPhysicalPin),
    call(watchFetchRanks),
    call(watchFetchVentureInfo),
    call(watchFetchVentureTimer),
    call(watchFetchStatisticsChart),
    call(watchLoadMoreActivity),
    call(watchFetchWorkflowChart),
    call(watchFetchTopCountries),
    call(watchFetchRecentActivity),
  ]);
}
