import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminUsersIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5003 8.16699C11.3624 8.16699 12.1699 8.40076 12.8629 8.80837C13.1239 6.47832 15.1006 4.66699 17.5003 4.66699C20.0776 4.66699 22.167 6.75633 22.167 9.33366C22.167 10.6392 21.6309 11.8196 20.7668 12.6666C22.3156 13.7153 23.3337 15.4889 23.3337 17.5003V18.8337V22.167C23.3337 22.8113 22.8113 23.3337 22.167 23.3337C21.5226 23.3337 21.0003 22.8113 21.0003 22.167V18.8337V17.5003C21.0003 15.5673 19.4334 14.0003 17.5003 14.0003C15.5673 14.0003 14.0003 15.5673 14.0003 17.5003V18.8337V22.167C14.0003 22.8113 13.478 23.3337 12.8337 23.3337C12.1893 23.3337 11.667 22.8113 11.667 22.167V18.8337V17.5003H10.5003H8.83366C7.82113 17.5003 7.00033 18.3212 7.00033 19.3336V22.167C7.00033 22.8113 6.47799 23.3337 5.83366 23.3337C5.18933 23.3337 4.66699 22.8113 4.66699 22.167V19.3336C4.66699 17.7669 5.53177 16.402 6.81005 15.6905C6.19799 14.9011 5.83366 13.9099 5.83366 12.8337C5.83366 10.2563 7.923 8.16699 10.5003 8.16699ZM10.5003 15.167C11.789 15.167 12.8337 14.1224 12.8337 12.8337C12.8337 11.545 11.789 10.5003 10.5003 10.5003C9.21166 10.5003 8.16699 11.545 8.16699 12.8337C8.16699 14.1224 9.21166 15.167 10.5003 15.167ZM17.5003 11.667C18.789 11.667 19.8337 10.6223 19.8337 9.33366C19.8337 8.04499 18.789 7.00033 17.5003 7.00033C16.2116 7.00033 15.167 8.04499 15.167 9.33366C15.167 10.6223 16.2116 11.667 17.5003 11.667Z"
        fill="url(#paint0_linear_14271_87539)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87539"
          x1="23.3337"
          y1="18.947"
          x2="5.31029"
          y2="13.7453"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminUsersIconLT;
