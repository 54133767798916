// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  DashboardState,
  FillRecentActivityActionPayload,
  FillStatisticsChartActionPayload,
  FillTopCountriesActionPayload,
  FillWorkflowChartActionPayload,
  LoadMoreTodoActionPayload,
  FillVentureTimerActionPayload,
  FillVentureInfoActionPayload,
  FillRanksActionPayload,
  ListItem,
  FillPhysicalPinActionPayload,
  FillRoyaltyEventsActionPayload,
} from './typedefs';
import { emptyPaginationState, WithPagination } from '../../setup/typedefs';

const initialState: DashboardState = {
  isFetching: false,
  isInitialized: true,
  topCountries: [],
  recentActivity: emptyPaginationState as WithPagination<ListItem[]> & {
    isFetching: boolean;
  },
  workflowProgress: {
    direct_sales_all_time: 0,
    direct_sales_all_time_usd: 0,
    teams_sales_all_time: 0,
    teams_sales_all_time_usd: 0,
    to_last_month: 0,
    direct_sales_month: 0,
    teams_sales_month: 0,
    royalty_fee_all_time: 0,
  },
  chart: [],
  venture: {
    end_date: '',
    users_count: 0,
    amount: '',
  },
  ranks: [],
  physicalPin: [],
  royaltyEvents: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    startRecentActivityFetching(state) {
      state.recentActivity.isFetching = true;
    },
    stopRecentActivityFetching(state) {
      state.recentActivity.isFetching = false;
    },
    fillTopCountries(
      state,
      action: PayloadAction<FillTopCountriesActionPayload>,
    ) {
      state.topCountries = action.payload;
    },
    fillRecentActivity(
      state,
      action: PayloadAction<FillRecentActivityActionPayload>,
    ) {
      state.recentActivity = { ...state.recentActivity, ...action.payload };
    },
    pushMoreActivity(state, action: PayloadAction<LoadMoreTodoActionPayload>) {
      const { results, ...rest } = action.payload;
      state.recentActivity = {
        ...state.recentActivity,
        ...rest,
        results: [...state.recentActivity.results, ...results],
      };
    },
    fillWorkflowChart(
      state,
      action: PayloadAction<FillWorkflowChartActionPayload>,
    ) {
      state.workflowProgress = { ...action.payload };
    },
    fillStatisticsChart(
      state,
      action: PayloadAction<FillStatisticsChartActionPayload>,
    ) {
      state.chart = action.payload;
    },
    fillVentureTimer(
      state,
      action: PayloadAction<FillVentureTimerActionPayload[]>,
    ) {
      state.venture.end_date = action.payload[0].end_date;
    },
    fillVentureInfo(
      state,
      action: PayloadAction<FillVentureInfoActionPayload>,
    ) {
      state.venture.amount = action.payload.amount;
      state.venture.users_count = action.payload.users_count;
    },
    fillRanks(state, action: PayloadAction<FillRanksActionPayload>) {
      state.ranks = action.payload.results;
    },
    fillPhysicalPin(
      state,
      action: PayloadAction<FillPhysicalPinActionPayload>,
    ) {
      state.physicalPin = action.payload.results;
    },
    fillRoyaltyEvents(
      state,
      action: PayloadAction<FillRoyaltyEventsActionPayload>,
    ) {
      state.royaltyEvents = action.payload.results;
    },
    // inject
  },
});

export default dashboardSlice;
