import { Box } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { useSelector } from 'react-redux';
import { getQrCode } from '../../../../bus/auth/selectors';
import { getUrlExtension } from '../../../../helpers/formating';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type QrCodeProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-5, 75],
    blur: 40,
  }),
  (colors) => ({
    color: colors.violet,
    position: [105, 30],
    blur: 40,
  }),
];

export const QrCode: React.FC<QrCodeProps> = () => {
  const qrCode = useSelector(getQrCode);

  const downloadImage = (qrCode: string) => {
    const imgExt = getUrlExtension(qrCode);
    fetch(qrCode).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `qr_code.${imgExt}`;
        a.click();
      });
    });
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box sx={styles.imgHolder}>
        <Box component={'img'} src={qrCode} alt={'qr'} />
      </Box>
      <Button
        variant={'secondary'}
        color={'violet'}
        sx={{ color: 'common.buttons.standardColor' }}
        onClick={() => {
          qrCode && downloadImage(qrCode);
        }}>
        Download QR code
      </Button>
    </GeneralCardHolder>
  );
};
