import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';
import { ITeamMember } from './typedefs';
import { dashboardSelector } from '../dashboard/selectors';
import { RankItem } from '../dashboard/typedefs';

const teamSelector = (state: RootState) => state.team;

const getChildrenByGen = (list: ITeamMember[], ranks: RankItem[]) => {
  const resultArray: any[] = [];

  const map = list.reduce((a: any, b: any) => {
    a[b.generation] = a[b.generation] || [];
    a[b.generation].push(b);

    return a;
  }, {});

  for (const key in map) {
    if (map[key][0].generation > 0 && map[key][0].generation < 21) {
      resultArray.push({
        generation: map[key][0].generation,
        list: map[key],
        bonus: ranks.find((rank) => rank.level === +key)?.royalty_fee_percent,
      });
    }
  }

  return resultArray;
};

const findChildren = (
  list: ITeamMember[],
  parentId: number | undefined,
): ITeamMember[] => {
  const children = list.filter((item) => item.parent_id === parentId);
  if (!children) {
    return [];
  } else {
    return children.map((item) => ({
      ...item,
      children: findChildren(list, item.team.id),
    }));
  }
};

export const getIsTeamFetching = createSelector([teamSelector], (result) => {
  return { isFetching: result.isFetching };
});

export const getRootTeamMember = createSelector([teamSelector], (result) => {
  const root = result.results.find((item) => item.parent_id === null);
  const children = findChildren(result.results, root?.team?.id);

  return root ? { ...root, children: children } : null;
});

export const getGenTeam = createSelector(
  [teamSelector, dashboardSelector],
  (result, { ranks }) => {
    const sortedList = result.results
      .slice()
      .sort((a, b) => a.generation - b.generation);
    const gen = getChildrenByGen(sortedList, ranks);

    return gen || null;
  },
);

export const getCurrentUserTeamId = createSelector([teamSelector], (result) => {
  return {
    teamId: result.results?.find((item) => item.parent_id === null)?.team.id,
  };
});

export const getChildrenTeamMembers = (id: number | null) =>
  createSelector([teamSelector], (result) => {
    return { children: result.results.filter((item) => item.parent_id === id) };
  });

export const getTeamMembers = createSelector([teamSelector], (result) => {
  return { team: result.results };
});

export const getOwner = createSelector([teamSelector], (result) => {
  return result.results.find((item) => item.parent_id === null);
});

export const getTeamStatistics = createSelector([teamSelector], (result) => {
  return {
    newMembers: result.newMembers,
    totalDirectSales: result.totalDirectSales,
    totalTeamSales: result.totalTeamSales,
    totalDirectSalesUsd: result.totalDirectSalesUsd,
    totalTeamSalesUsd: result.totalTeamSalesUsd,
    referralLink: result.referralLink,
  };
});

export const getTeamUser = createSelector([teamSelector], ({ teamUser }) => {
  return teamUser;
});
