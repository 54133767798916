import { Components, Theme } from '@mui/material';

export const MuiSvgIcon: Partial<Components<Theme>> = {
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: 'medium' },
        style: {
          fontSize: '28px',
          width: '28px',
          height: '28px',
        },
      },
    ],
  },
};
