import { all, call, takeEvery } from 'redux-saga/effects';
import { resourcesActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchResources,
  fetchResourcesCategories,
  deleteResources,
  editResource,
  postResource,
  loadMoreResources,
} from './workers';

// imports
function* watchFetchResources() {
  yield takeEvery(resourcesActions.fetchResources.type, fetchResources);
}
function* watchFetchResourcesCategories() {
  yield takeEvery(
    resourcesActions.fetchResourcesCategories.type,
    fetchResourcesCategories,
  );
}
function* watchDeleteResources() {
  yield takeEvery(resourcesActions.deleteResources.type, deleteResources);
}
function* watchEditResource() {
  yield takeEvery(resourcesActions.editResource.type, editResource);
}
function* watchPostResource() {
  yield takeEvery(resourcesActions.postResource.type, postResource);
}
function* watchLoadMoreResources() {
  yield takeEvery(resourcesActions.loadMoreResources.type, loadMoreResources);
}
// watchers
export function* watchResources() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchLoadMoreResources),
    call(watchPostResource),
    call(watchEditResource),
    call(watchDeleteResources),
    call(watchFetchResourcesCategories),
    call(watchFetchResources),
  ]);
}
