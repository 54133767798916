import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
    padding: '0 38px 0 30px',
    height: '100px',
    background: theme.palette.common.header.background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      padding: '0 22.3px 0 24.67px',
    },
  }),
  right: (theme: Theme) => ({
    position: 'relative',
    maxWidth: '495px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
    },
    '& > button': {
      padding: '8px',
      borderRadius: '50%',
      '&:hover': {
        background: theme.palette.common.buttons.hoverHeader,
      },
    },
    '& > div': {
      '& > button': {
        padding: '8px',
        borderRadius: '50%',
        '&:hover': {
          background: theme.palette.common.buttons.hoverHeader,
        },
      },
    },
  }),
  fullLogo: {
    width: '272px',
    height: '88px',
    '& svg': {
      width: '272px!important',
      height: '88px!important',
    },
  },
};
