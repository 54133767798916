import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      '& .MuiTypography-title': {
        fontSize: '24px',
      },
      '& .MuiTypography-h5': {
        fontSize: '15px',
      },
    },
  }),
};
