import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { RenderTextField } from './fields/RenderTextField';
import { RenderDatePickerField } from './fields/RenderDatePickerField';
import { Button } from '../../components/ui-kit/Button';
import { styles } from './styles';

export const CreateNewTaskForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        sx={styles.root}
        width={'100%'}
        maxWidth={'447px'}
        margin={'31px 0 0 0'}>
        <Field name={'text'} render={RenderTextField} />
        <Field name={'date'} render={RenderDatePickerField} />
        <Box mt={'30px'}>
          <Button
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            sx={styles.button}
            role={'submit'}>
            Create
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
