import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import DeleteIcon from '../../../ui-kit/Icons/DeleteIcon';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { todoActions } from '../../../../bus/todo/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type ConfirmDeleteAllTodoProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-25, 66],
  }),
  (colors) => ({
    color: colors.primary,
    position: [120, 50],
    blur: 50,
  }),
];

export const ConfirmDeleteAllTodo: React.FC<ConfirmDeleteAllTodoProps> = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  const deleteTodos = () => {
    dispatch(todoActions.bulkDeleteTodo());
    dispatch(uiActions.closeModal());
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box maxWidth={'230px'}>
        <Typography variant={'h1'} textAlign={'center'}>
          Are you really want to delete this To-Do?
        </Typography>
      </Box>
      <Box display={'flex'} gap={'10px'}>
        <Button variant={'secondary'} onClick={closeModal}>
          <Typography variant={'h4'}>Cancel</Typography>
        </Button>
        <Button
          onClick={deleteTodos}
          startIcon={<DeleteIcon />}
          variant={'secondary'}
          color={'pink'}>
          <Typography variant={'h4'}>Delete</Typography>
        </Button>
      </Box>
    </GeneralCardHolder>
  );
};
