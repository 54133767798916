import { apply, call, put } from 'redux-saga/effects';
import { api } from '../../../../REST/api';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { ForgotPasswordPayload } from '../../typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { authActions } from '../../actions';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* forgotPassword({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ForgotPasswordPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/reset-password-request/`, body, unsafe: true },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }

    if (!response.ok) {
      //TODO: add tooltips
      alert('forgot password - fail');
      reject();
      // throw new Error('forgot password - fail');
      yield throwError(response);
    }

    yield put(authActions.allowCountdown(true));

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
