import React from 'react';
import { book } from 'src/routes/book';
import { ActionCreatorWithPayload, Dispatch } from '@reduxjs/toolkit';
import DashboardIcon from '../Icons/DashboardIcon';
import BalanceIcon from '../Icons/BalanceIcon';
import MerchantsIcon from '../Icons/MerchantsIcon';
import TeamIcon from '../Icons/TeamIcon';
import ProductsIcon from '../Icons/ProductsIcon';
import AchievementsIcon from '../Icons/AchievementsIcon';
import ResourcesIcon from '../Icons/ResourcesIcon';
import FAQIcon from '../Icons/FAQIcon';
import SupportIcon from '../Icons/SupportIcon';
import AdminApplicationsIcon from '../Icons/AdminApplicationsIcon';
import AdminTransactionsIcon from '../Icons/AdminTransactionsIcon';
import AdminFeesIcon from '../Icons/AdminFeesIcon';
import AdminWithdrawalsIcon from '../Icons/AdminWithdrawalsIcon';
import AdminFaqIcon from '../Icons/AdminFaqIcon';
import PoolsIcon from '../Icons/PoolsIcon';
import VentureCapitalIcon from '../Icons/VentureCapitalIcon';
import NewsIcon from '../Icons/NewsIcon';
import AdminUsersIcon from '../Icons/AdminUsersIcon';
import RoyaltyFeesIcon from '../Icons/RoyaltyFeesIcon';
import AdminResourcesIcon from '../Icons/AdminResourcesIcon';
import AdminNewsIcon from '../Icons/AdminNewsIcon';
import AdminApplicationsIconLT from '../Icons/lightTeme/DashboardIconLT';
import DashboardIconLT from '../Icons/lightTeme/DashboardIconLT';
import BalanceIconLT from '../Icons/lightTeme/BalanceIconLT';
import TeamIconLT from '../Icons/lightTeme/TeamIconLT';
import MerchantsIconLT from '../Icons/lightTeme/MerchantsIconLT';
import PoolsIconLT from '../Icons/lightTeme/PoolsIconLT';
import VentureCapitalIconLT from '../Icons/lightTeme/VentureCapitalIconLT';
import AchievementsIconLT from '../Icons/lightTeme/AchievementsIconLT';
import ProductsIconLT from '../Icons/lightTeme/ProductsIconLT';
import NewsIconLT from '../Icons/lightTeme/NewsIconLT';
import ResourcesIconLT from '../Icons/lightTeme/ResourcesIconLT';
import FAQIconLT from '../Icons/lightTeme/FAQIconLT';
import SupportIconLT from '../Icons/lightTeme/SupportIconLT';
import AdminUsersIconLT from '../Icons/lightTeme/AdminUsersIconLT';
import AdminTransactionsIconLT from '../Icons/lightTeme/AdminTransactionsIconLT';
import AdminWithdrawalsIconLT from '../Icons/lightTeme/AdminWithdrawalsIconLT';
import AdminFeesIconLT from '../Icons/lightTeme/AdminFeesIconLT';
import RoyaltyFeesIconLT from '../Icons/lightTeme/RoyaltyFeesIconLT';
import AdminFaqIconLT from '../Icons/lightTeme/AdminFaqIconLT';
import AdminResourcesIconLT from '../Icons/lightTeme/AdminResourcesIconLT';
import AdminNewsIconLT from '../Icons/lightTeme/AdminNewsIconLT';

export type NavMenuItem = {
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconLT?: React.FC<React.SVGProps<SVGSVGElement>>;
  link: string;
  active: boolean;
  onClick?: (dispatch: Dispatch, action: ActionCreatorWithPayload<any>) => void;
};

export const MENUITEMS: NavMenuItem[] = [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    iconLT: DashboardIconLT,
    link: '/dashboard',
    active: false,
  },
  {
    label: 'Balance',
    link: '/balance',
    icon: BalanceIcon,
    iconLT: BalanceIconLT,
    active: false,
  },
  {
    label: 'Team',
    link: '/team',
    icon: TeamIcon,
    iconLT: TeamIconLT,
    active: false,
  },
  {
    label: 'Merchants',
    link: '/merchants',
    icon: MerchantsIcon,
    iconLT: MerchantsIconLT,
    active: false,
  },
  {
    label: 'Pools',
    link: '/pools',
    icon: PoolsIcon,
    iconLT: PoolsIconLT,
    active: false,
  },
  {
    label: 'Venture capital',
    link: '/ventureCapital',
    icon: VentureCapitalIcon,
    iconLT: VentureCapitalIconLT,
    active: false,
  },
  {
    label: 'Achievements',
    link: '/achievements',
    icon: AchievementsIcon,
    iconLT: AchievementsIconLT,
    active: false,
  },
  {
    label: 'Products',
    link: '/products',
    icon: ProductsIcon,
    iconLT: ProductsIconLT,
    active: false,
  },
  {
    label: 'News',
    link: '/news',
    icon: NewsIcon,
    iconLT: NewsIconLT,
    active: false,
  },
  {
    label: 'Resources',
    link: '/resources',
    icon: ResourcesIcon,
    iconLT: ResourcesIconLT,
    active: false,
  },
  {
    label: 'FAQ',
    link: '/faq',
    icon: FAQIcon,
    iconLT: FAQIconLT,
    active: false,
  },
  {
    label: 'Support',
    link: '',
    icon: SupportIcon,
    iconLT: SupportIconLT,
    active: false,
    onClick: (dispatch: Dispatch, action: ActionCreatorWithPayload<any>) => {
      dispatch(
        action({
          modalName: 'Support',
          modalPayload: {
            title: 'Contact Us',
            isOutSideClicked: true,
            titlePosition: 'self-start',
          },
        }),
      );
    },
  },
];

export const ADMINMENUITEMS: NavMenuItem[] = [
  {
    label: 'Admin Applications',
    link: book.adminApplications,
    icon: AdminApplicationsIcon,
    iconLT: AdminApplicationsIconLT,
    active: false,
  },
  {
    label: 'Admin Users',
    link: book.adminUsers,
    icon: AdminUsersIcon,
    iconLT: AdminUsersIconLT,
    active: false,
  },
  {
    label: 'Admin Merchants',
    link: book.adminMerchants,
    icon: TeamIcon,
    iconLT: TeamIconLT,
    active: false,
  },
  {
    label: 'Admin Transactions',
    link: book.adminTransactions,
    icon: AdminTransactionsIcon,
    iconLT: AdminTransactionsIconLT,
    active: false,
  },
  {
    label: 'Admin Withdrawals',
    link: book.adminWithdrawals,
    icon: AdminWithdrawalsIcon,
    iconLT: AdminWithdrawalsIconLT,
    active: false,
  },
  {
    label: 'Admin Fees',
    link: book.adminFees,
    icon: AdminFeesIcon,
    iconLT: AdminFeesIconLT,
    active: false,
  },
  {
    label: 'Royalty fees 75/25',
    link: book.royaltyFees,
    icon: RoyaltyFeesIcon,
    iconLT: RoyaltyFeesIconLT,
    active: false,
  },
  {
    label: 'Admin FAQ',
    link: book.adminFaq,
    icon: AdminFaqIcon,
    iconLT: AdminFaqIconLT,
    active: false,
  },
  {
    label: 'Admin Resources',
    link: book.adminResources,
    icon: AdminResourcesIcon,
    iconLT: AdminResourcesIconLT,
    active: false,
  },
  {
    label: 'Admin News',
    link: book.adminNews,
    icon: AdminNewsIcon,
    iconLT: AdminNewsIconLT,
    active: false,
  },
  {
    label: 'Admin Products',
    link: book.adminProducts,
    icon: ProductsIcon,
    iconLT: ProductsIconLT,
    active: false,
  },
];
