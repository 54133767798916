import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import { styles } from './styles';

const FullScreenIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon sx={styles.full} viewBox={'0 0 20 22'} width={'20'} height={'22'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0007 5.97825C20.0007 6.5306 19.5531 6.97837 19.0008 6.97837C18.4486 6.97837 18.001 6.5306 18.001 5.97825V4.38664L13.6456 8.6815C13.2523 9.06928 12.6192 9.06478 12.2316 8.67144C11.8439 8.27811 11.8484 7.64488 12.2416 7.2571L16.5624 2.99633L15.0197 2.99632C14.4675 2.99632 14.0198 2.54855 14.0198 1.9962C14.0198 1.44385 14.4675 0.996084 15.0197 0.996094L19.0008 0.996114C19.5531 0.996114 20.0007 1.44388 20.0007 1.99623V5.97825ZM0 5.97912C0 6.53135 0.44776 6.97901 1.0001 6.97902C1.55244 6.97902 2.00021 6.53135 2.00021 5.97912V4.41192L6.26594 8.67677C6.65651 9.06728 7.28974 9.06728 7.6803 8.67677C8.0709 8.28629 8.0709 7.65319 7.6803 7.2627L3.41456 2.99785L4.98212 2.99784C5.53446 2.99783 5.98222 2.55016 5.98221 1.99794C5.98221 1.44571 5.53444 0.998044 4.9821 0.998044L1.0001 0.998074C0.44776 0.998074 0 1.44574 0 1.99797V5.97912ZM19.0005 15.0187C19.5527 15.0187 20.0004 15.4665 20.0004 16.0189V20.0009C20.0004 20.5532 19.5527 21.001 19.0005 21.001H15.0194C14.4672 21.001 14.0195 20.5533 14.0195 20.0009C14.0195 19.4486 14.4672 19.0008 15.0194 19.0008H16.5866L12.2364 14.6496C11.8459 14.259 11.8459 13.6258 12.2364 13.2352C12.6269 12.8446 13.26 12.8446 13.6504 13.2352L18.0006 17.5864V16.0189C18.0006 15.4665 18.4483 15.0187 19.0005 15.0187ZM0.000329971 16.018C0.000329971 15.4658 0.44809 15.0181 1.00043 15.0181C1.55277 15.0181 2.00053 15.4658 2.00054 16.018V17.5607L6.26113 13.24C6.64891 12.8467 7.28212 12.8422 7.67545 13.2299C8.0688 13.6176 8.0733 14.2507 7.6855 14.644L3.39082 18.9993H4.98245C5.53479 18.9993 5.98255 19.4469 5.98254 19.9992C5.98254 20.5514 5.53477 20.9991 4.98243 20.9991L1.00043 20.999C0.44809 20.999 0.000329971 20.5514 0.000329971 19.9991V16.018Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default FullScreenIcon;
