import React from 'react';
import { useDispatch } from 'react-redux';
import { todoActions } from '../../../../bus/todo/actions';
import { uiActions } from '../../../../bus/ui/actions';
import FinalForm from '../../../FinalForm';
import { CreateNewTaskForm } from '../../../../Forms/CreateNewTaskForm';
import { schema } from '../../../../Forms/CreateNewTaskForm/schema';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type ModalProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-9, 73],
    blur: 35,
  }),
  (colors) => ({
    color: colors.violet,
    position: [105, 25],
    blur: 35,
  }),
];

export const CreateNewTask: React.FC<ModalProps> = () => {
  const dispatch = useDispatch();

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={CreateNewTaskForm}
        onSubmit={(value) => dispatch(todoActions.createTodo(value))}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
