import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';

export interface IItem {
  id: number;
  text: string;
  royalty: string;
}

type RemainingListProps = {
  remainingList: IItem[];
};

export const RemainingList: React.FC<RemainingListProps> = ({
  remainingList,
}) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <List sx={styles.remainingList}>
      {remainingList?.map((item) => (
        <ListItem key={item.id}>
          <ListItemIcon>
            <Box sx={styles.circle} />
          </ListItemIcon>
          <Box sx={styles.remainingText}>
            <Typography
              variant={isSM ? 'Poppins600/2/small' : 'Poppins600/2'}
              color={'common.buttons.pink'}>
              {item.royalty}
            </Typography>
            <Typography
              sx={{ color: 'common.notifications.whiteText' }}
              variant={'Poppins400'}>
              {item.text}
            </Typography>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};
