import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import VisibilityToggleOffIcon from '../../Icons/VisibilityToggleOffIcon';
import VisibilityToggleOnIcon from '../../Icons/VisibilityToggleOnIcon';
import { slugString } from '../../../../helpers/formating';
import { styles } from './styles';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';
import VisibilityToggleOffIconLT from '../../Icons/lightTeme/VisibilityToggleOffIconLT';
import VisibilityToggleOnIconLT from '../../Icons/lightTeme/VisibilityToggleOnIconLT';

export type PasswordFieldProps = {
  value: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  setShowPassword: (bool: boolean) => void;
  showPassword: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  testId?: string;
  sx?: React.CSSProperties;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const PasswordInput: React.FC<PasswordFieldProps> = ({
  value,
  onChange,
  setShowPassword,
  showPassword,
  label,
  error,
  helperText,
  fullWidth,
  testId,
  onBlur,
  ...rest
}) => {
  const isLightTheme = useIsLightTheme();

  return (
    <FormControl
      sx={styles.root}
      fullWidth={fullWidth}
      variant="outlined"
      error={error}>
      <InputLabel htmlFor={`outlined-adornment-password-${slugString(label)}`}>
        {label}
      </InputLabel>
      <OutlinedInput
        fullWidth={fullWidth}
        autoComplete={'new-password'}
        id={`outlined-adornment-password-${slugString(label)}`}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onBlur={(event) => setTimeout(() => onBlur(event), 100)}
        inputProps={{ 'data-testid': testId }}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
              }}
              edge="end">
              {showPassword ? (
                isLightTheme ? (
                  <VisibilityToggleOnIconLT />
                ) : (
                  <VisibilityToggleOnIcon />
                )
              ) : isLightTheme ? (
                <VisibilityToggleOffIconLT />
              ) : (
                <VisibilityToggleOffIcon />
              )}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        {...rest}
      />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
