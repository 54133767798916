import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    boxSizing: 'border-box',
    background: theme.palette.common.news.cardBg,
    boxShadow: theme.palette.common.news.cardShadow,
    mixBlendMode: 'normal',
    '&:before': {
      ...theme.mixins.cardBefore,
    },
    [theme.breakpoints.down('sm')]: {
      flexShrink: 0,
    },
    '& .MuiListItemButton-root': {
      height: 'initial',
      padding: '20px',
      gap: '40px',
      margin: '0',
      justifyContent: 'space-between',
      '&:after': {
        display: 'none',
      },
    },
  }),
  content: (theme: Theme) => ({
    '& > span:nth-of-type(odd)': {
      color: theme.palette.common.news.text,
    },
    '& > span:nth-of-type(2)': {
      margin: '8px 0 5px 0',
    },
  }),
  chosen: (theme: Theme) => ({
    border: `1px solid ${theme.palette.common.news.cardChoosenBorder}`,
    background: theme.palette.common.news.cardChoosen,
    '&:before': {
      ...theme.mixins.cardBefore,
      background: (theme: Theme) => theme.palette.common.news.cardChoosenBefore,
      padding: '2px',
    },
  }),
  image: {
    '& img': {
      width: '100%',
    },
  },
};
