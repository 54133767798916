import React from 'react';
import { Box, Typography } from '@mui/material';
import FinalForm from '../../../components/FinalForm';
import { schema } from '../../../Forms/VerificationCodeForm/schema';
import { authActions } from '../../../bus/auth/actions';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { book } from '../../../routes/book';
import { useNavigate } from 'react-router-dom';
import { styles } from './styles';
import { EffectFunc } from '../../../components/ui-kit/GeneralCardHolder';
import { VerificationCodeForm } from '../../../Forms/VerificationCodeForm';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [5, 94],
    size: 100,
    blur: 70,
  }),
  (colors) => ({
    color: colors.primary,
    position: [-5, 54],
    size: 180,
    blur: 120,
  }),
  (colors) => ({
    color: colors.violet,
    position: [95, 50],
    size: 100,
    blur: 60,
  }),
];

const VerificationCodePage = () => {
  const navigate = useNavigate();

  const onSubmitSuccess = () => {
    const path: string = localStorage.getItem('target_pathname') || book.home;

    navigate(path, { replace: true });
  };

  return (
    <>
      <Box mb={10}>
        <FormBackgroundHolder effects={effects}>
          <Box
            sx={styles.root}
            display={'flex'}
            flexDirection={'column'}
            textAlign={'center'}>
            <Typography sx={{ zIndex: 1 }} variant={'Headlines/H3'}>
              Enter verification code
            </Typography>
            <Box
              maxWidth={'339px'}
              width={'100%'}
              alignSelf={'center'}
              mt={'24px'}>
              <Typography
                sx={{ opacity: 0.5 }}
                variant={'Body/Table/Basic 15'}
                color={'common.declineChip'}>
                Enter 6 digit code from your authentication app
              </Typography>
            </Box>
            <FinalForm
              component={VerificationCodeForm}
              sagaAction={authActions.postTwoFactor}
              onSubmitSuccess={onSubmitSuccess}
              initialValues={{}}
              schema={schema}
            />
          </Box>
        </FormBackgroundHolder>
      </Box>
    </>
  );
};

export default VerificationCodePage;
