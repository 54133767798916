import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    '& .MuiDialog-paper': {
      borderRadius: '16px',
      maxHeight: '100%',
    },
    '& .MuiTypography-h1': {
      maxWidth: 'calc(100% - 90px)',
      width: '100%',
      position: 'absolute',
      top: '30px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 10,
      [theme.breakpoints.down('md')]: {
        paddingRight: '25px',
        maxWidth: 'calc(100% - 50px)',
        top: '20px',
      },
    },
    '& .MuiPaper-root': {
      background: theme.palette.common.profileBackground,
      maxWidth: 'calc(100% - 40px)',
      maxHeight: 'calc(100% - 40px)',
    },
  }),
  qwe: (theme: Theme) => ({
    position: 'relative',
    overflow: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '2px',
      height: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.mainColorFirst,
      borderRadius: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  }),
  effects: (theme: Theme) => ({
    background: theme.palette.common.profileBackground,
  }),
  closeButton: (theme: Theme) => ({
    position: 'absolute',
    top: '30px',
    right: '30px',
    zIndex: 20,
    width: '18px',
    height: '18px',
    minWidth: '18px',
    '&:hover': {
      background: 'transparent',
    },
    '& > .MuiButton-startIcon': {
      '& > *:nth-of-type(1)': {
        fontSize: '18px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      top: '23px',
      right: '23px',
    },
  }),
};
