import { Box, CircularProgress, Fade, Portal } from '@mui/material';
import React, { useEffect } from 'react';
import { styles } from './styles';
import { useSelector } from 'react-redux';
import { getIsSidebarOpen } from '../../../bus/ui/selectors';

type PreloaderProps = {
  fetching: boolean;
};

export const Preloader: React.FC<PreloaderProps> = ({ fetching }) => {
  const isSidebarOpen = useSelector(getIsSidebarOpen);

  useEffect(() => {
    document.body.style.overflow = fetching ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [fetching]);

  return (
    <Portal>
      <Box
        sx={[
          styles.root,
          styles.sidebarNotCollapsed,
          !isSidebarOpen && styles.sidebarCollapsed,
        ]}>
        <Fade
          in={fetching}
          style={{
            transitionDelay: fetching ? '100ms' : '100ms',
          }}
          unmountOnExit>
          <CircularProgress />
        </Fade>
      </Box>
    </Portal>
  );
};
