import { apply, call, put } from 'redux-saga/effects';
import { teamActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { FillTeamUserActionPayload } from '../../typedefs';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchTeamUser({ payload }: PayloadAction<number>) {
  try {
    yield put(teamActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `team-user/${payload}/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillTeamUserActionPayload = yield call([response, 'json']);

    yield put(teamActions.fillTeamUser(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(teamActions.stopFetching());
  }
}
