import { apply, call, put } from 'redux-saga/effects';
import { adminActions } from '../../actions';
import { api } from '../../../../REST/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers } from '../../../../setup/typedefs';
import { FillAdminWithdrawActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchAdminWithdraw({
  payload,
}: PayloadAction<FetchListWorkers>) {
  try {
    yield put(adminActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `transactions/`, query: payload.query },
    ]);

    if (!response.ok) {
      // throw new Error('failed to fetch');
      yield throwError(response);
    }

    const data: FillAdminWithdrawActionPayload = yield call([response, 'json']);

    yield put(adminActions.fillAdminWithdraw(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(adminActions.stopFetching());
  }
}
