import { all, call, takeEvery } from 'redux-saga/effects';
import { poolsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchPools,
  fetchPoolsLevels,
  fetchPoolYears,
  fetchYearStats,
  poolInvest,
  poolReinvest,
  monthsEarnings,
} from './workers';

// imports
function* watchFetchPools() {
  yield takeEvery(poolsActions.fetchPools.type, fetchPools);
}
function* watchFetchPoolsLevels() {
  yield takeEvery(poolsActions.fetchPoolsLevels.type, fetchPoolsLevels);
}
function* watchFetchPoolYears() {
  yield takeEvery(poolsActions.fetchPoolYears.type, fetchPoolYears);
}
function* watchFetchYearStats() {
  yield takeEvery(poolsActions.fetchYearStats.type, fetchYearStats);
}
function* watchPoolInvest() {
  yield takeEvery(poolsActions.poolInvest.type, poolInvest);
}
function* watchPoolReinvest() {
  yield takeEvery(poolsActions.poolReinvest.type, poolReinvest);
}
function* watchMonthsEarnings() {
  yield takeEvery(poolsActions.monthsEarnings.type, monthsEarnings);
}
// watchers
export function* watchPools() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchMonthsEarnings),
    call(watchPoolReinvest),
    call(watchPoolInvest),
    call(watchFetchYearStats),
    call(watchFetchPoolsLevels),
    call(watchFetchPoolYears),
    call(watchFetchPools),
  ]);
}
