import { createAction } from '@reduxjs/toolkit';
import appSlice from './slice';

export const appActions = {
  ...appSlice.actions,
  isInitializeBalance: createAction('app/usInitializeBalance'),
  isInitializeDashboard: createAction('app/isInitializeDashboard'),
  isInitializeTeam: createAction('app/isInitializeTeam'),
  isInitializeMerchants: createAction('app/isInitializeMerchants'),
  isInitializePools: createAction('app/isInitializePools'),
  isInitializeVentureCapital: createAction('app/isInitializeVentureCapital'),
  isInitializeAchievements: createAction('app/isInitializeAchievements'),
  isInitializeProducts: createAction('app/isInitializeProducts'),
  isInitializeNews: createAction('app/isInitializeNews'),
  isInitializeResources: createAction('app/isInitializeResources'),
  isInitializeFaq: createAction('app/isInitializeFaq'),
  isInitializeAdminApplication: createAction(
    'app/isInitializeAdminApplication',
  ),
  isInitializeAdminUsers: createAction('app/isInitializeAdminUsers'),
  isInitializeAdminMerchants: createAction('app/isInitializeAdminMerchants'),
  isInitializeAdminTransaction: createAction(
    'app/isInitializeAdminTransaction',
  ),
  isInitializeAdminWithdrawals: createAction(
    'app/isInitializeAdminWithdrawals',
  ),
  isInitializeAdminFees: createAction('app/isInitializeAdminFees'),
  isInitializeRoyaltyFees: createAction('app/isInitializeRoyaltyFees'),
  isInitializeAdminFaq: createAction('app/isInitializeAdminFaq'),
  isInitializeAdminResources: createAction('app/isInitializeAdminResources'),
  isInitializeAdminNews: createAction('app/isInitializeAdminNews'),
  isInitializeAdminProducts: createAction('app/isInitializeAdminProducts'),
};
