import { Box, FormHelperText, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderEmailField } from './fields/RenderEmailField';
import { getAllowCountdown } from '../../bus/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../bus/auth/actions';
import { styles } from './styles';

const getTwoDigits = (digit: number) => {
  return digit.toString().length === 2 ? digit : '0' + digit;
};

export const ForgotPassword = (props: FormRenderProps) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(0);
  const [zero, setZero] = useState<boolean>(false);
  const allowCountdown = useSelector(getAllowCountdown);

  useEffect(() => {
    allowCountdown && setCount(59);
    allowCountdown && setZero(!allowCountdown);
  }, [allowCountdown]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    if (zero) {
      clearInterval(intervalId);
      dispatch(authActions.allowCountdown(false));
    }

    return () => clearInterval(intervalId);
  }, [zero]);

  useEffect(() => {
    setZero(count === 0);
  }, [count]);

  return (
    <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
      <Box sx={styles.root}>
        <Field name={'email'} render={RenderEmailField} />
        <Box my={1}>
          <Button
            disabled={!zero || props.pristine}
            fullWidth
            type={'submit'}
            variant={'primary'}
            color={'green'}
            role={'submit'}>
            Send Password Reset Link
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography
            textAlign={'center'}
            variant={'h7'}
            color={'common.neonPink'}>
            Send link again&nbsp;
          </Typography>
          <Typography textAlign={'center'} variant={'h7'}>
            00:{getTwoDigits(count)}
          </Typography>
        </Box>
      </Box>
    </form>
  );
};
export default ForgotPassword;
