import React, { useEffect, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { getModalData } from '../../bus/ui/selectors';
import { getCurrenciesRate } from '../../bus/options/selectors';

const ResultBlock = ({ currency, amount, promotion }: any) => {
  const theme = useTheme();
  const modalData = useSelector(getModalData);
  const optionList = useSelector(getCurrenciesRate);

  const [currentCurrency, setCurrentCurrency] = useState<any>();
  const [FZSRate, setFZSRate] = useState<number>(0);
  const [FZSValue, setFZSValue] = useState<number>(0);
  const [percent, setPercent] = useState(0);

  const [name, setName] = useState<string>();

  useEffect(() => {
    if (modalData?.modalPayload?.userName?.label) {
      setName(modalData.modalPayload.userName.label);
    }
  }, [modalData]);

  useEffect(() => {
    if (optionList.length) {
      setFZSRate(Number(optionList.find((item) => item.label === 'FZS')?.rate));
    }
  }, [optionList]);

  useEffect(() => {
    if (currency) {
      setCurrentCurrency(optionList.find((item) => item.id === currency));
    }
  }, [currency]);

  useEffect(() => {
    if (promotion) {
      setPercent(amount * (promotion / 100));
    } else {
      setPercent(0);
    }
  }, [promotion]);

  useEffect(() => {
    if (amount && currentCurrency) {
      setFZSValue(
        Number(
          (
            (Number(amount) + Number(percent)) *
            (currentCurrency.rate / FZSRate)
          ).toFixed(2),
        ),
      );
    }
  }, [amount, percent, currentCurrency]);

  return (
    <Typography mt={2.5} mb={3.3} variant={'poppins20'}>
      <span style={{ opacity: 0.5 }}>{name} will get</span>{' '}
      <span style={{ color: theme.palette.common.pink }}>
        {currentCurrency?.label === 'FZS' ? amount : FZSValue}
      </span>{' '}
      <span style={{ opacity: 0.5 }}>FZS</span>
    </Typography>
  );
};

export default ResultBlock;
