import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';
import {
  convertRankToAchievement,
  rankItemToCategory,
} from '../../helpers/selectorHelpers';
import { getRoyaltyCategories } from '../options/selectors';

export const dashboardSelector = (state: RootState) => state.dashboard;

export const getIsDashboardFetching = createSelector(
  [dashboardSelector],
  (result) => {
    return { isFetching: result.isFetching };
  },
);

export const getIsRecentActivityFetching = createSelector(
  [dashboardSelector],
  (result) => {
    return { isFetching: result.recentActivity.isFetching };
  },
);

export const getTopCountries = createSelector(
  [dashboardSelector],
  ({ topCountries }) => {
    return topCountries;
  },
);

export const getRecentActivity = createSelector(
  [dashboardSelector],
  (result) => {
    return { recentActivity: result.recentActivity.results };
  },
);

export const activityListNextUrl = createSelector(
  [dashboardSelector],
  (result) => {
    return { nextUrl: result.recentActivity.next };
  },
);

export const getWorkflowProgress = createSelector(
  [dashboardSelector],
  ({ workflowProgress }) => {
    return workflowProgress;
  },
);

export const getChartData = createSelector([dashboardSelector], ({ chart }) => {
  return {
    directData: chart.map((item) => +item.volume),
    teamData: chart.map((item) => +item.team_volume),
  };
});

export const getVentureTimer = createSelector(
  [dashboardSelector],
  ({ venture }) => {
    return { ventureEndDate: venture.end_date ? venture.end_date : new Date() };
  },
);

export const getVentureInfo = createSelector(
  [dashboardSelector],
  ({ venture }) => {
    return { usersCount: venture.users_count, amount: venture.amount };
  },
);

export const getRanks = createSelector([dashboardSelector], ({ ranks }) => {
  return { ranks: ranks };
});

export const getRanksForAchievements = (level: number) =>
  createSelector(
    [dashboardSelector, getRoyaltyCategories],
    ({ ranks, physicalPin }, royaltyCategories) => {
      if (!ranks?.length) return [];

      const arrayForSort = [...ranks];
      arrayForSort.sort((x, y) => x.id - y.id);
      const arrayForSortTmp = arrayForSort.filter((item: any) => {
        return item.category?.id !== 1 && item.category !== null;
      });

      const tmp = arrayForSortTmp.map((item) => {
        return convertRankToAchievement(item, level);
      });

      const map = tmp.reduce((a: any, b: any) => {
        a[b.category] = a[b.category] || [];
        a[b.category].push(b);

        return a;
      }, {});

      const tmpArr = rankItemToCategory(map);

      const clearPhysicalPin = physicalPin.map((item) => {
        return {
          ...item,
          imageMini: item.image,
          name: 'Physical Pin',
          confettiDecorate: true,
        };
      });

      const pinForLevel = clearPhysicalPin.filter((item) => !!item.level);
      const pinForCategory = clearPhysicalPin.filter((item) => !!item.category);

      pinForCategory.forEach((pin) => {
        const tmp = royaltyCategories?.find(
          (item) => item?.id === pin?.category,
        );
        const arrIndex = tmpArr?.findIndex((item) => {
          return item.title === tmp?.title;
        });

        tmpArr.splice(arrIndex + 1, 0, { title: '', list: [pin] });
      });

      const newArr: any[] = tmpArr.map((obj) => {
        pinForLevel.map((pin) => {
          const index = obj.list.findIndex((i: any) => i?.level === pin.level);

          if (index >= 0) {
            obj.list.splice(index + 1, 0, { ...pin, level: '' });
          }

          return {
            ...obj,
            list: obj.list,
          };
        });

        return obj;
      });

      return newArr?.map((item: any, index: number) => {
        return {
          ...item,
          id: index + 1,
          list: item.list.map((listItem: any, i: number) => {
            return { ...listItem, slideId: `${index}.${i}` };
          }),
        };
      });
    },
  );

export const getRoyaltyEvents = createSelector(
  [dashboardSelector],
  ({ royaltyEvents }) => {
    if (!royaltyEvents?.length) return null;

    return royaltyEvents.map((item) => {
      return {
        title: item.title,
        description: item.description,
        endDate: item.end_at,
      };
    })[0];
  },
);

export const getNextRanks = (id: number | undefined) =>
  createSelector([dashboardSelector], ({ ranks }) => {
    return id
      ? ranks.find((rank) => rank.id === id + 1) || ranks[ranks.length - 1]
      : null;
  });

export const getCurrentRanks = (id: number) =>
  createSelector([dashboardSelector], ({ ranks }) => {
    return ranks.find((rank) => rank.id === id);
  });
