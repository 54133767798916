import { Box } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Link } from 'react-router-dom';
import LogoSidebarIcon from '../Icons/LogoSidebarIcon';
import LogoSidebarCollapsedIcon from '../Icons/LogoSidebarColapsedIcon';

type LogoProps = {
  small?: boolean;
};

export const Logo: React.FC<LogoProps> = ({ small = false }) => {
  return (
    <Link to={`/`}>
      <Box sx={small ? styles.smallLogo : styles.logo}>
        {small ? <LogoSidebarCollapsedIcon /> : <LogoSidebarIcon />}
      </Box>
    </Link>
  );
};
