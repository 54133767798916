import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminResourcesIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9987 21.334C6.84404 21.334 2.66536 17.1553 2.66536 12.0007C2.66536 6.84599 6.84404 2.66732 11.9987 2.66732C17.1534 2.66732 21.332 6.84599 21.332 12.0007C21.332 17.1553 17.1534 21.334 11.9987 21.334ZM0.332031 12.0007C0.332031 5.55733 5.55537 0.333984 11.9987 0.333984C18.442 0.333984 23.6654 5.55733 23.6654 12.0007C23.6654 18.4439 18.442 23.6673 11.9987 23.6673C5.55537 23.6673 0.332031 18.4439 0.332031 12.0007ZM11.9987 15.5007C10.0656 15.5007 8.4987 13.9337 8.4987 12.0007C8.4987 10.0676 10.0656 8.50065 11.9987 8.50065C13.9317 8.50065 15.4987 10.0676 15.4987 12.0007C15.4987 13.9337 13.9317 15.5007 11.9987 15.5007ZM6.16536 12.0007C6.16536 8.77899 8.77704 6.16732 11.9987 6.16732C15.2203 6.16732 17.832 8.77899 17.832 12.0007C17.832 15.2223 15.2203 17.834 11.9987 17.834C8.77704 17.834 6.16536 15.2223 6.16536 12.0007Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminResourcesIcon;
