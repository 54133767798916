import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { notificationsActions } from '../../../notifications/actions';
import { INotification } from '../../../notifications/typedefs';

export function* handleMessage({ payload }: PayloadAction<any>) {
  try {
    const { message } = payload;
    const data: INotification = yield call(JSON.parse, message);

    yield put(
      notificationsActions.addNewNotificationInList({
        ...data,
        is_new: true,
      }),
    );
  } catch (e) {
    console.log(e);
  } finally {
    console.log('finally');
  }
}
