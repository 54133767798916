import { apply, call, put } from 'redux-saga/effects';
import { poolsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { FillMonthsEarningsActionPayload } from '../../typedefs';

export function* monthsEarnings() {
  try {
    yield put(poolsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `pools/months-earnings/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillMonthsEarningsActionPayload = yield call([
      response,
      'json',
    ]);
    yield put(poolsActions.fillMonthsEarnings(data));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(poolsActions.stopFetching());
  }
}
