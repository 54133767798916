import { Components, Theme } from '@mui/material';

export const MuiOutlinedInput: Partial<Components<Theme>> = {
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        top: 0,
      },
      root: ({ theme }) => {
        return {
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '15px',
          borderRadius: '8px',
          background: theme.palette.common.inputs.gray,
          minWidth: '50px',
          '& textarea': {
            padding: '10px 2px 7.5px 2px',
            resize: 'both',
          },
          '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
              minWidth: '24px',
              borderRadius: '8px',
            },
          },
          '& legend': {
            display: 'none',
          },
        };
      },
      focused: ({ theme }) => ({
        backgroundColor: theme.palette.common.inputs.gray,
        '& fieldset': {
          '&.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: theme.palette.common.inputs.mainColor,
          },
        },
      }),
      input: {
        borderRadius: '8px',
        padding: '23px 16px 7.5px 16px',
      },
      adornedStart: {
        '& input': {
          padding: '15.5px 16px 14.5px 3px',
        },
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          height: '42px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          '& fieldset': {
            display: 'none',
          },
        },
      },
    ],
  },
};
