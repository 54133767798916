import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: 'auto',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '60px 24px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 20px',
      width: 'calc(100% - 40px)',
    },
    '& > button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
  }),
  imgHolder: (theme: Theme) => ({
    margin: '0 auto 30px',
    display: 'flex',
    justifyContent: 'center',
    width: '372px',
    height: '372px',
    overflow: 'hidden',
    borderRadius: '12px',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '287px',
      height: '287px',
    },
  }),
};
