import { apply, call, put } from 'redux-saga/effects';
import { dashboardActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadMoreTodoActionPayload,
  PushMoreActivityActionPayload,
} from '../../typedefs';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* loadMoreActivity({
  payload,
}: PayloadAction<PushMoreActivityActionPayload>) {
  try {
    yield put(dashboardActions.startRecentActivityFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: payload.nextPageUrl, direct: true },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: LoadMoreTodoActionPayload = yield call([response, 'json']);
    yield put(dashboardActions.pushMoreActivity(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(dashboardActions.stopRecentActivityFetching());
  }
}
