import { apply, call, put } from 'redux-saga/effects';
import { paymentActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { FillAdvcashActionPayload } from '../../typedefs';

export function* fetchAdvcash({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(paymentActions.startFetching());
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `transactions/deposit/`, body },
    ]);

    if (!response.ok) {
      const errors: { [key: string]: string } = yield call([response, 'json']);
      reject(errors);
      yield throwError(response);
    }

    const data: FillAdvcashActionPayload = yield call([response, 'json']);
    yield put(paymentActions.fillAdvcash(data));

    const win = window.open(data.paayment_url, '_blank');
    win?.focus();

    resolve();
  } catch (e) {
    reject(e);
  } finally {
    yield put(paymentActions.stopFetching());
  }
}
