// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillMerchantsActionPayload,
  FillSingleMerchantActionPayload,
  MerchantsState,
} from './typedefs';
import { emptyPaginationState } from '../../setup/typedefs';

const initialState: MerchantsState = {
  isFetching: false,
  isInitialized: true,
  merchants: emptyPaginationState as any,
  notConfirmMerchants: emptyPaginationState as any,
  singleMerchant: null,
};

const merchantsSlice = createSlice({
  name: 'merchants',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillMerchants(state, action: PayloadAction<FillMerchantsActionPayload>) {
      state.merchants = action.payload;
    },
    clearMerchants(state) {
      state.merchants = emptyPaginationState as any;
    },
    fillNotConfirmMerchants(
      state,
      action: PayloadAction<FillMerchantsActionPayload>,
    ) {
      state.notConfirmMerchants = action.payload;
    },
    fillSingleMerchant(
      state,
      action: PayloadAction<FillSingleMerchantActionPayload>,
    ) {
      state.singleMerchant = action.payload;
    },
    // inject
  },
});

export default merchantsSlice;
