import { createSelector } from 'reselect';
import { book } from '../../routes/book';
import { RootState, UserRole } from '../../setup/typedefs';
import { getRanks } from '../dashboard/selectors';
import ShoppingCartIcon from '../../components/ui-kit/Icons/ShoppingCartIcon';
import TeamIcon from '../../components/ui-kit/Icons/TeamIcon';
import CurrencyIcon from '../../components/ui-kit/Icons/CurrencyIcon';

const authSelector = (state: RootState) => state.auth;

export const getIsAuthFetching = createSelector([authSelector], (result) => {
  return { isFetching: result.isFetching };
});

export const getAllowCountdown = createSelector([authSelector], (result) => {
  return result.allowCountdown;
});

export const getIsAuthenticate = createSelector([authSelector], (result) => {
  return result.isAuthenticated;
});
export const getIsInitialize = createSelector([authSelector], (result) => {
  return result.isInitialised;
});

export const getCurrentUserProfile = createSelector(
  [authSelector],
  (result) => {
    return result.profile
      ? {
          ...result.profile,
          role: result.profile?.is_admin ? UserRole.admin : UserRole.user,
        }
      : null;
  },
);

export const getPaymentProfile = createSelector([authSelector], (result) => {
  // @ts-ignore
  return result.profile?.payment;
});

export const getPaymentId = createSelector([authSelector], (result) => {
  // @ts-ignore
  return result.profile?.payment.id;
});

export const getTwoFactorAuthentication = createSelector(
  [authSelector],
  (result) => {
    return result?.profile?.two_factor_authentication_enabled;
  },
);

export const getTwoFactorAuthenticationQrCode = createSelector(
  [authSelector],
  (result) => {
    return result?.twoFactor?.qr_code;
  },
);

export const getCurrentUserWalletSettings = createSelector(
  [authSelector],
  ({ profile }) => {
    return {
      defaultWalletAddress: profile?.payment?.wallet_address,
      defaultWalletNetwork: profile?.payment?.network.name,
      owner_id: profile?.payment?.owner,
    };
  },
);

export const getUserReferralLink = createSelector([authSelector], (result) => {
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    book.signUp +
    '/' +
    result.profile?.referral_code
  );
});

export const getCurrentUserId = createSelector([authSelector], (result) => {
  return result.profile?.id;
});

export const hasTransactionPassword = createSelector(
  [authSelector],
  (result) => {
    return result.profile?.has_transaction_password;
  },
);

export const getReferralCode = createSelector([authSelector], (result) => {
  return { code: result.profile?.referral_code };
});

export const getQrCode = createSelector([authSelector], (result) => {
  return result.profile?.qr_code;
});

export const getMyRank = createSelector([authSelector], (result) => {
  return {
    purchaseTotal: result?.profile?.rank.personal_purchase_total || 0,
    totalPurchaseTotal:
      result?.profile?.rank.level.personal_purchase_total || 0,
    teamsVolume: result?.profile?.rank.teams_total_volume || 0,
    teamsTotalVolume: result?.profile?.rank.level.teams_volume_total || 0,
    teamsCount: result?.profile?.rank.teams_count || 0,
    minTeamsCount: result?.profile?.rank.level.teams_required || 0,
    rankProgress: result.profile ? result.profile?.rank.rank_progress : 0,
    currentRankId: result.profile?.rank.level.id,
    currentRank: result.profile?.rank.level.rank,
    name: `${result.profile?.first_name} ${result.profile?.last_name}`,
    createdAt: result.profile?.created_at,
    volume_per_team_max: result.profile?.rank.volume_per_team_max,
    level: result.profile?.rank.level.level || 0,
  };
});

export const getMyLevel = createSelector([authSelector], ({ profile }) => {
  if (!profile?.rank) return 1;

  return profile?.rank.level.level;
});

export const getMyChildren = createSelector([authSelector], ({ profile }) => {
  if (!profile?.numchild) return 0;

  return profile?.numchild;
});

export const getInfoForAchievements = createSelector(
  [authSelector, getRanks],
  ({ profile }, ranks) => {
    if (!profile?.rank && !ranks) return null;

    const currentLevel = ranks?.ranks.find(
      (item: any) => item.level === Number(profile?.rank.level.level),
    );

    const nextLevel = ranks?.ranks.find(
      (item: any) => item.level === Number(profile?.rank.level.level) + 1,
    );

    const percentOf = (x: number, y: number) => {
      const onePercent = x / 100;

      return y / onePercent;
    };

    const progressTeams =
      profile?.rank?.teams_total_volume && currentLevel?.teams_volume_total
        ? profile?.rank?.teams_total_volume /
          (currentLevel?.teams_volume_total / 100)
        : 0;

    const rankItems = [
      {
        icon: 'ShoppingCartIcon',
        title: 'Personal purchase',
        subtitle: 'Make a personal purchase',
        checked: profile?.rank.personal_purchase,
      },
      {
        icon: 'TeamIcon',
        title: `${profile?.rank?.teams_count || 0} of teams`,
        subtitle: 'Create a few teams',
        countOf: `${profile?.rank?.teams_count || 0}/${
          nextLevel?.teams_required || 0
        }`,
        progress:
          nextLevel?.teams_required && profile?.rank?.teams_count
            ? percentOf(nextLevel?.teams_required, profile?.rank?.teams_count)
            : 0,
        checked:
          nextLevel && profile
            ? profile?.rank?.teams_count >= nextLevel?.teams_required
            : false,
      },
      {
        icon: 'CurrencyIcon',
        title: 'Teams total volume (all time)',
        subtitle: 'Make a personal purchase',
        progress: progressTeams,
        checked: profile ? progressTeams >= 100 : false,
      },
      {
        icon: 'ShoppingCartIcon',
        title: 'Volume per Team required',
        subtitle: 'Volume per Team required',
        checked: profile?.rank.volume_per_team_required,
      },
    ];

    return {
      level: {
        name: profile?.rank.level.rank,
        number: profile?.rank.level.level,
        progress: profile?.rank.rank_progress,
        currentProgress: rankItems.filter((item: any) => item.checked).length,
      },
      nextLevel: {
        name: nextLevel?.rank,
        nextProgress: rankItems.length,
      },
      royaltyFee: profile?.rank.level.royalty_fee_percent,
      achievementPool: profile?.rank.level.achievement_pool_bonus,
      rankItems,
    };
  },
);

export const getMyMail = createSelector([authSelector], ({ profile }) => {
  return profile?.email;
});
