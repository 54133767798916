// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  BalanceState,
  FillTransactionsHistoryPayload,
  FillWalletsPayload,
  FillWalletsWithStatsPayload,
  ITransaction,
} from './typedefs';
import { emptyPaginationState, WithPagination } from '../../setup/typedefs';

const initialState: BalanceState = {
  isFetching: false,
  isInitialized: true,
  transactions: emptyPaginationState as WithPagination<ITransaction[]>,
  walletsWithStatsList: [],
  wallets: [],
};

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillTransactionsHistory(
      state,
      action: PayloadAction<FillTransactionsHistoryPayload>,
    ) {
      state.transactions = action.payload;
    },
    fillWalletsWithStats(
      state,
      action: PayloadAction<FillWalletsWithStatsPayload>,
    ) {
      state.walletsWithStatsList = action.payload;
    },
    fillWallets(state, action: PayloadAction<FillWalletsPayload>) {
      state.wallets = action.payload;
    },
    // inject
  },
});

export default balanceSlice;
