import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const FaqIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} width={'20'} height={'20'}>
      <g opacity="0.9">
        <path
          d="M9.16699 14.9993H10.8337V13.3327H9.16699V14.9993ZM10.0003 1.66602C5.40033 1.66602 1.66699 5.39935 1.66699 9.99935C1.66699 14.5993 5.40033 18.3327 10.0003 18.3327C14.6003 18.3327 18.3337 14.5993 18.3337 9.99935C18.3337 5.39935 14.6003 1.66602 10.0003 1.66602ZM10.0003 16.666C6.32533 16.666 3.33366 13.6743 3.33366 9.99935C3.33366 6.32435 6.32533 3.33268 10.0003 3.33268C13.6753 3.33268 16.667 6.32435 16.667 9.99935C16.667 13.6743 13.6753 16.666 10.0003 16.666ZM10.0003 4.99935C8.15866 4.99935 6.66699 6.49102 6.66699 8.33268H8.33366C8.33366 7.41602 9.08366 6.66602 10.0003 6.66602C10.917 6.66602 11.667 7.41602 11.667 8.33268C11.667 9.99935 9.16699 9.79102 9.16699 12.4993H10.8337C10.8337 10.6243 13.3337 10.416 13.3337 8.33268C13.3337 6.49102 11.842 4.99935 10.0003 4.99935Z"
          fill="url(#paint0_linear_9684_126904)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9684_126904"
          x1="18.3337"
          y1="14.416"
          x2="2.24137"
          y2="9.77169"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default FaqIconLT;
