import React from 'react';
import FinalForm from '../../../FinalForm';
import UserSendForm from '../../../../Forms/UserSendForm';
import { schema } from '../../../../Forms/UserSendForm/schema';
import { useDispatch } from 'react-redux';
import { adminActions } from '../../../../bus/admin/actions';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { uiActions } from '../../../../bus/ui/actions';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type UserSendProps = {
  id: number;
  onSuccess: () => void;
};

const effects: EffectFunc[] = [
  (colors, isSM) => ({
    color: colors.secondary,
    position: isSM ? [105, 86] : [90, 86],
    size: isSM ? 100 : 80,
    blur: isSM ? 50 : 30,
  }),
  (colors) => ({
    color: colors.tertiary,
    position: [0, 12],
    size: 130,
    blur: 50,
  }),
];

export const UserSend: React.FC<UserSendProps> = ({ id, onSuccess }) => {
  const dispatch = useDispatch();

  //TODO: remove this part
  const onSubmit = ({ values, ...rest }: OnFormSubmitPayload) => {
    const updatedValue = {
      ...values,
      is_direct: values.is_direct,
      owner_id: id,
      amount: values.amount,
    };

    dispatch(adminActions.adminSent({ values: updatedValue, ...rest }));
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={UserSendForm}
        onSubmit={onSubmit}
        onSubmitSuccess={(showSnackbar) => {
          onSuccess && onSuccess();
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
