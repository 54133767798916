import { Components } from '@mui/material';

export const MuiLink: Partial<Components> = {
  MuiLink: {
    defaultProps: {
      display: 'contents',
      color: 'primary',
      underline: 'none',
    },
  },
};
