// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  PaymentState,
  FillMercurioActionPayload,
  FillAdvcashActionPayload,
  FillPaymentCallbackActionPayload,
} from './typedefs';

const initialState: PaymentState = {
  isFetching: false,
  results: [],
  advcashData: null,
  mercurioData: null,
  fetchPaymentCallback: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillMercurio(state, action: PayloadAction<FillMercurioActionPayload>) {
      state.mercurioData = action.payload;
    },
    fillAdvcash(state, action: PayloadAction<FillAdvcashActionPayload>) {
      state.advcashData = action.payload;
    },
    fillPaymentCallback(
      state,
      action: PayloadAction<FillPaymentCallbackActionPayload>,
    ) {
      state.fetchPaymentCallback = action.payload;
    },
    // inject
  },
});

export default paymentSlice;
