import { apply, call } from 'redux-saga/effects';
import { api } from '../../../../REST/api';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { ResetPasswordPayload } from '../../typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { handleError } from '../../../ui/saga/workers/handleError';
import { throwError } from '../../../ui/saga/workers/throwError';

export function* resetPassword({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ResetPasswordPayload>) {
  try {
    const { values, params } = payload;

    const body: string = yield call(JSON.stringify, values);

    const response: Response = yield apply(api, api.post, [
      {
        endpoint: `auth/reset-password/${params.user_id}/${params.reset_token}/`,
        body,
        unsafe: true,
      },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }

    if (!response.ok) {
      //TODO: add tooltips
      alert('reset password - fail');
      reject();
      // throw new Error('reset password - fail');
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
