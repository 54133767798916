import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Content } from './Content';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFirstNewSlug } from '../../../bus/news/selectors';
import { book } from '../../../routes/book';
import { styles } from '../AppLayout/styles';
import background from '../../../assets/bg-dashboard.mp4';
import Sidebar from '../../../components/ui-kit/Sidebar';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';
import { getIsSidebarOpen } from '../../../bus/ui/selectors';
import backgroundLight from '../../../assets/bgLight.png';
import MobileSidebar from '../../../components/ui-kit/MobileSidebar';

export type NewsLayoutProps = {
  title: string;
};

const NewsLayout: React.FC<NewsLayoutProps> = ({ title }) => {
  const themes = useTheme();
  const isMD = useMediaQuery(themes.breakpoints.down('md'));
  const params = useParams();
  const navigate = useNavigate();
  const slug = useSelector(getFirstNewSlug);
  const isLight = useIsLightTheme();
  const isSidebarOpen = useSelector(getIsSidebarOpen);

  useEffect(() => {
    if (!params.slug && slug) {
      navigate(`${book.news}/${slug}`);
    }
  }, [params, slug]);

  return (
    <>
      <Container maxWidth={false} disableGutters style={{ overflow: 'hidden' }}>
        <Box
          display={'flex'}
          position={'relative'}
          sx={{ transition: '0.4s' }}
          pl={isMD ? '0' : isSidebarOpen ? '300px' : '117px'}>
          {!isMD && (
            <Box sx={styles.root}>
              {isLight ? (
                <Box
                  width={'100%'}
                  height={'100%'}
                  component={'img'}
                  alt={'background image'}
                  src={backgroundLight}
                />
              ) : (
                <video loop autoPlay muted>
                  <source src={background} type="video/mp4" />
                </video>
              )}
            </Box>
          )}
          {isMD ? <MobileSidebar /> : <Sidebar />}
          <Content title={title} />
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
};

export default NewsLayout;
