import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';
import { IWalletsWithStats } from './typedefs';
import {
  getCurrencies,
  getTransactionStatus,
  getTransactionType,
} from '../options/selectors';
import {
  getDescription,
  getTransactionFromTo,
  numberToPercentInArray,
} from '../../helpers/selectorHelpers';

const balanceSelector = (state: RootState) => state.balance;

export const getIsBalanceFetching = createSelector(
  [balanceSelector],
  ({ isFetching }) => {
    return { isFetching: isFetching };
  },
);

export const getBalanceWalletsWithStats = createSelector(
  [balanceSelector, getCurrencies],
  ({ walletsWithStatsList }, currencies) => {
    if (!walletsWithStatsList) return [];

    return walletsWithStatsList.map((item: IWalletsWithStats, index) => {
      return {
        id: item.id,
        odd: !!(index % 2),
        currency: item.currency,
        title:
          currencies.find((currency) => currency.id === item.currency)?.label +
          ' Balance',
        available: item.amount,
        subAvailable: item.amount_usd,
        lockedInPool: item.locked_in_pool,
        lockedInPoolUsd: item.locked_in_pool_usd,
        currencyLabel: currencies.find(
          (currency) => currency.id === item.currency,
        )?.label,
        diagramData: {
          percent: Number(item.to_last_month.toFixed(2)),
          diagram: item.current_year_statistic.length
            ? numberToPercentInArray(
                item.current_year_statistic.map((statistic) => {
                  return statistic.amount;
                }),
              )
            : [],
        },
        buttons: [
          item.currency === 3 && 'buyFZS',
          item.currency !== 3 && 'convertToFZS',
          item.currency === 3 && 'investInPool',
          'withdraw',
          'transfer',
        ].filter((item) => {
          return item !== false;
        }),
      };
    });
  },
);

export const getBalanceWalletsWithStatsForAdmin = createSelector(
  [balanceSelector, getCurrencies],
  ({ walletsWithStatsList }, currencies) => {
    if (!walletsWithStatsList) return [];

    return walletsWithStatsList.map((item: IWalletsWithStats, index) => {
      return {
        available: item.currency === 3 ? null : item.amount,
        total: item.currency === 3 ? item.amount : null,
        totalUSD: item.currency === 3 ? item.amount_usd : null,
        accounts: item.currency === 3 ? item.accounts : null,
        accountsUSD: item.currency === 3 ? item.accounts_usd : null,
        pools: item.currency === 3 ? item.locked_in_pool : null,
        poolsUSD: item.currency === 3 ? item.locked_in_pool_usd : null,
        currency: item.currency,
        title:
          'Total ' +
          currencies.find((currency) => currency.id === item.currency)?.label +
          ' on User Accounts',
        odd: !!(index % 2),
        currencyLabel: currencies.find(
          (currency) => currency.id === item.currency,
        )?.label,
        diagramData: {
          percent: Number(item.to_last_month.toFixed(2)),
          diagram: item.current_year_statistic.length
            ? numberToPercentInArray(
                item.current_year_statistic.map((statistic) => {
                  return statistic.amount;
                }),
              )
            : [],
        },
      };
    });
  },
);

export const getBalanceForWithdrawal = createSelector(
  [balanceSelector, getCurrencies],
  ({ walletsWithStatsList }, currencies) => {
    if (!walletsWithStatsList) return [];

    return walletsWithStatsList.map((item: IWalletsWithStats) => {
      return {
        id: item.id,
        currency: item.currency,
        currencyLabel: currencies.find(
          (currency) => currency.id === item.currency,
        )?.label,
        available: item.amount,
        subAvailable: item.amount_usd,
        lockedInPool: item.locked_in_pool,
      };
    });
  },
);

export const getTransactionsHistoryList = createSelector(
  [balanceSelector, getCurrencies, getTransactionType, getTransactionStatus],
  ({ transactions }, currency, transactionType, transactionStatus) => {
    if (!transactions?.results.length) return [];

    return transactions.results.map((item) => {
      return {
        id: item.id,
        created_at: item.created_at,
        from: getTransactionFromTo(item.type, 'from', item, false),
        to: getTransactionFromTo(item.type, 'to', item, false),
        type: transactionType?.find((i) => +i.id === +item?.type)?.name,
        amount: {
          number: item?.type === 9 ? -item.amount : item.amount,
          currency: '',
        },
        crypto: {
          text:
            currency?.find(
              (singleCurrency) => singleCurrency.id === item?.currency_id,
            )?.label || '-- no data --',
          information: getDescription(
            item.type,
            item.description,
            item.rate,
            currency?.find(
              (singleCurrency) => singleCurrency.id === item?.currency_id,
            )?.label,
          ),
        },
        status: {
          text: transactionStatus?.find((i) => +i.id === +item?.status)?.name,
          tooltip: item?.status === 4 ? item.description : null,
        },
      };
    });
  },
);

export const getTransactionsCurrentPage = createSelector(
  [balanceSelector],
  ({ transactions }) => {
    if (!transactions?.previous) return 1;

    return (
      Number(
        new URLSearchParams(transactions.previous.split('?')[1]).get('page'),
      ) + 1 || 1
    );
  },
);

export const getTransactionsHistoryPagination = (pageSize: number) =>
  createSelector(
    [balanceSelector, getTransactionsCurrentPage],
    ({ transactions }, currentPage) => {
      if (!transactions) return null;

      return {
        totalRows: transactions.count,
        totalPage: Math.ceil(transactions.count / pageSize),
        currentPage: currentPage,
        rowsPerPage: pageSize,
        nextPage: transactions.next,
        prevPage: transactions.previous,
      };
    },
  );

export const getBalanceId = createSelector(
  [balanceSelector],
  ({ walletsWithStatsList }) => {
    if (!walletsWithStatsList) return [];

    return walletsWithStatsList.find((i) => i.currency === 3)?.id;
  },
);
