import { Components, Theme } from '@mui/material';

export const MuiAvatar: Partial<Components<Theme>> = {
  MuiAvatar: {
    styleOverrides: {
      root: {},
    },
    variants: [
      {
        props: { variant: 'rounded' },
        style: ({ theme }) => ({
          width: '39px',
          height: '39px',
          background: theme.palette.common.inputs.gray,
          borderRadius: '4px',
          border: `1px solid ${theme.palette.common.inputs.defaultBorder}`,
        }),
      },
      {
        props: { variant: 'square' },
        style: ({ theme }) => ({
          width: '50px',
          height: '50px',
          mixBlendMode: 'normal',
          background: theme.palette.common.avatarSquareBackground,
          borderRadius: '12px',
        }),
      },
      {
        props: { variant: 'circular' },
        style: ({ theme }) => ({
          width: '39px',
          height: '39px',
          mixBlendMode: 'normal',
          background: 'none',
          borderRadius: '50%',
          border: `1px solid ${theme.palette.common.inputs.defaultBorder}`,
        }),
      },
    ],
  },
};
