import { all, call, takeEvery } from 'redux-saga/effects';
import { todoActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  bulkDeleteTodo,
  bulkUpdateTodo,
  createTodo,
  deleteTodo,
  fetchTodo,
  loadMoreTodo,
  updateTodo,
} from './workers';

// imports
function* watchFetchTodo() {
  yield takeEvery(todoActions.fetchTodo.type, fetchTodo);
}
function* watchLoadMoreTodo() {
  yield takeEvery(todoActions.loadMoreTodo.type, loadMoreTodo);
}
function* watchUpdateTodo() {
  yield takeEvery(todoActions.updateTodo.type, updateTodo);
}
function* watchDeleteTodo() {
  yield takeEvery(todoActions.deleteTodo.type, deleteTodo);
}
function* watchCreateTodo() {
  yield takeEvery(todoActions.createTodo.type, createTodo);
}
function* watchBulkDeleteTodo() {
  yield takeEvery(todoActions.bulkDeleteTodo.type, bulkDeleteTodo);
}
function* watchBulkUpdateTodo() {
  yield takeEvery(todoActions.bulkUpdateTodo.type, bulkUpdateTodo);
}
// watchers
export function* watchTodo() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchBulkUpdateTodo),
    call(watchBulkDeleteTodo),
    call(watchCreateTodo),
    call(watchDeleteTodo),
    call(watchUpdateTodo),
    call(watchLoadMoreTodo),
    call(watchFetchTodo),
  ]);
}
