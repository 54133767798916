/* eslint-disable prettier/prettier */
import {
  commonPagesRoutes
} from '../../layouts/common/CommonPagesLayout/layoutRoutes';
// inject:import
import { AppRoute } from '../../setup/typedefs';

export const commonRoutes: AppRoute<any>[] = [
  ...commonPagesRoutes
];
