import { apply, call, put } from 'redux-saga/effects';
import { api } from '../../../../REST/api';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { SignInActionPayload } from '../../typedefs';
import { FORM_ERROR } from 'final-form';
import { authActions } from '../../actions';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';
import { history } from '../../../../setup/configureStore';
import { book } from '../../../../routes/book';

export function* signIn({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SignInActionPayload>) {
  try {
    const { remember, password, email } = payload;
    const body: string = yield call(JSON.stringify, { password, email });

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/signin/`, body, unsafe: true },
    ]);

    if (response.status === 400) {
      reject({
        [FORM_ERROR]: 'Please check you credentials',
      });
      yield throwError(response);
    }

    if (response.status === 401) {
      reject({
        [FORM_ERROR]: 'Please check you credentials',
      });
      yield throwError(response);
    }

    const { access, refresh, two_factor_authentication_enabled } = yield call([
      response,
      'json',
    ]);

    if (two_factor_authentication_enabled) {
      yield call(history.push, book.verificationCode);
    } else {
      yield put(authActions.isAuthenticatedTrue());
      const path: string = localStorage.getItem('target_pathname') || book.home;
      yield call(history.push, path);
    }

    if (remember) {
      window.localStorage.setItem('accessToken', JSON.stringify(access));
      window.localStorage.setItem('refreshToken', JSON.stringify(refresh));
      window.localStorage.setItem('remember', JSON.stringify(remember));
    } else {
      window.sessionStorage.setItem('accessToken', JSON.stringify(access));
      window.sessionStorage.setItem('refreshToken', JSON.stringify(refresh));
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
