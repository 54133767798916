// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  TransactionsState,
  FillWithdrawFeeActionPayload,
  IWithdrawFeeItem,
} from './typedefs';
import { emptyPaginationState, WithPagination } from '../../setup/typedefs';

const initialState: TransactionsState = {
  isFetching: false,
  isInitialized: true,
  withdrawFee: emptyPaginationState as WithPagination<IWithdrawFeeItem[]>,
  transactionTypes: [
    {
      id: 1,
      type: 'Transfer',
    },
    {
      id: 2,
      type: 'Convert',
    },
    {
      id: 3,
      type: 'Deposit',
    },
    {
      id: 4,
      type: 'Withdraw',
    },
    {
      id: 5,
      type: 'Admin',
    },
    {
      id: 6,
      type: 'Bonus',
    },
    {
      id: 7,
      type: 'Royalty fee',
    },
    {
      id: 8,
      type: 'Pool out',
    },
    {
      id: 9,
      type: 'Pool in',
    },
    {
      id: 10,
      type: 'Admin sent (promotion)',
    },
    {
      id: 11,
      type: 'Admin sent direct',
    },
    {
      id: 12,
      type: 'Admin withdraw',
    },
    {
      id: 13,
      type: 'Withdraw fee',
    },
    {
      id: 14,
      type: 'Royalty fee withdraw',
    },
  ],
  transactionStatuses: [
    {
      id: 0,
      status: 'Fail',
    },
    {
      id: 1,
      status: 'New',
    },
    {
      id: 2,
      status: 'Pending',
    },
    {
      id: 3,
      status: 'Completed',
    },
    {
      id: 4,
      status: 'Cancelled',
    },
  ],
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillWithdrawFee(
      state,
      action: PayloadAction<FillWithdrawFeeActionPayload>,
    ) {
      state.withdrawFee = action.payload;
    },
    // inject
  },
});

export default transactionsSlice;
