import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const MerchantsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99851 11.6673C5.44334 11.6673 4.32362 10.174 4.75907 8.68065L5.92933 4.66732H9.33125V9.33398C9.33125 10.6226 8.28685 11.6673 6.99851 11.6673ZM10.4976 12.4208C9.64274 13.3896 8.39196 14.0007 6.99851 14.0007V19.2507H12.8304V18.6673C12.8304 16.7343 14.397 15.1673 16.3295 15.1673C18.262 15.1673 19.8286 16.7343 19.8286 18.6673V23.334H20.995V14.0007C19.6016 14.0007 18.3508 13.3896 17.4959 12.4208C16.6409 13.3896 15.3903 14.0007 13.9968 14.0007C12.6033 14.0007 11.3525 13.3896 10.4976 12.4208ZM18.6623 25.6673H20.995C22.2833 25.6673 23.3278 24.6227 23.3278 23.334V13.3789C25.1059 12.3491 26.104 10.1882 25.4739 8.02732L24.2036 3.67103C23.9726 2.8787 23.2465 2.33398 22.4213 2.33398H17.4959H10.4976H5.57217C4.74704 2.33398 4.02093 2.8787 3.7899 3.67102L2.51963 8.02732C1.88951 10.1883 2.88763 12.3491 4.66576 13.3789V23.334C4.66576 24.6227 5.71016 25.6673 6.99851 25.6673H13.9968H18.6623ZM17.4959 23.334V18.6673C17.4959 18.023 16.9737 17.5007 16.3295 17.5007C15.6853 17.5007 15.1631 18.023 15.1631 18.6673V23.334H17.4959ZM12.8304 23.334V21.584H6.99851V23.334H12.8304ZM18.6623 9.33398V4.66732H22.0642L23.2345 8.68065C23.6699 10.174 22.5502 11.6673 20.995 11.6673C19.7067 11.6673 18.6623 10.6226 18.6623 9.33398ZM11.664 4.66732H16.3295V9.33398C16.3295 10.6226 15.2851 11.6673 13.9968 11.6673C12.7084 11.6673 11.664 10.6226 11.664 9.33398V4.66732Z"
        fill="url(#paint0_linear_14271_84429)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84429"
          x1="25.6634"
          y1="20.184"
          x2="3.13421"
          y2="13.6819"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default MerchantsIconLT;
