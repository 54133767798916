import { Box, Dialog, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { registeredModals } from './Modals';
import { useDispatch, useSelector } from 'react-redux';
import { getIsModalOpen, getModalData } from '../../bus/ui/selectors';
import { Button } from '../ui-kit/Button';
import CloseIcon from '../ui-kit/Icons/CloseIcon';
import { uiActions } from '../../bus/ui/actions';

type ModalsProps = {};

export const Modals: React.FC<ModalsProps> = () => {
  const modalData = useSelector(getModalData);
  const isOpen = useSelector(getIsModalOpen);
  const dispatch = useDispatch();
  const [titleAlign, setTitleAlign] = useState('center');
  const theme = useTheme();

  useEffect(() => {
    modalData?.modalPayload?.titlePosition &&
      setTitleAlign(modalData.modalPayload.titlePosition);
  }, [modalData]);

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  const closeModalOutside = () => {
    modalData!.modalPayload.isOutSideClicked &&
      dispatch(uiActions.closeModal());
  };

  return modalData ? (
    <Dialog
      // @ts-ignore
      sx={styles.root}
      onClose={closeModalOutside}
      open={isOpen}
      // @ts-ignore
      disableScrollLock>
      {/*@ts-ignore*/}
      <Box sx={styles.qwe}>
        {modalData.modalPayload.title && !modalData?.hideUi && (
          <Typography
            variant={'h1'}
            sx={{
              color: theme.palette.common.buttons.standardColor,
              textAlign: titleAlign,
            }}>
            {modalData.modalPayload.title}
          </Typography>
        )}
        {!modalData.hideUi && (
          <Button
            sx={styles.closeButton}
            onClick={closeModal}
            variant={'square'}
            color={'none'}
            startIcon={<CloseIcon color={theme.palette.common.birchBlack} />}
          />
        )}
        {registeredModals[modalData.modalName]
          ? React.createElement(
              registeredModals[modalData.modalName],
              modalData.modalPayload,
            )
          : // eslint-disable-next-line react/no-children-prop
            React.createElement('div', {
              children: <>{`${modalData.modalName} is not registered`}</>,
            })}
      </Box>
    </Dialog>
  ) : null;
};
