import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const RoyaltyFeesIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66536 3.18499C2.66536 2.87045 2.9261 2.61518 3.2487 2.61518H15.8916C15.6396 3.13373 15.4987 3.71379 15.4987 4.32607V20.9673C15.4987 21.1987 15.3069 21.3861 15.0703 21.3861H3.2487C2.92653 21.3861 2.66536 21.1308 2.66536 20.8159V12.0007V3.18499ZM18.9987 0.333984V0.374409C19.1892 0.347774 19.3839 0.333984 19.582 0.333984C21.8372 0.333984 23.6654 2.12131 23.6654 4.32607V7.12109C23.6654 7.78222 23.1171 8.31816 22.4409 8.31816H17.832V20.9673C17.832 22.4585 16.5956 23.6673 15.0703 23.6673H3.2487C1.63787 23.6673 0.332031 22.3906 0.332031 20.8159V12.0007V3.18499C0.332031 1.60973 1.6383 0.333984 3.2487 0.333984H18.9987ZM21.332 4.32607V6.03697H17.832V4.32607C17.832 3.38117 18.6156 2.61518 19.582 2.61518C20.5485 2.61518 21.332 3.38117 21.332 4.32607ZM6.79596 16.1582C6.34034 16.6036 5.60165 16.6036 5.14604 16.1582C4.69043 15.7128 4.69043 14.9906 5.14604 14.5451L11.3681 8.4621C11.8237 8.01667 12.5624 8.01667 13.018 8.4621C13.4737 8.90754 13.4737 9.62972 13.018 10.0752L6.79596 16.1582ZM4.41536 9.26862C4.41536 8.42873 5.11179 7.74786 5.97089 7.74786C6.82998 7.74786 7.52641 8.42873 7.52641 9.26862C7.52641 10.1086 6.82998 10.7894 5.97089 10.7894C5.11179 10.7894 4.41536 10.1086 4.41536 9.26862ZM12.1931 13.8309C11.334 13.8309 10.6375 14.5118 10.6375 15.3517C10.6375 16.1916 11.334 16.8724 12.1931 16.8724C13.0522 16.8724 13.7486 16.1916 13.7486 15.3517C13.7486 14.5118 13.0522 13.8309 12.1931 13.8309Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default RoyaltyFeesIcon;
