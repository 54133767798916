import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import DeleteIcon from '../../components/ui-kit/Icons/DeleteIcon';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../bus/ui/actions';
import { RenderUserIdField } from './fields/RenderUserId';

export const UserDeleteForm = (props: FormRenderProps) => {
  const dispatch = useDispatch();

  return (
    <form onSubmit={props.handleSubmit}>
      <Box sx={{ opacity: 0 }}>
        <Field name={'userId'} render={RenderUserIdField} type={'hidden'} />
      </Box>
      <Box display={'flex'} gap={4} mt={'30px'}>
        <Button
          variant="secondary"
          onClick={() => dispatch(uiActions.closeModal())}>
          Cancel
        </Button>
        <Button
          color="pink"
          variant="secondary"
          type={'submit'}
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
      </Box>
      {props.hasSubmitErrors && (
        <Box mx={2}>
          <FormHelperText error>{props.submitError}</FormHelperText>
        </Box>
      )}
    </form>
  );
};
export default UserDeleteForm;
