import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { InputAdornment, TextField } from '@mui/material';
import { getFieldError } from '../../../helpers/getFieldError';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencies } from '../../../bus/options/selectors';
import { optionsActions } from '../../../bus/options/actions';

const RenderAmountField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
  valuePercent,
  currency,
}) => {
  const dispatch = useDispatch();
  const optionList = useSelector(getCurrencies);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');

  useEffect(() => {
    dispatch(optionsActions.fetchCurrencies());
  }, []);

  useEffect(() => {
    if (valuePercent) {
      input.onChange(valuePercent);
    }
  }, [valuePercent]);

  useEffect(() => {
    if (currency && optionList) {
      const { label }: any = optionList.find((item) => item.id === currency);
      setCurrentCurrency(label);
    }
  }, [currency, optionList]);

  return (
    <TextField
      {...input}
      fullWidth
      label={'Amount'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{currentCurrency}</InputAdornment>
        ),
      }}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
    />
  );
};

export default RenderAmountField;
