import { Theme } from '@mui/material';

export const styles = {
  text: {
    width: '300px',
    whiteSpace: 'nowrap',
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
    '& .MuiTypography-root': {
      color: (theme: Theme) => theme.palette.common.sidebar?.itemColor,
    },
  },
  collapsedText: {
    transition: 'all 0.4s cubic-bezier(0,0,0.2,1)',
    opacity: 0,
  },
};
