import { apply, call, put } from 'redux-saga/effects';
import { resourcesActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { PayloadAction } from '@reduxjs/toolkit';
import { handleError } from '../../../ui/saga/workers/handleError';
import {
  LoadMoreResourcesActionPayload,
  PushMoreResourcesActionPayload,
} from '../../typedefs';

export function* loadMoreResources({
  payload,
}: PayloadAction<LoadMoreResourcesActionPayload>) {
  try {
    yield put(resourcesActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: payload.nextPageUrl, direct: true },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: PushMoreResourcesActionPayload = yield call([response, 'json']);

    yield put(resourcesActions.pushMoreResources(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(resourcesActions.stopFetching());
  }
}
