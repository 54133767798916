import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { styles } from '../styles';
import {
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Button } from '../../../components/ui-kit/Button';

export const RenderPercentField: React.FC<FieldRenderProps<string>> = ({
  input: { onChange, value },
  meta,
  tab = '',
}) => {
  const [currentValue, setValue] = useState(value);
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setValue('0');
  }, [tab]);

  return (
    <>
      <Box sx={styles.buttonGroup}>
        <Button
          sx={[value === '25' && styles.focus]}
          value={'25'}
          onClick={handleChange}
          data-testid={'25'}
          variant={'todo'}
          color={'birch'}>
          <Typography variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
            25 %
          </Typography>
        </Button>
        <Button
          sx={[value === '50' && styles.focus]}
          value={'50'}
          onClick={handleChange}
          data-testid={'50'}
          variant={'todo'}
          color={'birch'}>
          <Typography variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
            50 %
          </Typography>
        </Button>
        <Button
          sx={[value === '75' && styles.focus]}
          value={'75'}
          onClick={handleChange}
          data-testid={'75'}
          variant={'todo'}
          color={'birch'}>
          <Typography variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
            75 %
          </Typography>
        </Button>
        <Button
          sx={[value === '100' && styles.focus]}
          value={'100'}
          onClick={handleChange}
          data-testid={'100'}
          variant={'todo'}
          color={'birch'}>
          <Typography variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
            100 %
          </Typography>
        </Button>
      </Box>
      {(meta.error || meta.submitError) && (
        <Box mx={2}>
          <FormHelperText error>
            {meta.error || meta.submitError}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
