import React from 'react';
import FinalForm from '../../../FinalForm';
import EditFaqForm from '../../../../Forms/EditFaqForm';
import { faqActions } from '../../../../bus/faq/actions';
import { FAQItemType } from '../../../../bus/faq/typedefs';
import { schema } from '../../../../Forms/EditFaqForm/schema';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type ModalProps = {
  item: FAQItemType;
};

const effects: EffectFunc[] = [
  (colors, isSM) => ({
    color: colors.secondary,
    position: isSM ? [-7, 50] : [-3, 10],
    blur: 50,
  }),
  (colors, isSM) => ({
    color: colors.tertiary,
    position: isSM ? [103, 50] : [103, 85],
    blur: 45,
  }),
];

export const EditFaq: React.FC<ModalProps> = ({ item }) => {
  return (
    <GeneralCardHolder effects={effects} sx={styles.root}>
      <FinalForm
        initialValues={item}
        component={EditFaqForm}
        sagaAction={faqActions.updateFaq}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
