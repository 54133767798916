import { Box, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { styles } from './styles';
import ArrowIcon from '../Icons/ArrowIcon';
import { Button } from '../Button';

type AccordionProps = {
  title: string;
  children?: any;
};

export const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={styles.root} p={'20px'}>
      <Box
        sx={styles.titleBlock}
        display={'flex'}
        justifyContent={'space-between'}>
        <Typography variant={'h1'}>{title}</Typography>
        <Button
          onClick={toggle}
          sx={{ width: '20px', minWidth: '20px', padding: 0 }}
          startIcon={<ArrowIcon color={theme.palette.common.mainColorFirst} />}
        />
      </Box>
      <Box sx={styles.textBlock} height={open ? 'auto' : '70px'}>
        {children}
      </Box>
    </Box>
  );
};
