import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';
import { EffectColors } from '../../../themes/dark/typetefs';
import { styles } from './styles';
import { BoxProps } from '@mui/material/Box/Box';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';
export type EffectFunc = (
  colors: EffectColors,
  isSM: boolean,
) => {
  color: string;
  position: [number | string, number | string];
  size?: number;
  opacity?: number;
  blur?: number;
};
type GeneralCardHolderProps = {
  children: React.ReactNode;
  active?: boolean;
  effects?: EffectFunc[];
  backgroundImage?: string;
  withoutBefore?: boolean;
};

export const GeneralCardHolder: React.ForwardRefExoticComponent<
  GeneralCardHolderProps & BoxProps
  // eslint-disable-next-line react/display-name
> = forwardRef(
  (
    {
      children,
      sx,
      p = '30px',
      active,
      effects,
      backgroundImage,
      withoutBefore = false,
      ...props
    },
    ref = null,
  ) => {
    const classes = Array.isArray(sx) ? sx : [sx];
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isLight = useIsLightTheme();

    return (
      <Box
        ref={ref}
        borderRadius={'16px'}
        sx={[
          withoutBefore ? styles.rootWithoutBefore : styles.root,
          !!active && styles.rootActive,
          ...classes,
        ]}
        p={p}
        {...props}>
        {children}
        {effects && !isLight && (
          <Box
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            overflow={'hidden'}
            borderRadius={'16px'}
            zIndex={-1}
            left={0}
            top={0}>
            {effects.map((effect, index) => (
              <Box
                key={index}
                sx={(theme) => {
                  const fn = effect(theme.palette.common.effects, isSM);

                  return {
                    transformOrigin: 'center, center',
                    width: fn.size || 100,
                    height: fn.size || 100,
                    position: 'absolute',
                    top: isNaN(fn.position[0] as number)
                      ? fn.position[0]
                      : `${fn.position[0]}%`,
                    left: isNaN(fn.position[1] as number)
                      ? fn.position[1]
                      : `${fn.position[1]}%`,
                    transform: 'translate(-50%, -50%)',
                    filter: `blur(${fn.blur || 60}px)`,
                    opacity: fn.opacity || 0.8,
                    background: fn.color,
                  };
                }}
              />
            ))}
          </Box>
        )}
        {!!backgroundImage && (
          <Box
            display={'block'}
            position={'absolute'}
            top={0}
            left={0}
            width={'100%'}
            height={'100%'}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              borderRadius: '16px',
              zIndex: -10,
              pointerEvents: 'none',
            }}
          />
        )}
      </Box>
    );
  },
);
