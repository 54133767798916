// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillProductsActionPayload,
  IProductItem,
  ProductsState,
  UpdateProductActionPayload,
  RemoveProductActionPayload,
  AddProductActionPayload,
  PushMoreProductsActionPayload,
} from './typedefs';
import { emptyPaginationState, WithPagination } from '../../setup/typedefs';

const initialState: ProductsState = {
  isFetching: false,
  isInitialized: false,
  products: emptyPaginationState as WithPagination<IProductItem[]>,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillProducts(state, action: PayloadAction<FillProductsActionPayload>) {
      state.products = action.payload;
    },
    clearProducts(state) {
      state.products = null;
    },
    updateProduct(state, action: PayloadAction<UpdateProductActionPayload>) {
      if (state.products) {
        state.products.results = state.products.results.map((product) =>
          product.id === action.payload.id ? action.payload : product,
        );
      }
    },
    removeProduct(state, action: PayloadAction<RemoveProductActionPayload>) {
      if (state.products) {
        state.products.count = state.products.count - 1;
        state.products.results = state.products.results.filter(
          (product) => product.slug !== action.payload,
        );
      }
    },
    addProduct(state, action: PayloadAction<AddProductActionPayload>) {
      if (state.products) {
        state.products.results = [action.payload, ...state.products.results];
      }
    },
    pushMoreProducts(
      state,
      action: PayloadAction<PushMoreProductsActionPayload>,
    ) {
      if (state.products) {
        state.products.count = action.payload.count;
        state.products.next = action.payload.next;
        state.products.results = [
          ...state.products.results,
          ...action.payload.results,
        ];
      }
    },
    // inject
  },
});

export default productsSlice;
