import { put, select, take } from 'redux-saga/effects';
import { appActions } from '../../actions';

export function* initializeAdminTransactions() {
  let isInit = false;
  const deps = ['auth', 'options', 'notifications', 'admin'];

  while (!isInit) {
    yield take('*');
    const state: { [key: string]: any } = yield select();
    const isAllInitialized = deps.every((dep: string) => {
      return state[dep].isInitialized === false;
    });

    if (isAllInitialized) {
      isInit = true;
      yield put(appActions.initializationAdminTransaction());
    }
  }
}
