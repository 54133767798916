import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminWithdrawalsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.333 12.8337C24.6217 12.8337 25.6663 11.789 25.6663 10.5003V7.00033C25.6663 5.71166 24.6217 4.66699 23.333 4.66699H20.9997H18.6663H9.33301H4.66634C3.37768 4.66699 2.33301 5.71166 2.33301 7.00033V10.5003C2.33301 11.789 3.37768 12.8337 4.66634 12.8337H6.99967V19.8337C6.99967 21.7667 8.56668 23.3337 10.4997 23.3337H17.4997C19.4327 23.3337 20.9997 21.7667 20.9997 19.8337V12.8337H23.333ZM6.99967 7.00033H4.66634V10.5003H6.99967V7.00033ZM18.6663 7.00033V9.33366V19.8337C18.6663 20.478 18.144 21.0003 17.4997 21.0003H12.833V7.00033H16.333H18.6663ZM10.4997 7.00033V21.0003C9.85535 21.0003 9.33301 20.478 9.33301 19.8337V9.33366V7.00033H10.4997ZM20.9997 7.00033V10.5003H23.333V7.00033H20.9997ZM16.333 18.667C15.6887 18.667 15.1663 18.1447 15.1663 17.5003V12.8337C15.1663 12.1893 15.6887 11.667 16.333 11.667C16.9774 11.667 17.4997 12.1893 17.4997 12.8337V17.5003C17.4997 18.1447 16.9774 18.667 16.333 18.667Z"
        fill="url(#paint0_linear_14271_87553)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87553"
          x1="25.6663"
          y1="18.947"
          x2="4.07113"
          y2="11.1564"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminWithdrawalsIconLT;
