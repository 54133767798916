import { apply, put } from 'redux-saga/effects';
import { faqActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { uiActions } from '../../../ui/actions';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* deleteFaq({ payload }: PayloadAction<string>) {
  try {
    yield put(faqActions.startFetching());
    const response: Response = yield apply(api, api.delete, [
      { endpoint: `faqs/${payload}/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    yield put(faqActions.removeFaq(payload));
    yield put(uiActions.closeModal());
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(faqActions.stopFetching());
  }
}
