import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderTextField } from './fields/RenderTextField';

const WithdrawDeclineForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box mt={'33px'}>
        <Field name={'reason'} render={RenderTextField} />
        <Box mt={'30px'}>
          <Button
            type={'submit'}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            <Typography variant={'Button/Button 16'}>Send</Typography>
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default WithdrawDeclineForm;
