import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { TeamUserInfo } from '../../../../ui-kit/HoverInfo/TeamUserInfo';
import { HoverInfo } from '../../../../ui-kit/HoverInfo';
import {
  formatToRound,
  formatToUSLocale,
} from '../../../../../helpers/formating';
import { sliceRankMVT } from '../../../../../helpers/selectorHelpers';

type TeamUserRankProps = {
  rankImage: string;
  level: string;
  levelNum: any;
  purchaseTotal: number;
  totalPurchaseTotal: number;
  teamsVolume: number;
  teamsTotalVolume: number;
  teamsCount: number;
  minTeamsCount: number;
  isNext?: boolean;
  mvtCurrent: number[];
};

export const TeamUserRank: React.FC<TeamUserRankProps> = ({
  rankImage,
  level,
  levelNum,
  purchaseTotal,
  totalPurchaseTotal,
  teamsVolume,
  teamsTotalVolume,
  teamsCount,
  minTeamsCount,
  isNext = false,
  mvtCurrent,
}) => {
  const mvtCurrentTmp = sliceRankMVT(mvtCurrent, levelNum);
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={styles.wrapper} display={'flex'} mb={'24px'} gap={'15px'}>
      {!isSM && (
        <Box>
          <Box
            width={'60px'}
            height={'60px'}
            component={'img'}
            src={rankImage}
            alt={'rank-icon'}
          />
        </Box>
      )}
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'}>
          {isSM && (
            <Box
              width={'60px'}
              height={'60px'}
              component={'img'}
              src={rankImage}
              alt={'rank-image'}
            />
          )}
          <Typography variant={'Headlines/H7'} mb={isSM ? 0 : '20px'}>
            {isNext ? 'Next rank' : 'Current rank'}: {level}
          </Typography>
        </Box>
        <Box mb={'7.5px'}>
          <Typography
            variant={'Poppins'}
            color={theme.palette.common.teamPage.userModal.inverseText}>
            Personal purchase total:
          </Typography>
          <Typography
            variant={'Poppins600'}
            color={
              purchaseTotal >= totalPurchaseTotal
                ? theme.palette.common.lightGreen
                : theme.palette.common.lightPink
            }>
            &nbsp;${formatToUSLocale(formatToRound(purchaseTotal))}
          </Typography>
          <Typography variant={'Poppins600'}>
            /{formatToUSLocale(formatToRound(totalPurchaseTotal))}
          </Typography>
        </Box>
        <Box mb={'7.5px'}>
          <Typography
            variant={'Poppins'}
            color={theme.palette.common.teamPage.userModal.inverseText}>
            Team purchase total:
          </Typography>
          <Typography
            variant={'Poppins600'}
            color={
              teamsVolume >= teamsTotalVolume
                ? theme.palette.common.teamPage.userModal.green
                : theme.palette.common.teamPage.userModal.red
            }>
            &nbsp;${formatToUSLocale(formatToRound(teamsVolume))}
          </Typography>
          <Typography variant={'Poppins600'}>
            /{formatToUSLocale(formatToRound(teamsTotalVolume))}
          </Typography>
        </Box>
        <Box mb={'7.5px'}>
          <Typography
            variant={'Poppins'}
            color={theme.palette.common.teamPage.userModal.inverseText}>
            Team count:
          </Typography>
          <Typography
            variant={'Poppins600'}
            color={
              teamsCount >= minTeamsCount
                ? theme.palette.common.teamPage.userModal.green
                : theme.palette.common.teamPage.userModal.red
            }>
            &nbsp;&nbsp;{teamsCount}
          </Typography>
          <Typography variant={'Poppins600'}>/{minTeamsCount}</Typography>
        </Box>
        {mvtCurrentTmp?.map((item, index) => {
          return (
            <Box
              key={index}
              display={'flex'}
              mb={1}
              gap={'8.5px'}
              alignItems={'center'}>
              <Box>
                <Typography
                  variant={'Poppins'}
                  color={theme.palette.common.teamPage.userModal.inverseText}>
                  MVT #{index + 1} Volume:
                </Typography>
                <Typography
                  variant={'Poppins600'}
                  color={
                    item >= teamsTotalVolume
                      ? theme.palette.common.teamPage.userModal.green
                      : theme.palette.common.teamPage.userModal.red
                  }>
                  &nbsp; ${formatToUSLocale(formatToRound(item))}
                </Typography>
                <Typography variant={'Poppins600'}>
                  /{formatToUSLocale(formatToRound(teamsTotalVolume))}
                </Typography>
              </Box>
              <HoverInfo
                size={'16px'}
                information={<TeamUserInfo />}
                arrow
                placement={'top'}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
