import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    paddingTop: '100px',
    width: 'calc(100vw - 300px)',
    transition: 'width 0.4s cubic-bezier(0,0,0.2,1)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  sidebarCollapsed: (theme: Theme) => ({
    transition: 'width 0.4s cubic-bezier(0,0,0.2,1)',
    width: 'calc(100vw - 117px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
};
