import { apply, call, put } from 'redux-saga/effects';
import { adminActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import { AdminSentActionPayload } from '../../typedefs';
import { FORM_ERROR } from 'final-form';
import { handleError } from '../../../ui/saga/workers/handleError';
import { throwError } from '../../../ui/saga/workers/throwError';

export function* adminSent({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<AdminSentActionPayload>) {
  try {
    yield put(adminActions.startFetching());

    const newValue = {
      transaction: {
        amount: payload.amount,
        currency: payload.currency,
        owner_id: payload.owner_id,
      },
      promotion: payload.promotion,
      is_direct: payload.is_direct,
      description: payload.description,
    };

    const body: string = yield call(JSON.stringify, newValue);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `transactions/admin_sent/`, body },
    ]);

    if (response.status !== 201) {
      reject({
        [FORM_ERROR]: 'wrong data',
      });
      // throw new Error('Sent user fail');
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(adminActions.stopFetching());
  }
}
