import { apply, call, put } from 'redux-saga/effects';
import { poolsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';

export function* poolInvest({ payload }: PayloadActionWithPromiseMeta<any>) {
  const { resolve, reject, values } = payload;
  try {
    yield put(poolsActions.startFetching());

    const body: string = yield call(JSON.stringify, values);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `pools-transactions/`, body },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      throw new Error();
    }

    resolve();
  } catch (e) {
    console.log(e);
  } finally {
    yield put(poolsActions.stopFetching());
  }
}
