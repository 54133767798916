import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminUsersIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5013 8.16602C11.3634 8.16602 12.1709 8.39978 12.8639 8.80739C13.1249 6.47735 15.1016 4.66602 17.5013 4.66602C20.0786 4.66602 22.168 6.75535 22.168 9.33268C22.168 10.6383 21.6319 11.8186 20.7677 12.6656C22.3166 13.7143 23.3346 15.4879 23.3346 17.4993V18.8327V22.166C23.3346 22.8104 22.8123 23.3327 22.168 23.3327C21.5236 23.3327 21.0013 22.8104 21.0013 22.166V18.8327V17.4993C21.0013 15.5663 19.4344 13.9993 17.5013 13.9993C15.5683 13.9993 14.0013 15.5663 14.0013 17.4993V18.8327V22.166C14.0013 22.8104 13.479 23.3327 12.8346 23.3327C12.1903 23.3327 11.668 22.8104 11.668 22.166V18.8327V17.4993H10.5013H8.83464C7.82211 17.4993 7.0013 18.3202 7.0013 19.3326V22.166C7.0013 22.8104 6.47896 23.3327 5.83464 23.3327C5.19031 23.3327 4.66797 22.8104 4.66797 22.166V19.3326C4.66797 17.7659 5.53275 16.401 6.81103 15.6895C6.19896 14.9001 5.83464 13.9089 5.83464 12.8327C5.83464 10.2554 7.92397 8.16602 10.5013 8.16602ZM10.5013 15.166C11.79 15.166 12.8346 14.1214 12.8346 12.8327C12.8346 11.544 11.79 10.4993 10.5013 10.4993C9.21264 10.4993 8.16797 11.544 8.16797 12.8327C8.16797 14.1214 9.21264 15.166 10.5013 15.166ZM17.5013 11.666C18.79 11.666 19.8346 10.6213 19.8346 9.33268C19.8346 8.04402 18.79 6.99935 17.5013 6.99935C16.2126 6.99935 15.168 8.04402 15.168 9.33268C15.168 10.6213 16.2126 11.666 17.5013 11.666Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminUsersIcon;
