import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Content } from './Content';
import { styles } from '../AppLayout/styles';
import background from '../../../assets/bg-dashboard.mp4';
import Sidebar from '../../../components/ui-kit/Sidebar';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';
import { useSelector } from 'react-redux';
import { getIsSidebarOpen } from '../../../bus/ui/selectors';
import MobileSidebar from '../../../components/ui-kit/MobileSidebar';

export type SettingsLayoutProps = {
  title: string;
};

const SettingsLayout: React.FC<SettingsLayoutProps> = ({ title }) => {
  const themes = useTheme();
  const isMD = useMediaQuery(themes.breakpoints.down('md'));
  const isLight = useIsLightTheme();
  const isSidebarOpen = useSelector(getIsSidebarOpen);

  return (
    <>
      <Container maxWidth={false} disableGutters style={{ overflow: 'hidden' }}>
        <Box
          display={'flex'}
          position={'relative'}
          sx={{ transition: '0.4s' }}
          pl={isMD ? '0' : isSidebarOpen ? '300px' : '117px'}>
          {!isMD && !isLight && (
            <Box
              sx={styles.root}
              position={'absolute'}
              width={'100vw'}
              height={'100vh'}
              zIndex={-1}>
              <video loop autoPlay muted>
                <source src={background} type="video/mp4" />
              </video>
            </Box>
          )}
          {isMD ? <MobileSidebar /> : <Sidebar />}
          <Content title={title} />
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
};

export default SettingsLayout;
