import { useEffect, useState } from 'react';
import { Nullable } from '../../setup/typedefs';

const useIsLightTheme = () => {
  const currentTheme: Nullable<string> = localStorage.getItem('currentTheme');
  const [isLight, setIsLight] = useState<boolean>(false);

  useEffect(() => {
    setIsLight(currentTheme === 'light');
  }, [currentTheme]);

  return isLight;
};

export default useIsLightTheme;
