import { Components, Theme } from '@mui/material';

export const MuiDivider: Partial<Components<Theme>> = {
  MuiDivider: {
    styleOverrides: {
      root: {
        background:
          'linear-gradient(90deg, rgba(117, 121, 231, 0.71) 0%, rgba(255, 255, 255, 0.22) 100.48%)',
        border: 'none',
        height: '1px',
      },
    },
  },
};
