import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const BurgerMenuIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      sx={{ fill: 'none', width: '20px', height: '14px' }}
      width={'20'}
      height={'14'}
      viewBox={'0 0 20 14'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666016 1.16667C0.666016 0.52234 1.18836 0 1.83268 0H18.166C18.8104 0 19.3327 0.52234 19.3327 1.16667C19.3327 1.81099 18.8104 2.33333 18.166 2.33333H1.83268C1.18836 2.33333 0.666016 1.81099 0.666016 1.16667ZM0.666016 7C0.666016 6.35565 1.18836 5.83333 1.83268 5.83333H18.166C18.8104 5.83333 19.3327 6.35565 19.3327 7C19.3327 7.64435 18.8104 8.16667 18.166 8.16667H1.83268C1.18836 8.16667 0.666016 7.64435 0.666016 7ZM1.83268 11.6667C1.18836 11.6667 0.666016 12.189 0.666016 12.8333C0.666016 13.4777 1.18836 14 1.83268 14H18.166C18.8104 14 19.3327 13.4777 19.3327 12.8333C19.3327 12.189 18.8104 11.6667 18.166 11.6667H1.83268Z"
        fill="#A3AED0"
      />
    </SvgIcon>
  );
};

export default BurgerMenuIcon;
