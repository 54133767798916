import { Components, Theme } from '@mui/material';

export const MuiGrid: Partial<Components<Theme>> = {
  MuiGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiGrid-grid-xxl-1': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '8.33333333%',
            maxWidth: '8.33333333%',
          },
        },
        '&.MuiGrid-grid-xxl-2': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '16.66666667%',
            maxWidth: '16.66666667%',
          },
        },
        '&.MuiGrid-grid-xxl-3': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '25%',
            maxWidth: '25%',
          },
        },
        '&.MuiGrid-grid-xxl-4': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '33.33333333%',
            maxWidth: '33.33333333%',
          },
        },
        '&.MuiGrid-grid-xxl-5': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '41.66666667%',
            maxWidth: '41.66666667%',
          },
        },
        '&.MuiGrid-grid-xxl-6': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '50%',
            maxWidth: '50%',
          },
        },
        '&.MuiGrid-grid-xxl-7': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '58.33333333%',
            maxWidth: '58.33333333%',
          },
        },
        '&.MuiGrid-grid-xxl-8': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '66.66666667%',
            maxWidth: '66.66666667%',
          },
        },
        '&.MuiGrid-grid-xxl-9': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '75%',
            maxWidth: '75%',
          },
        },
        '&.MuiGrid-grid-xxl-10': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '83.33333333%',
            maxWidth: '83.33333333%',
          },
        },
        '&.MuiGrid-grid-xxl-11': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '91.66666667%',
            maxWidth: '91.66666667%',
          },
        },
        '&.MuiGrid-grid-xxl-12': {
          [`@media (min-width: ${theme.breakpoints.values!.xxl}px)`]: {
            flexBasis: '100%',
            maxWidth: '100%',
          },
        },
      }),
    },
  },
};
