import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderPasswordField } from './fields/RenderPasswordField';
import { styles } from './styles';

const UserActivationForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box mt={'30px'}>
        <Field name={'password'} render={RenderPasswordField} />
        <Box maxWidth={'200px'} mt={'30px'}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Activate user
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default UserActivationForm;
