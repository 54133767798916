import React, { lazy } from 'react';
import { book } from '../../../routes/book';
import { AppRoute } from '../../../setup/typedefs';
import CommonPagesLayout, { CommonPagesLayoutProps } from './index';
import AuthLayout from '../../public/AuthLayout';
const CookiePolicy = lazy(() => import('../../../pages/common/CookiePolicy'));
const ResetPasswordPage = lazy(
  () => import('../../../pages/common/ResetPasswordPage'),
);
const FranchiseTerms = lazy(
  () => import('../../../pages/common/FranchiseTerms'),
);
const GeneralTerms = lazy(() => import('../../../pages/common/GeneralTerms'));
const PrivacyPolicy = lazy(() => import('../../../pages/common/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('../../../pages/common/TermsOfUse'));
// imports

export const commonPagesRoutes: AppRoute<CommonPagesLayoutProps>[] = [
  // inject
  {
    path: book.generalTerms,
    Layout: CommonPagesLayout,
    Component: <GeneralTerms />,
    layoutProps: {},
  },
  {
    path: `${book.resetPassword}/:user_id/:reset_token`,
    Component: <ResetPasswordPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.privacy,
    Component: <PrivacyPolicy />,
    Layout: CommonPagesLayout,
    layoutProps: {},
  },
  {
    path: book.termsOfUse,
    Component: <TermsOfUse />,
    Layout: CommonPagesLayout,
    layoutProps: {},
  },
  {
    path: book.cookiePolicy,
    Component: <CookiePolicy />,
    Layout: CommonPagesLayout,
    layoutProps: {},
  },
  {
    path: book.franchiseTerms,
    Component: <FranchiseTerms />,
    Layout: CommonPagesLayout,
    layoutProps: {},
  },
];
