import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';
import { UploadFileButton } from '../../../components/ui-kit/DropZone/UploadFileButton';
import { Box, FormHelperText } from '@mui/material';

export const RenderUploadFileField: React.FC<FieldRenderProps<string>> = ({
  input: { onChange, value },
  meta,
}) => {
  return (
    <>
      <UploadFileButton
        testId={'create-new-resource-file'}
        value={value}
        onChange={onChange}
      />
      {getFieldError(meta) && (
        <Box mx={2}>
          <FormHelperText error>
            {meta.error || meta.submitError}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
