import { createAction } from '@reduxjs/toolkit';
import balanceSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const balanceActions = {
  ...balanceSlice.actions,
  fetchTransactionHistory: createAction(
    'balance/transactions',
    ({ order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  fetchTransactionByString: createAction(
    'balance/fetchTransactionByString',
    (payload) => {
      return {
        payload,
      };
    },
  ),
  fetchWalletsWithStats: createAction(
    'balance/fetchWalletsWithStats',
    ({ ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  fetchWallets: createAction('balance/wallets'),
};
