import { createAction } from '@reduxjs/toolkit';
import optionsSlice from './slice';

export const optionsActions = {
  ...optionsSlice.actions,
  fetchRoyaltyCategories: createAction('options/fetchRoyaltyCategories'),
  fetchCodes: createAction('options/fetchCodes'),
  fetchActivities: createAction('options/fetchActivities'),
  fetchCountries: createAction('options/fetchCountries'),
  fetchCities: createAction('options/fetchCities'),
  fetchCurrencies: createAction('options/fetchCurrencies'),
};
