import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormHelperText,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import DefaultIconLT from '../Checkbox/DefaultIconLT';
import DefaultIcon from '../Checkbox/DefaultIcon';
import CheckedIconLT from '../Checkbox/CheckedIconLT';
import CheckedIcon from '../Checkbox/CheckedIcon';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';

type CheckboxAndLabelProps = {
  label: string;
  error?: boolean;
  helperText?: string;
  input: Partial<FieldRenderProps<boolean>>;
};

export const CheckboxAndLabel: React.FC<CheckboxAndLabelProps> = ({
  label,
  error,
  helperText,
  input: { value, ...input },
}) => {
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();

  return (
    <>
      <FormControlLabel
        control={
          <MuiCheckbox
            {...input}
            icon={isLightTheme ? <DefaultIconLT /> : <DefaultIcon />}
            checkedIcon={isLightTheme ? <CheckedIconLT /> : <CheckedIcon />}
          />
        }
        label={
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'h8'}
            color={theme.palette.grey['50']}>
            {label}
          </Typography>
        }
      />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
};
