import { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Partial<Components<Theme>> = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& input': {
          padding: '15px 4px 0 6px!important',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.common.inputs.mainColor}`,
            },
          },
          paddingBottom: '6px',
        },
      }),
      endAdornment: {
        right: '16px!important',
      },
      paper: ({ theme }) => ({
        marginTop: '6px',
        marginBottom: '6px',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.common.inputs.defaultBorder}`,
        background: theme.palette.common.richBlack,
        '& ::-webkit-scrollbar': {
          width: '5px',
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.common.mainColorFirst,
          borderRadius: '8px',
        },
      }),
      listbox: ({ theme }) => ({
        maxHeight: '239px',
        background: theme.palette.common.white,
        '& .MuiAutocomplete-option': {
          backgroundColor: 'transparent',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: 15,
          lineHeight: '100%',
          padding: '15px',
          '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.common.inputs.defaultBorder}`,
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.common.inputs.mainColor,
          },
          '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
            color: theme.palette.common.inputs.mainColor,
            borderColor: theme.palette.common.inputs.mainColor,
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.common.inputs.mainColor,
            },
          },
        },
      }),
    },
  },
};
