import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';
import { Autocomplete } from '../../../components/ui-kit/inputs/Autocomplete';
import { City } from 'country-state-city';
import { IOption } from '../../../bus/options/typedefs';
import { v4 as uuidv4 } from 'uuid';

export const RenderCityField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const [cities, setCities] = useState<IOption[]>([]);
  const {
    input: { value: country },
  } = useField('country');

  useEffect(() => {
    City.getCitiesOfCountry(country) &&
      setCities(
        City.getCitiesOfCountry(country)!.map((city) => ({
          label: city.name,
          id: uuidv4(),
        })),
      );
  }, [country]);

  return (
    <Box my={1}>
      <Autocomplete
        input={input}
        options={cities}
        label={'City'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};
