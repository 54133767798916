import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../../bus/auth/actions';
import { getCurrentUserProfile } from '../../../bus/auth/selectors';
import { uiActions } from '../../../bus/ui/actions';
import { book } from '../../../routes/book';
import ArrowIcon from '../../ui-kit/Icons/ArrowIcon';
import MobileLogOutIcon from './MobileLogOutIcon';
import MobileSettingsIcon from './MobileSettingsIcon';
import { styles } from './styles';

type ProfileProps = {};

export const MobileProfile: React.FC<ProfileProps> = () => {
  const profile = useSelector(getCurrentUserProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  return profile ? (
    <Accordion sx={styles.root}>
      <AccordionSummary
        expandIcon={<ArrowIcon color={theme.palette.common.mainColorFirst} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {profile.rank.level.image && (
          <Box width={'56.47px'} height={'56.47px'}>
            <Box
              width={'100%'}
              component={'img'}
              alt={'profile image for mobile'}
              src={profile.rank.level.image}
            />
          </Box>
        )}
        <Box sx={styles.titleBlock} display={'flex'} flexDirection={'column'}>
          <Typography variant={'profile1'}>
            {`${profile.first_name} ${profile.last_name}`}
          </Typography>
          <Typography variant={'profile2'}>
            {profile.rank.level.rank}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              dispatch(uiActions.toggleMobileSidebar());
              localStorage.setItem(
                'settings_back_url',
                window.location.pathname,
              );
              navigate(book.settingsProfile);
            }}>
            <ListItemIcon>
              <MobileSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              dispatch(authActions.clearData());
              dispatch(uiActions.toggleMobileSidebar());
            }}>
            <ListItemIcon>
              <MobileLogOutIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItemButton>
        </ListItem>
      </AccordionDetails>
    </Accordion>
  ) : null;
};
