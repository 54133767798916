import { apply, call, put } from 'redux-saga/effects';
import { faqActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import { FAQItemType } from '../../typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { uiActions } from '../../../ui/actions';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* updateFaq({
  payload: { values, reject, resolve },
}: PayloadAction<OnFormSubmitPayload>) {
  try {
    yield put(faqActions.startFetching());
    const body: string = yield call(JSON.stringify, values);
    const response: Response = yield apply(api, api.put, [
      {
        endpoint: `faqs/${values.slug}/`,
        body,
      },
    ]);
    if (!response.ok) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }
    const faq: FAQItemType = yield call([response, 'json']);
    yield put(faqActions.replaceFaq(faq));
    yield put(uiActions.closeModal());
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(faqActions.stopFetching());
  }
}
