import { apply, call, put } from 'redux-saga/effects';
import { resourcesActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { IResourceItem } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* postResource({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(resourcesActions.startFetching());

    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `resources/`, body },
    ]);

    const data: ServerFormErrors | IResourceItem = yield call([
      response,
      'json',
    ]);

    if (!response.ok) {
      const formErrors: FormErrors = yield call(
        getServerFormErrors,
        data as ServerFormErrors,
      );
      reject(formErrors);
      yield throwError(response);
    }

    yield put(resourcesActions.addResource(data as IResourceItem));

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(resourcesActions.stopFetching());
  }
}
