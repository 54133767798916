import { apply, call, put } from 'redux-saga/effects';
import { faqActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* sortFaqs({
  payload: { slug, ...values },
}: PayloadAction<{
  slug: string;
  old_position: number;
  new_position: number;
}>) {
  try {
    yield put(faqActions.startFetching());
    const body: string = yield call(JSON.stringify, values);
    const response: Response = yield apply(api, api.post, [
      {
        endpoint: `faqs/${slug}/change_position/`,
        body,
      },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(faqActions.stopFetching());
  }
}
