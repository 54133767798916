import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextField, Typography } from '@mui/material';
import { getFieldError } from '../../../helpers/getFieldError';

const RenderAmountField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
  valuePercent,
}) => {
  useEffect(() => {
    if (valuePercent) {
      input.onChange(valuePercent);
    }
  }, [valuePercent]);

  return (
    <TextField
      fullWidth
      InputProps={{
        endAdornment: (
          <Typography variant={'Button/Button 16'}>BUSD</Typography>
        ),
      }}
      label={'Amount'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
      {...input}
    />
  );
};

export default RenderAmountField;
