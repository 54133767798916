import { createAction } from '@reduxjs/toolkit';
import todoSlice from './slice';

export const todoActions = {
  ...todoSlice.actions,
  bulkUpdateTodo: createAction('todo/bulkUpdateTodo', (done) => {
    return {
      payload: { done },
    };
  }),
  bulkDeleteTodo: createAction('todo/bulkDeleteTodo'),
  createTodo: createAction('ui/createTodo', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: { resolve, reject },
    };
  }),
  deleteTodo: createAction('todo/deleteTodo', (id) => {
    return {
      payload: { id },
    };
  }),
  updateTodo: createAction('todo/updateTodo', (item) => {
    return {
      payload: item,
    };
  }),
  loadMoreTodo: createAction('todo/loadMoreTodo', (nextPageUrl) => {
    return {
      payload: { nextPageUrl },
    };
  }),
  fetchTodo: createAction('todo/fetchTodo'),
};
