import { apply, call, put } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';
import { ITwoFactorQrCode } from '../../typedefs';

export function* fetchTwoFactorQrCode() {
  try {
    yield put(authActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: 'users/two-factor-qr-code/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const twoFactor: ITwoFactorQrCode = yield call([response, 'json']);
    yield put(authActions.fillTwoFactorQrCode(twoFactor));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(authActions.stopFetching());
  }
}
