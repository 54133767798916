import React from 'react';
import { styles } from './styles';
import { Box, Divider, List } from '@mui/material';
import { ADMINMENUITEMS, MENUITEMS } from './menu';
import { MenuItem } from './MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIsSidebarOpen } from '../../../bus/ui/selectors';
import { getCurrentUserProfile } from '../../../bus/auth/selectors';
import { uiActions } from '../../../bus/ui/actions';
import { Logo } from '../Logo';
import { CollapseButton } from '../CollapseButton';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentPageName = `/${pathname.split('/')[1]}`;
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const profile = useSelector(getCurrentUserProfile);
  const isLight = useIsLightTheme();

  const toggleSidebar = () => {
    dispatch(uiActions.toggleSidebar());
  };

  return (
    <Box
      marginTop={isLight ? '100px' : '0'}
      height={isLight ? 'calc(100vh - 100px)' : '100vh'}
      sx={[styles.root, !isSidebarOpen && styles.collapsed]}>
      {!isLight && (
        <Box
          sx={[styles.logoWrapper, !isSidebarOpen && styles.logoWrapperSmall]}>
          <Logo small={!isSidebarOpen} />
          <Box sx={styles.collapseButtonWrapper}>
            <CollapseButton
              onClick={toggleSidebar}
              collapsed={!isSidebarOpen}
            />
          </Box>
        </Box>
      )}
      <Box sx={[styles.menuWrapper]}>
        {isLight && (
          <Box sx={styles.collapseButtonWrapperLight}>
            <CollapseButton
              onClick={toggleSidebar}
              collapsed={!isSidebarOpen}
            />
          </Box>
        )}
        <Box
          component="div"
          sx={styles.scrollbar}
          width={!isSidebarOpen ? '117px' : '100%'}>
          <List sx={[styles.menu, !isSidebarOpen && styles.menuCollapsed]}>
            {MENUITEMS.map((item) => (
              <MenuItem
                key={item.label}
                item={item}
                collapsed={!isSidebarOpen}
                activeLink={currentPageName === item.link}
              />
            ))}
            {profile?.is_admin && (
              <>
                <Divider sx={{ m: 3 }} />
                {ADMINMENUITEMS.map((item) => (
                  <MenuItem
                    key={item.label}
                    item={item}
                    collapsed={!isSidebarOpen}
                    activeLink={currentPageName === item.link}
                  />
                ))}
              </>
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
