import { all, call, takeEvery } from 'redux-saga/effects';
// eslint-disable-next-line prettier/prettier
import {
  handleMessage,
  handleConnect,
} from './workers';
import { types } from '../types';
// imports
function* watchHandleMessage() {
  yield takeEvery(types.MESSAGE, handleMessage);
}

function* watchHandleConnect() {
  yield takeEvery(types.CONNECT, handleConnect);
}
// watchers
export function* watchWebSocket() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchHandleMessage),
    call(watchHandleConnect),
  ]);
}
