import { date, object, string } from 'yup';
import moment from 'moment/moment';

export const schema = object().shape({
  first_name: string()
    .min(2, 'Minimum 2 symbol')
    .required('First Name is required field'),
  last_name: string()
    .min(2, 'Minimum 2 symbol')
    .required('Last Name is required field'),
  email: string().email('Wrong email').required('Email is required field'),
  contact: string().required('Contact phone is required field'),
  date_of_birth: date()
    .max(
      moment().subtract(18, 'years').add(1, 'hour').toDate(),
      'Your age should be more than 18',
    )
    .typeError('Invalid Date')
    .required('Date of birth is required field'),
  country: string().required('Country is required field'),
  source: string(),
  reason: string(),
});
