import { apply, call, put } from 'redux-saga/effects';
import { transactionsActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { TransactionPasswordActionPayload } from '../../typedefs';
import { handleError } from '../../../ui/saga/workers/handleError';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { throwError } from '../../../ui/saga/workers/throwError';

export function* sendCodeForResetPassword({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<TransactionPasswordActionPayload>) {
  try {
    yield put(transactionsActions.startFetching());

    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.get, [
      { endpoint: `users/reset-transaction-password-request/`, body },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }

    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(transactionsActions.stopFetching());
  }
}
