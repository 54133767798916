import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '60px 24px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 20px',
      width: 'calc(100% - 40px)',
    },
  }),
  inactive: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '40',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
      width: 'calc(100% - 40px)',
    },
  }),
  title: (theme: Theme) => ({
    paddingBottom: '19px',
    borderBottom: `1px solid ${theme.palette.common.white09}`,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  }),
  id: (theme: Theme) => ({
    borderRadius: '7px',
    background: theme.palette.common.teamPage.userModal.idBackground,
    padding: '2px 6px',
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
  }),
  central: (theme: Theme) => ({
    padding: '29.5px 7px 0 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '26px',
    },
  }),
  userRanks: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        display: '-webkit-inline-box',
        flexShrink: 0,
      },
      flexDirection: 'row',
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        height: '6px',
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.common.mainColorFirst,
        borderRadius: '2px',
      },
    },
  }),
  button: {
    minWidth: '140px',
    minHeight: '40px',
    height: '40px',
  },
};
