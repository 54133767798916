// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  WalletsState,
  FillWalletsWithStatsActionPayload,
} from './typedefs';

const initialState: WalletsState = {
  isFetching: false,
  isInitialized: true,
  results: [],
};

const walletsSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillWalletsWithStats(
      state,
      action: PayloadAction<FillWalletsWithStatsActionPayload>,
    ) {
      state.results = action.payload;
    },
    // inject
  },
});

export default walletsSlice;
