import { all, call, takeEvery } from 'redux-saga/effects';
import { productsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  editProduct,
  deleteProduct,
  fetchProducts,
  postProduct,
  loadMoreProducts,
} from './workers';

// imports
function* watchFetchProducts() {
  yield takeEvery(productsActions.fetchProducts.type, fetchProducts);
}
function* watchEditProduct() {
  yield takeEvery(productsActions.editProduct.type, editProduct);
}
function* watchDeleteProduct() {
  yield takeEvery(productsActions.deleteProduct.type, deleteProduct);
}
function* watchPostProduct() {
  yield takeEvery(productsActions.postProduct.type, postProduct);
}
function* watchLoadMoreProducts() {
  yield takeEvery(productsActions.loadMoreProducts.type, loadMoreProducts);
}
// watchers
export function* watchProducts() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchLoadMoreProducts),
    call(watchPostProduct),
    call(watchDeleteProduct),
    call(watchEditProduct),
    call(watchFetchProducts),
  ]);
}
