import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const FullLogoLight: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      width={'272'}
      height={'88'}
      viewBox={'0 0 272 88'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d="M68.4541 13.8457L63.0243 13.8457C58.6207 13.8457 55.0535 17.3953 55.0569 21.7739L55.068 36.2232C55.0713 40.6019 58.6439 44.1514 63.0476 44.1514L68.4774 44.1514C72.8811 44.1514 76.4482 40.6019 76.4448 36.2232L76.4338 21.7739C76.4304 17.3953 72.8578 13.8457 68.4541 13.8457Z"
        fill="white"
      />
      <path
        d="M68.4541 13.8457L63.0243 13.8457C58.6207 13.8457 55.0535 17.3953 55.0569 21.7739L55.068 36.2232C55.0713 40.6019 58.6439 44.1514 63.0476 44.1514L68.4774 44.1514C72.8811 44.1514 76.4482 40.6019 76.4448 36.2232L76.4338 21.7739C76.4304 17.3953 72.8578 13.8457 68.4541 13.8457Z"
        stroke="white"
      />
      <path
        d="M38.9146 15.7539L38.9146 15.7539C33.1487 15.7539 28.4746 20.4015 28.4746 26.1345L28.4746 56.9618C28.4746 62.6949 33.1487 67.3424 38.9146 67.3424L38.9146 67.3424C44.6804 67.3424 49.3545 62.6949 49.3545 56.9618L49.3545 26.1345C49.3545 20.4015 44.6804 15.7539 38.9146 15.7539Z"
        fill="#B5D2FF"
      />
      <path
        d="M69.6017 26.8492C69.6017 38.489 60.395 47.9249 49.0381 47.9249C37.6811 47.9249 28.4746 38.489 28.4746 26.8492C28.4746 15.2093 37.6811 5.77344 49.0381 5.77344C60.395 5.77344 69.6017 15.2093 69.6017 26.8492Z"
        fill="white"
      />
      <path
        d="M69.6017 26.8492C69.6017 38.489 60.395 47.9249 49.0381 47.9249C37.6811 47.9249 28.4746 38.489 28.4746 26.8492C28.4746 15.2093 37.6811 5.77344 49.0381 5.77344C60.395 5.77344 69.6017 15.2093 69.6017 26.8492Z"
        stroke="white"
      />
      <path
        d="M3.13917 48.0476C0.662168 48.6957 -0.641522 51.3314 0.314518 53.6648C3.74756 62.0042 16.1326 82.1829 56.8946 86.7631C56.8946 86.7631 90.4864 89.442 97.3525 54.3993C97.3525 54.3993 108.608 51.8932 110.694 40.918C110.694 40.918 101.307 47.5722 96.0922 46.1032L94.2236 50.2512L89.6172 48.1772C89.6172 48.1772 82.925 49.8192 75.8415 45.8872C75.8415 45.8872 76.0588 56.0413 91.0514 54.7882C91.0514 54.7882 90.2256 72.2448 43.3798 51.8932C41.6849 51.1587 39.9467 50.5969 38.165 50.2512C31.0815 48.9117 12.3085 45.671 3.13917 48.0476Z"
        fill="#4B5FA6"
      />
      <path
        d="M22.6512 72.7188L17.3496 81.9224C17.3496 81.9224 21.0434 84.4285 26.9535 77.3421L22.6512 72.7188Z"
        fill="#4B5FA6"
      />
      <path
        d="M66.24 62.2196C66.0225 62.8245 61.1121 61.701 55.202 59.6702C49.2919 57.6826 44.6855 55.5653 44.9029 54.9603C45.1202 54.3555 50.0306 55.4789 55.9407 57.5097C61.8508 59.5406 66.4572 61.6146 66.24 62.2196Z"
        fill="white"
      />
      <path
        d="M0.318359 53.6647C0.318359 53.6647 24.1759 46.2327 43.9485 62.7387C50.5104 68.2262 58.767 71.3373 67.3714 71.294C75.4978 71.2509 85.3623 69.5657 94.4447 63.5596C94.4447 63.5596 88.2304 86.4606 57.9414 86.763C57.985 86.8062 13.5291 84.9483 0.318359 53.6647Z"
        fill="#B5D2FF"
      />
      <path
        d="M44.7734 62.0898C44.7734 62.0898 46.4683 75.7872 61.8953 76.5217C61.8953 76.5217 56.246 65.2009 48.8149 62.0898L44.7734 62.0898Z"
        fill="#4B5FA6"
      />
      <path
        d="M59.1656 15.7539L59.1655 15.7539C53.3997 15.7539 48.7256 20.4015 48.7256 26.1345L48.7256 56.9618C48.7256 62.6949 53.3997 67.3424 59.1656 67.3424C64.9314 67.3424 69.6055 62.6949 69.6055 56.9618L69.6055 26.1345C69.6055 20.4015 64.9314 15.7539 59.1656 15.7539Z"
        fill="white"
      />
      <path
        d="M42.0975 39.8756C50.9735 39.424 58.5519 31.8887 59.0244 23.0451C59.4969 14.2015 52.6846 7.39851 43.8086 7.85017C34.9327 8.30183 27.3543 15.8371 26.8817 24.6807C26.4092 33.5243 33.2216 40.3273 42.0975 39.8756Z"
        fill="#B5D2FF"
      />
      <path
        opacity="0.7"
        d="M32.0666 24.417C33.6357 24.6911 35.3629 23.2428 35.9246 21.1823C36.4865 19.1217 35.6699 17.2291 34.1009 16.9552C32.532 16.6811 30.8046 18.1294 30.2429 20.1899C29.681 22.2504 30.4976 24.143 32.0666 24.417Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M30.4045 29.5149C31.0616 29.5665 31.7279 28.9061 31.8927 28.0399C32.0573 27.1735 31.658 26.4295 31.0007 26.3778C30.3436 26.3262 29.6773 26.9866 29.5127 27.853C29.3479 28.7192 29.7472 29.4634 30.4045 29.5149Z"
        fill="white"
      />
      <path
        d="M52.9168 14.1096C52.8624 13.9307 53.4271 14.2869 53.4271 14.2869L53.8543 14.4538C53.8543 14.4538 54.6025 14.7905 54.9599 14.9815C55.3202 15.1785 55.6704 15.3927 56.0118 15.6194C56.419 15.8967 56.7434 16.2854 56.9326 16.754C57.7346 18.744 58.1135 20.9687 57.9682 23.3056C57.6747 27.9889 55.795 31.059 52.5868 33.5785C52.5868 33.5785 53.7172 31.4028 54.3017 26.5861C54.6238 23.8981 54.8334 20.5725 52.9168 14.1096Z"
        fill="#4B5FA6"
      />
      <path
        d="M127.214 55.1654C127.214 56.5145 126.12 57.6081 124.771 57.6081L118.678 57.6081C117.664 57.6081 116.842 58.4301 116.842 59.4442C116.842 60.4582 117.664 61.2802 118.678 61.2802L122.709 61.2802C124.058 61.2802 125.152 62.3739 125.152 63.723C125.152 65.0721 124.058 66.1657 122.709 66.1657L117.842 66.1657C117.289 66.1657 116.842 66.6134 116.842 67.1657L116.842 73.7973L113.371 73.7973C111.714 73.7973 110.371 72.4542 110.371 70.7973L110.371 55.7226C110.371 54.0658 111.714 52.7226 113.371 52.7226L124.771 52.7226C126.12 52.7226 127.214 53.8163 127.214 55.1654Z"
        fill="#4B5FA6"
      />
      <path
        d="M132.195 73.7973C130.538 73.7973 129.195 72.4542 129.195 70.7973L129.195 60.7752C129.195 59.1495 130.513 57.8316 132.139 57.8316C133.706 57.8316 134.999 59.06 135.078 60.6255L135.126 61.5676C135.486 60.3116 136.046 59.3218 136.807 58.598C137.59 57.8742 138.605 57.5123 139.852 57.5123C140.296 57.5123 140.645 57.5549 140.899 57.64C141.313 57.7363 141.505 58.1568 141.459 58.5795L141.055 62.2816C141.009 62.7086 140.56 62.9884 140.138 62.9087C139.799 62.8449 139.408 62.8129 138.964 62.8129C138.013 62.8129 137.209 63.079 136.554 63.6112C135.919 64.1221 135.602 64.8991 135.602 65.9422L135.602 70.7973C135.602 72.4542 134.259 73.7973 132.602 73.7973L132.195 73.7973Z"
        fill="#4B5FA6"
      />
      <path
        d="M151.67 63.7709C151.67 63.1535 151.512 62.6746 151.195 62.334C150.877 61.9721 150.433 61.7911 149.862 61.7911C149.313 61.7911 148.837 61.9401 148.435 62.2382C147.627 62.8706 146.857 63.7758 145.848 63.5916L144.848 63.409C143.409 63.1463 142.35 61.7308 143.144 60.5023C143.516 59.9269 144 59.4199 144.597 58.9812C145.929 58.0019 147.832 57.5123 150.306 57.5123C152.844 57.5123 154.758 58.0552 156.047 59.1408C157.359 60.2265 158.014 61.7166 158.014 63.6112L158.014 68.9757C158.014 69.8272 158.416 70.2529 159.219 70.2529C159.531 70.2529 159.88 70.5275 159.852 70.8378L159.675 72.7626C159.628 73.2672 159.329 73.7276 158.843 73.8729C158.301 74.0354 157.697 74.1166 157.031 74.1166C155.91 74.1166 154.948 73.9144 154.144 73.5099C153.341 73.0842 152.802 72.4456 152.527 71.5941C152.104 72.3817 151.427 72.999 150.497 73.4461C149.566 73.8931 148.53 74.1166 147.388 74.1166C145.739 74.1166 144.449 73.7441 143.519 72.999C142.588 72.2327 142.123 71.1683 142.123 69.8059C142.123 67.443 143.561 65.9741 146.437 65.3994L151.139 64.424C151.448 64.3598 151.67 64.087 151.67 63.7709ZM148.276 68.9757C148.276 69.3802 148.403 69.6995 148.657 69.9336C148.932 70.1465 149.291 70.2529 149.736 70.2529C150.243 70.2529 150.687 70.0826 151.068 69.742C151.469 69.4014 151.67 68.8905 151.67 68.2093L151.67 67.0279L149.799 67.443C148.784 67.6771 148.276 68.188 148.276 68.9757Z"
        fill="#4B5FA6"
      />
      <path
        d="M164.471 73.7973C162.814 73.7973 161.471 72.4542 161.471 70.7973L161.471 60.7856C161.471 59.1542 162.793 57.8316 164.425 57.8316C166.002 57.8316 167.302 59.0714 167.375 60.6473L167.402 61.2164C167.867 60.0243 168.565 59.1089 169.496 58.4703C170.426 57.8316 171.547 57.5123 172.858 57.5123C174.465 57.5123 175.733 57.9913 176.664 58.9492C177.594 59.8859 178.06 61.2377 178.06 63.0045L178.06 70.7973C178.06 72.4542 176.716 73.7973 175.06 73.7973L174.652 73.7973C172.996 73.7973 171.652 72.4542 171.652 70.7973L171.652 64.665C171.652 63.7922 171.504 63.1855 171.208 62.8449C170.933 62.5043 170.532 62.334 170.003 62.334C169.369 62.334 168.851 62.5681 168.449 63.0365C168.068 63.5048 167.878 64.2818 167.878 65.3674L167.878 70.7973C167.878 72.4542 166.535 73.7973 164.878 73.7973L164.471 73.7973Z"
        fill="#4B5FA6"
      />
      <path
        d="M189.038 57.5123C190.814 57.5123 192.273 57.7678 193.415 58.2787C194.578 58.7896 195.455 59.4389 196.048 60.2265C196.104 60.3015 196.158 60.3769 196.21 60.4528C197.013 61.628 196.085 63.0171 194.724 63.4347L193.085 63.9377C192.085 64.2446 191.189 63.4483 190.592 62.5894C190.317 62.1637 189.873 61.9508 189.26 61.9508C188.456 61.9508 187.854 62.2488 187.452 62.8449C187.071 63.4409 186.881 64.4521 186.881 65.8783C186.881 67.3685 187.103 68.3796 187.547 68.9118C187.991 69.4227 188.573 69.6782 189.292 69.6782C191.063 69.6782 192.866 67.8471 194.555 68.3823L194.749 68.444C196.081 68.8663 197.006 70.2495 196.162 71.3631C196.086 71.4628 196.006 71.5611 195.921 71.6579C195.286 72.403 194.409 72.999 193.288 73.4461C192.167 73.8931 190.846 74.1166 189.323 74.1166C186.574 74.1166 184.407 73.3929 182.821 71.9453C181.235 70.4978 180.442 68.4541 180.442 65.8145C180.442 64.0476 180.802 62.5468 181.521 61.3122C182.239 60.0775 183.244 59.1408 184.534 58.5022C185.824 57.8423 187.325 57.5123 189.038 57.5123Z"
        fill="#4B5FA6"
      />
      <path
        d="M202.198 73.7973C200.541 73.7973 199.198 72.4542 199.198 70.7973L199.198 54.2538C199.198 52.5969 200.541 51.2538 202.198 51.2538L202.605 51.2538C204.262 51.2538 205.605 52.5969 205.605 54.2538L205.605 59.3686C206.536 57.0908 208.196 57.5123 210.585 57.5123C212.192 57.5123 213.461 57.9913 214.391 58.9492C215.322 59.8859 215.787 61.2377 215.787 63.0045L215.787 70.7973C215.787 72.4542 214.444 73.7973 212.787 73.7973L212.38 73.7973C210.723 73.7973 209.38 72.4542 209.38 70.7973L209.38 64.7288C209.38 63.8134 209.232 63.1855 208.936 62.8449C208.661 62.5043 208.27 62.334 207.762 62.334C207.128 62.334 206.61 62.5788 206.208 63.0684C205.806 63.558 205.605 64.335 205.605 65.3994L205.605 70.7973C205.605 72.4542 204.262 73.7973 202.605 73.7973L202.198 73.7973Z"
        fill="#4B5FA6"
      />
      <path
        d="M222.071 56.4267C220.802 56.4267 219.882 56.2138 219.311 55.788C218.762 55.341 218.487 54.6066 218.487 53.5848C218.487 52.563 218.762 51.8285 219.311 51.3815C219.882 50.9345 220.802 50.7109 222.071 50.7109C223.361 50.7109 224.281 50.9345 224.83 51.3815C225.38 51.8285 225.655 52.563 225.655 53.5848C225.655 54.6066 225.38 55.341 224.83 55.788C224.281 56.2138 223.361 56.4267 222.071 56.4267ZM222.274 57.8316C223.931 57.8316 225.274 59.1748 225.274 60.8316L225.274 70.7973C225.274 72.4542 223.931 73.7973 222.274 73.7973L221.867 73.7973C220.211 73.7973 218.867 72.4542 218.867 70.7973L218.867 60.8316C218.867 59.1748 220.211 57.8316 221.867 57.8316L222.274 57.8316Z"
        fill="#4B5FA6"
      />
      <path
        d="M235.386 74.1166C233.822 74.1166 232.341 73.8825 230.946 73.4142C230.193 73.1615 229.499 72.8439 228.863 72.4612C227.95 71.9116 227.85 70.6898 228.49 69.8379C229.25 68.8274 230.752 68.7272 231.878 69.3031C231.957 69.3436 232.038 69.3835 232.119 69.4227C233.029 69.8485 233.98 70.0614 234.974 70.0614C235.482 70.0614 235.904 69.9975 236.243 69.8698C236.602 69.7208 236.782 69.4972 236.782 69.1992C236.782 68.9863 236.687 68.816 236.497 68.6883C236.327 68.5393 235.957 68.4116 235.386 68.3051L233.737 67.9858C231.559 67.5601 230.026 66.9427 229.138 66.1338C228.25 65.3036 227.806 64.2179 227.806 62.8768C227.806 62.004 228.07 61.1631 228.599 60.3542C229.148 59.524 230.005 58.8428 231.168 58.3106C232.352 57.7784 233.875 57.5123 235.735 57.5123C237.364 57.5123 238.812 57.7358 240.081 58.1829C240.686 58.3961 241.236 58.6481 241.73 58.9389C242.623 59.4637 242.694 60.6487 242.071 61.4753C241.317 62.4741 239.832 62.5733 238.669 62.1129C238.611 62.0903 238.553 62.0682 238.495 62.0466C237.628 61.7273 236.803 61.5676 236.021 61.5676C234.794 61.5676 234.181 61.8443 234.181 62.3978C234.181 62.6107 234.297 62.8023 234.53 62.9726C234.784 63.1216 235.238 63.26 235.894 63.3877L238.431 63.8347C240.102 64.1328 241.318 64.6862 242.079 65.4952C242.84 66.3041 243.221 67.294 243.221 68.4648C243.221 69.5079 242.946 70.4552 242.396 71.3067C241.846 72.1582 240.99 72.8394 239.827 73.3503C238.664 73.8612 237.184 74.1166 235.386 74.1166Z"
        fill="#4B5FA6"
      />
      <path
        d="M253.378 74.1166C250.629 74.1166 248.472 73.3929 246.907 71.9453C245.343 70.4978 244.56 68.4541 244.56 65.8145C244.56 64.0476 244.92 62.5468 245.639 61.3122C246.358 60.0775 247.362 59.1408 248.652 58.5022C249.942 57.8423 251.433 57.5123 253.124 57.5123C254.858 57.5123 256.328 57.8423 257.533 58.5022C258.759 59.1408 259.69 60.0349 260.324 61.1844C260.98 62.334 261.307 63.6751 261.307 65.2078C261.307 66.3804 260.301 67.2195 259.128 67.2195L250.714 67.2195C250.862 68.1774 251.158 68.8373 251.602 69.1992C252.067 69.5398 252.68 69.7101 253.441 69.7101C254.097 69.7101 254.615 69.5717 254.996 69.295C255.667 68.8324 256.468 68.3293 257.25 68.5625L258.387 68.9016C259.832 69.3325 260.705 70.9425 259.61 71.979C259.554 72.0326 259.496 72.0853 259.436 72.1369C258.675 72.7968 257.766 73.2971 256.708 73.6377C255.651 73.957 254.541 74.1166 253.378 74.1166ZM253.124 61.8869C252.448 61.8869 251.908 62.0785 251.507 62.4617C251.126 62.8449 250.872 63.4941 250.745 64.4095L255.249 64.4095C255.165 63.5793 254.953 62.9513 254.615 62.5255C254.298 62.0998 253.801 61.8869 253.124 61.8869Z"
        fill="#4B5FA6"
      />
      <path
        d="M125.328 52.8255C125.328 54.1746 124.234 55.2683 122.885 55.2683L116.792 55.2683C115.778 55.2683 114.956 56.0903 114.956 57.1043C114.956 58.1184 115.778 58.9404 116.792 58.9404L120.823 58.9404C122.172 58.9404 123.266 60.034 123.266 61.3831C123.266 62.7322 122.172 63.8259 120.823 63.8259L115.956 63.8259C115.404 63.8259 114.956 64.2736 114.956 64.8259L114.956 71.4575L111.485 71.4575C109.829 71.4575 108.485 70.1143 108.485 68.4575L108.485 53.3828C108.485 51.7259 109.828 50.3828 111.485 50.3828L122.885 50.3828C124.234 50.3828 125.328 51.4764 125.328 52.8255Z"
        fill="white"
      />
      <path
        d="M130.309 71.4575C128.652 71.4575 127.309 70.1143 127.309 68.4575L127.309 58.4354C127.309 56.8097 128.627 55.4918 130.253 55.4918C131.82 55.4918 133.113 56.7202 133.193 58.2856L133.241 59.2278C133.6 57.9718 134.16 56.9819 134.922 56.2581C135.704 55.5344 136.719 55.1725 137.967 55.1725C138.411 55.1725 138.76 55.2151 139.013 55.3002C139.427 55.3964 139.619 55.817 139.573 56.2397L139.169 59.9417C139.123 60.3687 138.674 60.6486 138.252 60.5689C137.914 60.505 137.523 60.4731 137.078 60.4731C136.127 60.4731 135.323 60.7392 134.668 61.2714C134.033 61.7823 133.716 62.5593 133.716 63.6024L133.716 68.4575C133.716 70.1143 132.373 71.4575 130.716 71.4575L130.309 71.4575Z"
        fill="white"
      />
      <path
        d="M149.785 61.431C149.785 60.8137 149.626 60.3347 149.309 59.9941C148.992 59.6322 148.548 59.4513 147.977 59.4513C147.427 59.4513 146.951 59.6003 146.549 59.8983C145.742 60.5307 144.972 61.4359 143.963 61.2517L142.962 61.0691C141.523 60.8065 140.464 59.391 141.258 58.1625C141.63 57.5871 142.115 57.08 142.711 56.6413C144.044 55.6621 145.947 55.1725 148.421 55.1725C150.958 55.1725 152.872 55.7153 154.162 56.801C155.473 57.8866 156.128 59.3768 156.128 61.2714L156.128 66.6358C156.128 67.4873 156.53 67.9131 157.334 67.9131C157.645 67.9131 157.994 68.1876 157.966 68.4979L157.789 70.4227C157.742 70.9273 157.443 71.3877 156.958 71.5331C156.415 71.6956 155.811 71.7768 155.145 71.7768C154.024 71.7768 153.062 71.5746 152.259 71.1701C151.455 70.7443 150.916 70.1057 150.641 69.2542C150.218 70.0419 149.541 70.6592 148.611 71.1062C147.681 71.5533 146.645 71.7768 145.503 71.7768C143.853 71.7768 142.563 71.4043 141.633 70.6592C140.703 69.8928 140.237 68.8285 140.237 67.4661C140.237 65.1031 141.675 63.6343 144.551 63.0595L149.253 62.0841C149.563 62.0199 149.785 61.7472 149.785 61.431ZM146.391 66.6358C146.391 67.0403 146.518 67.3596 146.771 67.5938C147.046 67.8067 147.406 67.9131 147.85 67.9131C148.357 67.9131 148.801 67.7428 149.182 67.4022C149.584 67.0616 149.785 66.5507 149.785 65.8695L149.785 64.688L147.913 65.1031C146.898 65.3373 146.391 65.8482 146.391 66.6358Z"
        fill="white"
      />
      <path
        d="M162.585 71.4575C160.928 71.4575 159.585 70.1143 159.585 68.4575L159.585 58.4457C159.585 56.8143 160.908 55.4918 162.539 55.4918C164.117 55.4918 165.416 56.7315 165.49 58.3075L165.516 58.8765C165.982 57.6844 166.679 56.769 167.61 56.1304C168.54 55.4918 169.661 55.1725 170.972 55.1725C172.579 55.1725 173.848 55.6514 174.778 56.6094C175.709 57.546 176.174 58.8978 176.174 60.6647L176.174 68.4575C176.174 70.1143 174.831 71.4575 173.174 71.4575L172.767 71.4575C171.11 71.4575 169.767 70.1143 169.767 68.4575L169.767 62.3251C169.767 61.4523 169.619 60.8456 169.323 60.505C169.048 60.1644 168.646 59.9941 168.117 59.9941C167.483 59.9941 166.965 60.2283 166.563 60.6966C166.183 61.1649 165.992 61.9419 165.992 63.0276L165.992 68.4575C165.992 70.1143 164.649 71.4575 162.992 71.4575L162.585 71.4575Z"
        fill="white"
      />
      <path
        d="M187.152 55.1725C188.928 55.1725 190.387 55.4279 191.529 55.9388C192.692 56.4497 193.57 57.099 194.162 57.8866C194.218 57.9617 194.272 58.0371 194.324 58.1129C195.127 59.2881 194.199 60.6772 192.839 61.0948L191.199 61.5978C190.199 61.9047 189.303 61.1085 188.706 60.2496C188.431 59.8238 187.987 59.6109 187.374 59.6109C186.571 59.6109 185.968 59.909 185.566 60.505C185.186 61.1011 184.995 62.1122 184.995 63.5385C184.995 65.0286 185.217 66.0398 185.661 66.572C186.105 67.0829 186.687 67.3383 187.406 67.3383C189.177 67.3383 190.981 65.5072 192.669 66.0425L192.864 66.1042C194.196 66.5264 195.12 67.9097 194.276 69.0233C194.201 69.123 194.12 69.2212 194.035 69.3181C193.401 70.0631 192.523 70.6592 191.402 71.1062C190.282 71.5533 188.96 71.7768 187.438 71.7768C184.689 71.7768 182.521 71.053 180.935 69.6055C179.349 68.1579 178.556 66.1143 178.556 63.4746C178.556 61.7078 178.916 60.207 179.635 58.9723C180.354 57.7376 181.358 56.801 182.648 56.1623C183.938 55.5024 185.439 55.1725 187.152 55.1725Z"
        fill="white"
      />
      <path
        d="M200.313 71.4575C198.656 71.4575 197.313 70.1143 197.313 68.4575L197.313 51.9139C197.313 50.2571 198.656 48.9139 200.313 48.9139L200.72 48.9139C202.377 48.9139 203.72 50.2571 203.72 51.9139L203.72 58.5891C204.65 56.3114 206.31 55.1725 208.699 55.1725C210.307 55.1725 211.575 55.6514 212.506 56.6094C213.436 57.546 213.901 58.8978 213.901 60.6647L213.901 68.4575C213.901 70.1143 212.558 71.4575 210.901 71.4575L210.494 71.4575C208.837 71.4575 207.494 70.1143 207.494 68.4575L207.494 62.389C207.494 61.4736 207.346 60.8456 207.05 60.505C206.775 60.1644 206.384 59.9941 205.877 59.9941C205.242 59.9941 204.724 60.2389 204.322 60.7285C203.921 61.2182 203.72 61.9951 203.72 63.0595L203.72 68.4575C203.72 70.1143 202.377 71.4575 200.72 71.4575L200.313 71.4575Z"
        fill="white"
      />
      <path
        d="M220.185 54.0868C218.916 54.0868 217.997 53.8739 217.426 53.4482C216.876 53.0011 216.601 52.2667 216.601 51.2449C216.601 50.2231 216.876 49.4887 217.426 49.0417C217.997 48.5946 218.916 48.3711 220.185 48.3711C221.475 48.3711 222.395 48.5946 222.945 49.0417C223.494 49.4887 223.769 50.2231 223.769 51.2449C223.769 52.2667 223.494 53.0011 222.945 53.4482C222.395 53.8739 221.475 54.0868 220.185 54.0868ZM220.389 55.4918C222.046 55.4918 223.389 56.8349 223.389 58.4918L223.389 68.4575C223.389 70.1143 222.046 71.4575 220.389 71.4575L219.982 71.4575C218.325 71.4575 216.982 70.1143 216.982 68.4575L216.982 58.4918C216.982 56.8349 218.325 55.4918 219.982 55.4918L220.389 55.4918Z"
        fill="white"
      />
      <path
        d="M233.501 71.7768C231.936 71.7768 230.456 71.5426 229.06 71.0743C228.307 70.8217 227.613 70.504 226.977 70.1214C226.064 69.5717 225.964 68.3499 226.604 67.498C227.364 66.4875 228.867 66.3874 229.992 66.9633C230.071 67.0038 230.152 67.0436 230.234 67.0829C231.143 67.5086 232.095 67.7215 233.088 67.7215C233.596 67.7215 234.019 67.6576 234.357 67.5299C234.717 67.3809 234.896 67.1574 234.896 66.8594C234.896 66.6465 234.801 66.4762 234.611 66.3485C234.442 66.1994 234.072 66.0717 233.501 65.9653L231.851 65.646C229.673 65.2202 228.14 64.6029 227.252 63.794C226.364 62.9637 225.92 61.8781 225.92 60.5369C225.92 59.6642 226.184 58.8233 226.713 58.0144C227.263 57.1842 228.119 56.503 229.282 55.9708C230.466 55.4386 231.989 55.1725 233.85 55.1725C235.478 55.1725 236.926 55.396 238.195 55.843C238.8 56.0563 239.35 56.3083 239.845 56.599C240.737 57.1238 240.809 58.3089 240.185 59.1354C239.431 60.1343 237.946 60.2334 236.783 59.7731C236.726 59.7504 236.668 59.7283 236.609 59.7067C235.742 59.3874 234.917 59.2278 234.135 59.2278C232.909 59.2278 232.295 59.5045 232.295 60.058C232.295 60.2709 232.412 60.4624 232.644 60.6327C232.898 60.7818 233.353 60.9201 234.008 61.0479L236.546 61.4949C238.216 61.7929 239.432 62.3464 240.193 63.1553C240.955 63.9642 241.335 64.9541 241.335 66.1249C241.335 67.168 241.06 68.1153 240.51 68.9668C239.961 69.8183 239.104 70.4995 237.941 71.0104C236.778 71.5213 235.298 71.7768 233.501 71.7768Z"
        fill="white"
      />
      <path
        d="M251.492 71.7768C248.743 71.7768 246.586 71.053 245.022 69.6055C243.457 68.1579 242.675 66.1143 242.675 63.4746C242.675 61.7078 243.034 60.207 243.753 58.9723C244.472 57.7376 245.476 56.801 246.766 56.1623C248.056 55.5024 249.547 55.1725 251.238 55.1725C252.972 55.1725 254.442 55.5024 255.647 56.1623C256.874 56.801 257.804 57.6951 258.439 58.8446C259.094 59.9941 259.422 61.3352 259.422 62.8679C259.422 64.0406 258.415 64.8796 257.242 64.8796L248.828 64.8796C248.976 65.8376 249.272 66.4975 249.716 66.8594C250.181 67.2 250.794 67.3703 251.556 67.3703C252.211 67.3703 252.729 67.2319 253.11 66.9552C253.782 66.4925 254.583 65.9895 255.364 66.2226L256.501 66.5618C257.946 66.9927 258.819 68.6026 257.725 69.6392C257.668 69.6928 257.61 69.7454 257.55 69.797C256.789 70.457 255.88 70.9572 254.823 71.2978C253.765 71.6171 252.655 71.7768 251.492 71.7768ZM251.238 59.5471C250.562 59.5471 250.023 59.7387 249.621 60.1218C249.24 60.505 248.986 61.1543 248.86 62.0697L253.364 62.0697C253.279 61.2394 253.068 60.6115 252.729 60.1857C252.412 59.76 251.915 59.5471 251.238 59.5471Z"
        fill="white"
      />
      <path
        d="M107.986 20.6759C107.986 22.0001 106.906 23.0737 105.574 23.0737L95.5928 23.0737C94.261 23.0737 93.1812 24.1472 93.1812 25.4716L93.1812 25.8591C93.1812 27.1835 94.261 28.2569 95.5928 28.2569L102.632 28.2569C103.964 28.2569 105.043 29.3305 105.043 30.6548L105.043 32.7552C105.043 34.0794 103.964 35.1529 102.632 35.1529L95.5928 35.1529C94.261 35.1529 93.1812 36.2265 93.1812 37.5507L93.1812 45.9251L91.9836 45.9251C87.5442 45.9251 83.9453 42.3466 83.9453 37.9324L83.9453 24.1705C83.9453 19.7561 87.5442 16.1777 91.9836 16.1777L105.574 16.1777C106.906 16.1777 107.986 17.2512 107.986 18.5756L107.986 20.6759Z"
        fill="#4B5FA6"
      />
      <path
        d="M120.32 22.9395C122.765 22.9395 124.908 23.4051 126.75 24.3366C128.621 25.2382 130.07 26.5752 131.096 28.3481C132.122 30.0908 132.635 32.1941 132.635 34.6581C132.635 37.122 132.122 39.2404 131.096 41.0132C130.07 42.756 128.621 44.0932 126.75 45.0247C124.908 45.9261 122.765 46.3768 120.32 46.3768C117.876 46.3768 115.733 45.9261 113.892 45.0247C112.05 44.0932 110.602 42.756 109.545 41.0132C108.519 39.2404 108.006 37.122 108.006 34.6581C108.006 32.1941 108.519 30.0908 109.545 28.3481C110.602 26.5752 112.05 25.2382 113.892 24.3366C115.733 23.4051 117.876 22.9395 120.32 22.9395ZM120.32 29.2045C119.325 29.2045 118.555 29.61 118.012 30.4214C117.468 31.2026 117.197 32.6149 117.197 34.6581C117.197 36.7013 117.468 38.1287 118.012 38.9399C118.555 39.7212 119.325 40.1118 120.32 40.1118C121.317 40.1118 122.086 39.7212 122.63 38.9399C123.173 38.1287 123.444 36.7013 123.444 34.6581C123.444 32.6149 123.173 31.2026 122.63 30.4214C122.086 29.61 121.317 29.2045 120.32 29.2045Z"
        fill="#4B5FA6"
      />
      <path
        d="M137.478 45.9246C136.146 45.9246 135.066 44.851 135.066 43.5268L135.066 41.4753C135.066 40.8865 135.284 40.3183 135.679 39.8789L143.035 31.6819L145.253 29.4734L141.858 29.5635L137.478 29.5635C136.146 29.5635 135.066 28.49 135.066 27.1656L135.066 25.7865C135.066 24.4623 136.146 23.3887 137.478 23.3887L152.983 23.3887C154.315 23.3887 155.395 24.4623 155.395 25.7865L155.395 27.8388C155.395 28.4271 155.177 28.9949 154.784 29.4341L147.517 37.5413L145.208 39.8398L149.057 39.7498L153.39 39.7498C154.722 39.7498 155.802 40.8232 155.802 42.1476L155.802 43.5268C155.802 44.851 154.722 45.9246 153.39 45.9246L137.478 45.9246Z"
        fill="#4B5FA6"
      />
      <path
        d="M170.642 46.3768C166.719 46.3768 163.64 45.3551 161.407 43.3119C159.172 41.2687 158.056 38.384 158.056 34.6581C158.056 32.1642 158.569 30.0457 159.596 28.3031C160.622 26.5602 162.056 25.2382 163.897 24.3366C165.738 23.4051 167.866 22.9395 170.281 22.9395C172.756 22.9395 174.854 23.4051 176.573 24.3366C178.324 25.2382 179.652 26.5001 180.558 28.1227C181.493 29.7452 181.962 31.6383 181.962 33.8017C181.962 34.1045 181.956 34.3949 181.944 34.6731C181.898 35.8221 180.898 36.6413 179.742 36.6413L166.84 36.6413C167.051 37.9935 167.474 38.9249 168.107 39.4358C168.77 39.9166 169.647 40.1569 170.734 40.1569C171.669 40.1569 172.408 39.9616 172.952 39.571C173.91 38.9185 175.05 38.2069 176.163 38.5351L179.227 39.4389C180.491 39.812 181.258 41.1679 180.506 42.2458C180.165 42.7336 179.761 43.179 179.291 43.5823C178.203 44.5138 176.905 45.2199 175.396 45.7007C173.887 46.1514 172.303 46.3768 170.642 46.3768ZM170.281 29.1143C169.315 29.1143 168.545 29.3847 167.971 29.9256C167.428 30.4664 167.065 31.3829 166.885 32.6749L173.314 32.6749C173.193 31.503 172.892 30.6167 172.408 30.0157C171.955 29.4148 171.246 29.1143 170.281 29.1143Z"
        fill="#4B5FA6"
      />
      <path
        d="M192.784 46.3753C190.459 46.3753 188.618 45.7744 187.26 44.5725C185.901 43.3405 185.223 41.3273 185.223 38.5328L185.223 25.7865C185.223 24.4623 186.302 23.3887 187.634 23.3887L191.956 23.3887C193.288 23.3887 194.368 24.4623 194.368 25.7865L194.368 36.4144C194.368 37.5562 194.579 38.3676 195.002 38.8484C195.424 39.3292 196.044 39.5695 196.858 39.5695C197.733 39.5695 198.427 39.239 198.941 38.5779C199.483 37.9169 199.755 36.8502 199.755 35.3778L199.755 25.7865C199.755 24.4623 200.835 23.3887 202.167 23.3887L206.49 23.3887C207.822 23.3887 208.902 24.4623 208.902 25.7865L208.902 43.5268C208.902 44.851 207.822 45.9246 206.49 45.9246L203 45.9246C201.715 45.9246 200.656 44.9226 200.591 43.6464L200.481 41.4174C199.122 44.7227 196.555 46.3753 192.784 46.3753Z"
        fill="#4B5FA6"
      />
      <path
        d="M223.35 46.3768C221.117 46.3768 219.005 46.0462 217.012 45.3852C215.774 44.9745 214.647 44.442 213.631 43.7875C212.587 43.1154 212.469 41.7018 213.22 40.715L213.892 39.8309C214.774 38.6695 216.503 38.5581 217.776 39.2789C218.065 39.443 218.369 39.6003 218.688 39.7513C219.986 40.3522 221.343 40.6527 222.762 40.6527C223.486 40.6527 224.089 40.5625 224.573 40.3822C225.086 40.1719 225.343 39.8564 225.343 39.4358C225.343 39.1353 225.207 38.8949 224.934 38.7146C224.694 38.5043 224.166 38.3239 223.35 38.1737L220.996 37.723C217.887 37.122 215.699 36.2506 214.432 35.1088C213.164 33.937 212.529 32.4046 212.529 30.5116C212.529 29.2796 212.907 28.0926 213.662 26.9508C214.447 25.7789 215.668 24.8174 217.329 24.0663C219.02 23.3151 221.192 22.9395 223.849 22.9395C226.172 22.9395 228.24 23.2549 230.051 23.8859C231.081 24.2449 232 24.6819 232.805 25.1964C233.819 25.8439 233.9 27.211 233.173 28.1652L232.507 29.0373C231.634 30.1816 229.935 30.293 228.627 29.6852C228.358 29.5609 228.078 29.4457 227.787 29.3397C226.55 28.889 225.373 28.6636 224.256 28.6636C222.505 28.6636 221.63 29.0541 221.63 29.8354C221.63 30.1359 221.796 30.4064 222.127 30.6468C222.49 30.8571 223.139 31.0523 224.075 31.2327L227.697 31.8637C230.081 32.2843 231.817 33.0656 232.903 34.2074C233.99 35.3492 234.533 36.7465 234.533 38.3991C234.533 39.8714 234.14 41.2085 233.356 42.4105C232.571 43.6124 231.35 44.574 229.689 45.2951C228.029 46.0163 225.915 46.3768 223.35 46.3768Z"
        fill="#4B5FA6"
      />
      <path
        d="M105.661 18.3653C105.661 19.6896 104.581 20.7632 103.249 20.7632L93.2676 20.7632C91.9358 20.7632 90.8562 21.8367 90.8562 23.1611L90.8562 23.5485C90.8562 24.8729 91.9358 25.9464 93.2676 25.9464L100.307 25.9464C101.638 25.9464 102.718 27.02 102.718 28.3442L102.718 30.4447C102.718 31.7689 101.638 32.8424 100.307 32.8424L93.2676 32.8424C91.9358 32.8424 90.8562 33.916 90.8562 35.2402L90.8562 43.6146L89.6586 43.6146C85.2191 43.6146 81.6201 40.0361 81.6201 35.6218L81.6201 21.86C81.6201 17.4456 85.219 13.8672 89.6586 13.8672L103.249 13.8672C104.581 13.8672 105.661 14.9407 105.661 16.2649L105.661 18.3653Z"
        fill="white"
      />
      <path
        d="M117.995 20.627C120.44 20.627 122.583 21.0926 124.424 22.0241C126.296 22.9257 127.744 24.2627 128.771 26.0356C129.797 27.7783 130.31 29.8816 130.31 32.3456C130.31 34.8095 129.797 36.9279 128.771 38.7007C127.744 40.4435 126.296 41.7807 124.424 42.7122C122.583 43.6136 120.44 44.0643 117.995 44.0643C115.551 44.0643 113.408 43.6136 111.566 42.7122C109.725 41.7807 108.276 40.4435 107.22 38.7007C106.194 36.9279 105.681 34.8095 105.681 32.3456C105.681 29.8816 106.194 27.7783 107.22 26.0356C108.276 24.2627 109.725 22.9257 111.566 22.0241C113.408 21.0926 115.551 20.627 117.995 20.627ZM117.995 26.892C116.999 26.892 116.23 27.2975 115.686 28.1089C115.143 28.8902 114.871 30.3024 114.871 32.3456C114.871 34.3888 115.143 35.8162 115.686 36.6274C116.23 37.4087 116.999 37.7993 117.995 37.7993C118.991 37.7993 119.761 37.4087 120.304 36.6274C120.848 35.8162 121.119 34.3888 121.119 32.3456C121.119 30.3024 120.848 28.8902 120.304 28.1089C119.761 27.2975 118.991 26.892 117.995 26.892Z"
        fill="white"
      />
      <path
        d="M135.153 43.616C133.821 43.616 132.741 42.5424 132.741 41.2181L132.741 39.1667C132.741 38.5778 132.959 38.0097 133.354 37.5702L140.71 29.3733L142.928 27.1648L139.532 27.2549L135.153 27.2549C133.821 27.2549 132.741 26.1814 132.741 24.857L132.741 23.4779C132.741 22.1537 133.821 21.0801 135.153 21.0801L150.658 21.0801C151.99 21.0801 153.07 22.1537 153.07 23.4779L153.07 25.5302C153.07 26.1185 152.852 26.6863 152.458 27.1255L145.192 35.2327L142.883 37.5312L146.731 37.4412L151.065 37.4412C152.397 37.4412 153.477 38.5146 153.477 39.8389L153.477 41.2181C153.477 42.5424 152.397 43.616 151.065 43.616L135.153 43.616Z"
        fill="white"
      />
      <path
        d="M168.317 44.0643C164.393 44.0643 161.315 43.0426 159.08 40.9994C156.847 38.9562 155.73 36.0715 155.73 32.3456C155.73 29.8517 156.244 27.7332 157.27 25.9904C158.296 24.2477 159.729 22.9257 161.572 22.0241C163.413 21.0926 165.54 20.627 167.954 20.627C170.429 20.627 172.527 21.0926 174.248 22.0241C175.999 22.9257 177.327 24.1876 178.232 25.8102C179.168 27.4327 179.635 29.3258 179.635 31.4892C179.635 31.792 179.631 32.0824 179.619 32.3606C179.573 33.5096 178.573 34.3287 177.417 34.3287L164.514 34.3287C164.725 35.681 165.147 36.6125 165.781 37.1233C166.445 37.6041 167.321 37.8443 168.407 37.8443C169.344 37.8443 170.083 37.6491 170.626 37.2584C171.584 36.606 172.725 35.8944 173.838 36.2226L176.902 37.1264C178.166 37.4995 178.933 38.8554 178.181 39.9333C177.84 40.4211 177.434 40.8665 176.964 41.2699C175.878 42.2013 174.58 42.9074 173.071 43.3882C171.562 43.8389 169.977 44.0643 168.317 44.0643ZM167.954 26.8018C166.99 26.8018 166.22 27.0722 165.646 27.6131C165.103 28.1539 164.74 29.0704 164.559 30.3624L170.988 30.3624C170.867 29.1905 170.565 28.3042 170.083 27.7032C169.63 27.1023 168.92 26.8018 167.954 26.8018Z"
        fill="white"
      />
      <path
        d="M190.461 44.0667C188.136 44.0667 186.295 43.4658 184.936 42.2639C183.578 41.0319 182.899 39.0186 182.899 36.2241L182.899 23.4779C182.899 22.1537 183.979 21.0801 185.311 21.0801L189.633 21.0801C190.965 21.0801 192.045 22.1537 192.045 23.4779L192.045 34.1058C192.045 35.2476 192.256 36.059 192.678 36.5398C193.101 37.0204 193.719 37.2608 194.535 37.2608C195.409 37.2608 196.104 36.9303 196.617 36.2693C197.16 35.6083 197.432 34.5416 197.432 33.0692L197.432 23.4779C197.432 22.1537 198.511 21.0801 199.843 21.0801L204.167 21.0801C205.497 21.0801 206.577 22.1537 206.577 23.4779L206.577 41.2182C206.577 42.5424 205.497 43.616 204.167 43.616L200.677 43.616C199.392 43.616 198.333 42.614 198.268 41.3378L198.156 39.1088C196.799 42.4141 194.232 44.0667 190.461 44.0667Z"
        fill="white"
      />
      <path
        d="M221.027 44.0643C218.794 44.0643 216.68 43.7337 214.689 43.0727C213.451 42.662 212.324 42.1294 211.308 41.475C210.264 40.8028 210.146 39.3891 210.896 38.4025L211.569 37.5184C212.451 36.3569 214.18 36.2456 215.451 36.9663C215.742 37.1305 216.046 37.2878 216.364 37.4388C217.661 38.0396 219.019 38.3402 220.438 38.3402C221.163 38.3402 221.766 38.25 222.25 38.0697C222.763 37.8594 223.018 37.5439 223.018 37.1233C223.018 36.8228 222.883 36.5824 222.611 36.4021C222.371 36.1918 221.841 36.0114 221.027 35.8612L218.673 35.4105C215.563 34.8095 213.376 33.9381 212.107 32.7963C210.84 31.6245 210.206 30.0921 210.206 28.1991C210.206 26.9671 210.584 25.7801 211.339 24.6383C212.124 23.4664 213.345 22.5049 215.006 21.7538C216.696 21.0026 218.869 20.627 221.526 20.627C223.849 20.627 225.917 20.9424 227.728 21.5734C228.758 21.9324 229.675 22.3692 230.482 22.8839C231.495 23.5314 231.577 24.8985 230.849 25.8527L230.183 26.7248C229.311 27.8691 227.612 27.9805 226.302 27.3727C226.035 27.2484 225.755 27.1332 225.464 27.0272C224.226 26.5763 223.049 26.3511 221.933 26.3511C220.182 26.3511 219.307 26.7416 219.307 27.5229C219.307 27.8234 219.472 28.0939 219.804 28.3343C220.167 28.5446 220.816 28.7398 221.751 28.9202L225.374 29.5512C227.758 29.9718 229.494 30.7531 230.579 31.8949C231.667 33.0367 232.209 34.434 232.209 36.0865C232.209 37.5589 231.817 38.896 231.032 40.098C230.248 41.2999 229.025 42.2614 227.365 42.9826C225.706 43.7036 223.592 44.0643 221.027 44.0643Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default FullLogoLight;
