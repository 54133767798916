import { apply, call, put } from 'redux-saga/effects';
import { paymentActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';

export function* fetchPaymentCallback({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(paymentActions.startFetching());

    const body: string = yield call(JSON.stringify, payload);
    const response: Response = yield apply(api, api.post, [
      { endpoint: `paymentCallback/`, body },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    reject(e);
  } finally {
    yield put(paymentActions.stopFetching());
  }
}
