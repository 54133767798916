import { Components } from '@mui/material';

export const MuiTableContainer: Partial<Components> = {
  MuiTableContainer: {
    styleOverrides: {
      root: {
        '& .MuiTable-root': {
          border: 'none',
          '& .MuiTableCell-root': {
            border: 'none',
            padding: '15px 20px',
            boxSizing: 'border-box',
          },
          '& .MuiTableHead-root': {
            height: '47px',
          },
          '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
              height: '47px',
              '&:nth-of-type(odd)': {
                background: 'rgba(116, 107, 155, 0.1)!important',
              },
              '&:nth-of-type(even)': {
                background: 'rgba(185, 178, 215, 0.1)!important',
              },
            },
          },
        },
      },
    },
  },
};
