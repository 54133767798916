import { apply, call, put } from 'redux-saga/effects';
import { todoActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillTodoActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchTodo() {
  try {
    yield put(todoActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `todo/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillTodoActionPayload = yield call([response, 'json']);
    yield put(todoActions.fillTodo(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(todoActions.stopFetching());
  }
}
