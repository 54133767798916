import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';
import moment from 'moment/moment';

export const notificationsSelector = (state: RootState) => state.notifications;

export const getIsNotificationsFetching = createSelector(
  [notificationsSelector],
  (result) => {
    return result.isFetching;
  },
);

export const getNotifications = createSelector(
  [notificationsSelector],
  ({ notifications }) => {
    if (!notifications.results.length) return [];

    return notifications.results.map((item) => {
      return {
        ...item,
        created_at: moment(item.created_at).format('DD MMM YYYY').toUpperCase(),
      };
    });
  },
);

export const newsActivitiesNextUrl = createSelector(
  [notificationsSelector],
  (result) => {
    return result.notifications.next;
  },
);
