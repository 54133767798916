import { createAction } from '@reduxjs/toolkit';
import faqSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';
import { OnFormSubmitPayload } from '../../setup/typedefs';
import slugify from 'slugify';

export const faqActions = {
  ...faqSlice.actions,
  createFaq: createAction('faq/createFaq', (payload: OnFormSubmitPayload) => {
    const slug = slugify(payload.values.title);

    return {
      payload: {
        ...payload,
        values: {
          ...payload.values,
          slug,
          excerpt: payload.values.content.substring(0, 200),
        },
      },
    };
  }),
  deleteFaq: createAction('faq/deleteFaq', (payload: string) => {
    return {
      payload,
    };
  }),
  updateFaq: createAction('faq/updateFaq', (payload: OnFormSubmitPayload) => {
    return {
      payload,
    };
  }),
  fetchFaq: createAction('faq/fetchFaq', (slug: string) => {
    return {
      payload: slug,
    };
  }),
  sortFaqs: createAction(
    'faq/sortFaqs',
    ({ slug, old_position, new_position }) => {
      return {
        payload: {
          slug,
          old_position,
          new_position,
        },
      };
    },
  ),
  fetchFaqs: createAction('faq/fetchFaqs', (categories?: number[]) => {
    return {
      payload: getEndpointParams2(
        { categories },
        new URLSearchParams('page_size=10000'),
      ),
    };
  }),
};
