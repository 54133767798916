import { apply, call, put } from 'redux-saga/effects';
import { adminActions } from '../../actions';
import { handleError } from '../../../ui/saga/workers/handleError';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';

export function* editApplications({
  payload: { id, ...values },
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<{ id: number; status: number }>) {
  try {
    yield put(adminActions.startFetching());

    const body: string = yield call(JSON.stringify, values);

    const response: Response = yield apply(api, api.patch, [
      {
        endpoint: `applications/${id}/`,
        body,
      },
    ]);

    if (!response.ok) {
      reject('something go data');
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(adminActions.stopFetching());
  }
}
