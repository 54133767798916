import { Theme } from '@mui/material';

const styles = {
  root: {
    '& *': {
      '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: (theme: Theme) => theme.palette.common.mainColorFirst,
        borderRadius: '10px',
      },
    },
  },
};

export default styles;
