import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const MercurioIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      sx={{ fontSize: '28px' }}
      viewBox={'0 0 24 28'}
      width={'24'}
      height={'28'}>
      <path
        d="M11.0214 28.5612L7.21748 28.6206C7.17222 25.2546 1.26557 20.5304 1.163 13.2771C1.0725 7.1009 2.28222 3.66 3.09667 2.08631C3.72717 0.868569 4.39081 0.128557 5.60657 0.109822C6.9701 0.087966 7.83889 0.862325 8.22202 3.40709C9.49505 11.8501 12.6173 17.7951 11.0214 28.5612Z"
        fill="#FBFBFB"
        stroke="#232323"
        strokeMiterlimit="10"
      />
      <path
        d="M10.1382 28.5933C9.008 26.2025 8.25982 21.6967 7.85204 17.8976C7.39951 13.7041 7.08881 7.60923 6.76901 3.72182C6.6484 2.26679 6.18376 1.68914 5.50204 1.67977C4.72679 1.67041 4.28931 2.47911 3.99972 3.61254C3.67093 4.9021 2.25315 9.76682 2.72366 14.7938C3.25568 20.503 8.17143 24.9523 8.67376 28.5933"
        stroke="#232323"
        strokeMiterlimit="10"
      />
      <path
        d="M13.016 28.5612L16.82 28.6206C16.8683 25.2578 22.7749 20.5304 22.8774 13.277C22.9649 7.1009 21.7583 3.66313 20.9407 2.08631C20.3102 0.868568 19.6466 0.128556 18.4309 0.109823C17.0674 0.0879656 16.1986 0.862323 15.8155 3.40708C14.5455 11.85 11.4202 17.7951 13.016 28.5612Z"
        fill="#FBFBFB"
        stroke="#232323"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9004 28.5933C15.0307 26.2025 15.7788 21.6967 16.1866 17.8976C16.6391 13.7041 16.9498 7.60923 17.2696 3.72182C17.3903 2.26679 17.8548 1.68914 18.5366 1.67977C19.3118 1.67041 19.7493 2.47911 20.0389 3.61254C20.3707 4.9021 21.7855 9.76682 21.318 14.7938C20.7859 20.503 15.8702 24.9523 15.3652 28.5933"
        stroke="#232323"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};

export default MercurioIcon;
