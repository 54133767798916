import { Theme } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsOptions } from '../../../../themes/dark/breakpoints';

const breakpoints = createBreakpoints(breakpointsOptions);
export const styles = {
  root: (theme: Theme) => ({
    marginBottom: '30px',
    ...theme.mixins.card,
    height: 'initial',
    '&:before': {
      ...theme.mixins.cardBefore,
    },
    [breakpoints.down('xxl')]: {
      padding: '25.5px 29.58px 25.5px 0',
    },
    padding: '25.5px 29.58px 25.5px 0',
    '& ul': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      '& .MuiListItemIcon-root': {
        '& .MuiSvgIcon-root > path': {
          fill: theme.palette.common.gray100,
        },
      },
      '& .MuiTypography-root.MuiTypography-body2': {
        color: theme.palette.common.settings.settingTabsText,
      },
      '& .Mui-selected': {
        '& .MuiTypography-root.MuiTypography-body2': {
          color: theme.palette.common.settings.settingTabsTextActive,
        },
        '& .MuiListItemIcon-root': {
          '& .MuiSvgIcon-root > path': {
            fill: theme.palette.common.inputs.iconColor,
          },
        },
      },
    },
  }),
  items: {
    zIndex: 111111111112,
    position: 'absolute',
    top: 80,
    left: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    background: (theme: Theme) => theme.palette.common.darkLink,
    width: '100%',
    backdropFilter: 'blur(130px)',
    borderRadius: '16px',
    '& .MuiListItemButton-root:first-of-type': {
      '&:hover': {
        '&:after': {
          borderRadius: '16px 0 16px 0',
        },
      },
    },
    '& .MuiListItemButton-root:last-child': {
      '&:hover': {
        '&:after': {
          borderRadius: '0 16px 0 16px',
        },
      },
    },
  },
  drop: (theme: Theme) => ({
    marginBottom: '24px',
    ...theme.mixins.card,
    backdropFilter: 'none',
    height: 'initial',
    '&:before': {
      ...theme.mixins.cardBefore,
    },
    [breakpoints.down('xxl')]: {
      padding: '22px 25px',
    },
    padding: '22px 25px',
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
};
