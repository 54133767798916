import {
  Box,
  ClickAwayListener,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  getIsMobileSidebarOpen,
  getIsSidebarOpen,
  getTheme,
} from 'src/bus/ui/selectors';
import { getNotifications } from '../../../bus/notifications/selectors';
import { getUserReferralLink } from '../../../bus/auth/selectors';
import { ThemeVariant } from '../../../bus/ui/typedefs';
import { uiActions } from '../../../bus/ui/actions';
import { notificationsActions } from '../../../bus/notifications/actions';
import BurgerMenuIcon from '../Icons/BurgerMenuIcon';
import QrCodeIcon from '../Icons/QRCodeIcon';
import { HoverInfo } from '../HoverInfo';
import { Notifications } from '../../common/Notifications';
import MessagesIcon from '../Icons/MessagesIcon';
import FullScreenIcon from '../Icons/FullScreenIcon';
import HeaderLinkIcon from '../Icons/HeaderLinkIcon';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { Profile } from '../../common/Profile';
import FullLogoLight from '../Icons/FullLogoLight';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

type HeaderProps = {
  title: string;
};

const remainingList = [
  {
    id: 1,
    text: 'royalty waiting you on next rank',
    royalty: '$500 ',
  },
  {
    id: 2,
    text: 'royalty waiting for you in total',
    royalty: '$1100 ',
  },
];

export const Header: React.FC<HeaderProps> = ({ title }) => {
  const themes = useTheme();
  const isMD = useMediaQuery(themes.breakpoints.down('md'));
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));
  const theme = useSelector(getTheme);
  const notifications = useSelector(getNotifications);
  const referralLink = useSelector(getUserReferralLink);
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const isMobileSidebarOpen = useSelector(getIsMobileSidebarOpen);
  const isLight = useIsLightTheme();
  const [headerWidth, setHeaderWidth] = useState<string>('100%');

  const [active, setActive] = useState(false);
  const [newMessages, setNewMessages] = useState(false);

  const onChange = (theme: ThemeVariant) => {
    dispatch(uiActions.fillTheme(theme));
    localStorage.setItem('currentTheme', theme);
  };

  useEffect(() => {
    const { body } = document;
    isLight ? body.classList.add('light') : body.classList.remove('light');
  }, [isLight]);

  const handleTooltipClose = () => {
    setActive(false);
  };

  const clickOnBell = () => {
    active && setActive(false);
  };

  useEffect(() => {
    setNewMessages(!!notifications.filter((item) => item.is_new).length);
  }, [notifications]);

  useEffect(() => {
    dispatch(notificationsActions.fetchNotifications());
  }, []);
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const toggleMobileSidebar = () => {
    dispatch(uiActions.toggleMobileSidebar());
  };

  // Watch for fullscreenchange
  React.useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    enqueueSnackbar('Invitation link copied to your clipboard', {
      variant: 'infoSnackbar',
    });
  };

  const showQrModal = () => {
    dispatch(
      uiActions.modal({
        modalName: 'QrCode',
        modalPayload: {
          title: 'QR code',
          isOutSideClicked: true,
          titlePosition: 'left',
        },
      }),
    );
  };

  useEffect(() => {
    setHeaderWidth(
      isLight
        ? '100%'
        : isMD
        ? '100%'
        : isSidebarOpen
        ? 'calc(100% - 300px)'
        : 'calc(100% - 118px)',
    );
  }, [isLight, isMD, isSidebarOpen]);

  useEffect(() => {
    document.body.style.overflow = isMobileSidebarOpen ? 'hidden' : 'auto';
  }, [isMobileSidebarOpen]);

  return (
    <Box
      // @ts-ignore
      sx={styles.root}
      width={headerWidth}>
      {isMD ? (
        <Box display={'flex'} gap={'20px'}>
          <IconButton onClick={toggleMobileSidebar}>
            <BurgerMenuIcon />
          </IconButton>
          {!isSM && (
            <Box>
              <Typography
                sx={{ color: themes.palette.common.header.titleColor }}
                variant={'headerTitle'}>
                {title}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box display={'flex'} alignItems={'center'}>
          {isLight && (
            <Box sx={styles.fullLogo}>
              <FullLogoLight />
            </Box>
          )}
          <Box>
            <Typography
              sx={{ color: themes.palette.common.header.titleColor }}
              variant={'headerTitle'}>
              {title}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={styles.right}>
        <IconButton onClick={showQrModal}>
          <QrCodeIcon color={themes.palette.common.header.iconColor} />
        </IconButton>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box onClick={clickOnBell}>
            <HoverInfo
              open={active}
              handleClickOpen={setActive}
              disableHoverListener
              disableFocusListener
              disableTouchListener
              information={
                <Notifications
                  handleTooltipClose={handleTooltipClose}
                  notifications={notifications}
                  remainingList={remainingList}
                />
              }
              startIcon={
                <MessagesIcon
                  color={
                    newMessages
                      ? isLight
                        ? '#312dce'
                        : themes.palette.common.birch
                      : themes.palette.common.header.iconColor
                  }
                />
              }
            />
          </Box>
        </ClickAwayListener>
        {!isMD && (
          <IconButton onClick={toggleFullscreen}>
            <FullScreenIcon color={themes.palette.common.header.iconColor} />
          </IconButton>
        )}
        <IconButton onClick={copyReferralLink}>
          <HeaderLinkIcon color={themes.palette.common.header.iconColor} />
        </IconButton>
        <ThemeSwitcher onChange={onChange} value={theme} />
        {!isMD && <Profile />}
      </Box>
    </Box>
  );
};
