import { Box } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { NotificationTitle } from './blocks/NotificationTitle';
import { NotificationList } from './blocks/NotificationList';
import { INotification } from '../../../bus/notifications/typedefs';
import { Remaining } from './blocks/Remaining';
import { IItem } from './blocks/RemainingList';

type NotificationsProps = {
  notifications: INotification[];
  remainingList: IItem[];
  handleTooltipClose: () => void;
};

export const Notifications: React.FC<NotificationsProps> = ({
  notifications,
  remainingList,
  handleTooltipClose,
}) => {
  return (
    <Box sx={styles.root}>
      <NotificationTitle />
      <NotificationList notifications={notifications} />
      <Remaining
        remainingList={remainingList}
        handleTooltipClose={handleTooltipClose}
      />
    </Box>
  );
};
