import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const VisibilityToggleOnIconLT: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7708 11.2913C21.8174 11.3837 21.8529 11.4581 21.8777 11.5115C21.8901 11.5382 21.8998 11.5597 21.9069 11.5756L21.9155 11.5952L21.9184 11.6017L21.9195 11.6042L21.92 11.6052C22.0279 11.8567 22.0279 12.1433 21.92 12.3948L21.9195 12.3958L21.9184 12.3983L21.9155 12.4048L21.9069 12.4244C21.8998 12.4403 21.8901 12.4618 21.8777 12.4885C21.8529 12.5419 21.8174 12.6163 21.7708 12.7087C21.6775 12.8933 21.5395 13.1506 21.3538 13.4565C20.9834 14.0665 20.4175 14.8792 19.6317 15.6941C18.0554 17.3288 15.5612 19 12.0011 19C8.44103 19 5.94689 17.3288 4.37059 15.6941C3.58479 14.8792 3.01899 14.0665 2.64859 13.4565C2.46289 13.1506 2.32489 12.8933 2.23159 12.7087C2.18499 12.6163 2.14939 12.5419 2.12459 12.4885C2.11219 12.4618 2.10249 12.4403 2.09539 12.4244L2.08679 12.4048L2.08389 12.3983L2.08289 12.3958L2.08239 12.3948C1.97449 12.1433 1.97449 11.8567 2.08239 11.6052L2.08289 11.6042L2.08389 11.6017L2.08679 11.5952L2.09539 11.5756C2.10249 11.5597 2.11219 11.5382 2.12459 11.5115C2.14939 11.4581 2.18499 11.3837 2.23159 11.2913C2.32489 11.1067 2.46289 10.8494 2.64859 10.5435C3.01899 9.93349 3.58479 9.1208 4.37059 8.3059C5.94689 6.6712 8.44103 5 12.0011 5C15.5612 5 18.0554 6.6712 19.6317 8.3059C20.4175 9.1208 20.9834 9.9335 21.3538 10.5435C21.5394 10.8494 21.6775 11.1067 21.7708 11.2913ZM18.192 14.3059C16.8754 15.6712 14.8696 17 12.0011 17C9.13263 17 7.12689 15.6712 5.81029 14.3059C5.14979 13.6208 4.67089 12.9335 4.35819 12.4185C4.26199 12.26 4.18199 12.1187 4.11789 12C4.18199 11.8813 4.26199 11.74 4.35819 11.5815C4.67089 11.0665 5.14979 10.3792 5.81029 9.69409C7.12689 8.32879 9.13263 7 12.0011 7C14.8696 7 16.8754 8.3288 18.192 9.6941C18.8525 10.3792 19.3314 11.0665 19.6441 11.5815C19.7403 11.74 19.8203 11.8813 19.8844 12C19.8203 12.1187 19.7403 12.26 19.6441 12.4185C19.3314 12.9335 18.8525 13.6208 18.192 14.3059Z"
        fill="url(#paint0_linear_5_20)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 9.99998 10.8954 9.99998 12C9.99998 13.1046 10.8954 14 12 14ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79084 8 7.99998 9.79086 7.99998 12C7.99998 14.2091 9.79084 16 12 16Z"
        fill="url(#paint1_linear_5_20)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_20"
          x1="22.0009"
          y1="15.71"
          x2="4.12137"
          y2="8.33864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_20"
          x1="22.0009"
          y1="15.71"
          x2="4.12137"
          y2="8.33864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default VisibilityToggleOnIconLT;
