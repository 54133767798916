import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const DarkIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon
      sx={{ width: '18px', height: '20px' }}
      width="18"
      height="20"
      viewBox="0 0 18 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1943 1.21161C17.1943 1.88076 16.6533 2.42321 15.9858 2.42321C15.3184 2.42321 14.7773 1.88076 14.7773 1.21161C14.7773 0.54245 15.3184 0 15.9858 0C16.6533 0 17.1943 0.54245 17.1943 1.21161ZM11.9575 6.4619C12.6249 6.4619 13.166 5.91944 13.166 5.25029C13.166 4.58114 12.6249 4.03869 11.9575 4.03869C11.2901 4.03869 10.749 4.58114 10.749 5.25029C10.749 5.91944 11.2901 6.4619 11.9575 6.4619ZM16.7915 8.8851C17.4589 8.8851 18 8.3427 18 7.6735C18 7.00435 17.4589 6.4619 16.7915 6.4619C16.1241 6.4619 15.583 7.00435 15.583 7.6735C15.583 8.3427 16.1241 8.8851 16.7915 8.8851ZM2.58426 14.7482C0.58195 11.2712 1.77021 6.82517 5.23831 4.81772C5.46707 4.6853 5.69987 4.56686 5.93574 4.46211C5.57132 6.82981 5.97893 9.3348 7.2669 11.5714C8.7253 14.1038 11.0445 15.8278 13.6383 16.5867C13.2895 16.8918 12.906 17.1679 12.4893 17.4091C9.0212 19.4165 4.58657 18.2252 2.58426 14.7482ZM4.43264 3.41868C0.19386 5.87223 -1.25846 11.3063 1.18881 15.556C3.63607 19.8056 9.0562 21.2617 13.295 18.8081C14.3029 18.2247 15.1547 17.4714 15.8337 16.6046C16.2153 16.1175 15.9305 15.3987 15.3195 15.3069C12.6221 14.9016 10.1308 13.3136 8.6624 10.7636C7.3384 8.4646 7.1058 5.8354 7.8043 3.47518C7.9802 2.88095 7.4505 2.31776 6.84851 2.45888C6.01971 2.65317 5.20585 2.97111 4.43264 3.41868Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DarkIcon;
