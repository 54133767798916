import { Nullable } from '../../../setup/typedefs';

export interface Settings {
  tableTitle: string;
  tableCount?: number;
  search?: (e: any) => void;
  filter?: FormInTable;
  sort?: FormInTable;
  hideFields?: string[];
  pagination: Nullable<Pagination>;
  clickOnRow?: (id: string) => void;
  ordering: {
    order: {
      order: Order;
      setOrder: (e: any) => void;
    };
    orderBy: {
      orderBy: string;
      setOrderBy: (e: any, order: Order) => void;
    };
  };
}

export interface Pagination {
  totalPage: number;
  currentPage: number;
  totalRows: number;
  rowsPerPage: number;
  goToPage: ({ page, link }: { page?: number; link?: string }) => void;
  setRowsPerPage: (e: any) => void;
  nextPageLink?: any;
  prevPageLink?: any;
}

export type FormInTable = {
  mutators?: any;
  form: (e: any) => void;
  schemaForm?: any;
  onSubmitForm: (e: any) => void;
  clearFilters?: () => void;
};

export interface CellMaxWidth {
  cellName: string;
  cellMaxWidth: string | number;
}

export interface HeadData {
  id: string;
  label: string;
  component?: CellVariants;
  maxWidth?: string | number;
}

export interface BodyData {
  [key: string]: SimpleBodyContent | ArrayBodyContent | any;
}

export type SimpleBodyContent = string | number | boolean;
export type ArrayBodyContent = string[] | number[] | { [key: string]: any }[];

export enum CellVariants {
  Hidden = 'HiddenCell',
  Status = 'StatusCell',
  Text = 'TextCell',
  DateTime = 'DateTimeCell',
  Amount = 'AmountCell',
  Link = 'LinkCell',
  TextWithIcon = 'TextWithIcon',
  Switcher = 'SwitcherCell',
  IncreaseDecrease = 'IncreaseDecreaseCell',
  Image = 'ImageCell',
  Title = 'TitleCell',
  GrayDescription = 'GrayDescriptionCell',
  Buttons = 'ButtonsCell',
  IconButton = 'IconButtonCell',
  TextWithCopyButton = 'TextWithCopyButtonCell',
  CircleImage = 'CircleImageCell',
  TitleWithLink = 'TitleWithLinkCell',
  Tags = 'TagsCell',
  ButtonWithIcon = 'ButtonWithIconCell',
  UserAndRole = 'UserAndRoleCell',
  TextWithTooltip = 'TextWithTooltipCell',
}

export enum Order {
  asc = 'asc',
  desc = 'desc',
}
