import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Box } from '@mui/material';
import { TextArea } from '../../../components/ui-kit/inputs/TextArea';
import { getFieldError } from '../../../helpers/getFieldError';

const RenderReasonField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={1}>
      <TextArea
        {...input}
        rows={5}
        testId={'reason-field'}
        fullWidth
        label={'Why do you want to become a Fozeus franchisee?'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};

export default RenderReasonField;
