// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  AdminState,
  FillAdminTransactionHistoryActionPayload,
  FillAdminWithdrawActionPayload,
  FillAdminWithdrawRequestActionPayload,
  FillApplicationsActionPayload,
} from './typedefs';
import { emptyPaginationState } from '../../setup/typedefs';

const initialState: AdminState = {
  isFetching: false,
  isInitialized: true,
  adminTransactionHistory:
    emptyPaginationState as FillAdminTransactionHistoryActionPayload,
  adminWithdraw: emptyPaginationState as FillAdminWithdrawActionPayload,
  adminWithdrawRequest:
    emptyPaginationState as FillAdminWithdrawRequestActionPayload,
  applications: null,
  applicationStatus: [
    {
      id: 0,
      status: 'Declined',
    },
    {
      id: 1,
      status: 'Approved',
    },
    {
      id: 2,
      status: 'Leader',
    },
    {
      id: 3,
      status: 'Waiting',
    },
  ],
  applicationSources: [
    { id: 'advertising', source: 'Advertising' },
    { id: 'friends', source: 'Friend recommended' },
    { id: 'internet', source: 'Internet' },
    { id: 'promo', source: 'Promo' },
    { id: 'social_media', source: 'Social media' },
  ],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillAdminTransactionHistory(
      state,
      action: PayloadAction<FillAdminTransactionHistoryActionPayload>,
    ) {
      state.adminTransactionHistory = action.payload;
    },
    fillAdminWithdraw(
      state,
      action: PayloadAction<FillAdminWithdrawActionPayload>,
    ) {
      state.adminWithdraw = action.payload;
    },
    fillAdminWithdrawRequests(
      state,
      action: PayloadAction<FillAdminWithdrawRequestActionPayload>,
    ) {
      state.adminWithdrawRequest = action.payload;
    },
    fillApplications(
      state,
      action: PayloadAction<FillApplicationsActionPayload>,
    ) {
      state.applications = action.payload;
    },
    // inject
  },
});

export default adminSlice;
