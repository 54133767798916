import { Box } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Field, FormRenderProps } from 'react-final-form';
import { RenderEmailField } from './fields/RenderEmailField';
import { RenderMessageField } from './fields/RenderMessageField';
import { RenderSubjectField } from './fields/RenderSubjectField';
import { Button } from '../../components/ui-kit/Button';

export const SupportForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box width={'100%'} maxWidth={'447px'} margin={'0'}>
        <Field name={'email'} render={RenderEmailField} />
        <Field name={'subject'} render={RenderSubjectField} />
        <Field name={'message'} render={RenderMessageField} />
        <Box maxWidth={'200px'} mt={'30px'}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Send
          </Button>
          {/*{props.hasSubmitErrors && (*/}
          {/*  <FormHelperText error>{props.submitError}</FormHelperText>*/}
          {/*)}*/}
        </Box>
      </Box>
    </form>
  );
};
