export const styles = {
  root: {
    background: 'none',
    borderRadius: '16px',
    position: 'relative',
    boxSizing: 'border-box',
    '&.MuiPaper-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: '0 20px',
      },
    },
    '&.MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiButtonBase-root': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        margin: '10px 0 0 0',
        alignSelf: 'baseline',
      },
      '& .MuiAccordionSummary-content': {
        margin: '0',
        gap: '16px',
        alignItems: 'center',
      },
    },
  },
  details: {
    padding: 0,
    '& .MuiListItemButton-root': {
      paddingLeft: 0,
      marginBottom: '10px',
    },
  },
  titleBlock: {
    gap: '6px',
    '& h1': {
      margin: '0 0 16px 0',
    },
  },
};
