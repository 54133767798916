import { Link as MuiLink, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Link as RouterLink } from 'react-router-dom';
import { TypographyProps as MuiTypographyProps } from '@mui/material/Typography/Typography';

export interface LinkProps extends MuiTypographyProps {
  to?: string;
  fontSize?: number;
  onClick?: () => void;
  component?: React.ElementType;
  tag?: string;
}

export const Link: React.FC<LinkProps> = ({
  tag = 'p',
  to,
  variant = 'h7',
  children,
  color,
  fontSize = 14,
  onClick,
  component = RouterLink,
}) => {
  return (
    <MuiLink sx={styles.root} to={to} component={component} onClick={onClick}>
      <Typography
        variant={variant}
        color={color || 'common.birch'}
        variantMapping={{ h7: tag }}
        fontSize={fontSize}>
        {children}
      </Typography>
    </MuiLink>
  );
};
