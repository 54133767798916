import React from 'react';
import {
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderNameField } from './fields/RenderNameField';
import { RenderCountryField } from './fields/RenderCountryField';
import { RenderAddressField } from './fields/RenderAddressField';
import { RenderPostalCodeField } from './fields/RenderPostalCodeField';
import { RenderCompanyUrlField } from './fields/RenderCompanyURLField';
import { RenderActivityField } from './fields/RenderActivityField';
import { RenderCityField } from './fields/RenderCityField';
import { useDispatch, useSelector } from 'react-redux';
import { getCountriesOptions } from '../../bus/options/selectors';
import { styles } from './styles';
import { uiActions } from '../../bus/ui/actions';

const AddNewMerchantForm = (props: FormRenderProps) => {
  const countries = useSelector(getCountriesOptions);
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  const dispatch = useDispatch();

  return (
    <form>
      <Box
        sx={styles.root}
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'880px'}
        width={'100%'}
        mt={'33px'}>
        <Box display={'flex'} sx={styles.content}>
          <Box sx={styles.wrapper} display={'flex'} flexDirection={'column'}>
            <Typography variant={'Body/Form UPPERCASE 14'}>
              General Info
            </Typography>
            <Field name={'name'} render={RenderNameField} />
            <Box sx={styles.country}>
              <Field
                name={'country'}
                render={RenderCountryField}
                parse={(value) => value?.code || ''}
                format={(value) =>
                  countries.find(
                    (option: { code: any }) => option.code === value,
                  )?.label || ''
                }
              />
              <Field
                name={'city'}
                render={RenderCityField}
                parse={(value) => value?.label || ''}
              />
            </Box>
            <Box sx={styles.address}>
              <Field name={'address'} render={RenderAddressField} />
              <Field name={'postal_code'} render={RenderPostalCodeField} />
            </Box>
            <Typography variant={'Body/Form UPPERCASE 14'}>Website</Typography>
            <Field name={'url'} render={RenderCompanyUrlField} />
          </Box>
          <Box sx={styles.wrapper} display={'flex'} flexDirection={'column'}>
            <Typography variant={'Body/Form UPPERCASE 14'}>
              Field of activity
            </Typography>
            <Field
              name={'activities'}
              render={RenderActivityField}
              type={'checkbox'}
              initialValues={props.values?.activities}
            />
          </Box>
        </Box>
        <Box display={'flex'} gap={2} mt={'42px'} sx={styles.buttons}>
          <Button
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            onClick={() => {
              props?.handleSubmit();
            }}>
            <Typography
              variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
              Add Merchant
            </Typography>
          </Button>
          <Button
            disabled={props.pristine}
            variant="secondary"
            onClick={() =>
              props?.handleSubmit()?.then(() => {
                dispatch(
                  uiActions.modal({
                    modalName: 'AddNewMerchant',
                    modalPayload: {
                      title: 'Add New Merchant',
                      isOutSideClicked: true,
                      titlePosition: 'self-start',
                    },
                  }),
                );
              })
            }>
            <Typography
              variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
              Save and add another
            </Typography>
          </Button>
        </Box>
        {props.hasSubmitErrors && (
          <FormHelperText error sx={{ textAlign: 'center' }}>
            {props.submitError}
          </FormHelperText>
        )}
      </Box>
    </form>
  );
};

export default AddNewMerchantForm;
