import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { styles } from './styles';

export interface ILabelItem {
  id: number;
  label: string;
}

type SwitcherProps = {
  onChange: (id: number) => void;
  value: number;
  labelList: ILabelItem[];
};

function a11yProps(index: number) {
  return {
    id: `switch-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Switcher: React.FC<SwitcherProps> = ({
  onChange,
  value,
  labelList,
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Box sx={styles.root}>
      <Tabs
        sx={styles.tabs}
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example">
        {labelList?.map((label) => (
          <Tab
            key={label.id}
            sx={styles.tab}
            label={label.label}
            {...a11yProps(0)}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
};
