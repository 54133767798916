import { all, call, takeEvery } from 'redux-saga/effects';
import { transactionsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchWithdrawFee,
  postWithdraw,
  convert,
  transaction,
  transactionPassword,
  postRoyaltyFees,
  postUserWithdraw,
  changeTransactionPasswordWithCode,
  sendCodeForResetPassword,
} from './workers';
// imports
function* watchFetchWithdrawFee() {
  yield takeEvery(transactionsActions.fetchWithdrawFee.type, fetchWithdrawFee);
}
function* watchPostWithdraw() {
  yield takeEvery(transactionsActions.postWithdraw.type, postWithdraw);
}
function* watchPostUserWithdraw() {
  yield takeEvery(transactionsActions.postUserWithdraw.type, postUserWithdraw);
}
function* watchConvert() {
  yield takeEvery(transactionsActions.convert.type, convert);
}
function* watchTransaction() {
  yield takeEvery(transactionsActions.transaction.type, transaction);
}
function* watchTransactionPassword() {
  yield takeEvery(
    transactionsActions.transactionPassword.type,
    transactionPassword,
  );
}
function* watchChangeTransactionPasswordWithCode() {
  yield takeEvery(
    transactionsActions.changeTransactionPasswordWithCode.type,
    changeTransactionPasswordWithCode,
  );
}
function* watchSendCodeForResetPassword() {
  yield takeEvery(
    transactionsActions.sendCodeForResetPassword.type,
    sendCodeForResetPassword,
  );
}
function* watchPostRoyaltyFees() {
  yield takeEvery(transactionsActions.postRoyaltyFees.type, postRoyaltyFees);
}
// watchers
export function* watchTransactions() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchPostRoyaltyFees),
    call(watchTransactionPassword),
    call(watchTransaction),
    call(watchConvert),
    call(watchPostWithdraw),
    call(watchPostUserWithdraw),
    call(watchFetchWithdrawFee),
    call(watchChangeTransactionPasswordWithCode),
    call(watchSendCodeForResetPassword),
  ]);
}
