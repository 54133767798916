import { breakpoints as breakpointsOptions } from './breakpoints';
import { MixinsOptions } from '@mui/material/styles/createMixins';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { Theme } from '@mui/material';

const breakpoints = createBreakpoints(breakpointsOptions);
export const mixins: MixinsOptions = {
  menuItemDense: {
    background: (theme: Theme) => theme.palette.common.navbar.denseActiveBg,
    mixBlendMode: 'normal',
    backdropFilter: 'blur(20px)',
    borderRadius: '8px',
  },
  card: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    padding: '40px',
    boxSizing: 'border-box',
    background: (theme: Theme) => theme.palette.common.tabs.background,
    mixBlendMode: 'normal',
    backdropFilter: 'blur(130px)',
    [breakpoints.down('xxl')]: {
      padding: '35px',
    },
  },
  cardBefore: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    borderRadius: 'inherit',
    background: (theme: Theme) => theme.palette.common.tabs.border,
    mask: (theme: Theme) =>
      `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    lineHeight: 0,
    padding: '1px',
    boxSizing: 'border-box',
  },
  activeCardBefore: {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: (theme: Theme) => theme.palette.common.neonPink,
    top: 0,
    left: 0,
    zIndex: -1,
    borderRadius: 'inherit',
    padding: '1px',
    boxSizing: 'border-box',
    mask: (theme: Theme) =>
      `linear-gradient(${theme.palette.common.white} 0 0) content-box, linear-gradient(${theme.palette.common.white} 0 0)`,
    maskComposite: 'exclude',
    WebkitMaskComposite: 'xor',
    lineHeight: 0,
  },
};
