import { apply, call, put } from 'redux-saga/effects';
import { newsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillNewsActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchNews({ payload }: PayloadAction<FetchListWorkers>) {
  try {
    yield put(newsActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `news/`, query: payload.query },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillNewsActionPayload = yield call([response, 'json']);

    yield put(newsActions.fillNews(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(newsActions.stopFetching());
  }
}
