import React, { useLayoutEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from '../layouts/public/AuthLayout';
import { publicRoutes } from '../pages/public/routes';
import { book } from './book';
import { uiActions } from '../bus/ui/actions';
import { ThemeVariant } from '../bus/ui/typedefs';
import { useDispatch } from 'react-redux';
import { useScrollToTop } from '../helpers/customHooks/useScroll';

const PublicRoutes = () => {
  const dispatch = useDispatch();
  useScrollToTop();

  useLayoutEffect(() => {
    dispatch(uiActions.fillTheme('dark' as ThemeVariant));
  }, []);

  return (
    <>
      <Routes>
        {publicRoutes.map(({ path, Component, Layout, layoutProps }, i) => (
          <Route element={<Layout {...layoutProps} />} key={i}>
            <Route path={path} element={Component} />
          </Route>
        ))}
        <Route element={<AuthLayout />}>
          <Route path={'/*'} element={<Navigate replace to={book.signIn} />} />
        </Route>
      </Routes>
    </>
  );
};

export default PublicRoutes;
