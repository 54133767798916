import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    overflowBlock: 'hidden',
    position: 'fixed',
    top: '100px',
    right: 0,
    height: 'calc(100vh - 100px)',
    overflow: 'hidden',
    background: theme.palette.common.tabs.background,
    backdropFilter: 'blur(35px)',
    zIndex: 2,
    '& > .MuiCircularProgress-root': {
      position: 'relative',
      left: '50%',
      top: '50%',
      transform: 'transition(-50%, -50%)',
    },
  }),
  sidebarNotCollapsed: (theme: Theme) => ({
    width: 'calc(100vw - 300px)',
    transition: 'width 0.4s cubic-bezier(0,0,0.2,1)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  sidebarCollapsed: (theme: Theme) => ({
    transition: 'width 0.4s cubic-bezier(0,0,0.2,1)',
    width: 'calc(100vw - 117px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
};
