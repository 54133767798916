import { Theme } from '@mui/material';

export const styles = {
  button: {
    color: (theme: Theme) => theme.palette.common.buttons?.standardColor,
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons?.standardColor,
    },
  },
};
