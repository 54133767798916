import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

type LevelInfoProps = {};

export const TeamUserInfo: React.FC<LevelInfoProps> = () => {
  return (
    <Box sx={styles.root}>
      <Typography variant={'Headlines/H7'}>Most Valuable Team</Typography>
    </Box>
  );
};
