import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { IItem, RemainingList } from './RemainingList';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../../../bus/ui/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import { Countdown } from '../../../ui-kit/Countdown';
import imgInNotification from '../../../../assets/imgInNotification.png';

type RemainingProps = {
  time?: string;
  remainingList: IItem[];
  handleTooltipClose: () => void;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.primary,
    position: [27, 11],
    size: 100,
    blur: 60,
  }),
  (colors) => ({
    color: colors.green,
    position: [85, 110],
    size: 150,
    blur: 100,
  }),
];

export const Remaining: React.FC<RemainingProps> = ({
  remainingList,
  handleTooltipClose,
}) => {
  const dispatch = useDispatch();

  const addPaymentChoice = () => {
    handleTooltipClose();
    dispatch(
      uiActions.modal({
        modalName: 'PaymentChoice',
        modalPayload: {
          title: 'Choose your payment channel ',
          isOutSideClicked: true,
          titlePosition: 'self-start',
        },
      }),
    );
  };

  return (
    <Box sx={styles.wrapper}>
      <GeneralCardHolder
        height={'100%'}
        sx={styles.remaining}
        effects={effects}>
        <RemainingList remainingList={remainingList} />
        <Box sx={styles.decorImage}>
          <Box component={'img'} src={imgInNotification} />
        </Box>
        <Box sx={styles.remainingRight}>
          <Typography variant={'Body/Table/Basic 15'} color={'common.gray100'}>
            Remaining
          </Typography>
          <Countdown
            times={new Date('2022-12-12T17:02:09.013935Z').getTime()}
            padding={'0px'}
            isNotification
          />
          <Button
            onClick={addPaymentChoice}
            fullWidth
            variant={'secondary'}
            color={'green'}>
            Buy FZS
          </Button>
        </Box>
      </GeneralCardHolder>
    </Box>
  );
};
