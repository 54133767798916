import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const ResourcesIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 22 24'} width={'22'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20.1673H5.16667C5.16667 22.1004 6.73368 23.6673 8.66667 23.6673H18C19.9331 23.6673 21.5 22.1004 21.5 20.1673V7.33398C21.5 5.40099 19.9331 3.83398 18 3.83398C18 1.90099 16.433 0.333984 14.5 0.333984H4C2.06701 0.333984 0.5 1.90099 0.5 3.83398V16.6673C0.5 18.6004 2.06701 20.1673 4 20.1673ZM6.33333 17.834H6.33513H14.5C15.1443 17.834 15.6667 17.3117 15.6667 16.6673V5.00065V3.83398C15.6667 3.18966 15.1443 2.66732 14.5 2.66732H4C3.35567 2.66732 2.83333 3.18965 2.83333 3.83398V16.6673C2.83333 17.3117 3.35567 17.834 4 17.834H6.33154H6.33333ZM19.1667 7.33398C19.1667 6.68966 18.6444 6.16732 18 6.16732V16.6673C18 18.6004 16.433 20.1673 14.5 20.1673H7.5C7.5 20.8117 8.02234 21.334 8.66667 21.334H18C18.6444 21.334 19.1667 20.8117 19.1667 20.1673V7.33398ZM12.1667 5.00065C12.811 5.00065 13.3333 5.52299 13.3333 6.16732C13.3333 6.81166 12.811 7.33398 12.1667 7.33398H6.33333C5.68901 7.33398 5.16667 6.81166 5.16667 6.16732C5.16667 5.52299 5.68901 5.00065 6.33333 5.00065H12.1667ZM13.3333 10.2507C13.3333 9.60632 12.811 9.08398 12.1667 9.08398H6.33333C5.68901 9.08398 5.16667 9.60632 5.16667 10.2507C5.16667 10.895 5.68901 11.4173 6.33333 11.4173H12.1667C12.811 11.4173 13.3333 10.895 13.3333 10.2507ZM12.1667 13.1673C12.811 13.1673 13.3333 13.6896 13.3333 14.334C13.3333 14.9783 12.811 15.5007 12.1667 15.5007H6.33333C5.68901 15.5007 5.16667 14.9783 5.16667 14.334C5.16667 13.6896 5.68901 13.1673 6.33333 13.1673H12.1667Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ResourcesIcon;
