import { object, string, number } from 'yup';

export const schema = object().shape({
  amount: number()
    .required('Amount is required field')
    .min(1, 'Amount must be greater than or equal to 1'),
  currency: string().required('Currency is required field'),
  promotion: string(),
  description: string().required('Description is required field'),
});
