import { apply, put, select } from 'redux-saga/effects';
import { todoActions } from '../../actions';
import { api } from '../../../../REST/api';
import { getTodoIds } from '../../selectors';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* bulkDeleteTodo() {
  try {
    yield put(todoActions.startFetching());
    const { ids }: { ids: string } = yield select(getTodoIds);
    const response: Response = yield apply(api, api.delete, [
      { endpoint: `todo/bulk/`, query: `ids=${ids}` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    yield put(todoActions.fetchTodo());
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(todoActions.stopFetching());
  }
}
