import { all, call, takeEvery } from 'redux-saga/effects';
import { adminActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchAdminTransactionHistory,
  adminSent,
  fetchAdminWithdraw,
  declineWithdrawRequest,
  approveWithdrawRequest,
  fetchAdminWithdrawRequests,
  fetchApplications,
  editApplications,
} from './workers';
// imports
function* watchAdminSent() {
  yield takeEvery(adminActions.adminSent.type, adminSent);
}

function* watchFetchAdminTransactionHistory() {
  yield takeEvery(
    adminActions.fetchAdminTransactionHistory.type,
    fetchAdminTransactionHistory,
  );
}

function* watchFetchAdminWithdraw() {
  yield takeEvery(adminActions.fetchAdminWithdraw.type, fetchAdminWithdraw);
}

function* watchFetchAdminWithdrawRequests() {
  yield takeEvery(
    adminActions.fetchAdminWithdrawRequests.type,
    fetchAdminWithdrawRequests,
  );
}

function* watchDeclineWithdrawRequest() {
  yield takeEvery(
    adminActions.declineWithdrawRequest.type,
    declineWithdrawRequest,
  );
}

function* watchApproveWithdrawRequest() {
  yield takeEvery(
    adminActions.approveWithdrawRequest.type,
    approveWithdrawRequest,
  );
}

function* watchFetchApplications() {
  yield takeEvery(adminActions.fetchApplications.type, fetchApplications);
}
function* watchEditApplications() {
  yield takeEvery(adminActions.editApplications.type, editApplications);
}
// watchers
export function* watchAdmin() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchEditApplications),
    call(watchFetchApplications),
    call(watchApproveWithdrawRequest),
    call(watchDeclineWithdrawRequest),
    call(watchFetchAdminWithdraw),
    call(watchFetchAdminTransactionHistory),
    call(watchFetchAdminWithdrawRequests),
    call(watchAdminSent),
  ]);
}
