import React from 'react';
import { styles } from './styles';
import { Box, Divider, List } from '@mui/material';
import { ADMINMENUITEMS, MENUITEMS } from './menu';
import { MenuItem } from './MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from '../Button';
import { MobileProfile } from '../../common/MobileProfile';
import CloseBurgerMenuIcon from '../../../components/ui-kit/Icons/CloseBurgerMenuIcon';
import LogoSidebarIcon from '../../../components/ui-kit/Icons/LogoSidebarIcon';
import { uiActions } from '../../../bus/ui/actions';
import { getIsMobileSidebarOpen } from '../../../bus/ui/selectors';
import { getCurrentUserProfile } from '../../../bus/auth/selectors';

const MobileSidebar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMobileSidebarOpen = useSelector(getIsMobileSidebarOpen);
  const profile = useSelector(getCurrentUserProfile);

  const toggleMobileSidebar = () => {
    dispatch(uiActions.toggleMobileSidebar());
  };

  return (
    <Box sx={[styles.root, !isMobileSidebarOpen && styles.collapsed]}>
      <Button
        sx={styles.closeButton}
        startIcon={<CloseBurgerMenuIcon />}
        onClick={toggleMobileSidebar}
      />
      <Box sx={styles.logoWrapper}>
        <LogoSidebarIcon />
      </Box>
      <Box sx={styles.menuWrapper}>
        <Box component={'div'} sx={styles.scrollbar}>
          <List sx={styles.menu}>
            {MENUITEMS.map((item) => (
              <MenuItem
                key={item.label}
                item={item}
                collapsed={!isMobileSidebarOpen}
                activeLink={pathname === item.link}
              />
            ))}
          </List>
          {profile?.is_admin && (
            <>
              <Divider sx={{ m: 3 }} />
              <List sx={styles.menu}>
                {ADMINMENUITEMS.map((item) => (
                  <MenuItem
                    key={item.label}
                    item={item}
                    collapsed={!isMobileSidebarOpen}
                    activeLink={pathname === item.link}
                  />
                ))}
              </List>
            </>
          )}
          <Divider sx={{ m: 3 }} />
          <Box mb={'30px'}>
            <MobileProfile />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileSidebar;
