import { Box } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { CheckboxAndLabel } from '../../../components/ui-kit/inputs/CheckboxAndLabel';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderRememberPasswordField: React.FC<
  FieldRenderProps<string>
> = ({ input, meta }) => {
  return (
    <Box my={1}>
      <CheckboxAndLabel
        label={'Remember me'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        input={input}
      />
    </Box>
  );
};
