import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import { styles } from './styles';

const QrCodeIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#A3AED0',
}) => {
  return (
    <SvgIcon
      sx={{ ...styles.qr, width: '20px', height: '20px' }}
      viewBox={'0 0 20 20'}
      width={'20'}
      height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H5C5.55228 0 6 0.44772 6 1C6 1.55228 5.55228 2 5 2H2.5C2.22386 2 2 2.22386 2 2.5V5C2 5.55228 1.55228 6 1 6C0.44772 6 0 5.55228 0 5V2.5ZM3 5C3 3.89543 3.89543 3 5 3H6C7.10457 3 8 3.89543 8 5V6C8 7.10457 7.10457 8 6 8H5C3.89543 8 3 7.10457 3 6V5ZM5 6H6V5H5V6ZM12 14C12 12.8954 12.8954 12 14 12H15C16.1046 12 17 12.8954 17 14V15C17 16.1046 16.1046 17 15 17H14C12.8954 17 12 16.1046 12 15V14ZM14 15H15V14H14V15ZM5 12C3.89543 12 3 12.8954 3 14V15C3 16.1046 3.89543 17 5 17H6C7.10457 17 8 16.1046 8 15V14C8 12.8954 7.10457 12 6 12H5ZM6 15H5V14H6V15ZM10 7C10.5523 7 11 7.44772 11 8V9C11 10.1046 10.1046 11 9 11H4C3.44772 11 3 10.5523 3 10C3 9.4477 3.44772 9 4 9H9V8C9 7.44772 9.4477 7 10 7ZM13 3C12.4477 3 12 3.44772 12 4C12 4.55228 12.4477 5 13 5H15.5H16C16.5523 5 17 4.55228 17 4C17 3.44772 16.5523 3 16 3H15.5H13ZM12 10V7C12 6.44772 12.4477 6 13 6H16C16.5523 6 17 6.44772 17 7V10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10V8H14V10C14 10.5523 13.5523 11 13 11C12.4477 11 12 10.5523 12 10ZM9 13V13.5C9 14.0523 9.4477 14.5 10 14.5C10.5523 14.5 11 14.0523 11 13.5V13C11 12.4477 10.5523 12 10 12C9.4477 12 9 12.4477 9 13ZM9 5V4C9 3.44772 9.4477 3 10 3C10.5523 3 11 3.44772 11 4V5C11 5.55228 10.5523 6 10 6C9.4477 6 9 5.55228 9 5ZM9 16C9 15.4477 9.4477 15 10 15C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17C9.4477 17 9 16.5523 9 16ZM17.5 0C18.8807 0 20 1.11929 20 2.5V5C20 5.55228 19.5523 6 19 6C18.4477 6 18 5.55228 18 5V2.5C18 2.22386 17.7761 2 17.5 2H15C14.4477 2 14 1.55228 14 1C14 0.44772 14.4477 0 15 0H17.5ZM0 17.5C0 18.8807 1.11929 20 2.5 20H5C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18H2.5C2.22386 18 2 17.7761 2 17.5V15C2 14.4477 1.55228 14 1 14C0.44772 14 0 14.4477 0 15V17.5ZM17.5 20C18.8807 20 20 18.8807 20 17.5V15C20 14.4477 19.5523 14 19 14C18.4477 14 18 14.4477 18 15V17.5C18 17.7761 17.7761 18 17.5 18H15C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20H17.5Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default QrCodeIcon;
