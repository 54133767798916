import { createAction } from '@reduxjs/toolkit';
import authSlice from './slice';

export const authActions = {
  ...authSlice.actions,
  twoFactorAuthSettings: createAction(
    'auth/twoFactorAuthSettings',
    ({ enabled }) => {
      return {
        payload: {
          enabled,
        },
      };
    },
  ),
  postTwoFactor: createAction(
    'auth/postTwoFactor',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchTwoFactorQrCode: createAction('auth/fetchTwoFactorQrCode'),
  updateLoginPassword: createAction(
    'auth/updateLoginPassword',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  updatePaymentDetails: createAction(
    'auth/updatePaymentDetails',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  postSupport: createAction(
    'auth/postSupport',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),

  updateProfile: createAction(
    'auth/updateProfile',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchProfile: createAction('auth/fetchProfile'),
  authenticate: createAction('auth/authenticate'),
  signUp: createAction('auth/signUp', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: { resolve, reject },
    };
  }),
  applyMembership: createAction(
    'applications',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  signIn: createAction('auth/signIn', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: { resolve, reject },
    };
  }),
  forgotPassword: createAction(
    'auth/reset-password-request',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  resetPassword: createAction(
    'auth/reset-password',
    (credentials, resolve, reject) => {
      return {
        payload: credentials,
        meta: { resolve, reject },
      };
    },
  ),
  setPassword: createAction(
    'auth/set-password',
    (credentials, resolve, reject) => {
      return {
        payload: credentials,
        meta: { resolve, reject },
      };
    },
  ),
  confirmEmail: createAction(
    'auth/confirm-email',
    (credentials, resolve, reject) => {
      return {
        payload: credentials,
        meta: { resolve, reject },
      };
    },
  ),
};
