import { apply, call, put } from 'redux-saga/effects';
import { ventureActions } from '../../actions';
import { api } from '../../../../REST/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { FillVentureActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchVenture({ payload }: PayloadAction<any>) {
  try {
    yield put(ventureActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `venture/`, query: payload.query },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillVentureActionPayload = yield call([response, 'json']);
    yield put(ventureActions.fillVenture(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(ventureActions.stopFetching());
  }
}
