import React from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

type InformationIconProps = {
  size?: string;
};

const InformationIcon: React.FC<InformationIconProps> = ({ size = '20px' }) => {
  const isLight = useIsLightTheme();

  return (
    <>
      {isLight ? (
        <SvgIcon
          sx={{ fontSize: size, width: size, height: size }}
          viewBox={'0 0 24 24'}
          width={size}
          height={size}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6C3.79086 2 2 3.79086 2 6ZM6 20C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6Z"
            fill="url(#paint0_linear_9684_118990)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75V15.75C10.75 16.4404 11.3096 17 12 17C12.6904 17 13.25 16.4404 13.25 15.75V11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM12 6.75C12.6904 6.75 13.25 7.3096 13.25 8C13.25 8.6904 12.6904 9.25 12 9.25C11.3096 9.25 10.75 8.6904 10.75 8C10.75 7.3096 11.3096 6.75 12 6.75Z"
            fill="url(#paint1_linear_9684_118990)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_9684_118990"
              x1="22"
              y1="17.3"
              x2="2.68925"
              y2="11.7268"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D88EE" />
              <stop offset="1" stopColor="#A351BF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_9684_118990"
              x1="13.25"
              y1="9.15875"
              x2="10.648"
              y2="9.34191"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D88EE" />
              <stop offset="1" stopColor="#A351BF" />
            </linearGradient>
          </defs>
        </SvgIcon>
      ) : (
        <SvgIcon
          sx={{ fontSize: size, width: size, height: size }}
          viewBox={'0 0 20 20'}
          width={size}
          height={size}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4C1.79086 0 0 1.79086 0 4ZM4 18C2.89543 18 2 17.1046 2 16V4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4Z"
            fill="url(#paint0_linear_16_104949)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 8.5C9.3096 8.5 8.75 9.0596 8.75 9.75V13.75C8.75 14.4404 9.3096 15 10 15C10.6904 15 11.25 14.4404 11.25 13.75V9.75C11.25 9.0596 10.6904 8.5 10 8.5ZM10 4.75C10.6904 4.75 11.25 5.3096 11.25 6C11.25 6.6904 10.6904 7.25 10 7.25C9.3096 7.25 8.75 6.6904 8.75 6C8.75 5.3096 9.3096 4.75 10 4.75Z"
            fill="url(#paint1_linear_16_104949)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_16_104949"
              x1="-0.999999"
              y1="2.81289e-06"
              x2="16.5829"
              y2="-4.14084"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#01A4FF" />
              <stop offset="1" stopColor="#27FF97" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_16_104949"
              x1="8.625"
              y1="15"
              x2="10.9371"
              y2="15.1328"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#01A4FF" />
              <stop offset="1" stopColor="#27FF97" />
            </linearGradient>
          </defs>
        </SvgIcon>
      )}
    </>
  );
};

export default InformationIcon;
