// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  AppState,
} from './typedefs';

const initialState: AppState = {
  isFetching: false,
  isInitializeDashboard: true,
  isInitializeBalance: true,
  isInitializeTeam: true,
  isInitializeMerchants: true,
  isInitializePools: true,
  isInitializeVentureCapital: true,
  isInitializeAchievements: true,
  isInitializeProducts: true,
  isInitializeNews: true,
  isInitializeResources: true,
  isInitializeFaq: true,
  isInitializeAdminApplication: true,
  isInitializeAdminUsers: true,
  isInitializeAdminMerchants: true,
  isInitializeAdminTransaction: true,
  isInitializeAdminWithdrawals: true,
  isInitializeAdminFees: true,
  isInitializeRoyaltyFees: true,
  isInitializeAdminFaq: true,
  isInitializeAdminResources: true,
  isInitializeAdminNews: true,
  isInitializeAdminProducts: true,
  isInitializeSettings: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    initializationDashboard(state) {
      state.isInitializeDashboard = false;
    },
    initializationBalance(state) {
      state.isInitializeBalance = false;
    },
    initializationTeam(state) {
      state.isInitializeTeam = false;
    },
    initializationMerchants(state) {
      state.isInitializeMerchants = false;
    },
    initializationPools(state) {
      state.isInitializePools = false;
    },
    initializationVentureCapital(state) {
      state.isInitializeVentureCapital = false;
    },
    initializationAchievements(state) {
      state.isInitializeAchievements = false;
    },
    initializationProducts(state) {
      state.isInitializeProducts = false;
    },
    initializationNews(state) {
      state.isInitializeNews = false;
    },
    initializationResources(state) {
      state.isInitializeResources = false;
    },
    initializationFaq(state) {
      state.isInitializeFaq = false;
    },
    initializationAdminApplication(state) {
      state.isInitializeAdminApplication = false;
    },
    initializationAdminUsers(state) {
      state.isInitializeAdminUsers = false;
    },
    initializationAdminMerchants(state) {
      state.isInitializeAdminMerchants = false;
    },
    initializationAdminTransaction(state) {
      state.isInitializeAdminTransaction = false;
    },
    initializationAdminWithdrawals(state) {
      state.isInitializeAdminWithdrawals = false;
    },
    initializationAdminFees(state) {
      state.isInitializeAdminFees = false;
    },
    initializationRoyaltyFees(state) {
      state.isInitializeRoyaltyFees = false;
    },
    initializationAdminFaq(state) {
      state.isInitializeAdminFaq = false;
    },
    initializationAdminResources(state) {
      state.isInitializeAdminResources = false;
    },
    initializationAdminNews(state) {
      state.isInitializeAdminNews = false;
    },
    initializationAdminProducts(state) {
      state.isInitializeAdminProducts = false;
    },
    initializationSettings(state) {
      state.isInitializeSettings = false;
    },
    // inject
  },
});

export default appSlice;
