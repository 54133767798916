import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Theme,
} from '@mui/material';
import React, { ChangeEventHandler } from 'react';
import { slugString } from '../../../../helpers/formating';
import { SxProps } from '@mui/system';

export type InputWithIconProps = {
  value: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  testId?: string;
  sx?: SxProps<Theme>;
  icon?: any;
  actionOnClick?: any;
  iconIsButton?: boolean;
  disabled?: boolean;
};

export const InputWithIcon: React.FC<InputWithIconProps> = ({
  iconIsButton = false,
  value,
  onChange,
  label,
  error,
  helperText,
  fullWidth,
  testId,
  icon,
  actionOnClick,
  disabled,
  ...rest
}) => {
  return (
    <FormControl fullWidth={fullWidth} variant="outlined" error={error}>
      <InputLabel htmlFor={`outlined-adornment-password-${slugString(label)}`}>
        {label}
      </InputLabel>
      <OutlinedInput
        fullWidth={fullWidth}
        id={`outlined-adornment-password-${slugString(label)}`}
        type={'text'}
        disabled={disabled}
        value={value}
        inputProps={{ 'data-testid': testId }}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            {iconIsButton ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={actionOnClick}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();
                }}
                edge="end">
                {icon}
              </IconButton>
            ) : (
              <>{icon}</>
            )}
          </InputAdornment>
        }
        label={label}
        {...rest}
      />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
