import React, { ChangeEventHandler, useState } from 'react';
import { styles } from './styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Box, Typography } from '@mui/material';
import { HoverInfo } from '../../HoverInfo';
import axios from 'axios';

type PhoneInputProps = {
  focus?: boolean;
  disabled?: boolean;
  error?: boolean;
  label: string;
  helperText?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  fullWidth?: boolean;
};

export const PhoneInput: React.FC<PhoneInputProps> = ({
  focus = false,
  disabled = false,
  error = false,
  label,
  helperText,
  id,
  fullWidth = false,
  value,
  onChange,
}) => {
  const [tmp, setTmp] = useState('us');

  axios.get('https://ipapi.co/json/').then((response: any) => {
    const { country_code } = response.data;
    setTmp(country_code.toLowerCase() || 'us');
  });

  const handlePhoneChange = (e: any) => {
    onChange(e);
  };

  return (
    <MuiPhoneNumber
      sx={styles.root}
      id={id}
      label={label}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <HoverInfo
            size={'20px'}
            arrow
            information={
              <Box
                sx={(theme) => ({ background: theme.palette.common.gray60 })}
                borderRadius={'16px'}
                maxWidth={'250px'}
                p={'16px'}>
                <Typography
                  variant={'Headlines/H10'}
                  color={'common.declineChip'}>
                  The number must be associated with WhatsApp, WeChat, Telegram,
                  Viber
                </Typography>
              </Box>
            }
          />
        ),
      }}
      fullWidth={fullWidth}
      autoFocus={focus}
      defaultCountry={!value ? tmp : ''}
      value={value}
      onChange={(e) => handlePhoneChange(e)}
      variant={'outlined'}
      disabled={disabled}
      // disableDropdown={disabled}
    />
  );
};
