import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminFaqIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1663 2.33301H5.83301C3.90002 2.33301 2.33301 3.90002 2.33301 5.83301V22.1663C2.33301 24.0994 3.90002 25.6663 5.83301 25.6663H22.1663C24.0994 25.6663 25.6663 24.0994 25.6663 22.1663V5.83301C25.6663 3.90002 24.0994 2.33301 22.1663 2.33301ZM4.66634 5.83301C4.66634 5.18868 5.18868 4.66634 5.83301 4.66634H22.1663C22.8107 4.66634 23.333 5.18868 23.333 5.83301V22.1663C23.333 22.8107 22.8107 23.333 22.1663 23.333H5.83301C5.18868 23.333 4.66634 22.8107 4.66634 22.1663V5.83301ZM13.7565 16.0437C14.2302 16.0437 14.5732 15.668 14.6385 15.2433L14.6875 14.9493C16.4679 14.59 17.9052 13.7407 17.9052 11.6663V11.6337C17.9052 9.46134 16.3045 8.25267 13.9525 8.25267C12.5152 8.25267 11.4209 8.71001 10.5552 9.42867C10.2612 9.67367 10.1305 10.0003 10.1305 10.3107C10.1305 10.9313 10.6532 11.454 11.2739 11.454C11.5515 11.454 11.8129 11.3397 12.0089 11.1927C12.5969 10.7517 13.2012 10.5393 13.9035 10.5393C14.8509 10.5393 15.4062 10.964 15.4062 11.699V11.7317C15.4062 12.532 14.7529 12.973 13.3319 13.1527C12.8092 13.218 12.4989 13.5937 12.5805 14.1327L12.7439 15.2433C12.8092 15.6843 13.1522 16.0437 13.6259 16.0437H13.7565ZM12.2049 18.51V18.5427C12.2049 19.3267 12.8092 19.931 13.6259 19.931C14.4425 19.931 15.0469 19.3267 15.0469 18.5427V18.51C15.0469 17.726 14.4425 17.1217 13.6259 17.1217C12.8092 17.1217 12.2049 17.726 12.2049 18.51Z"
        fill="url(#paint0_linear_14271_87585)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87585"
          x1="25.6663"
          y1="20.183"
          x2="3.13713"
          y2="13.6809"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminFaqIconLT;
