import { Box, Theme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { PasswordInput } from '../../../components/ui-kit/inputs/PasswordInput';
import { getFieldError } from '../../../helpers/getFieldError';

const styles = {
  root: (theme: Theme) => ({
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      '& span': {
        fontSize: '10px',
      },
    },
  }),
};

export const RenderPasswordField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const val = input.value;

  const hasDigit = (str: string) => {
    return /\d/.test(str);
  };

  const hasLowerCase = (str: string) => {
    return /[a-z]/.test(str);
  };

  const hasUpperCase = (str: string) => {
    return /[A-Z]/.test(str);
  };

  return (
    <Box my={1}>
      <PasswordInput
        fullWidth
        setShowPassword={setShowPassword}
        showPassword={showPassword}
        data-testid={'sign-up-password'}
        label={'Password'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
      <Box
        sx={styles.root}
        display={'flex'}
        justifyContent={'space-between'}
        mt={1}>
        <Typography
          sx={{ opacity: val.length >= 8 ? 1 : 0.5 }}
          variant={'textRegular'}
          color={val.length >= 8 ? 'common.birch' : 'common.grey50'}>
          8 characters
        </Typography>
        <Typography
          sx={{ opacity: hasUpperCase(val) ? 1 : 0.5 }}
          variant={'textRegular'}
          color={hasUpperCase(val) ? 'common.birch' : 'common.grey50'}>
          Uppercase (A-Z)
        </Typography>
        <Typography
          sx={{ opacity: hasLowerCase(val) ? 1 : 0.5 }}
          variant={'textRegular'}
          color={hasLowerCase(val) ? 'common.birch' : 'common.grey50'}>
          Lowercase (a-z)
        </Typography>
        <Typography
          sx={{ opacity: hasDigit(val) ? 1 : 0.5 }}
          variant={'textRegular'}
          color={hasDigit(val) ? 'common.birch' : 'common.grey50'}>
          Digits (0-9)
        </Typography>
      </Box>
    </Box>
  );
};
