import React from 'react';
import FinalForm from '../../../FinalForm';
import CreateFaqForm from '../../../../Forms/CreateFaqForm';
import { faqActions } from '../../../../bus/faq/actions';
import { schema } from '../../../../Forms/CreateFaqForm/schema';
import { useScrollTo } from '../../../../helpers/customHooks/useScrollTo';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type ModalProps = {};

const effects: EffectFunc[] = [
  (colors, isSM) => ({
    color: colors.secondary,
    position: isSM ? [-7, 50] : [30, 105],
  }),
  (colors, isSM) => ({
    color: colors.violet,
    position: isSM ? [103, 50] : [65, -5],
    blur: 50,
  }),
];

export const CreateFaq: React.FC<ModalProps> = () => {
  const { scrollToBottom } = useScrollTo();

  return (
    <GeneralCardHolder effects={effects} sx={styles.root}>
      <FinalForm
        initialValues={{}}
        component={CreateFaqForm}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          scrollToBottom();
        }}
        sagaAction={faqActions.createFaq}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
