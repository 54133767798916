import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

export const todoSelector = (state: RootState) => state.todo;

export const getIsTodoFetching = createSelector([todoSelector], (result) => {
  return { isFetching: result.isFetching };
});

export const getTodo = createSelector([todoSelector], (result) => {
  return { todoList: result.results };
});

export const todoListNextUrl = createSelector([todoSelector], (result) => {
  return { nextUrl: result.next };
});

export const getTodoIds = createSelector([todoSelector], (result) => {
  return { ids: result.results.map((todo) => todo.id).join(',') };
});
