import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminNewsIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77647 3.25016C4.77647 2.92842 5.0248 2.66732 5.33203 2.66732H16.2618C16.0218 3.19772 15.8875 3.79105 15.8875 4.41732V6.16732H18.1098H15.8875V13.1673V14.0747V18.5206C15.8875 20.0744 14.688 21.334 13.2082 21.334C11.7284 21.334 10.5288 20.0744 10.5288 18.5206V16.7251C10.5288 16.0489 10.0067 15.5007 9.36268 15.5007H4.77647V12.2674V3.25016ZM18.1098 14.0747V18.5206C18.1098 21.363 15.9153 23.6673 13.2082 23.6673C13.1753 23.6673 13.1425 23.667 13.1098 23.6663V23.6673H6.9987C3.3616 23.6673 0.332031 20.5742 0.332031 16.7251C0.332031 16.0489 0.854138 15.5007 1.49815 15.5007H2.55425V12.2674V3.25016C2.55425 1.6389 3.79831 0.333984 5.33203 0.333984H19.7765C21.9242 0.333984 23.6654 2.16215 23.6654 4.41732V7.27623C23.6654 7.95246 23.1433 8.50065 22.4993 8.50065H18.1098V13.1673V14.0747ZM18.1098 6.16732V4.41732C18.1098 3.45082 18.856 2.66732 19.7765 2.66732C20.6969 2.66732 21.4431 3.45082 21.4431 4.41732V6.16732H18.1098ZM8.30657 18.5206C8.30657 19.5589 8.59939 20.5254 9.10304 21.334H6.9987C4.928 21.334 3.16938 19.8354 2.68584 17.834H3.66536H8.30657V18.5206Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminNewsIcon;
