import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminNewsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77745 5.24918C6.77745 4.92744 7.02577 4.66634 7.333 4.66634H18.2628C18.0228 5.19674 17.8885 5.79007 17.8885 6.41634V8.16634H20.1108H17.8885V15.1663V16.0738V20.5196C17.8885 22.0735 16.689 23.333 15.2092 23.333C13.7294 23.333 12.5298 22.0735 12.5298 20.5196V18.7241C12.5298 18.0479 12.0077 17.4997 11.3637 17.4997H6.77745V14.2664V5.24918ZM20.1108 16.0738V20.5196C20.1108 23.3621 17.9163 25.6663 15.2092 25.6663C15.1763 25.6663 15.1435 25.666 15.1108 25.6653V25.6663H8.99968C5.36257 25.6663 2.33301 22.5733 2.33301 18.7241C2.33301 18.0479 2.85511 17.4997 3.49913 17.4997H4.55523V14.2664V5.24918C4.55523 3.63792 5.79929 2.33301 7.333 2.33301H21.7775C23.9252 2.33301 25.6663 4.16117 25.6663 6.41634V9.27526C25.6663 9.95148 25.1443 10.4997 24.5003 10.4997H20.1108V15.1663V16.0738ZM20.1108 8.16634V6.41634C20.1108 5.44984 20.857 4.66634 21.7775 4.66634C22.6979 4.66634 23.4441 5.44984 23.4441 6.41634V8.16634H20.1108ZM10.3075 20.5196C10.3075 21.5579 10.6004 22.5244 11.104 23.333H8.99968C6.92897 23.333 5.17035 21.8344 4.68682 19.833H5.66634H10.3075V20.5196Z"
        fill="url(#paint0_linear_14271_87599)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87599"
          x1="25.6663"
          y1="20.183"
          x2="3.13713"
          y2="13.6809"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminNewsIconLT;
