export const styles = {
  root: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '9.5px',
      left: '10px',
      // zIndex: -1,
    },
    '& textarea': {
      width: '100%!important',
    },
  },
};
