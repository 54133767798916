import React from 'react';
import { useDispatch } from 'react-redux';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { uiActions } from '../../../../bus/ui/actions';
import { schema } from '../../../../Forms/CreateNewProductForm/schema';
import FinalForm from '../../../FinalForm';
import { productsActions } from '../../../../bus/products/actions';
import { CreateNewProductForm } from '../../../../Forms/CreateNewProductForm';
import slugify from 'slugify';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type CreateNewProductProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-3, 23],
  }),
  (colors) => ({
    color: colors.primary,
    position: [92, 80],
    blur: 50,
  }),
];

export const CreateNewProduct: React.FC<CreateNewProductProps> = () => {
  const dispatch = useDispatch();

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    const slug = slugify(payload.values.title);
    dispatch(
      productsActions.postProduct({
        ...payload,
        values: {
          ...payload.values,
          slug: slug,
          is_active: true,
          excerpt: 'Metallica',
        },
      }),
    );
  };

  return (
    // @ts-ignore
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={CreateNewProductForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
