import { createAction } from '@reduxjs/toolkit';
import notificationsSlice from './slice';

export const notificationsActions = {
  ...notificationsSlice.actions,
  loadMoreNotifications: createAction(
    'notifications/loadMoreNotifications',
    (nextPageUrl) => {
      return {
        payload: { nextPageUrl },
      };
    },
  ),
  setSeen: createAction('notifications/setSeen', ({ id }) => {
    return {
      payload: {
        id,
      },
    };
  }),
  fetchNotifications: createAction('notifications/fetchNotifications'),
};
