import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AchievementsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51861 5.04314C5.68671 4.8067 5.96042 4.66603 6.25238 4.66603H21.7479C22.0398 4.66603 22.3135 4.8067 22.4816 5.04314L25.5031 9.2931C25.696 9.56446 25.7203 9.91968 25.5659 10.2143C25.4116 10.5089 25.1044 10.6938 24.7693 10.6938H3.2309C2.89582 10.6938 2.58865 10.5089 2.43432 10.2143C2.27999 9.91968 2.30421 9.56446 2.49714 9.2931L5.51861 5.04314ZM6.71804 6.44381L4.96046 8.91599H23.0398L21.2822 6.44381H6.71804Z"
        fill="url(#paint0_linear_14271_84454)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41702 9.43033C2.56417 9.11665 2.88167 8.916 3.23089 8.916H24.7693C25.1186 8.916 25.4361 9.11668 25.5832 9.43041C25.7304 9.74413 25.6804 10.1139 25.4551 10.3782L14.6838 23.0172C14.5133 23.2173 14.2623 23.3327 13.9979 23.3327C13.7336 23.3327 13.4826 23.2172 13.3121 23.017L2.545 10.3781C2.3198 10.1138 2.26987 9.74402 2.41702 9.43033ZM5.16237 10.6938L13.9982 21.0656L22.8374 10.6938H5.16237Z"
        fill="url(#paint1_linear_14271_84454)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12693 9.28371C9.2957 9.05275 9.56608 8.916 9.85393 8.916H18.1421C18.43 8.916 18.7003 9.05275 18.8691 9.28371C19.0379 9.51466 19.0846 9.81179 18.9946 10.0826L15.0801 21.8707C14.9618 22.2269 14.6305 22.4713 14.2521 22.4815L13.7972 22.4939C13.4007 22.5046 13.0441 22.2563 12.9202 21.8831L9.00142 10.0826C8.91148 9.81179 8.95816 9.51467 9.12693 9.28371ZM11.0938 10.6938L13.9981 19.4392L16.9022 10.6938H11.0938Z"
        fill="url(#paint2_linear_14271_84454)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6754 4.87408C6.05503 4.55847 6.6211 4.60744 6.93975 4.98345L9.90066 8.47743L13.3525 4.93741C13.5216 4.76398 13.7546 4.66604 13.998 4.66604C14.2415 4.66604 14.4744 4.76398 14.6435 4.93741L18.0958 8.47783L21.0608 4.98306C21.3797 4.60723 21.9458 4.55858 22.3252 4.87441C22.7047 5.19024 22.7538 5.75095 22.4349 6.12678L18.8292 10.3767C18.6656 10.5695 18.4272 10.6845 18.1731 10.6932C17.9191 10.7019 17.6732 10.6035 17.4966 10.4224L13.998 6.83445L10.4995 10.4224C10.3228 10.6036 10.0768 10.702 9.82265 10.6932C9.5685 10.6845 9.33004 10.5693 9.16654 10.3764L5.56498 6.1264C5.24633 5.75038 5.29577 5.1897 5.6754 4.87408Z"
        fill="url(#paint3_linear_14271_84454)"
      />
      <path
        d="M2.96665 8.83403C3.05161 8.81129 3.14032 8.79933 3.23089 8.79933H4.90025L6.16711 7.01739L5.47598 6.20182C5.33465 6.03505 5.25732 5.83617 5.24136 5.63447L2.96665 8.83403Z"
        fill="url(#paint4_linear_14271_84454)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84454"
          x1="25.6668"
          y1="18.946"
          x2="3.98863"
          y2="11.0864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14271_84454"
          x1="25.6668"
          y1="18.946"
          x2="3.98863"
          y2="11.0864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14271_84454"
          x1="25.6668"
          y1="18.946"
          x2="3.98863"
          y2="11.0864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14271_84454"
          x1="25.6668"
          y1="18.946"
          x2="3.98863"
          y2="11.0864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14271_84454"
          x1="25.6668"
          y1="18.946"
          x2="3.98863"
          y2="11.0864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AchievementsIconLT;
