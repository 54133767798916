import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const SupportIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9997 2.33398C7.55635 2.33398 2.33301 7.55733 2.33301 14.0007C2.33301 20.4439 7.55635 25.6673 13.9997 25.6673C20.4429 25.6673 25.6663 20.4439 25.6663 14.0007C25.6663 7.55733 20.4429 2.33398 13.9997 2.33398ZM23.2608 12.834C23.3085 13.2162 23.333 13.6055 23.333 14.0007C23.333 14.3958 23.3085 14.7851 23.2608 15.1673H20.9028C20.9665 14.7879 20.9997 14.3981 20.9997 14.0007C20.9997 13.6032 20.9665 13.2134 20.9028 12.834H23.2608ZM22.6546 10.5007C21.7075 8.16102 19.8393 6.29281 17.4997 5.34576V7.93712C18.5624 8.55186 19.4485 9.43796 20.0632 10.5007H22.6546ZM20.0632 17.5007H22.6546C21.7075 19.8403 19.8393 21.7085 17.4997 22.6556V20.0642C18.5624 19.4494 19.4485 18.5634 20.0632 17.5007ZM15.1663 20.9038C14.7869 20.9675 14.3972 21.0007 13.9997 21.0007C13.6022 21.0007 13.2124 20.9675 12.833 20.9038V23.2618C13.2152 23.3095 13.6045 23.334 13.9997 23.334C14.3948 23.334 14.7841 23.3095 15.1663 23.2618V20.9038ZM4.66634 14.0007C4.66634 14.3958 4.69089 14.7851 4.73855 15.1673H7.09646C7.03281 14.7879 6.99967 14.3981 6.99967 14.0007C6.99967 13.6032 7.03281 13.2134 7.09646 12.834H4.73855C4.69089 13.2162 4.66634 13.6055 4.66634 14.0007ZM5.34478 10.5007C6.29183 8.16102 8.16004 6.29281 10.4997 5.34576V7.93712C9.43698 8.55186 8.55089 9.43796 7.93615 10.5007H5.34478ZM10.4997 22.6556C8.16004 21.7085 6.29183 19.8403 5.34478 17.5007H7.93615C8.55089 18.5634 9.43698 19.4494 10.4997 20.0642V22.6556ZM15.1663 4.73952C14.7841 4.69186 14.3948 4.66732 13.9997 4.66732C13.6045 4.66732 13.2152 4.69186 12.833 4.73952V7.09744C13.2124 7.03378 13.6022 7.00065 13.9997 7.00065C14.3972 7.00065 14.7869 7.03378 15.1663 7.09744V4.73952ZM13.9997 9.33398C16.577 9.33398 18.6663 11.4233 18.6663 14.0007C18.6663 16.5779 16.577 18.6673 13.9997 18.6673C11.4223 18.6673 9.33301 16.5779 9.33301 14.0007C9.33301 11.4233 11.4223 9.33398 13.9997 9.33398Z"
        fill="url(#paint0_linear_14271_84490)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84490"
          x1="25.6663"
          y1="20.184"
          x2="3.13713"
          y2="13.6819"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default SupportIconLT;
