import { all, call, takeEvery } from 'redux-saga/effects';
import { usersActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  deactivateUser,
  activateUser,
  fetchUsers,
  deleteUser,
} from './workers';

// imports

function* watchFetchUsers() {
  yield takeEvery(usersActions.fetchUsers.type, fetchUsers);
}

function* watchDeactivateUser() {
  yield takeEvery(usersActions.deactivateUser.type, deactivateUser);
}

function* watchActivateUser() {
  yield takeEvery(usersActions.activateUser.type, activateUser);
}

function* watchDeleteUser() {
  yield takeEvery(usersActions.deleteUser.type, deleteUser);
}

// watchers
export function* watchUsers() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchDeactivateUser),
    call(watchActivateUser),
    call(watchFetchUsers),
    call(watchDeleteUser),
  ]);
}
