import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { DatePicker } from '../../../components/ui-kit/inputs/DatePicker';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderDatePickerField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <DatePicker
      {...input}
      placement={'top'}
      fullWidth
      label={'Date'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
    />
  );
};
