// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillActivitiesActionPayload,
  FillCountriesActionPayload,
  FillCurrenciesPayload,
  OptionsState,
  FillCitiesActionPayload,
  FillCodesActionPayload,
  FillRoyaltyCategoriesPayload,
} from './typedefs';

const initialState: OptionsState = {
  isFetching: false,
  isInitialized: true,
  countries: null,
  cities: null,
  currencies: null,
  activities: null,
  codes: null,
  royaltyCategories: null,
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillCountries(state, action: PayloadAction<FillCountriesActionPayload>) {
      state.countries = action.payload;
    },
    fillCities(state, action: PayloadAction<FillCitiesActionPayload>) {
      state.cities = action.payload;
    },
    fillCurrencies(state, action: PayloadAction<FillCurrenciesPayload>) {
      state.currencies = action.payload;
    },
    fillActivities(state, action: PayloadAction<FillActivitiesActionPayload>) {
      state.activities = action.payload;
    },
    fillCodes(state, action: PayloadAction<FillCodesActionPayload>) {
      state.codes = action.payload;
    },
    fillRoyaltyCategories(
      state,
      action: PayloadAction<FillRoyaltyCategoriesPayload>,
    ) {
      state.royaltyCategories = action.payload;
    },
    // inject
  },
});

export default optionsSlice;
