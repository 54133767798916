import { Box, FormHelperText } from '@mui/material';
import { Link as UiLink } from '../../components/ui-kit/typography/Link';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { RenderPasswordField } from './fields/RenderPasswordField';
import { Button } from '../../components/ui-kit/Button';
import { RenderEmailField } from './fields/RenderEmailField';
import { RenderRememberPasswordField } from './fields/RenderRememberPasswordField';
import { book } from '../../routes/book';
import { styles } from './styles';

export const SignIn = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
      <Box sx={styles.root}>
        <Field name={'email'} render={RenderEmailField} />
        <Field name={'password'} render={RenderPasswordField} />
        <Box
          sx={styles.bottomText}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Field name={'remember'} render={RenderRememberPasswordField} />
          <UiLink to={book.forgotPassword}>Forgot password?</UiLink>
        </Box>
        <Box my={1}>
          <Button
            fullWidth
            type={'submit'}
            variant={'primary'}
            color={'green'}
            role={'submit'}>
            Sign In
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default SignIn;
