import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const DatePicerIcon: React.FC<SVGProps<SVGSVGElement>> = ({ onClick }) => {
  return (
    <SvgIcon
      onClick={onClick}
      sx={{ fontSize: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C7.44772 2 7 2.44772 7 3V4H6C4.34315 4 3 5.34315 3 7V9V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9V7C21 5.34314 19.6569 4 18 4H17V3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3C9 2.44772 8.55228 2 8 2ZM16 6H8H6C5.44772 6 5 6.44772 5 7V8H19V7C19 6.44772 18.5523 6 18 6H16ZM5 19V10H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19Z"
        fill="#37E6FF"
      />
      <path d="M9 11H7V13H9V11Z" fill="#37E6FF" />
      <path d="M9 14H7V16H9V14Z" fill="#37E6FF" />
      <path d="M9 17H7V19H9V17Z" fill="#37E6FF" />
      <path d="M13 17H11V19H13V17Z" fill="#37E6FF" />
      <path d="M13 14H11V16H13V14Z" fill="#37E6FF" />
      <path d="M13 11H11V13H13V11Z" fill="#37E6FF" />
      <path d="M17 17H15V19H17V17Z" fill="#37E6FF" />
      <path d="M17 14H15V16H17V14Z" fill="#37E6FF" />
      <path d="M17 11H15V13H17V11Z" fill="#37E6FF" />
    </SvgIcon>
  );
};

export default DatePicerIcon;
