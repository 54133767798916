// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillPoolsActionPayload,
  FillPoolsLevelsActionPayload,
  FillPoolYearsActionPayload,
  FillYearStatsActionPayload,
  PoolsState,
  FillMonthsEarningsActionPayload,
} from './typedefs';
import { emptyPaginationState } from '../../setup/typedefs';

const initialState: PoolsState = {
  isFetching: false,
  isInitialized: true,
  pools: [],
  poolsLevels: emptyPaginationState as any,
  poolsYearStats: [],
  poolYears: [],
  poolsMonthsEarnings: [],
};

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillPools(state, action: PayloadAction<FillPoolsActionPayload>) {
      state.pools = action.payload;
    },
    fillPoolYears(state, action: PayloadAction<FillPoolYearsActionPayload>) {
      state.poolYears = action.payload;
    },
    fillPoolsLevels(
      state,
      action: PayloadAction<FillPoolsLevelsActionPayload>,
    ) {
      state.poolsLevels = { ...state.poolsLevels, ...action.payload };
    },
    fillYearStats(state, action: PayloadAction<FillYearStatsActionPayload>) {
      state.poolsYearStats = action.payload;
    },
    fillMonthsEarnings(
      state,
      action: PayloadAction<FillMonthsEarningsActionPayload>,
    ) {
      state.poolsMonthsEarnings = action.payload;
    },
    // inject
  },
});

export default poolsSlice;
