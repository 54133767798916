export const styles = {
  root: {
    display: 'flex',
    cursor: 'pointer',
  },
  rankImageBackground: {
    background: 'rgba(255, 255, 255, 0.24)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '48px',
    height: '48px',
    zIndex: -1,
    borderRadius: '9px',
  },
  right: {
    zIndex: 1,
    position: 'relative',
    margin: '0 0 0 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
};
