import { Components } from '@mui/material';

export const MuiTooltip: Partial<Components> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        maxWidth: 'initial',
        background: 'none',
      },
    },
  },
};
