import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '306px',
    gap: '29px',
    background: 'none',
    backdropFilter: 'none',
    padding: '30px 70px 20px 70px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px 20px',
      width: 'calc(100% - 40px)',
      maxWidth: 'calc(100% - 40px)',
    },
    '& .MuiButton-root': {
      maxWidth: '148px',
      minWidth: '140px',
      width: '100%',
      '&:last-child': {
        gap: '12px',
      },
    },
  }),
};
