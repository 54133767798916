import { array, number, object, string } from 'yup';

export const schema = object().shape({
  name: string().required('Name is required field'),
  country: string().required('Country is required field'),
  city: string().required('City is required field'),
  address: string(),
  postal_code: number(),
  url: string(),
  activities: array()
    .required('Field of Activities is required field')
    .min(1, 'Field of Activities is required field'),
});
