import { createAction } from '@reduxjs/toolkit';
import walletsSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const walletsActions = {
  ...walletsSlice.actions,
  fetchWalletsWithStats: createAction(
    'wallets/fetchWalletsWithStats',
    ({ ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
};
