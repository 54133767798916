import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& > button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
      },
    }),
  },
  content: (theme: Theme) => ({
    gap: 5,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    '@media (max-width: 485px)': {
      gap: 3,
    },
  }),
  wrapper: (theme: Theme) => ({
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  }),
  buttons: (theme: Theme) => ({
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
  country: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 1.5,
    '@media (max-width: 485px)': {
      gridTemplateColumns: '1fr',
      gap: 0,
    },
  },
  address: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gap: 1.5,
    marginBottom: '50px',
    '@media (max-width: 485px)': {
      gridTemplateColumns: '1fr',
      gap: 0,
      marginBottom: '24px',
    },
  },
};
