import { Box, FormHelperText, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import RenderAmountField from './fields/RenderAmountField';
import { RenderPercentField } from './fields/RenderPercentField';
import { RenderTransactionPassField } from './fields/RenderTransactionPassField';
import { useSelector } from 'react-redux';
import { getBalanceForWithdrawal } from '../../bus/balance/selectors';
import { getModalData } from '../../bus/ui/selectors';
import { useEffect, useState } from 'react';
import { getCurrencyCourse } from '../../bus/options/selectors';
import { styles } from './styles';
import { formatToRound, formatToUSLocale } from '../../helpers/formating';

export const ConvertForm = (props: FormRenderProps) => {
  const theme = useTheme();
  const wallets = useSelector(getBalanceForWithdrawal);
  const modalData = useSelector(getModalData);
  const [walletData, setWalletData] = useState<any>();
  const [received, setReceived] = useState<any>(0);
  const currencyCourse = useSelector(getCurrencyCourse(3));
  const [percent, setPercent] = useState<number | string>(0);

  useEffect(() => {
    if (modalData?.modalPayload.id) {
      const tmp = wallets.find((item) => item.id === modalData.modalPayload.id);

      setWalletData(tmp);
    }
  }, [modalData?.modalPayload]);

  useEffect(() => {
    if (props.values.percent) {
      const val = +props.values.available;
      const fixedVal = Number((val / 100) * +props.values.percent).toFixed(2);
      setPercent(fixedVal);
    }
  }, [props.values]);

  useEffect(() => {
    if (currencyCourse) {
      setReceived(Number(props.values.amount) / currencyCourse);
    }
  }, [props.values, currencyCourse]);

  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        sx={styles.root}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}>
        <Typography
          sx={{ marginBottom: '12px', opacity: 0.5 }}
          variant={'Body/Table/Basic 13'}>
          Available
        </Typography>
        {walletData && (
          <Typography
            sx={{ marginBottom: '25px' }}
            color={'common.modals.mainColor'}
            variant={'Headlines/H4'}>
            {formatToUSLocale(walletData.available)} {walletData.currencyLabel}
          </Typography>
        )}
        <Box mb={'12px'}>
          <Field
            name={'amount'}
            valuePercent={percent}
            render={RenderAmountField}
            type={'number'}
          />
        </Box>
        <Field name={'percent'} render={RenderPercentField} />
        <Box display={'flex'} justifyContent={'space-between'} mt={'30px'}>
          {!!received && (
            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                sx={{ marginBottom: '12px', opacity: 0.5 }}
                variant={'Body/Table/Basic 13'}>
                Received upon exchange
              </Typography>
              <Typography
                sx={{ marginBottom: '25px' }}
                color={theme.palette.common.pink}
                variant={'Headlines/H4'}>
                {formatToRound(received)} FZS
              </Typography>
            </Box>
          )}
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{ marginBottom: '12px', opacity: 0.5 }}
              variant={'Body/Table/Basic 13'}>
              Exchange rate today
            </Typography>
            {currencyCourse && (
              <Typography
                sx={{ marginBottom: '25px' }}
                color={theme.palette.common.mainColorFirst}
                variant={'Body/Table/Basic 13'}>
                1 FZS = {formatToRound(currencyCourse, 4)} BUSD
              </Typography>
            )}
          </Box>
        </Box>
        <Box mt={'30px'}>
          <Field name={'password'} render={RenderTransactionPassField} />
        </Box>
        <Box mt={'30px'}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Convert
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'left' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </form>
  );
};
