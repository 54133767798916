import { Theme } from '@mui/material';

export const styles = {
  root: {
    '&.MuiList-root': {
      position: 'absolute',
      zIndex: 99999,
      bottom: '-10px',
      transform: 'translateY(100%)',
      background: (theme: Theme) =>
        theme.palette.common.header.dropdownBackground,
      width: '131px',
      height: '96px',
      borderRadius: '18px',
      padding: '12px 0 12px 0',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .MuiListItem-root': {
        '& .MuiListItemButton-root': {
          padding: '0 0 0 18px',
          margin: 0,
          borderRadius: '18px',
          height: 'initial',
          '&:hover': {
            '&:after': {
              display: 'none',
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: '36px',
          },
          '& .MuiListItemText-root': {
            fontFamily: 'Work Sans, sans-serif',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: '-0.02em',
            textTransform: 'capitalize',
          },
        },
      },
    },
  },
};
