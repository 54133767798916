import { all, call, takeEvery } from 'redux-saga/effects';
import { balanceActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchTransactionHistory,
  fetchWallets,
  fetchWalletsWithStats,
} from './workers';

// imports

function* watchFetchTransaction() {
  yield takeEvery(
    balanceActions.fetchTransactionHistory.type,
    fetchTransactionHistory,
  );
}

function* watchFetchWalletsWithStats() {
  yield takeEvery(
    balanceActions.fetchWalletsWithStats.type,
    fetchWalletsWithStats,
  );
}

function* watchFetchWallets() {
  yield takeEvery(balanceActions.fetchWallets.type, fetchWallets);
}

// watchers
export function* watchBalance() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchTransaction),
    call(watchFetchWallets),
    call(watchFetchWalletsWithStats),
  ]);
}
