import { Box } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';
import { Autocomplete } from '../../../components/ui-kit/inputs/Autocomplete';
import { useSelector } from 'react-redux';
import { getCountriesOptions } from '../../../bus/options/selectors';

export const RenderCountryField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const options = useSelector(getCountriesOptions);

  return (
    <Box my={1}>
      <Autocomplete
        input={input}
        options={options}
        label={'Country'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};
