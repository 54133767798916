import { call, put } from 'redux-saga/effects';
import { optionsActions } from '../../actions';
import { City } from 'country-state-city';
import { ICity } from 'country-state-city/lib/interface';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchCities() {
  try {
    yield put(optionsActions.startFetching());
    const cities: ICity[] = yield call(City.getAllCities);
    yield put(optionsActions.fillCities(cities));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(optionsActions.stopFetching());
  }
}
