import { createAction } from '@reduxjs/toolkit';
import royaltyFeesSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const royaltyFeesActions = {
  ...royaltyFeesSlice.actions,
  fetchRoyaltyFees: createAction(
    'royaltyFees/fetchRoyaltyFees',
    ({ order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering:
                order === 'desc' ? '-' + value.ordering : '' + value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
};
