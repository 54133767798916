import { Theme } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsOptions } from '../../../../themes/dark/breakpoints';
const breakpoints = createBreakpoints(breakpointsOptions);

export const styles = {
  root: (theme: Theme) => ({
    borderRadius: '8px',
    '& input[type="file"]': {
      display: 'none',
    },
    '& label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      ...theme.mixins.card,
      '&:before': {
        ...theme.mixins.cardBefore,
      },
      [breakpoints.down('xxl')]: {
        padding: '15.5px 15.5px',
      },
      borderRadius: '8px',
      padding: '15.5px 15.5px',
    },
  }),
  preview: {
    overflow: 'hidden',
  },
  previewImg: (theme: Theme) => ({
    width: '49px',
    height: '49px',
    minWidth: '49px',
    '& img': {
      boxSizing: 'border-box',
      borderRadius: '16px',
      border: `1px solid ${theme.palette.common.neonPink}`,
      width: '100%',
      height: '100%',
    },
  }),
  textWrapper: {
    overflow: 'hidden',
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  withFile: (theme: Theme) => ({
    ...theme.mixins.card,
    '&:before': {
      ...theme.mixins.cardBefore,
    },
    [breakpoints.down('xxl')]: {
      padding: '12px 16px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    '& .MuiButton-root': {
      minWidth: 'inherit',
      '& .MuiSvgIcon-root': {
        fontSize: '11.25px',
      },
    },
  }),
};
