import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminWithdrawalsIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 24 20'} width={'24'} height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.332 8.83268C22.6207 8.83268 23.6654 7.78805 23.6654 6.49935V2.99935C23.6654 1.71068 22.6207 0.666016 21.332 0.666016H18.9987H16.6654H7.33203H2.66536C1.3767 0.666016 0.332031 1.71068 0.332031 2.99935V6.49935C0.332031 7.78805 1.3767 8.83268 2.66536 8.83268H4.9987V15.8327C4.9987 17.7657 6.56571 19.3327 8.4987 19.3327H15.4987C17.4317 19.3327 18.9987 17.7657 18.9987 15.8327V8.83268H21.332ZM4.9987 2.99935H2.66536V6.49935H4.9987V2.99935ZM16.6654 2.99935V5.33268V15.8327C16.6654 16.477 16.143 16.9993 15.4987 16.9993H10.832V2.99935H14.332H16.6654ZM8.4987 2.99935V16.9993C7.85437 16.9993 7.33203 16.477 7.33203 15.8327V5.33268V2.99935H8.4987ZM18.9987 2.99935V6.49935H21.332V2.99935H18.9987ZM14.332 14.666C13.6877 14.666 13.1654 14.1437 13.1654 13.4993V8.83268C13.1654 8.18833 13.6877 7.66602 14.332 7.66602C14.9764 7.66602 15.4987 8.18833 15.4987 8.83268V13.4993C15.4987 14.1437 14.9764 14.666 14.332 14.666Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminWithdrawalsIcon;
