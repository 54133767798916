import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

const productsSelector = (state: RootState) => state.products;

export const getIsProductsFetching = createSelector(
  [productsSelector],
  (result) => {
    return { isFetching: result.isFetching };
  },
);

export const productsListNextUrl = createSelector(
  [productsSelector],
  (result) => {
    return result.products?.next;
  },
);

export const getProducts = createSelector([productsSelector], (result) => {
  return result.products ? result.products.results : [];
});

export const getAllProductsList = createSelector(
  [productsSelector],
  ({ products }) => {
    if (!products?.results) return [];

    return products.results.map((item) => {
      return {
        id: item.id,
        slug: item.slug,
        created_at: item.created_at,
        image: item.thumbnail,
        title: item.title,
        description: item.content,
        link: item.external_url,
        active: item.is_active,
        action: '',
      };
    });
  },
);

export const getAllProductsCurrentPage = createSelector(
  [productsSelector],
  ({ products }) => {
    if (!products?.previous) return 1;

    return (
      Number(new URLSearchParams(products.previous.split('?')[1]).get('page')) +
        1 || 1
    );
  },
);

export const getAllProductsPagination = (pageSize: number) =>
  createSelector(
    [productsSelector, getAllProductsCurrentPage],
    ({ products }, currentPage) => {
      if (!products) return null;

      return {
        totalRows: products.count,
        totalPage: Math.ceil(products.count / pageSize),
        currentPage: currentPage,
        rowsPerPage: pageSize,
        nextPage: products.next,
        prevPage: products.previous,
      };
    },
  );

export const getOneProduct = (slug: string) =>
  createSelector([productsSelector], ({ products }) => {
    return products?.results.find((product) => product.slug === slug);
  });
