import { apply, call, put } from 'redux-saga/effects';
import { merchantsActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { CreateNewMerchantActionPayload } from '../../typedefs';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* updateMerchant({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<CreateNewMerchantActionPayload>) {
  try {
    yield put(merchantsActions.startFetching());

    const { merchantId, ...data } = payload;

    const body: string = yield call(JSON.stringify, data);

    const response: Response = yield apply(api, api.put, [
      { endpoint: `merchants/${merchantId}/`, body },
    ]);

    if (!response.ok) {
      const errors: { [key: string]: string } = yield call([response, 'json']);
      reject(errors);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(merchantsActions.stopFetching());
  }
}
