import {
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderTransactionPassField } from './fields/RenderTransactionPassField';
import { RenderAmountField } from './fields/RenderAmountField';
import { RenderPercentField } from './fields/RenderPercentField';
import { RenderWalletsAddressField } from './fields/RenderWalletsAddressField';
import { RenderNetworkField } from './fields/RenderNetworkField';
import { useSelector } from 'react-redux';
import { getModalData } from '../../bus/ui/selectors';
import { getBalanceForWithdrawal } from '../../bus/balance/selectors';
import { styles } from './styles';
import RenderCurrencyField from './fields/RenderCurrencyField';

const feePercent = 5;

export const WithdrawalForm = (props: FormRenderProps) => {
  const theme = useTheme();
  const [percent, setPercent] = useState<number | string>(0);
  const [fee, setFee] = useState(0);
  const wallets = useSelector(getBalanceForWithdrawal);
  const modalData = useSelector(getModalData);
  const [walletData, setWalletData] = useState<any>();
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));

  useEffect(() => {
    if (modalData?.modalPayload.id) {
      const tmp = wallets.find((item) => item.id === modalData.modalPayload.id);

      setWalletData(tmp);
    }
  }, [modalData]);

  useEffect(() => {
    if (props.values.percent) {
      const val = +props.values.available;
      const fixedVal = Number((val / 100) * +props.values.percent).toFixed(2);
      setPercent(fixedVal);
    }

    if (props.values.amount) {
      const val = +props.values.amount;
      setFee((val / 100) * feePercent);
    }
  }, [props.values]);

  return (
    <form onSubmit={props.handleSubmit}>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={2}>
        <Box sx={styles.wrapper} mb={0}>
          <Field
            name={'amount'}
            valuePercent={percent}
            render={RenderAmountField}
            type={'number'}
          />
          <Field name={'currency'} render={RenderCurrencyField} />
        </Box>
        <Field name={'percent'} render={RenderPercentField} />
        <Box>
          <Typography
            variant={'Body/Table/Basic 13'}
            sx={{ opacity: 0.5 }}
            color={'common.modals.mainColor'}>
            Fees
          </Typography>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography
              variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}
              color={theme.palette.common.pink}>
              {feePercent}%
            </Typography>
            {!!fee && (
              <Typography variant={'Body/Table/Basic 13'} sx={{ opacity: 0.5 }}>
                {fee} {walletData.currencyLabel}
              </Typography>
            )}
          </Box>
        </Box>
        <Field name={'card'} render={RenderWalletsAddressField} />
        <Field name={'network'} render={RenderNetworkField} />
        <Typography
          ml={'20px'}
          variant={'Headlines/H10'}
          sx={{ opacity: 0.5, width: '80%' }}>
          Ensure the network you choose to deposit matches the withdrawal
          network, or assets may be lost
        </Typography>
        <Field name={'password'} render={RenderTransactionPassField} />
        <Box mt={'30px'}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Withdraw
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
