import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const VentureCapitalIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4333 3.64682C13.7856 3.45106 14.214 3.45106 14.5663 3.64682L25.0663 9.48015C25.5311 9.73833 25.7625 10.278 25.6291 10.7927C25.4958 11.3073 25.0314 11.6667 24.4998 11.6667H23.3331V22.1667H24.4998C25.1441 22.1667 25.6665 22.689 25.6665 23.3333C25.6665 23.9777 25.1441 24.5 24.4998 24.5H3.49978C2.85546 24.5 2.33312 23.9777 2.33312 23.3333C2.33312 22.689 2.85546 22.1667 3.49978 22.1667H4.66645V11.6667H3.49978C2.96817 11.6667 2.50378 11.3073 2.37042 10.7927C2.23707 10.278 2.46849 9.73833 2.9332 9.48015L13.4333 3.64682ZM6.99978 22.1667H9.91645V11.6667H6.99978V22.1667ZM12.2498 22.1667H15.7498V11.6667H12.2498V22.1667ZM18.0831 22.1667H20.9998V11.6667H18.0831V22.1667ZM19.9975 9.33334H16.9165H11.0831H8.0021L13.9998 6.00129L19.9975 9.33334Z"
        fill="url(#paint0_linear_14271_84447)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84447"
          x1="25.6665"
          y1="19.565"
          x2="3.53631"
          y2="12.4684"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default VentureCapitalIconLT;
