import { createAction } from '@reduxjs/toolkit';
import transactionsSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const transactionsActions = {
  ...transactionsSlice.actions,
  postRoyaltyFees: createAction(
    'transactions/postRoyaltyFees',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  transactionPassword: createAction(
    'transactions/transactionPassword',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  changeTransactionPasswordWithCode: createAction(
    'transactions/changeTransactionPasswordWithCode',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  sendCodeForResetPassword: createAction(
    'transactions/sendCodeForResetPassword',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  transaction: createAction(
    'transactions/transaction',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  convert: createAction(
    'transactions/convert',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  postWithdraw: createAction(
    'transactions/postWithdraw',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  postUserWithdraw: createAction(
    'transactions/postUserWithdraw',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchWithdrawFee: createAction(
    'transactions/fetchWithdrawFee',
    ({ order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering:
                order === 'desc' ? '-' + value.ordering : '' + value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
};
