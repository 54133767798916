import { apply, call, put } from 'redux-saga/effects';
import { productsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillProductsActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchProducts({ payload }: PayloadAction<FetchListWorkers>) {
  try {
    yield put(productsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `products/`, query: payload.query },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillProductsActionPayload = yield call([response, 'json']);
    yield put(productsActions.fillProducts(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(productsActions.stopFetching());
  }
}
