import { YearStatistic } from '../bus/wallets/typedefs';

export const nFormatter = (num: number) => {
  const newFormat = Math.abs(num) / 1000;

  return Math.abs(num) > 9999
    ? (Math.sign(num) * newFormat).toFixed(0) + 'k'
    : num;
};

export const BigNFormatter = (num: number) => {
  if (Math.round(Math.abs(num)) > 999 && Math.round(Math.abs(num)) < 1000000) {
    return (
      (Math.sign(num) * (Math.round(Math.abs(num)) / 1000)).toFixed(0) + 'K'
    );
  }

  if (Math.round(Math.abs(num)) > 999999) {
    return (
      (Math.sign(num) * (Math.round(Math.abs(num)) / 1000000)).toFixed(0) + 'M'
    );
  }

  return num;
};

export const formatToRound = (num: number, signAfter = 2) => {
  if (!isNaN(+num) && num) {
    return +num.toFixed(signAfter);
  }

  return 0;
};

export const formatToUSLocale = (num: number) => {
  const dollarUSLocale = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });

  return dollarUSLocale.format(num || 0);
};

export const getFullNameCountryByCode = (code: string) => {
  const regionNames: Intl.DisplayNames = new Intl.DisplayNames(['en'], {
    type: 'region',
  });

  return regionNames.of(code);
};

// use this for repeatable url params
export const getEndpointParams2 = (params: any, urlParams: URLSearchParams) => {
  Object.keys(params).forEach((key: string) => {
    if (params[key] instanceof Array && !!params[key].length) {
      return params[key].forEach((item: any) => {
        urlParams.append(key, item);
      });
    }
    if (params[key] && !Array.isArray(params[key])) {
      return urlParams.set(key, params[key]);
    }
  });

  return decodeURIComponent(urlParams.toString());
};

export const getUrlExtension = (url: string) => {
  // @ts-ignore
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const getFileFromUrl = async (imgUrl: string) => {
  const imgExt = getUrlExtension(imgUrl);
  const nameSplit = imgUrl.split('/');
  const name = nameSplit[nameSplit.length - 1].split('.')[0];

  const response = await fetch(imgUrl, {
    method: 'GET',
  });
  const blob = await response.blob();
  const file = new File([blob], `${name}.` + imgExt, {
    type: blob.type,
  });

  return {
    file,
    blob,
  };
};

export const diagramData = (stats: YearStatistic[]) => {
  if (!stats || !stats.length) {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }

  const arrNumbers = stats.map((item) => item.amount);

  const max = arrNumbers.reduce((prev, current) =>
    prev > current ? prev : current,
  );

  return stats.map((item) => +((item.amount * 100) / max).toFixed(2));
};

export const slugString = (str: string) => {
  return str.toLowerCase().replace(/ /g, '-');
};
