import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import authBackgroundBefore from '../../../assets/Before.mp4';
import authBackgroundAfter from '../../../assets/After.mp4';
import logo from '../../../assets/logo.png';
import CopyrightFooter from '../../../components/ui-kit/CopyrightFooter';
import { styles as style } from './styles';
import { LogoAnimation } from '../../../assets/LogoAnimation';
import { config, useSpring, animated } from 'react-spring';
import { uiActions } from '../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { ThemeVariant } from '../../../bus/ui/typedefs';

export type AuthLayoutProps = {};

const AuthLayout: React.FC<AuthLayoutProps> = () => {
  //TODO: animation is MVP
  const theme = useTheme();
  const dispatch = useDispatch();
  const isXL = useMediaQuery(theme.breakpoints.down('xl'));
  const isMD = useMediaQuery(theme.breakpoints.down('md'));
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const currentTheme = localStorage.getItem('currentTheme');
    if (currentTheme !== ThemeVariant.dark) {
      dispatch(uiActions.fillTheme(ThemeVariant.dark));
      localStorage.setItem('currentTheme', ThemeVariant.dark);
    }
  }, []);

  const styles = useSpring({
    from: {
      width: isXL ? '100%' : '1303px',
    },
    to: {
      width: isXL ? (isMD ? (isSM ? '50%' : '40%') : '25%') : '273px',
    },
    delay: 3700,
    config: config.molasses,
  });

  const styles2 = useSpring({
    from: {
      filter: 'blur(100px)',
    },
    to: {
      opacity: 0,
      filter: 'blur(5px)',
    },
    delay: 3800,
    config: config.molasses,
  });

  const styles3 = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 3900,
    config: config.molasses,
  });

  const styles4 = useSpring({
    from: {
      opacity: 0,
      top: '-100vh',
    },
    to: {
      opacity: 1,
      top: '0vh',
    },
    delay: 3900,
    config: config.molasses,
  });

  return (
    <Container maxWidth={false} disableGutters>
      {isMD ? (
        <Box
          sx={style.root}
          overflow={'hidden'}
          boxSizing={'border-box'}
          pb={'60px'}
          zIndex={1}
          minHeight={'100vh'}
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          position={'relative'}
          alignItems={'center'}>
          <Box component={'img'} src={logo} alt={'logo'} />
          <Outlet />
          <CopyrightFooter />
        </Box>
      ) : (
        <Box
          sx={style.root}
          overflow={'hidden'}
          boxSizing={'border-box'}
          pb={'60px'}
          zIndex={1}
          minHeight={'100vh'}
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          position={'relative'}
          alignItems={'center'}>
          <animated.div
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -1,
              ...styles2,
            }}>
            <Box sx={style.bgImg}>
              <video autoPlay muted>
                <source src={authBackgroundBefore} type="video/mp4" />
              </video>
            </Box>
          </animated.div>
          <animated.div
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -1,
              overflow: 'hidden',
              boxShadow: '0px 60px 300px 200px rgb(5 2 7)',
              filter: 'blur(5px)  brightness(100%)',
              ...styles4,
            }}>
            <Box sx={style.bgImg2} />
            <Box sx={style.bgImg1}>
              <video loop autoPlay muted>
                <source src={authBackgroundAfter} type="video/mp4" />
              </video>
            </Box>
          </animated.div>
          <animated.div
            style={{
              margin: 'auto auto 23px',
              ...styles,
            }}>
            <LogoAnimation />
          </animated.div>
          <animated.div style={styles3}>
            <Outlet />
          </animated.div>
          <CopyrightFooter />
        </Box>
      )}
    </Container>
  );
};

export default AuthLayout;
