// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { all, call } from 'redux-saga/effects';
import { watchAdmin as adminSaga } from '../bus/admin/saga/watchers';
import { watchAuth as authSaga } from '../bus/auth/saga/watchers';
import { watchBalance as balanceSaga } from '../bus/balance/saga/watchers';
import { watchDashboard as dashboardSaga } from '../bus/dashboard/saga/watchers';
import { watchFaq as faqSaga } from '../bus/faq/saga/watchers';
import { watchMerchants as merchantsSaga } from '../bus/merchants/saga/watchers';
import { watchNews as newsSaga } from '../bus/news/saga/watchers';
import { watchNotifications as notificationsSaga } from '../bus/notifications/saga/watchers';
import { watchOptions as optionsSaga } from '../bus/options/saga/watchers';
import { watchPools as poolsSaga } from '../bus/pools/saga/watchers';
import { watchProducts as productsSaga } from '../bus/products/saga/watchers';
import { watchResources as resourcesSaga } from '../bus/resources/saga/watchers';
import { watchRoyaltyFees as royaltyFeesSaga } from '../bus/royaltyFees/saga/watchers';
import { watchTeam as teamSaga } from '../bus/team/saga/watchers';
import { watchTodo as todoSaga } from '../bus/todo/saga/watchers';
import { watchTransactions as transactionsSaga } from '../bus/transactions/saga/watchers';
import { watchUi as uiSaga } from '../bus/ui/saga/watchers';
import { watchUsers as usersSaga } from '../bus/users/saga/watchers';
import { watchVenture as ventureSaga } from '../bus/venture/saga/watchers';
import { watchWallets as walletsSaga } from '../bus/wallets/saga/watchers';
import { watchWebSocket as webSocketSaga } from '../bus/webSocket/saga/watchers';
import { watchApp as appSaga } from '../bus/app/saga/watchers';
import { watchPayment as paymentSaga } from '../bus/payment/saga/watchers';
// imports

export function* rootSaga() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(appSaga),
    call(paymentSaga),
    call(webSocketSaga),
    call(walletsSaga),
    call(transactionsSaga),
    call(royaltyFeesSaga),
    call(ventureSaga),
    call(adminSaga),
    call(resourcesSaga),
    call(faqSaga),
    call(usersSaga),
    call(productsSaga),
    call(merchantsSaga),
    call(newsSaga),
    call(teamSaga),
    call(poolsSaga),
    call(dashboardSaga),
    call(todoSaga),
    call(notificationsSaga),
    call(authSaga),
    call(optionsSaga),
    call(uiSaga),
    call(balanceSaga),
  ]);
}
