import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const ProductsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91634 2.33398H5.24967C3.63885 2.33398 2.33301 3.63982 2.33301 5.25065V9.91732C2.33301 11.5281 3.63885 12.834 5.24967 12.834H9.91634C11.5272 12.834 12.833 11.5281 12.833 9.91732V5.25065C12.833 3.63982 11.5272 2.33398 9.91634 2.33398ZM4.66634 5.25065C4.66634 4.92849 4.92751 4.66732 5.24967 4.66732H9.91634C10.2385 4.66732 10.4997 4.92849 10.4997 5.25065V9.91732C10.4997 10.2395 10.2385 10.5007 9.91634 10.5007H5.24967C4.92751 10.5007 4.66634 10.2395 4.66634 9.91732V5.25065ZM9.91634 15.1673H5.24967C3.63885 15.1673 2.33301 16.4732 2.33301 18.084V22.7507C2.33301 24.3615 3.63885 25.6673 5.24967 25.6673H9.91634C11.5272 25.6673 12.833 24.3615 12.833 22.7507V18.084C12.833 16.4732 11.5272 15.1673 9.91634 15.1673ZM4.66634 18.084C4.66634 17.7619 4.92751 17.5007 5.24967 17.5007H9.91634C10.2385 17.5007 10.4997 17.7619 10.4997 18.084V22.7507C10.4997 23.0728 10.2385 23.334 9.91634 23.334H5.24967C4.92751 23.334 4.66634 23.0728 4.66634 22.7507V18.084ZM18.083 15.1673H22.7497C24.3605 15.1673 25.6663 16.4732 25.6663 18.084V22.7507C25.6663 24.3615 24.3605 25.6673 22.7497 25.6673H18.083C16.4722 25.6673 15.1663 24.3615 15.1663 22.7507V18.084C15.1663 16.4732 16.4722 15.1673 18.083 15.1673ZM18.083 17.5007C17.7609 17.5007 17.4997 17.7619 17.4997 18.084V22.7507C17.4997 23.0728 17.7609 23.334 18.083 23.334H22.7497C23.0718 23.334 23.333 23.0728 23.333 22.7507V18.084C23.333 17.7619 23.0718 17.5007 22.7497 17.5007H18.083ZM22.7497 2.33398H18.083C16.4722 2.33398 15.1663 3.63982 15.1663 5.25065V9.91732C15.1663 11.5281 16.4722 12.834 18.083 12.834H22.7497C24.3605 12.834 25.6663 11.5281 25.6663 9.91732V5.25065C25.6663 3.63982 24.3605 2.33398 22.7497 2.33398ZM17.4997 5.25065C17.4997 4.92849 17.7609 4.66732 18.083 4.66732H22.7497C23.0718 4.66732 23.333 4.92849 23.333 5.25065V9.91732C23.333 10.2395 23.0718 10.5007 22.7497 10.5007H18.083C17.7609 10.5007 17.4997 10.2395 17.4997 9.91732V5.25065Z"
        fill="url(#paint0_linear_14271_84461)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84461"
          x1="25.6663"
          y1="20.184"
          x2="3.13713"
          y2="13.6819"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default ProductsIconLT;
