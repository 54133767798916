import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

const resourcesSelector = (state: RootState) => state.resources;

export const getIsResourcesFetching = createSelector(
  [resourcesSelector],
  (result) => {
    return { isFetching: result.isFetching };
  },
);

export const resourcesListNextUrl = createSelector(
  [resourcesSelector],
  (result) => {
    return result.allResources?.next;
  },
);

export const getResources = (id: number) =>
  createSelector([resourcesSelector], ({ resourcesCategory }) => {
    const resources = resourcesCategory?.results?.find((item) => item.id === id)
      ?.resources?.results;

    return { resources: resources || [] };
  });

export const getOneResource = (slug: string) =>
  createSelector([resourcesSelector], ({ allResources }) => {
    return {
      resource: allResources?.results.find(
        (resource) => resource.slug === slug,
      ),
    };
  });

export const getResourceCategories = createSelector(
  [resourcesSelector],
  ({ resourcesCategory }) => ({
    categories: resourcesCategory?.results?.map((item) => ({
      id: item.id,
      title: item.title,
    })),
  }),
);

export const getResourcesList = createSelector(
  [resourcesSelector],
  ({ allResources }) => ({
    resources: allResources?.results,
  }),
);

export const getAllResourcesList = createSelector(
  [resourcesSelector],
  ({ allResources }) => {
    if (!allResources?.results) return [];

    return allResources.results.map((item) => {
      return {
        id: item.id,
        slug: item.slug,
        created_at: item.created_at,
        image: item.thumbnail,
        title: item.title,
        description: item.content,
        link: item.external_url,
        active: item.is_active,
        action: '',
      };
    });
  },
);

export const getAllResourcesCurrentPage = createSelector(
  [resourcesSelector],
  ({ allResources }) => {
    if (!allResources?.previous) return 1;

    return (
      Number(
        new URLSearchParams(allResources.previous.split('?')[1]).get('page'),
      ) + 1 || 1
    );
  },
);

export const getAllResourcesPagination = (pageSize: number) =>
  createSelector(
    [resourcesSelector, getAllResourcesCurrentPage],
    ({ allResources }, currentPage) => {
      if (!allResources) return null;

      return {
        totalRows: allResources.count,
        totalPage: Math.ceil(allResources.count / pageSize),
        currentPage: currentPage,
        rowsPerPage: pageSize,
        nextPage: allResources.next,
        prevPage: allResources.previous,
      };
    },
  );
