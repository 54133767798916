import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';

export function* handleConnect({ payload }: PayloadAction<any>) {
  try {
    yield call(console.log, 'connect success');
  } catch (e) {
    console.log(e);
  } finally {
    console.log('finally');
  }
}
