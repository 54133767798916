import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';
import { v4 as uuidv4 } from 'uuid';

export const optionsSelector = (state: RootState) => state.options;

export const getIsOptionsFetching = createSelector(
  [optionsSelector],
  (result) => {
    return { isFetching: result.isFetching };
  },
);

export const getCountriesOptions = createSelector(
  [optionsSelector],
  ({ countries }) => {
    if (!countries) return [];

    return countries.map((country) => ({
      label: country.name,
      code: country.isoCode,
      id: uuidv4(),
    }));
  },
);

export const getCitiesOptions = createSelector(
  [optionsSelector],
  ({ cities }) => {
    if (!cities) return [];

    return cities.map((city) => ({
      label: city.name,
      code: city.stateCode,
      id: uuidv4(),
    }));
  },
);

export const getCountriesListWithIcon = createSelector(
  [optionsSelector],
  ({ countries }) => {
    if (!countries) return [];

    return countries.map((country) => ({
      label: country.name,
      code: country.isoCode,
      flag: country.flag,
    }));
  },
);

export const getCurrencies = createSelector(
  [optionsSelector],
  ({ currencies }) => {
    if (!currencies?.results) return [];

    return currencies.results.map((item) => ({
      id: item.id,
      label: item.title,
    }));
  },
);

export const getCurrenciesRate = createSelector(
  [optionsSelector],
  ({ currencies }) => {
    if (!currencies?.results) return [];

    return currencies.results.map((item) => {
      return {
        id: item.id,
        label: item.title,
        rate: item.rate,
      };
    });
  },
);

export const getCurrencyCourse = (id: number) =>
  createSelector([optionsSelector], ({ currencies }) => {
    if (!currencies?.results.length) return null;

    const tmp = currencies?.results.find((item) => {
      return item.id === id;
    });

    return Number(tmp?.rate);
  });

export const getActivities = createSelector(
  [optionsSelector],
  ({ activities }) => {
    if (!activities) return [];

    return activities.map((item) => {
      return {
        id: item.id,
        label: item.title,
      };
    });
  },
);

export const getActivitiesMerchants = (initialValues: number[]) =>
  createSelector([optionsSelector], ({ activities }) => {
    if (!activities) return [];

    return activities.map((item) => {
      return {
        id: item.id,
        label: item.title,
        checked: !!initialValues?.includes(item.id),
      };
    });
  });

export const getOptionActivity = (id: number) =>
  createSelector([optionsSelector], ({ activities }) => {
    return activities?.find((item) => item.id === id);
  });

export const getOptionCountry = (code: string) =>
  createSelector([optionsSelector], ({ countries }) => {
    return countries?.find((item) => item.isoCode === code);
  });

export const getOptionCurrency = (id: number) =>
  createSelector([optionsSelector], ({ currencies }) => {
    return currencies?.results?.find((item) => item.id === id);
  });

export const getNotificationType = createSelector(
  [optionsSelector],
  ({ codes }) => {
    return codes?.notification_type || null;
  },
);

export const getTransactionStatus = createSelector(
  [optionsSelector],
  ({ codes }) => {
    return codes?.transaction_status || null;
  },
);

export const getTransactionType = createSelector(
  [optionsSelector],
  ({ codes }) => {
    return codes?.transaction_type || null;
  },
);

export const getRoyaltyCategories = createSelector(
  [optionsSelector],
  ({ royaltyCategories }) => {
    return royaltyCategories || null;
  },
);

export const getFZSCurrency = createSelector(
  [optionsSelector],
  ({ currencies }) => {
    return currencies?.results?.find((item) => item.title === 'FZS');
  },
);
