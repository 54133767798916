import { apply, call, put } from 'redux-saga/effects';
import { resourcesActions } from '../../actions';
import { api } from '../../../../REST/api';
import { IResourceItem } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers, WithPagination } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchResources({ payload }: PayloadAction<FetchListWorkers>) {
  try {
    yield put(resourcesActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `resources/`, query: payload.query },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: WithPagination<IResourceItem[]> = yield call([
      response,
      'json',
    ]);
    if (payload.categories) {
      yield put(
        resourcesActions.fillResources({
          results: data,
          id: payload.categories,
        }),
      );
    } else {
      yield put(resourcesActions.fillAllResources(data));
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(resourcesActions.stopFetching());
  }
}
