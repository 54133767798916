import React, { useEffect, useState } from 'react';
import FinalForm, { ShowSnackbar } from '../../../FinalForm';
import { ConvertForm } from '../../../../Forms/ConvertForm';
import { schema } from '../../../../Forms/ConvertForm/schema';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceForWithdrawal } from '../../../../bus/balance/selectors';
import { transactionsActions } from '../../../../bus/transactions/actions';
import { uiActions } from '../../../../bus/ui/actions';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { styles } from './styles';
import { balanceActions } from '../../../../bus/balance/actions';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type ConvertProps = {
  id: number;
  onSuccess: () => void;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-4, 73],
    size: 130,
    blur: 40,
  }),
  (colors) => ({
    color: colors.violet,
    position: [106, 25],
    blur: 35,
  }),
];

export const Convert: React.FC<ConvertProps> = ({ id, onSuccess }) => {
  const dispatch = useDispatch();
  const wallets = useSelector(getBalanceForWithdrawal);
  const [walletData, setWalletData] = useState<any>();

  const onSubmitHandler = ({
    values,
    resolve,
    reject,
  }: OnFormSubmitPayload) => {
    dispatch(
      transactionsActions.convert({
        values: {
          transaction: {
            wallet_id: values.wallet_id,
            currency: 1,
            amount: values.amount,
          },
          source_currency: 1,
          target_currency: 3,
          password: values.password,
        },
        resolve,
        reject,
      }),
    );
  };

  const onSuccessSubmit = (showSnackbar: ShowSnackbar) => {
    showSnackbar('Convert success');
    dispatch(balanceActions.fetchWalletsWithStats({}));
    dispatch(balanceActions.fetchTransactionHistory({ is_active: false }));
    onSuccess();
    dispatch(uiActions.closeModal());
  };

  useEffect(() => {
    if (id) {
      const tmp = wallets.find((item) => item.id === id);

      setWalletData(tmp);
    }
  }, [id]);

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      {walletData && (
        <FinalForm
          initialValues={{
            available: walletData.available,
            wallet_id: walletData.id,
          }}
          onSubmitSuccess={onSuccessSubmit}
          component={ConvertForm}
          onSubmit={onSubmitHandler}
          schema={schema}
        />
      )}
    </GeneralCardHolder>
  );
};
