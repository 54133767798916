import { Box, Typography, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';
import { styles } from './styles';
import icon from './icon.svg';
import { SnackbarContent } from 'notistack';

type InfoSnackbarProps = {
  message: React.ReactNode;
};

const InfoSnackbar: React.ForwardRefRenderFunction<
  HTMLDivElement,
  InfoSnackbarProps
> = ({ message }, ref) => {
  const theme = useTheme();

  return (
    <SnackbarContent ref={ref} style={{ width: '100%' }}>
      <Box sx={styles.root}>
        <Box sx={styles.bg}>
          <Box sx={styles.bg2}>
            <img src={icon} alt="info" />
            <Typography
              variant={'Headlines/H5'}
              color={theme.palette.common.white}>
              {message}
            </Typography>
          </Box>
        </Box>
      </Box>
    </SnackbarContent>
  );
};
export default forwardRef<HTMLDivElement, InfoSnackbarProps>(InfoSnackbar);
