import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

export const TechnicalPage: React.FC = (props) => {
  return (
    <Box sx={styles.root}>
      <Typography variant={'h1'} textAlign={'center'}>
        We’ll be back. We’re busy updating the Fozeus Franchise for you. Please
        check back soon.
      </Typography>
    </Box>
  );
};
