import { Theme } from '@mui/material';

export const styles = {
  root: {
    background: (theme: Theme) => theme.palette.common.buttons.todoBackground,
    borderRadius: '16px',
    position: 'relative',
    boxSizing: 'border-box',
    border: (theme: Theme) =>
      `1px solid ${theme.palette.common.inputs.defaultBorder}`,
    '&.MuiPaper-root': (theme: Theme) => ({
      padding: '28px 30px 23px 13px',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '100%',
      },
    }),
    '&.MuiAccordion-root:last-of-type': {
      borderRadius: '16px',
      background: (theme: Theme) => theme.palette.common.buttons.todoBackground,
    },
    '&.MuiAccordion-root:first-of-type': {
      borderRadius: '16px',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '16px',
      '& .MuiAccordionSummary-expandIconWrapper': {
        margin: '10px -12px 0 0',
        alignSelf: 'baseline',
      },
      '& .MuiAccordionSummary-content': {
        margin: '0',
      },
    },
  },
  details: {
    padding: '0 16px 16px',
  },
  titleBlock: {
    margin: '0 0 16px 0',
    '& h1': {
      lineHeight: '20px',
      position: 'initial!important',
    },
  },
  textBlock: {
    overflow: 'hidden',
  },
};
