import { apply, put } from 'redux-saga/effects';
import { newsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ChangeMoreNewsActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* readNews({
  payload,
}: PayloadAction<ChangeMoreNewsActionPayload>) {
  try {
    yield put(newsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `news/${payload}/read/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    yield put(newsActions.updateNews(payload));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(newsActions.stopFetching());
  }
}
