import { apply, call, put } from 'redux-saga/effects';
import { notificationsActions } from '../../actions';
import { handleError } from '../../../ui/saga/workers/handleError';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadMoreNotificationsActionPayload,
  PushMoreNotificationsActionPayload,
} from '../../typedefs';

export function* loadMoreNotifications({
  payload,
}: PayloadAction<LoadMoreNotificationsActionPayload>) {
  try {
    yield put(notificationsActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: payload.nextPageUrl, direct: true },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: PushMoreNotificationsActionPayload = yield call([
      response,
      'json',
    ]);
    yield put(notificationsActions.pushMoreNotifications(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(notificationsActions.stopFetching());
  }
}
