import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
      },
    }),
  },
  buttonGroup: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px',
    '& > button': {
      minWidth: '103px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
        padding: '12px 0',
      },
    },
  }),
  focus: (theme: Theme) => ({
    '&:focus': {
      background: theme.palette.common.percentage.background,
      '&:before': {
        background: theme.palette.common.percentage.backgroundBefore,
        opacity: 1,
      },
      '&:after': {
        background: theme.palette.common.percentage.backgroundAfter,
        opacity: 0,
      },
    },
    background: theme.palette.common.percentage.background,
    '&:before': {
      background: theme.palette.common.percentage.backgroundBefore,
      opacity: 1,
    },
    '&:after': {
      background: theme.palette.common.percentage.backgroundAfter,
      opacity: 0,
    },
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
  }),
  button: {
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  },
};
