import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const MerchantsIcon: React.FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <SvgIcon viewBox={'0 0 24 24'} width={'24'} height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99851 9.66732C3.44334 9.66732 2.32362 8.17398 2.75907 6.68065L3.92933 2.66732H7.33125V7.33398C7.33125 8.62265 6.28685 9.66732 4.99851 9.66732ZM8.49762 10.4208C7.64274 11.3896 6.39196 12.0007 4.99851 12.0007V17.2507H10.8304V16.6673C10.8304 14.7343 12.397 13.1673 14.3295 13.1673C16.262 13.1673 17.8286 14.7343 17.8286 16.6673V21.334H18.995V12.0007C17.6016 12.0007 16.3508 11.3896 15.4959 10.4208C14.6409 11.3896 13.3903 12.0007 11.9968 12.0007C10.6033 12.0007 9.35251 11.3896 8.49762 10.4208ZM16.6623 23.6673H18.995C20.2833 23.6673 21.3278 22.6227 21.3278 21.334V11.3789C23.1059 10.3491 24.104 8.18824 23.4739 6.02732L22.2036 1.67103C21.9726 0.878701 21.2465 0.333984 20.4213 0.333984H15.4959H8.49762H3.57217C2.74704 0.333984 2.02093 0.878701 1.7899 1.67102L0.519629 6.02732C-0.110487 8.18825 0.887631 10.3491 2.66576 11.3789V21.334C2.66576 22.6227 3.71016 23.6673 4.99851 23.6673H11.9968H16.6623ZM15.4959 21.334V16.6673C15.4959 16.023 14.9737 15.5007 14.3295 15.5007C13.6853 15.5007 13.1631 16.023 13.1631 16.6673V21.334H15.4959ZM10.8304 21.334V19.584H4.99851V21.334H10.8304ZM16.6623 7.33398V2.66732H20.0642L21.2345 6.68065C21.6699 8.17398 20.5502 9.66732 18.995 9.66732C17.7067 9.66732 16.6623 8.62265 16.6623 7.33398ZM9.664 2.66732H14.3295V7.33398C14.3295 8.62265 13.2851 9.66732 11.9968 9.66732C10.7084 9.66732 9.664 8.62265 9.664 7.33398V2.66732Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default MerchantsIcon;
