import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Partial<Components<Theme>> = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontFamily: 'Poppins, sans-serif',
        lineHeight: '100%',
        fontWeight: 500,
        fontSize: 12,
        margin: '7px 0 0 0',
      },
    },
  },
};
