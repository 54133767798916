import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';
import SeenNotificationIcon from '../../ui-kit/Icons/SeenNotificationIcon';
import { Link } from 'react-router-dom';
import { book } from '../../../routes/book';

type NewsItemProps = {
  image: string;
  date: string;
  title: string;
  excerptBody: string;
  isSeen: boolean;
  isChosen: boolean;
  slug: string;
};

export const NewsItem: React.FC<NewsItemProps> = ({
  image,
  excerptBody,
  isSeen,
  title,
  date,
  isChosen,
  slug,
}) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem sx={[styles.root, isChosen && styles.chosen]} disablePadding>
      <ListItemButton component={Link} to={`${book.news}/${slug}`}>
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <Box
            sx={styles.image}
            borderRadius={'12px'}
            overflow={'hidden'}
            height={isSM ? '50px' : '84px'}
            width={isSM ? '61px' : '84px'}
            flexShrink={0}>
            <Box component={'img'} alt={`img-${title}`} src={image} />
          </Box>
          <Box sx={styles.content} display={'flex'} flexDirection={'column'}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography
                variant={'Body/Table/Basic 13'}
                color={'common.news.text'}>
                {date}
              </Typography>
              {isSM && <SeenNotificationIcon seen={isSeen} fontSize={'14px'} />}
            </Box>
            <Typography variant={'Headlines/H7'}>{title}</Typography>
            <Typography variant={'Body/Table/Basic 14'}>
              {excerptBody}
            </Typography>
          </Box>
        </Box>
        {!isSM && <SeenNotificationIcon seen={isSeen} fontSize={'14px'} />}
      </ListItemButton>
    </ListItem>
  );
};
