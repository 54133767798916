import { Box, TextField } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderEmailField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={1}>
      <TextField
        {...input}
        id={'email'}
        fullWidth
        label={'Email'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};
