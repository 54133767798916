// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillNewsActionPayload,
  NewsState,
  PushMoreNewsActionPayload,
  UpdateNewsActionPayload,
  RemoveNewsActionPayload,
  NewsItemType,
} from './typedefs';
import { emptyPaginationState } from '../../setup/typedefs';

const initialState: NewsState = {
  isFetching: false,
  isInitialized: true,
  news: emptyPaginationState as any,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillNews(state, action: PayloadAction<FillNewsActionPayload>) {
      state.news = action.payload;
    },
    pushMoreNews(state, action: PayloadAction<PushMoreNewsActionPayload>) {
      state.news.count = action.payload.count;
      state.news.next = action.payload.next;
      state.news.results = [...state.news.results, ...action.payload.results];
    },
    updateNews(state, action: PayloadAction<UpdateNewsActionPayload>) {
      state.news.results = state.news.results.map((news) => {
        // @ts-ignore
        if (news.slug === action.payload) {
          return { ...news, is_read: true };
        } else {
          return news;
        }
      });
    },
    removeNews(state, action: PayloadAction<RemoveNewsActionPayload>) {
      state.news.count = state.news.count - 1;
      state.news.results = state.news.results.filter(
        (news) => news.slug !== action.payload,
      );
    },
    fillOneNews(state, action: PayloadAction<NewsItemType>) {
      state.news.results = [...state.news.results, action.payload];
    },
    clearNews(state) {
      state.news = emptyPaginationState as any;
    },
    // inject
  },
});

export default newsSlice;
