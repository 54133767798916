import { apply, call, put } from 'redux-saga/effects';
import { resourcesActions } from '../../actions';
import { api } from '../../../../REST/api';
import { ICategoryItem } from '../../typedefs';
import { WithPagination } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchResourcesCategories() {
  try {
    yield put(resourcesActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `resource-categories/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: WithPagination<ICategoryItem[]> = yield call([
      response,
      'json',
    ]);
    yield put(resourcesActions.fillResourcesCategories(data));
    for (let i = 0; i < data.results.length; i++) {
      yield put(
        resourcesActions.fetchResources({ categories: data.results[i].id }),
      );
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(resourcesActions.stopFetching());
  }
}
