import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const ThankYouSmallCheckIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      sx={{ fontSize: '20px', width: '20px', height: '16px' }}
      viewBox={'0 0 20 16'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6234 0.855701C20.1173 1.33804 20.1266 2.12944 19.6444 2.62334L7.4373 15.1234C7.19133 15.3752 6.85055 15.5116 6.49871 15.4992C6.14689 15.4867 5.81663 15.3265 5.5891 15.0579L0.29614 8.80788C-0.150023 8.281 -0.084623 7.49225 0.442202 7.04613C0.969015 6.6 1.75777 6.66537 2.20393 7.19212L6.6088 12.3935L17.8558 0.876663C18.338 0.382751 19.1295 0.373364 19.6234 0.855701Z"
          fill="#F13DD8"
        />
      </svg>
    </SvgIcon>
  );
};

export default ThankYouSmallCheckIcon;
