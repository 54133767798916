import { apply, call, put } from 'redux-saga/effects';
import { teamActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillTeamActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchTeam() {
  try {
    yield put(teamActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `users/team/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillTeamActionPayload = yield call([response, 'json']);
    yield put(teamActions.fillTeam(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(teamActions.stopFetching());
  }
}
