import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderCodeField } from './fields/RenderCodeField';
import { styles } from './styles';
import { RenderPasswordField } from './fields/RenderPasswordField';
import { RenderPasswordRepeatField } from './fields/RenderPasswordRepeatField';

export const EnterCodeForResetTransactionPasswordForm = (
  props: FormRenderProps,
) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        sx={styles.root}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        mt={1}>
        <Field name={'reset_code'} render={RenderCodeField} />
        <Field name={'password'} render={RenderPasswordField} />
        <Field name={'password_repeat'} render={RenderPasswordRepeatField} />
        <Box mt={2}>
          <Button
            sx={styles.button}
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Change Password
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default EnterCodeForResetTransactionPasswordForm;
