import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const CloseBurgerMenuIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon
      sx={{ fill: 'none' }}
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <rect
        width="24"
        height="24"
        rx="7"
        transform="matrix(-1 0 0 1 24 0)"
        fill={isLight ? 'none' : '#433D59'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2559 5.92259C19.5813 5.59715 19.5813 5.06952 19.2559 4.74408C18.9305 4.41864 18.4028 4.41864 18.0774 4.74408L12 10.8215L5.92259 4.74408C5.59715 4.41864 5.06952 4.41864 4.74407 4.74408C4.41864 5.06952 4.41864 5.59715 4.74407 5.92259L10.8215 12L4.74407 18.0774C4.41864 18.4028 4.41864 18.9305 4.74407 19.2559C5.06952 19.5813 5.59715 19.5813 5.92259 19.2559L12 13.1785L18.0774 19.2559C18.4028 19.5813 18.9305 19.5813 19.2559 19.2559C19.5813 18.9305 19.5813 18.4028 19.2559 18.0774L13.1785 12L19.2559 5.92259Z"
        fill="#040018"
      />
    </SvgIcon>
  );
};

export default CloseBurgerMenuIcon;
