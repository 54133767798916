import { all, call, takeEvery } from 'redux-saga/effects';
import { authActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  applyMembership,
  authenticate,
  confirmEmail,
  fetchProfile,
  forgotPassword,
  resetPassword,
  setPassword,
  signIn,
  signUp,
  updateProfile,
  postSupport,
  updatePaymentDetails,
  updateLoginPassword,
  fetchTwoFactorQrCode,
  postTwoFactor,
  twoFactorAuthSettings,
} from './workers';

// imports

function* watchSignIn() {
  yield takeEvery(authActions.signIn.type, signIn);
}

function* watchSignUp() {
  yield takeEvery(authActions.signUp.type, signUp);
}

function* watchConfirmEmail() {
  yield takeEvery(authActions.confirmEmail.type, confirmEmail);
}

function* watchForgotPassword() {
  yield takeEvery(authActions.forgotPassword.type, forgotPassword);
}

function* watchResetPassword() {
  yield takeEvery(authActions.resetPassword.type, resetPassword);
}

function* watchSetPassword() {
  yield takeEvery(authActions.setPassword.type, setPassword);
}

function* watchApplyMembership() {
  yield takeEvery(authActions.applyMembership.type, applyMembership);
}

function* watchAuthenticate() {
  yield takeEvery(authActions.authenticate.type, authenticate);
}
function* watchFetchProfile() {
  yield takeEvery(authActions.fetchProfile.type, fetchProfile);
}
function* watchUpdateProfile() {
  yield takeEvery(authActions.updateProfile.type, updateProfile);
}
function* watchPostSupport() {
  yield takeEvery(authActions.postSupport.type, postSupport);
}
function* watchUpdatePaymentDetails() {
  yield takeEvery(authActions.updatePaymentDetails.type, updatePaymentDetails);
}
function* watchUpdateLoginPassword() {
  yield takeEvery(authActions.updateLoginPassword.type, updateLoginPassword);
}
function* watchFetchTwoFactorQrCode() {
  yield takeEvery(authActions.fetchTwoFactorQrCode.type, fetchTwoFactorQrCode);
}
function* watchPostTwoFactor() {
  yield takeEvery(authActions.postTwoFactor.type, postTwoFactor);
}
function* watchTwoFactorAuthSettings() {
  yield takeEvery(
    authActions.twoFactorAuthSettings.type,
    twoFactorAuthSettings,
  );
}
// watchers
export function* watchAuth() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchTwoFactorAuthSettings),
    call(watchPostTwoFactor),
    call(watchFetchTwoFactorQrCode),
    call(watchUpdateLoginPassword),
    call(watchUpdatePaymentDetails),
    call(watchPostSupport),
    call(watchUpdateProfile),
    call(watchFetchProfile),
    call(watchAuthenticate),
    call(watchSignIn),
    call(watchSignUp),
    call(watchConfirmEmail),
    call(watchForgotPassword),
    call(watchResetPassword),
    call(watchSetPassword),
    call(watchApplyMembership),
  ]);
}
