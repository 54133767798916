import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const BalanceIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4997 11.1507C24.4997 9.40438 23.093 7.97135 21.3011 7.82557L20.5591 5.75787C19.9141 3.9604 17.8277 3.03824 15.9723 3.73052L4.82465 7.8898C4.7133 7.93135 4.61242 7.98741 4.52346 8.05482C3.23922 8.54919 2.33301 9.74903 2.33301 11.1507V21.1626C2.33301 23.0058 3.90002 24.5 5.83301 24.5H20.9997C22.9327 24.5 24.4997 23.0058 24.4997 21.1626V18.6402C25.1971 18.2554 25.6663 17.5364 25.6663 16.7128V14.488C25.6663 13.6645 25.1971 12.9454 24.4997 12.5608V11.1507ZM22.1663 12.2632V11.1507C22.1663 10.5363 21.644 10.0382 20.9997 10.0382H5.83301C5.18868 10.0382 4.66634 10.5363 4.66634 11.1507V21.1626C4.66634 21.777 5.18868 22.2752 5.83301 22.2752H20.9997C21.644 22.2752 22.1663 21.777 22.1663 21.1626V18.9378H20.9997C19.0666 18.9378 17.4997 17.4436 17.4997 15.6004C17.4997 13.7573 19.0666 12.2632 20.9997 12.2632H22.1663ZM18.3515 6.47822L18.8306 7.81335L11.4329 7.81336L16.8225 5.80243C17.441 5.57167 18.1364 5.87905 18.3515 6.47822ZM19.833 15.6004C19.833 14.9861 20.3553 14.488 20.9997 14.488H23.333V16.7128H20.9997C20.3553 16.7128 19.833 16.2148 19.833 15.6004Z"
        fill="url(#paint0_linear_14271_84408)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84408"
          x1="25.6663"
          y1="19.565"
          x2="3.53627"
          y2="12.4685"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default BalanceIconLT;
