import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderVerificationCodeField } from './fields/RenderVerificationCodeField';
import { styles } from './styles';

export const VerificationCodeForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
      <Box sx={styles.root}>
        <Field name={'verify_code'} render={RenderVerificationCodeField} />
        <Box my={1} mt={'53px'}>
          <Button
            fullWidth
            type={'submit'}
            variant={'primary'}
            color={'green'}
            role={'submit'}>
            Sign In
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </form>
  );
};
