import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0 18px 11px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px 11px 0',
    },
    '& > button': {
      padding: 0,
    },
  }),
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  list: (theme: Theme) => ({
    paddingRight: '29px',
    height: '255px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '11px',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: (theme: Theme) => theme.palette.common.mainColorFirst,
      borderRadius: '8px',
    },
  }),
  button: {
    justifyContent: 'space-between',
    margin: 0,
    height: 'initial',
    padding: '10px 0 10px 0',
    '&:after': {
      display: 'none',
    },
  },
  textWrapper: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 0 0 8px',
    gap: '5px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
    },
    '& .MuiListItemText-root': {
      margin: 0,
      '&:first-of-type': {
        '& span, & p': {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.02em',
        },
      },
      '&:last-child': {
        '& span, & p': {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '15px',
          letterSpacing: '-0.02em',
          color: (theme: Theme) => theme.palette.common.notifications.dateColor,
        },
      },
    },
  }),
  remaining: (theme: Theme) => ({
    boxSizing: 'border-box',
    padding: '20px 24px 20px 15px',
    maxWidth: '360px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.common.notifications.remainingBg,
    borderRadius: '16px',
    '&:before': {
      opacity: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px 20px 25px 10px',
    },
  }),
  decorImage: {
    width: '130px',
    height: '134px',
    margin: '0 15px',
    position: 'relative',
    bottom: '-20px',
    '& img': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  wrapper: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      marginRight: '15px',
    },
  }),
  circle: {
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    background: 'white',
  },
  remainingList: {
    maxWidth: '197px',
    width: '100%',
    padding: '0 11px',
    '& .MuiListItem-root': {
      '&:not(:last-child)': {
        padding: '4px 0 8px 0',
      },
      '&:last-child': {
        padding: 0,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: '13px',
      alignSelf: 'flex-start',
      padding: '8px 0 0 0',
    },
  },
  remainingText: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'white',
    letterSpacing: '-0.1px',
  },
  remainingCenter: {
    padding: '9px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  remainingRight: {
    maxWidth: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
    '& .MuiButton-root': {
      minWidth: 'inherit',
      minHeight: '40px',
      margin: '15px 0 0 0',
      '&:before, &:after': {
        padding: '2px',
      },
    },
  },
};
