import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import ThankYouCheckIcon from '../../../ui-kit/Icons/ThankYouCheckIcon';
import ThankYouSmallCheckIcon from '../../../ui-kit/Icons/ThankYouSmallCheckIcon';
import useIsLightTheme from '../../../../helpers/customHooks/useIsLightTheme';

type ThankYouProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-25, 66],
  }),
  (colors) => ({
    color: colors.primary,
    position: [120, 50],
    blur: 50,
  }),
];

export const ThankYou: React.FC<ThankYouProps> = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLightTheme = useIsLightTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box
        textAlign={'center'}
        sx={isLightTheme ? styles.checkLT : styles.check}>
        {isSM ? <ThankYouSmallCheckIcon /> : <ThankYouCheckIcon />}
      </Box>
      <Typography
        maxWidth={'275px'}
        width={'100%'}
        variant={'Headlines/H4'}
        textAlign={'center'}>
        Thank you, your request is being processed!
      </Typography>
      <Typography
        maxWidth={isSM ? '210px' : '100%'}
        variant={isSM ? 'Headlines/H8' : 'Body/Table/Basic 15'}
        color={'common.inversText'}
        textAlign={'center'}>
        Please note that withdrawals are made every Monday
      </Typography>
      <Button variant={'secondary'} color={'violet'} onClick={closeModal}>
        <Typography variant={'Button/Button 16'}>Close</Typography>
      </Button>
    </GeneralCardHolder>
  );
};
