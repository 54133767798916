import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const NewsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77745 5.25016C6.77745 4.92842 7.02577 4.66732 7.333 4.66732H18.2628C18.0228 5.19772 17.8885 5.79105 17.8885 6.41732V8.16732H20.1108H17.8885V15.1673V16.0747V20.5206C17.8885 22.0744 16.689 23.334 15.2092 23.334C13.7294 23.334 12.5298 22.0744 12.5298 20.5206V18.7251C12.5298 18.0489 12.0077 17.5007 11.3637 17.5007H6.77745V14.2674V5.25016ZM20.1108 16.0747V20.5206C20.1108 23.363 17.9163 25.6673 15.2092 25.6673C15.1763 25.6673 15.1435 25.667 15.1108 25.6663V25.6673H8.99968C5.36257 25.6673 2.33301 22.5742 2.33301 18.7251C2.33301 18.0489 2.85511 17.5007 3.49913 17.5007H4.55523V14.2674V5.25016C4.55523 3.6389 5.79929 2.33398 7.333 2.33398H21.7775C23.9252 2.33398 25.6663 4.16215 25.6663 6.41732V9.27623C25.6663 9.95246 25.1443 10.5007 24.5003 10.5007H20.1108V15.1673V16.0747ZM20.1108 8.16732V6.41732C20.1108 5.45082 20.857 4.66732 21.7775 4.66732C22.6979 4.66732 23.4441 5.45082 23.4441 6.41732V8.16732H20.1108ZM10.3075 20.5206C10.3075 21.5589 10.6004 22.5254 11.104 23.334H8.99968C6.92897 23.334 5.17035 21.8354 4.68682 19.834H5.66634H10.3075V20.5206ZM8.99968 11.6673C8.38602 11.6673 7.88856 12.1896 7.88856 12.834C7.88856 13.4783 8.38602 14.0007 8.99968 14.0007H15.6664C16.28 14.0007 16.7775 13.4783 16.7775 12.834C16.7775 12.1896 16.28 11.6673 15.6664 11.6673H8.99968ZM7.88856 8.16732C7.88856 7.52299 8.38602 7.00065 8.99968 7.00065H15.6664C16.28 7.00065 16.7775 7.52299 16.7775 8.16732C16.7775 8.81164 16.28 9.33398 15.6664 9.33398H8.99968C8.38602 9.33398 7.88856 8.81164 7.88856 8.16732Z"
        fill="url(#paint0_linear_14271_84468)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84468"
          x1="25.6663"
          y1="20.184"
          x2="3.13713"
          y2="13.6819"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default NewsIconLT;
