import { connect } from '@giantmachines/redux-websocket';
import { Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { authActions } from '../bus/auth/actions';
import { getCurrentUserProfile } from '../bus/auth/selectors';
import { optionsActions } from '../bus/options/actions';
import AppLayout from '../layouts/private/AppLayout';
import { privateRoutes } from '../pages/private/routes';
import { getAccessToken, removeQuotes } from '../REST/api';
import { book } from './book';
import SettingsLayout from '../layouts/private/SettingsLayout';
import { dashboardActions } from '../bus/dashboard/actions';
import { paymentActions } from '../bus/payment/actions';
import { Nullable } from '../setup/typedefs';
import { uiActions } from '../bus/ui/actions';
import { ThemeVariant } from '../bus/ui/typedefs';
import { useScrollToTop } from '../helpers/customHooks/useScroll';

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getCurrentUserProfile);
  useScrollToTop();

  useLayoutEffect(() => {
    const currentTheme: Nullable<string> = localStorage.getItem('currentTheme');
    currentTheme && dispatch(uiActions.fillTheme(currentTheme as ThemeVariant));
  }, []);

  const token = getAccessToken();

  useEffect(() => {
    dispatch(authActions.fetchProfile());
    dispatch(optionsActions.fetchActivities());
    dispatch(optionsActions.fetchCodes());
    dispatch(optionsActions.fetchCurrencies());
    dispatch(dashboardActions.fetchRanks());
    token &&
      dispatch(
        connect(
          `${process.env.REACT_APP_WS_API_URL}/?token=${removeQuotes(token)}`,
        ),
      );
    paymentCallBack();
  }, []);

  const paymentCallBack = () => {
    const waitPaymentCallback = localStorage.getItem('waitPaymentCallback');

    if (waitPaymentCallback) {
      const objFromParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
      );

      new Promise((resolve, reject) => {
        dispatch(
          paymentActions.fetchPaymentCallback({
            values: objFromParams,
            resolve,
            reject,
          }),
        );
      })
        .then(() => {
          localStorage.setItem('waitPaymentCallback', 'false');
        })
        .catch((errors) => {
          return errors;
        });
    }
  };

  return profile ? (
    <>
      <Routes>
        {privateRoutes
          .filter(({ forRole }) => !forRole || forRole === profile.role)
          .map(({ path, Component, Layout, layoutProps }) => (
            <Route element={<Layout {...layoutProps} />} key={path}>
              <Route path={path} element={Component} />
            </Route>
          ))}
        <Route element={<AppLayout title={'Dashboard'} />}>
          <Route
            path={'/'}
            element={<Navigate replace to={book.dashboard} />}
          />
        </Route>
        <Route element={<SettingsLayout title={'Settings'} />}>
          <Route
            path={book.settings}
            element={<Navigate replace to={book.settingsProfile} />}
          />
        </Route>
        <Route element={<AppLayout title={'404'} />}>
          <Route path={'/*'} element={<Typography>404</Typography>} />
        </Route>
      </Routes>
    </>
  ) : null;
};

export default PrivateRoutes;
