import React, { useEffect, useState } from 'react';
import FinalForm from '../../../FinalForm';
import { TransferForm } from '../../../../Forms/TransferForm';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceForWithdrawal } from '../../../../bus/balance/selectors';
import { schema } from '../../../../Forms/TransferForm/schema';
import { uiActions } from '../../../../bus/ui/actions';
import { transactionsActions } from '../../../../bus/transactions/actions';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type TransferProps = {
  id: number;
  currency: number;
  onSuccess: () => void;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-4, 73],
    size: 130,
    blur: 50,
  }),
  (colors) => ({
    color: colors.violet,
    position: [106, 25],
    blur: 40,
  }),
];

export const Transfer: React.FC<TransferProps> = ({
  id,
  currency,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const wallets = useSelector(getBalanceForWithdrawal);
  const [walletData, setWalletData] = useState<any>();
  const [currencyId, setCurrencyId] = useState<any>();

  const onSubmit = ({ values, ...rest }: OnFormSubmitPayload) => {
    const updatedValue = {
      transaction: {
        currency: values.currency,
        amount: values.amount,
      },
      to_user: values.wallet,
      password: values.password,
    };

    dispatch(
      transactionsActions.transaction({ values: updatedValue, ...rest }),
    );
  };

  useEffect(() => {
    if (id) {
      const tmp = wallets.find((item) => item.id === id);

      setWalletData(tmp);
    }

    if (currency) {
      setCurrencyId(currency);
    }
  }, []);

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      {walletData && (
        <FinalForm
          initialValues={{
            available: walletData.available,
            currency: currencyId,
          }}
          component={TransferForm}
          onSubmit={onSubmit}
          onSubmitSuccess={(showSnackbar) => {
            showSnackbar('Success');
            onSuccess();
            dispatch(uiActions.closeModal());
          }}
          schema={schema}
        />
      )}
    </GeneralCardHolder>
  );
};
