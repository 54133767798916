import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import FinalForm from '../../../components/FinalForm';
import { authActions } from '../../../bus/auth/actions';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Link as UiLink } from '../../../components/ui-kit/typography/Link';
import { book } from '../../../routes/book';
import { useNavigate } from 'react-router-dom';
import ApplyMembership from '../../../Forms/ApplyMembership';
import { schema } from '../../../Forms/ApplyMembership/schema';
import { EffectFunc } from '../../../components/ui-kit/GeneralCardHolder';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [5, 90],
    size: 100,
    blur: 70,
  }),
  (colors) => ({
    color: colors.violet,
    position: [110, 55],
    size: 200,
    blur: 80,
  }),
];

const ApplyMembershipPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmitSuccess = () => {
    navigate(book.almostReady);
  };

  return (
    <>
      <FormBackgroundHolder effects={effects}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant={'title'}>Apply for account</Typography>
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'h5'}
            color={theme.palette.grey['50']}>
            Fill out the form below
          </Typography>
          <Box mt={5}>
            <FinalForm
              component={ApplyMembership}
              sagaAction={authActions.applyMembership}
              initialValues={{}}
              schema={schema}
              onSubmitSuccess={onSubmitSuccess}
            />
          </Box>
        </Box>
      </FormBackgroundHolder>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={4}
        mb={10}>
        <Typography
          mb={1}
          sx={{ opacity: 0.5 }}
          variant={'h8'}
          color={theme.palette.grey['50']}>
          Already have an account?
        </Typography>
        <UiLink to={book.signIn}>Sign in</UiLink>
      </Box>
    </>
  );
};

export default ApplyMembershipPage;
