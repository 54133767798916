import { createAction } from '@reduxjs/toolkit';
import poolsSlice from './slice';
import { OnFormSubmitPayload } from '../../setup/typedefs';

export const poolsActions = {
  ...poolsSlice.actions,
  monthsEarnings: createAction('pools/monthsEarnings'),
  poolReinvest: createAction(
    'pools/poolReinvest',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  poolInvest: createAction(
    'pools/poolInvest',
    (payload: OnFormSubmitPayload) => {
      return { payload };
    },
  ),
  fetchYearStats: createAction('pools/fetchYearStats', (payload) => {
    return {
      payload,
    };
  }),
  fetchPoolsLevels: createAction('pools/fetchPoolsLevels'),
  fetchPoolYears: createAction('pools/fetchPoolYears'),
  fetchPools: createAction('pools/fetchPools', (payload) => {
    return {
      payload,
    };
  }),
};
