import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminResourcesIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9997 23.333C8.84501 23.333 4.66634 19.1544 4.66634 13.9997C4.66634 8.84501 8.84501 4.66634 13.9997 4.66634C19.1544 4.66634 23.333 8.84501 23.333 13.9997C23.333 19.1544 19.1544 23.333 13.9997 23.333ZM2.33301 13.9997C2.33301 7.55635 7.55635 2.33301 13.9997 2.33301C20.4429 2.33301 25.6663 7.55635 25.6663 13.9997C25.6663 20.4429 20.4429 25.6663 13.9997 25.6663C7.55635 25.6663 2.33301 20.4429 2.33301 13.9997ZM13.9997 17.4997C12.0666 17.4997 10.4997 15.9327 10.4997 13.9997C10.4997 12.0666 12.0666 10.4997 13.9997 10.4997C15.9327 10.4997 17.4997 12.0666 17.4997 13.9997C17.4997 15.9327 15.9327 17.4997 13.9997 17.4997ZM8.16634 13.9997C8.16634 10.778 10.778 8.16634 13.9997 8.16634C17.2213 8.16634 19.833 10.778 19.833 13.9997C19.833 17.2213 17.2213 19.833 13.9997 19.833C10.778 19.833 8.16634 17.2213 8.16634 13.9997Z"
        fill="url(#paint0_linear_14271_87592)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87592"
          x1="25.6663"
          y1="20.183"
          x2="3.13713"
          y2="13.6809"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AdminResourcesIconLT;
