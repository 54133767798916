import { createAction } from '@reduxjs/toolkit';
import ventureSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const ventureActions = {
  ...ventureSlice.actions,
  fetchVenture: createAction('venture/fetchVenture', ({ order, ...value }) => {
    return {
      payload: {
        query: getEndpointParams2(
          {
            ...value,
            ordering: order === 'desc' ? '-' : '' + value.ordering,
          },
          new URLSearchParams(),
        ),
      },
    };
  }),
};
