import {
  Button,
  ClickAwayListener,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import InformationIcon from '../Icons/InformationIcon';

type HoverInfoProps = {
  size?: string;
  information: React.ReactElement;
  placement?: any;
  arrow?: boolean;
  open?: boolean;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  startIcon?: React.ReactElement;
  handleClickOpen?: (click: boolean) => void;
};

export const HoverInfo: React.FC<HoverInfoProps> = ({
  size = '20px',
  information,
  placement = 'bottom',
  arrow = false,
  startIcon,
  disableFocusListener = false,
  disableHoverListener = false,
  disableTouchListener = false,
  open,
  handleClickOpen,
}) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isSM ? (
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleClose}
              open={isOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={information}>
              <Button
                onClick={handleToggle}
                sx={{ minWidth: size, padding: 0 }}
                startIcon={
                  startIcon ? startIcon : <InformationIcon size={size} />
                }
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      ) : (
        <Tooltip
          title={information}
          placement={placement}
          arrow={arrow}
          open={open}
          onClose={() => handleClickOpen && handleClickOpen(false)}
          disableFocusListener={disableFocusListener}
          disableHoverListener={disableHoverListener}
          disableTouchListener={disableTouchListener}>
          {startIcon ? (
            <Button
              onClick={() => handleClickOpen && handleClickOpen(true)}
              sx={{ minWidth: size, padding: 0 }}
              startIcon={startIcon}
            />
          ) : (
            <Button
              sx={{ minWidth: size, padding: 0 }}
              startIcon={<InformationIcon size={size} />}
            />
          )}
        </Tooltip>
      )}
    </>
  );
};
