// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FaqState,
  FAQItemType,
  FillFaqActionPayload,
  ReplaceFaqActionPayload,
  RemoveFaqActionPayload,
  PushFaqActionPayload,
} from './typedefs';

const initialState: FaqState = {
  isFetching: false,
  isInitialized: true,
  faqs: [],
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillFaqs(state, action: PayloadAction<FAQItemType[]>) {
      state.faqs = action.payload;
    },
    fillFaq(state, action: PayloadAction<FillFaqActionPayload>) {
      state.faqs = state.faqs.some((f) => f.id === action.payload.id)
        ? state.faqs.map((f) =>
            f.id === action.payload.id ? action.payload : f,
          )
        : [action.payload, ...state.faqs];
    },
    replaceFaq(state, action: PayloadAction<ReplaceFaqActionPayload>) {
      state.faqs = state.faqs.map((f) =>
        f.id === action.payload.id ? action.payload : f,
      );
    },
    removeFaq(state, action: PayloadAction<RemoveFaqActionPayload>) {
      state.faqs = state.faqs.filter((f) => f.slug !== action.payload);
    },
    pushFaq(state, action: PayloadAction<PushFaqActionPayload>) {
      state.faqs = [...state.faqs, action.payload];
    },
    // inject
  },
});

export default faqSlice;
