import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          top: 0,
          height: 'initial',
        },
      },
    },
  },
  paper: (theme: Theme) => ({
    '& .PrivatePickersSlideTransition-root': {
      marginTop: '12px',
      height: '280px',
      overflow: 'hidden',
      '& > div': {
        '& > div': {
          justifyContent: 'space-between',
          padding: '0 8px',
          margin: 0,
          '&:not(:last-child)': {
            margin: '0 0 7px 0',
          },
          '& .Mui-selected': {
            background: theme.palette.common.mainColorFirst,
            color: theme.palette.common.white,
          },
        },
      },
    },
    '& .MuiPaper-root': {
      background: theme.palette.common.calendarPopup.bg,
      backgroundImage: 'none',
      borderRadius: '16px',
      position: 'relative',
      width: '415px',
      height: '412px',
      '&:before': {
        ...theme.mixins.cardBefore,
      },
      [`@media (max-width: 568px)`]: {
        width: '100vw',
      },
      '& > div:first-of-type': {
        '& > div:first-of-type': {
          width: '100%',
          maxHeight: '412px',
        },
      },
      '& .css-cvc5nv': {
        display: 'block!important',
      },
      '& .PrivatePickersFadeTransitionGroup-root': {
        '& > div': {
          color: (theme: Theme) => theme.palette.common.inversText,
          overflow: 'auto!important',
          '& ::-webkit-scrollbar': {
            width: '5px',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: (theme: Theme) =>
              theme.palette.common.mainColorFirst,
            borderRadius: '8px',
          },
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          textAlign: 'center',
          letterSpacing: '-0.02em',
          '& > div:first-of-type': {
            justifyContent: 'space-between',
            height: '330px',
            padding: '0 8px',
            '& > span': {
              width: '40px',
              height: '40px',
              fontFamily: 'Work Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '21px',
              textAlign: 'center',
              letterSpacing: '-0.02em',
              color: theme.palette.common.white,
            },
          },
          '& .PrivatePickersSlideTransition-root': {
            marginTop: '0',
            height: '280px',
            overflow: 'hidden',
            '& > div': {
              '& > div': {
                justifyContent: 'space-between',
                padding: '0 8px',
                margin: 0,
                '&:not(:last-child)': {
                  margin: '0 0 7px 0',
                },
                '& .Mui-selected': {
                  background: theme.palette.common.mainColorFirst,
                  color: theme.palette.common.white,
                },
              },
            },
          },
        },
      },
      '& .MuiPickersDay-root': {
        background: 'none',
      },
      '& .MuiButtonBase-root': {
        width: '40px',
        height: '40px',
        fontFamily: 'Work Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
        letterSpacing: '-0.02em',
      },
      '& .MuiCalendarPicker-root': {
        width: '100%',
        maxHeight: 'initial',
        '& > div:last-child': {
          '& > div:first-of-type': {
            justifyContent: 'space-between',
            padding: '0 8px',
            '& > span': {
              width: '40px',
              height: '40px',
              fontFamily: 'Work Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '21px',
              textAlign: 'center',
              letterSpacing: '-0.02em',
              color: theme.palette.common.white,
            },
          },
        },
        '& > div:first-of-type': {
          marginTop: '19px',
          marginBottom: '30px',
          '& > div:first-of-type': {
            margin: '0 auto',
            position: 'relative',
            zIndex: 2,
            '& > .MuiButtonBase-root': {
              display: 'none',
            },
          },
        },
      },
      '& .MuiPickersArrowSwitcher-root': {
        position: 'absolute',
        width: 'calc(100% - 27px)',
        justifyContent: 'space-between',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 0,
      },
      '& .PrivatePickersYear-root': {
        flexBasis: '27%',
        '& .Mui-disabled': {
          display: 'none',
        },
        '& > button': {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          letterSpacing: '-0.02em',
          color: theme.palette.common.calendarPopup.text,
        },
      },
      '& .MuiMonthPicker-root': {
        width: 'initial',
        '& > .MuiTypography-root': {
          fontFamily: 'Work Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          letterSpacing: '-0.02em',
          color: theme.palette.common.calendarPopup.text,
        },
        '& > .Mui-selected': {
          color: theme.palette.common.white,
        },
      },
    },
  }),
};
