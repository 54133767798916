import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import DeleteIcon from '../../../ui-kit/Icons/DeleteIcon';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type ConfirmDeleteProps = {
  text: string;
  onConfirm: () => void;
  confirmButton?: {
    text?: string;
    icon?: any;
    disableIcon?: boolean;
  };
  cancelButton?: {
    text?: string;
    icon?: any;
  };
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-25, 66],
  }),
  (colors) => ({
    color: colors.primary,
    position: [120, 50],
    blur: 50,
  }),
];

export const ConfirmOrCancel: React.FC<ConfirmDeleteProps> = ({
  onConfirm,
  text,
  confirmButton,
  cancelButton,
}) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  return (
    // @ts-ignore
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <Box textAlign={'center'} mt={'15px'}>
        <Typography variant={'Headlines/H4'}>{text}</Typography>
      </Box>
      <Box display={'flex'} gap={'10px'}>
        <Button variant={'secondary'} onClick={closeModal}>
          <Typography variant={'h4'}>
            {cancelButton?.text || 'Cancel'}
          </Typography>
        </Button>
        <Button
          onClick={onConfirm}
          startIcon={
            confirmButton?.disableIcon
              ? ''
              : confirmButton?.icon || <DeleteIcon />
          }
          variant={'secondary'}
          color={'pink'}>
          <Typography variant={'h4'}>
            {confirmButton?.text || 'Delete'}
          </Typography>
        </Button>
      </Box>
    </GeneralCardHolder>
  );
};
