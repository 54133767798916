// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  ChangeTodoActionPayload,
  CreateTodoActionPayload,
  FillTodoActionPayload,
  PushMoreTodoActionPayload,
  RemoveTodoActionPayload,
  TodoState,
  UpdateAllTodoActionPayload,
} from './typedefs';

const initialState: TodoState = {
  isFetching: false,
  isInitialized: true,
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const todoSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    fillTodo(state, action: PayloadAction<FillTodoActionPayload>) {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.results = action.payload.results;
    },
    pushMoreTodo(state, action: PayloadAction<PushMoreTodoActionPayload>) {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.results = [...state.results, ...action.payload.results];
    },
    changeTodo(state, action: PayloadAction<ChangeTodoActionPayload>) {
      state.results = state.results.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );
    },
    removeTodo(state, action: PayloadAction<RemoveTodoActionPayload>) {
      state.count = state.count - 1;
      state.results = state.results.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    createNewTodo(state, action: PayloadAction<CreateTodoActionPayload>) {
      state.results = [action.payload, ...state.results];
    },
    removeAllTodo(state) {
      state.count = 0;
      state.results = [];
    },
    updateAllTodo(state, action: PayloadAction<UpdateAllTodoActionPayload>) {
      const ids = action.payload.ids.split(',');
      state.results = state.results.map((item) =>
        ids.some((id) => +id === item.id)
          ? {
              ...item,
              done: action.payload.done,
            }
          : item,
      );
    },
    // inject
  },
});

export default todoSlice;
