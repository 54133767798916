import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import FinalForm from '../../../components/FinalForm';
import { authActions } from '../../../bus/auth/actions';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Link as UiLink } from '../../../components/ui-kit/typography/Link';
import { book } from '../../../routes/book';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { schema } from '../../../Forms/SetPassword/schema';
import SetPassword from '../../../Forms/SetPassword';
import { OnFormSubmitPayload } from '../../../setup/typedefs';

const SetPasswordPage = () => {
  const dispatch = useDispatch();
  const { user_id, reset_token } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmit = ({ values, resolve, reject }: OnFormSubmitPayload) => {
    user_id &&
      reset_token &&
      dispatch(
        authActions.setPassword(
          { values, params: { user_id, reset_token } },
          resolve,
          reject,
        ),
      );
  };

  return (
    <>
      <FormBackgroundHolder>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant={'title'}>Set Password</Typography>
          <Box mt={4}>
            <FinalForm
              onSubmitSuccess={() => navigate(book.signIn)}
              component={SetPassword}
              onSubmit={onSubmit}
              initialValues={{}}
              schema={schema}
            />
          </Box>
        </Box>
      </FormBackgroundHolder>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={4}
        mb={10}>
        <Typography
          mb={1}
          sx={{ opacity: 0.5 }}
          variant={'h8'}
          color={theme.palette.grey['50']}>
          Don`t have an account?
        </Typography>
        <UiLink to={book.signUp}>Sign Up</UiLink>
      </Box>
    </>
  );
};

export default SetPasswordPage;
