import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Box,
} from '@mui/material';
import React from 'react';
import { AvatarVariants } from '../../../themes/dark/typetefs';

export interface AvatarProps extends MuiAvatarProps {
  icon?: string;
  svgIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
  variant: AvatarVariants;
}

export const Avatar: React.FC<AvatarProps> = ({
  icon,
  variant,
  svgIcon,
  children,
}) => {
  return (
    <MuiAvatar variant={variant}>
      {icon && (
        <Box
          sx={{ objectFit: 'contain' }}
          width={'100%'}
          height={'100%'}
          component="img"
          alt="avatar"
          src={icon}
        />
      )}
      {svgIcon && React.createElement(svgIcon)}
      {children && children}
    </MuiAvatar>
  );
};
