import { all, call, takeEvery } from 'redux-saga/effects';
import { optionsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchCountries,
  fetchCurrencies,
  fetchActivities,
  fetchCities,
  fetchCodes,
  fetchRoyaltyCategories,
} from './workers';
// imports

function* watchFetchCountries() {
  yield takeEvery(optionsActions.fetchCountries.type, fetchCountries);
}

function* watchFetchCities() {
  yield takeEvery(optionsActions.fetchCities.type, fetchCities);
}

function* watchFetchCurrencies() {
  yield takeEvery(optionsActions.fetchCurrencies.type, fetchCurrencies);
}

function* watchFetchActivities() {
  yield takeEvery(optionsActions.fetchActivities.type, fetchActivities);
}
function* watchFetchCodes() {
  yield takeEvery(optionsActions.fetchCodes.type, fetchCodes);
}
function* watchFetchRoyaltyCategories() {
  yield takeEvery(
    optionsActions.fetchRoyaltyCategories.type,
    fetchRoyaltyCategories,
  );
}
// watchers
export function* watchOptions() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchRoyaltyCategories),
    call(watchFetchCodes),
    call(watchFetchActivities),
    call(watchFetchCountries),
    call(watchFetchCities),
    call(watchFetchCurrencies),
  ]);
}
