import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import RenderAmountField from './fields/RenderAmountField';
import RenderPromotionField from './fields/RenderPromotionField';
import RenderDescriptionField from './fields/RenderDescriptionField';
import RenderCurrencyField from './fields/RenderCurrencyField';
import { RenderDirectDepositField } from './fields/RenderDirectDepositField';
import { styles } from './styles';
import RateBlock from './RateBlock';
import ResultBlock from './ResultBlock';

const UserSendForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box mt={'30px'} sx={styles.root}>
        <Box mt={2.5} my={1.5} sx={styles.wrapper} overflow={'hidden'}>
          <Field name={'amount'} render={RenderAmountField} type={'number'} />
          <Field name={'currency'} render={RenderCurrencyField} />
        </Box>
        {props.values.currency && <RateBlock value={props.values.currency} />}
        <Field
          name={'promotion'}
          render={RenderPromotionField}
          type={'number'}
        />
        <Field name={'description'} render={RenderDescriptionField} />
        {props.values.currency && props.values.amount && (
          <ResultBlock
            currency={props.values.currency}
            amount={props.values.amount}
            promotion={props.values.promotion}
          />
        )}
        <Box mt={'30px'} sx={styles.checkbox}>
          <Field name={'is_direct'} render={RenderDirectDepositField} />
        </Box>
        <Box maxWidth={'400px'} width={'100%'} mt={'20px'}>
          <Typography variant={'Input/Input 15'} color={'common.inversText'}>
            Direct deposit ignored by royalty fee calculation and won’t be
            automatically invested into pool.
          </Typography>
        </Box>
        <Box mt={'30px'}>
          <Button
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            sx={styles.button}
            role={'submit'}>
            Send
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default UserSendForm;
