import { apply, call, put, select } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { ProfilePayment } from '../../typedefs';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { throwError } from '../../../ui/saga/workers/throwError';
import { getPaymentId } from '../../selectors';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* updatePaymentDetails({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<Partial<ProfilePayment>>) {
  try {
    yield put(authActions.startFetching());
    const { wallet_address } = payload;

    const body: string = yield call(JSON.stringify, { wallet_address });

    const id: number = yield select(getPaymentId);

    const response: Response = yield apply(api, api.patch, [
      {
        endpoint: `payments/${id}/`,
        body,
      },
    ]);

    const data: ServerFormErrors | ProfilePayment = yield call([
      response,
      'json',
    ]);

    if (!response.ok) {
      const formErrors: FormErrors = yield call(
        getServerFormErrors,
        data as ServerFormErrors,
      );
      reject(formErrors);
      yield throwError(response);
    }

    yield put(authActions.changePaymentDetails(data as ProfilePayment));

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(authActions.stopFetching());
  }
}
