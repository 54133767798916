import { Theme } from '@mui/material';

export const styles = {
  root: {},
  buttonGroup: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px',
    '& > button': {
      minWidth: '103px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
        padding: '12px 0',
      },
    },
  }),
  focus: (theme: Theme) => ({
    '&:focus': {
      background: theme.palette.common.percentage.background,
      '&:before': {
        background: theme.palette.common.percentage.backgroundBefore,
        opacity: 1,
      },
      '&:after': {
        background: theme.palette.common.percentage.backgroundAfter,
        opacity: 0,
      },
    },
    background: theme.palette.common.percentage.background,
    '&:before': {
      background: theme.palette.common.percentage.backgroundBefore,
      opacity: 1,
    },
    '&:after': {
      background: theme.palette.common.percentage.backgroundAfter,
      opacity: 0,
    },
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
  }),
  button: (theme: Theme) => ({
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'initial',
      width: '100%',
    },
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  }),
  wrapper: {
    position: 'relative',
    '& > div:last-child': {
      position: 'absolute',
      right: 0,
      maxWidth: '60px',
      width: '100%',
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root': {
        background: 'unset',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '& .MuiSelect-outlined': {
        paddingTop: '16px',
        pointerEvents: 'none',
        paddingRight: 0,
        paddingLeft: 0,
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0!important',
        },
      },
    },
  },
};
