import { createAction } from '@reduxjs/toolkit';
import paymentSlice from './slice';

export const paymentActions = {
  ...paymentSlice.actions,
  fetchPaymentCallback: createAction(
    'payment/fetchPaymentCallback',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchAdvcash: createAction(
    'payment/fetchAdvcash',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchMercurio: createAction(
    'payment/fetchMercurio',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
};
