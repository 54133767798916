import React from 'react';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../../bus/auth/actions';
import { SupportForm } from '../../../../Forms/SupportForm';
import { schema } from '../../../../Forms/SupportForm/schema';
import FinalForm from '../../../FinalForm';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type SupportProps = {};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-10, 73],
    blur: 40,
  }),
  (colors) => ({
    color: colors.violet,
    position: [103, 30],
    size: 110,
    blur: 35,
  }),
];

export const Support: React.FC<SupportProps> = () => {
  const dispatch = useDispatch();

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    dispatch(authActions.postSupport(payload));
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={SupportForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
