import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticate, getIsInitialize } from '../bus/auth/selectors';
import { authActions } from '../bus/auth/actions';
import { Box, CircularProgress } from '@mui/material';
import { optionsActions } from '../bus/options/actions';
import { useSnackbar } from 'notistack';
import { parseCookies } from 'nookies';
import { HistoryRouter } from './HistoryRouter';
import { history } from '../setup/configureStore';

const Routers = () => {
  const isInitialised = useSelector(getIsInitialize);
  const isAuthenticated = useSelector(getIsAuthenticate);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const [cookies, setCookies] = useState(true);

  const checkCookies = () => {
    const cookies = parseCookies();
    const agree_all =
      'cookie_policy_agree_all' in cookies ||
      cookies['cookie_policy_agree_all'] === 'true';
    setCookies(agree_all);
  };

  useEffect(() => {
    checkCookies();
    if (!cookies) {
      setTimeout(() => {
        enqueueSnackbar('', {
          variant: 'cookieBannerSnackbar',
          persist: true,
        });
      }, 3000);
    }
  }, [cookies]);

  useEffect(() => {
    dispatch(authActions.authenticate());
    dispatch(optionsActions.fetchCountries());
    //TODO: next line drop app
    // dispatch(optionsActions.fetchCities());
  }, [dispatch]);

  return isInitialised ? (
    <HistoryRouter history={history}>
      <>
        <Suspense fallback={<>...loading</>}>
          <Routes>
            {isAuthenticated ? (
              <Route path={`/*`} element={<PrivateRoutes />} />
            ) : (
              <Route path={`/*`} element={<PublicRoutes />} />
            )}
          </Routes>
        </Suspense>
      </>
    </HistoryRouter>
  ) : (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      <CircularProgress />
    </Box>
  );
};

export default Routers;
