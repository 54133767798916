import { apply, call, put } from 'redux-saga/effects';
import { usersActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { FORM_ERROR } from 'final-form';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* activateUser({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    const { password, userId } = payload;

    yield put(usersActions.startFetching());
    const body: string = yield call(JSON.stringify, { password });

    const response: Response = yield apply(api, api.post, [
      { endpoint: `users/${userId}/activate/`, body },
    ]);

    if (response.status !== 200) {
      reject({
        [FORM_ERROR]: 'wrong data',
      });
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(usersActions.stopFetching());
  }
}
