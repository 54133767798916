import { Components, Theme } from '@mui/material';

export const MuiDialog: Partial<Components<Theme>> = {
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiCalendarPicker-root': {
          '& .PrivatePickersYear-root': {
            '& .Mui-selected': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.common.mainColorFirst,
            },
          },
          '& .PrivatePickersMonth-root.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.mainColorFirst,
          },
          '& .MuiPickersDay-root.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.mainColorFirst,
          },
        },
      }),
    },
  },
};
