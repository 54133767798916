import { Components } from '@mui/material';
import { experimental_sx as sx } from '@mui/material/styles';

export const MuiChip: Partial<Components> = {
  MuiChip: {
    styleOverrides: {
      root: {
        fontFamily: 'Poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '24px',
        borderRadius: '16px',
        width: '90px',
        height: '24px',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
        border: `1px solid`,
      },
    },
    variants: [
      {
        props: { color: 'primary', variant: 'outlined' },
        style: sx({
          borderColor: 'common.buttons.systemGreen',
        }),
      },
      {
        props: { color: 'error', variant: 'outlined' },
        style: sx({
          borderColor: 'common.failChip',
        }),
      },
      {
        props: { color: 'secondary', variant: 'outlined' },
        style: sx({
          borderColor: `common.pendingChip`,
        }),
      },
      {
        props: { color: 'info', variant: 'outlined' },
        style: sx({
          borderColor: `common.declineChip`,
        }),
      },
      {
        props: { variant: 'filled' },
        style: sx({
          border: 0,
        }),
      },
    ],
  },
};
