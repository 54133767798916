import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderPasswordField } from './fields/RenderPasswordField';
import { RenderRepeatPasswordField } from './fields/RenderRepeatPasswordField';

export const SetPassword = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box width={420} display={'flex'} flexDirection={'column'}>
        <Field name={'password'} render={RenderPasswordField} />
        <Field name={'password_repeat'} render={RenderRepeatPasswordField} />
        <Box my={1}>
          <Button
            fullWidth
            type={'submit'}
            disabled={props.pristine}
            variant={'primary'}
            color={'green'}
            role={'submit'}>
            Set Password
          </Button>
          {props.hasSubmitErrors && (
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {props.submitError}
            </FormHelperText>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default SetPassword;
