import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Box } from '@mui/material';
import { Select } from '../../../components/ui-kit/inputs/Select';

export const RenderSubjectField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const list = [
    {
      id: 'Problem',
      label: 'Problem',
    },
    {
      id: 'Solution',
      label: 'Solution',
    },
  ];

  return (
    <Box my={1}>
      <Select
        {...input}
        testId={'subject-support'}
        fullWidth
        options={list}
        label={'Subject'}
        error={(!!meta.error || !!meta.submitError) && meta.touched}
        helperText={meta.touched && (meta.error || meta.submitError)}
      />
    </Box>
  );
};
