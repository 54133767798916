import bg from './bg.svg';
import bg2 from './bg2.svg';
import { Theme } from '@mui/material';

export const styles = {
  root: {
    height: '108px',
    width: '100%',
    mixBlendMode: 'normal',
    borderRadius: '16px',
    backgroundColor: (theme: Theme) => theme.palette.common.shadeOfBlue,
    backdropFilter: 'blur(130px)',
    overflow: 'hidden',
    '& img': {
      marginTop: '-16px',
      marginRight: '10px',
    },
  },
  bg: {
    width: '100%',
    height: '100%',
    mixBlendMode: 'normal',
    backgroundImage: `url(${bg2})`,
    backdropFilter: 'blur(130px)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  bg2: {
    width: '100%',
    height: '100%',
    mixBlendMode: 'normal',
    backdropFilter: 'blur(130px)',
    backgroundImage: `url(${bg})`,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5,
  },
};
