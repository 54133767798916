import { apply, put } from 'redux-saga/effects';
import { usersActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* deleteUser({ payload }: PayloadActionWithPromiseMeta<any>) {
  try {
    const { id } = payload;

    yield put(usersActions.startFetching());

    const response: Response = yield apply(api, api.delete, [
      { endpoint: `users/${id}/` },
    ]);

    if (response.status !== 200) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(usersActions.stopFetching());
  }
}
