import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const RoyaltyFeesIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66634 5.18401C4.66634 4.86947 4.92708 4.6142 5.24967 4.6142H17.8926C17.6406 5.13275 17.4997 5.71282 17.4997 6.3251V22.9663C17.4997 23.1977 17.3079 23.3852 17.0713 23.3852H5.24967C4.92751 23.3852 4.66634 23.1298 4.66634 22.8149V13.9997V5.18401ZM20.9997 2.33301V2.37343C21.1902 2.3468 21.3849 2.33301 21.583 2.33301C23.8382 2.33301 25.6663 4.12033 25.6663 6.3251V9.12011C25.6663 9.78124 25.1181 10.3172 24.4419 10.3172H19.833V22.9663C19.833 24.4576 18.5966 25.6663 17.0713 25.6663H5.24967C3.63885 25.6663 2.33301 24.3897 2.33301 22.8149V13.9997V5.18401C2.33301 3.60876 3.63928 2.33301 5.24967 2.33301H20.9997ZM23.333 6.3251V8.03599H19.833V6.3251C19.833 5.38019 20.6165 4.6142 21.583 4.6142C22.5495 4.6142 23.333 5.38019 23.333 6.3251ZM8.79694 18.1572C8.34132 18.6026 7.60263 18.6026 7.14701 18.1572C6.69141 17.7118 6.69141 16.9896 7.14701 16.5442L13.3691 10.4611C13.8247 10.0157 14.5634 10.0157 15.019 10.4611C15.4747 10.9066 15.4747 11.6287 15.019 12.0742L8.79694 18.1572ZM6.41634 11.2676C6.41634 10.4277 7.11277 9.74688 7.97187 9.74688C8.83096 9.74688 9.52739 10.4277 9.52739 11.2676C9.52739 12.1076 8.83096 12.7884 7.97187 12.7884C7.11277 12.7884 6.41634 12.1076 6.41634 11.2676ZM14.194 15.8299C13.335 15.8299 12.6385 16.5108 12.6385 17.3507C12.6385 18.1906 13.335 18.8714 14.194 18.8714C15.0532 18.8714 15.7496 18.1906 15.7496 17.3507C15.7496 16.5108 15.0532 15.8299 14.194 15.8299Z"
        fill="url(#paint0_linear_14271_87579)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_87579"
          x1="25.6663"
          y1="20.183"
          x2="3.13713"
          y2="13.6809"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default RoyaltyFeesIconLT;
