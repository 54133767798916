import { all, call, takeEvery } from 'redux-saga/effects';
import { teamActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchTeam,
  fetchTeamUser,
} from './workers';

// imports
function* watchFetchTeam() {
  yield takeEvery(teamActions.fetchTeam.type, fetchTeam);
}
function* watchFetchTeamUser() {
  yield takeEvery(teamActions.fetchTeamUser.type, fetchTeamUser);
}
// watchers
export function* watchTeam() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchTeamUser),
    call(watchFetchTeam),
  ]);
}
