import React from 'react';
import {
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import { RenderNameField } from './field/RenderNameField';
import { RenderCountryField } from './field/RenderCountryField';
import { RenderAddressField } from './field/RenderAddressField';
import { RenderPostalCodeField } from './field/RenderPostalCodeField';
import { RenderCompanyUrlField } from './field/RenderCompanyURLField';
import { RenderActivityField } from './field/RenderActivityField';
import { RenderCityField } from './field/RenderCityField';
import { useDispatch, useSelector } from 'react-redux';
import { getCountriesOptions } from '../../bus/options/selectors';
import { RenderMerchantIdField } from './field/RenderMerchantIdField';
import { styles } from './styles';
import DeleteIcon from '../../components/ui-kit/Icons/DeleteIcon';
import { merchantsActions } from '../../bus/merchants/actions';
import { useSnackbar } from 'notistack';
import { ShowSnackbar } from '../../components/FinalForm';
import { uiActions } from '../../bus/ui/actions';
import { TableFilterKeys } from '../../bus/ui/typedefs';
import { Order } from '../../components/ui-kit/Table/tableTypes';

const EditMerchantForm = (props: FormRenderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const countries = useSelector(getCountriesOptions);
  const themes = useTheme();
  const isSM = useMediaQuery(themes.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const showSnackbar: ShowSnackbar = (
    message = 'Merchant deleted successfully',
    variant = 'infoSnackbar',
  ) => {
    enqueueSnackbar(message, { variant });
  };

  const defaultFilter = {
    [TableFilterKeys.activities]: [],
    [TableFilterKeys.country]: [],
    search: '',
    page_size: 10,
    page: 1,
    ordering: 'name',
    order: Order.asc,
    my_own: false,
    status: null,
  };

  const deleteUser = (id: number) => {
    return new Promise((resolve, reject) => {
      dispatch(
        merchantsActions.deleteMerchant({ values: id, resolve, reject }),
      );
    });
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'880px'}
        width={'100%'}
        mt={'33px'}>
        <Box display={'flex'} sx={styles.content}>
          <Box sx={styles.wrapper} display={'flex'} flexDirection={'column'}>
            <Typography variant={'Body/Form UPPERCASE 14'}>
              General Info
            </Typography>
            <Box sx={{ opacity: 0 }}>
              <Field
                name={'merchantId'}
                render={RenderMerchantIdField}
                type={'hidden'}
              />
            </Box>
            <Field name={'name'} render={RenderNameField} />
            <Box sx={styles.country}>
              <Field
                name={'country'}
                render={RenderCountryField}
                parse={(value) => value?.code || ''}
                format={(value) =>
                  countries.find(
                    (option: { code: any }) => option.code === value,
                  )?.label || ''
                }
              />
              <Field
                name={'city'}
                render={RenderCityField}
                parse={(value) => value?.label || ''}
              />
            </Box>
            <Box sx={styles.address}>
              <Field name={'address'} render={RenderAddressField} />
              <Field name={'postal_code'} render={RenderPostalCodeField} />
            </Box>
            <Typography variant={'Body/Form UPPERCASE 14'}>Website</Typography>
            <Field name={'url'} render={RenderCompanyUrlField} />
          </Box>
          <Box sx={styles.wrapper} display={'flex'} flexDirection={'column'}>
            <Typography variant={'Body/Form UPPERCASE 14'}>
              Field of activity
            </Typography>
            <Field
              name={'activities'}
              render={RenderActivityField}
              type={'checkbox'}
              initialValues={props.values.activities}
            />
          </Box>
        </Box>
        <Box
          display={'flex'}
          gap={2}
          mt={'42px'}
          justifyContent={'space-between'}>
          <Button
            sx={styles.button}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            type={'submit'}>
            <Typography
              variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
              Save Merchant
            </Typography>
          </Button>
          <Button
            sx={styles.button}
            variant={'secondary'}
            color={'pink'}
            onClick={() =>
              deleteUser(props?.values?.id).then(() => {
                dispatch(merchantsActions.fetchMerchants(defaultFilter));
                showSnackbar();
                dispatch(uiActions.closeModal());
              })
            }
            startIcon={<DeleteIcon />}>
            <Typography
              sx={{ marginLeft: '10px' }}
              variant={isSM ? 'Button/Button 14' : 'Button/Button 16'}>
              Delete Merchant
            </Typography>
          </Button>
        </Box>
        {props.hasSubmitErrors && (
          <FormHelperText error sx={{ textAlign: 'center' }}>
            {props.submitError}
          </FormHelperText>
        )}
      </Box>
    </form>
  );
};

export default EditMerchantForm;
