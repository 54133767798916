/* eslint-disable prettier/prettier */
import { appRoutes } from '../../layouts/private/AppLayout/layoutRoutes';
import { settingsRoutes } from '../../layouts/private/SettingsLayout/layoutRoutes';
import { newsRoutes } from '../../layouts/private/NewsLayout/layoutRoutes';
// inject:import
import { AppRoute } from '../../setup/typedefs';
import { commonRoutes } from '../common/routes';

export const privateRoutes: AppRoute<any>[] = [
  ...appRoutes,
  ...settingsRoutes,
  ...newsRoutes,
  ...commonRoutes,
];
