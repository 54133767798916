import React from 'react';
import FinalForm from '../../../FinalForm';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { usersActions } from '../../../../bus/users/actions';
import { getModalData } from '../../../../bus/ui/selectors';
import UserDeleteForm from '../../../../Forms/UserDeleteForm';
import { schema } from '../../../../Forms/UserDeleteForm/schema';
import { styles } from './styles';

export const UserDelete = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);

  const onSuccessHandler = () => {
    dispatch(uiActions.closeModal());
  };

  return (
    <Box sx={styles.root}>
      <FinalForm
        initialValues={{ userId: modalData?.modalPayload.userId.id }}
        component={UserDeleteForm}
        onSubmit={(payload) => dispatch(usersActions.deleteUser(payload))}
        onSubmitSuccess={onSuccessHandler}
        schema={schema}
      />
    </Box>
  );
};
