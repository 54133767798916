import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    background: 'none',
    backdropFilter: 'none',
    padding: '90px 40px 30px 40px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '60px 24px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 20px',
      width: 'calc(100% - 40px)',
    },
    '& > div:nth-of-type(2)': {
      width: '100%',
      '& > div': {
        width: '100%',
        '& button': {
          width: '50%',
          maxWidth: 'initial',
        },
      },
    },
  }),
  available: {
    '& span:nth-of-type(2)': {
      margin: '12px 0 10px 0',
    },
  },
  subtitle: {
    '& span': {
      width: '100%',
    },
  },
  buyButton: {
    marginTop: '4px',
    minWidth: 'initial',
    height: '39px',
    maxWidth: '140px',
    width: '100%',
  },
};
