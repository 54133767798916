import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const Convert: React.FC<SVGProps<SVGSVGElement>> = ({ color = 'white' }) => {
  return (
    <SvgIcon
      sx={{ width: '24px', height: '24px' }}
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.39179 12.285C6.78668 11.8989 7.41981 11.906 7.80592 12.3009C8.19203 12.6958 8.18492 13.3289 7.79003 13.715L5.4531 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H5.4531L7.79003 20.285C8.18492 20.6711 8.19203 21.3042 7.80592 21.6991C7.41981 22.094 6.78668 22.1011 6.39179 21.715L2.30088 17.715C2.10847 17.5269 2 17.2691 2 17C2 16.7309 2.10847 16.4731 2.30088 16.285L6.39179 12.285ZM17.6082 2.28499C17.2133 1.89888 16.5802 1.906 16.1941 2.30088C15.808 2.69577 15.8151 3.3289 16.21 3.71501L18.5469 6H3C2.44772 6 2 6.44771 2 7C2 7.55228 2.44772 8 3 8H18.5469L16.21 10.285C15.8151 10.6711 15.808 11.3042 16.1941 11.6991C16.5802 12.094 17.2133 12.1011 17.6082 11.715L21.6991 7.71501C21.8915 7.52687 22 7.26911 22 7C22 6.73089 21.8915 6.47313 21.6991 6.28499L17.6082 2.28499Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Convert;
