import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const DashboardIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 23.3301C12.25 24.6209 11.2053 25.6673 9.91667 25.6673H7C5.06701 25.6673 3.5 24.0978 3.5 22.1616V12.2998C3.5 11.3176 3.9114 10.3804 4.63406 9.71639L11.6649 3.25629C12.9641 2.06252 14.9471 2.02309 16.2926 3.16429L23.2617 9.0751C24.0471 9.74121 24.5 10.7198 24.5 11.7505V22.1616C24.5 24.0978 22.9329 25.6673 21 25.6673H18.0833C16.7946 25.6673 15.75 24.6209 15.75 23.3301V17.5301H12.25V23.3301ZM14.7848 4.94791C14.3362 4.56752 13.6753 4.58066 13.2422 4.97858L6.21136 11.4387C5.97046 11.66 5.83333 11.9724 5.83333 12.2998V22.1616C5.83333 22.807 6.35567 23.3301 7 23.3301H9.91667V16.3615C9.91667 15.7161 10.439 15.1929 11.0833 15.1929H16.9167C17.561 15.1929 18.0833 15.7161 18.0833 16.3615V23.3301H21C21.6443 23.3301 22.1667 22.807 22.1667 22.1616V11.7505C22.1667 11.4069 22.0157 11.0808 21.7539 10.8587L14.7848 4.94791Z"
        fill="url(#paint0_linear_14271_84394)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84394"
          x1="24.5"
          y1="20.184"
          x2="3.92311"
          y2="14.8392"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default DashboardIconLT;
