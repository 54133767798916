import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '76px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '25px 8px 19px 8px',
    border: `2.82266px solid ${theme.palette.common.countdown.border}`,
    borderRadius: '16.936px',
    background: theme.palette.common.countdown.background,
    '& span': {
      color: theme.palette.common.inversText,
    },
  }),
};
