import { configureStore, Middleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import logger from 'redux-logger';
import reduxWebsocket from '@giantmachines/redux-websocket';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

const sagaMiddleware = createSagaMiddleware();

const reduxWebsocketMiddleware = reduxWebsocket({
  reconnectOnClose: true,
  reconnectInterval: 5500,
});

process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    dsn:
      process.env.REACT_SENTRY_DSN ||
      'https://2504fe5c65cd47cda8f93393c977e814@sentry.evne.top/20',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

const middleware: Middleware[] = [sagaMiddleware, reduxWebsocketMiddleware];
process.env.IS_STORYBOOK && middleware.push(logger);

export const history = createBrowserHistory();

export const Store = (initialState: any) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const createStore = (initialState = {}) => Store(initialState);
