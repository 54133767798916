import { apply, call, put, select } from 'redux-saga/effects';
import { todoActions } from '../../actions';
import { getTodoIds } from '../../selectors';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* bulkUpdateTodo({ payload }: any) {
  try {
    yield put(todoActions.startFetching());
    const { ids }: { ids: string } = yield select(getTodoIds);
    const body: string = yield call(JSON.stringify, payload);
    const response: Response = yield apply(api, api.put, [
      { endpoint: `todo/bulk/`, query: `ids=${ids}`, body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    yield put(todoActions.fetchTodo());
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(todoActions.stopFetching());
  }
}
