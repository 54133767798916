import { all, call, takeEvery } from 'redux-saga/effects';
import { notificationsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchNotifications,
  setSeen,
  loadMoreNotifications,
} from './workers';

// imports
function* watchFetchNotifications() {
  yield takeEvery(
    notificationsActions.fetchNotifications.type,
    fetchNotifications,
  );
}
function* watchSetSeen() {
  yield takeEvery(notificationsActions.setSeen.type, setSeen);
}
function* watchLoadMoreNotifications() {
  yield takeEvery(
    notificationsActions.loadMoreNotifications.type,
    loadMoreNotifications,
  );
}
// watchers
export function* watchNotifications() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchLoadMoreNotifications),
    call(watchSetSeen),
    call(watchFetchNotifications),
  ]);
}
