import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    borderRadius: '16px',
    boxSizing: 'border-box',
    background: theme.palette.common.tabs.background,
    backdropFilter: 'blur(65px)',
    mixBlendMode: 'normal',
    position: 'relative',
    overflow: 'hidden',
    padding: '45px 40px 40px',
    '@media (max-width: 768px)': {
      padding: '40px 20px 35px',
    },
    '@media (max-width: 568px)': {
      padding: '35px 20px',
    },
    '@media (max-width: 475px)': {
      width: 'calc(100% - 40px)',
    },
  }),
  decorate1: {
    pointerEvents: 'none',
    opacity: 0.8,
    position: 'absolute',
    display: 'block',
    width: '200px',
    height: '200px',
    bottom: '0px',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    background: (theme: Theme) =>
      `radial-gradient(circle, ${theme.palette.common.slateGreen} 0%, ${theme.palette.common.slateGreen} 0%, ${theme.palette.common.mainBg0} 70%)`,
  },
  subDecorate1: {
    pointerEvents: 'none',
    opacity: 0.7,
    position: 'absolute',
    display: 'block',
    width: '400px',
    height: '400px',
    bottom: '-50px',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    background: (theme: Theme) =>
      `radial-gradient(circle, ${theme.palette.common.slateGreen} 0%, ${theme.palette.common.slateGreen} 0%, ${theme.palette.common.mainBg0} 70%)`,
  },
  decorate2: {
    pointerEvents: 'none',
    opacity: 0.6,
    position: 'absolute',
    display: 'block',
    width: '500px',
    height: '500px',
    top: 0,
    right: 0,
    transform: 'translate(60%, -60%)',
    background: (theme: Theme) =>
      `radial-gradient(circle, ${theme.palette.common.cyanBlue} 0%,  ${theme.palette.common.cyanBlue} 0%, ${theme.palette.common.mainBg0} 75%)`,
  },
  decorate3: {
    pointerEvents: 'none',
    opacity: 0.7,
    position: 'absolute',
    display: 'block',
    width: '450px',
    height: '450px',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: (theme: Theme) =>
      `radial-gradient(circle, ${theme.palette.common.slateGreen} 0%, ${theme.palette.common.slateGreen} 0%, ${theme.palette.common.mainBg0} 70%)`,
  },
};
