import { Box, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { Field, FormRenderProps } from 'react-final-form';
import { RenderPercentField } from './fields/RenderPercentField';
import { RenderAmountField } from './fields/RenderAmountField';
import { Button } from '../../components/ui-kit/Button';

export const PoolsReinvestWithdrawForm = (props: FormRenderProps) => {
  const [percent, setPercent] = useState<number | string>(0);

  useEffect(() => {
    if (props.values.percent) {
      const val = +props.values.available;
      const fixedVal = Number((val / 100) * +props.values.percent).toFixed(2);
      setPercent(fixedVal);
    }
  }, [props.values]);

  return (
    <form onSubmit={props.handleSubmit}>
      <Box sx={styles.root} width={'100%'}>
        <Field
          name={'amount'}
          valuePercent={percent}
          render={RenderAmountField}
          type={'number'}
        />
        <Field
          name={'percent'}
          render={RenderPercentField}
          tab={props.values.switcher}
        />
        <Box mt={'30px'}>
          <Button
            sx={styles.button}
            fullWidth
            type={'submit'}
            disabled={props.pristine}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            {props.values.switcher}
          </Button>
          {props.hasSubmitErrors && (
            <Box mx={2}>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
