import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

type SeenNotificationIconProps = {
  seen?: boolean;
  fontSize?: string;
};

const SeenNotificationIcon: React.FC<SeenNotificationIconProps> = ({
  seen = false,
  fontSize = '10px',
}) => {
  const theme = useTheme();
  const props = {
    color: seen
      ? theme.palette.common.news.newsStatusSeen
      : theme.palette.common.news.newsStatusNew,
    opacity: seen ? 0.5 : 1,
  };

  return (
    <SvgIcon
      sx={{ fontSize: fontSize, width: '10px', height: '10px' }}
      viewBox={'0 0 10 10'}
      width={'10'}
      height={'10'}>
      <circle
        cx="5"
        cy="5"
        r="5"
        fill={props.color}
        fillOpacity={props.opacity}
      />
    </SvgIcon>
  );
};

export default SeenNotificationIcon;
