import { apply, call, put } from 'redux-saga/effects';
import { transactionsActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchListWorkers, WithPagination } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import { IWithdrawFeeItem } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchWithdrawFee({
  payload,
}: PayloadAction<FetchListWorkers>) {
  try {
    yield put(transactionsActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `transactions/`, query: payload.query },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: WithPagination<IWithdrawFeeItem[]> = yield call([
      response,
      'json',
    ]);

    yield put(transactionsActions.fillWithdrawFee(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(transactionsActions.stopFetching());
  }
}
