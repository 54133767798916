import { Box } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';
import { DatePicker } from '../../../components/ui-kit/inputs/DatePicker';
import moment from 'moment/moment';

export const RenderDateOfBirthField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={1}>
      <DatePicker
        maxDate={moment().subtract(18, 'years')}
        defaultValue={moment().subtract(18, 'years')}
        label={'Date of birth'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        data-testid={'apply-date-of-birth'}
        {...input}
      />
    </Box>
  );
};
