import { apply, call, put } from 'redux-saga/effects';
import { newsActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { api } from '../../../../REST/api';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { NewsItemType } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* editNews({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(newsActions.startFetching());
    const body: string = yield call(JSON.stringify, payload);
    const response: Response = yield apply(api, api.patch, [
      {
        endpoint: `news/${payload.slug}/`,
        body,
      },
    ]);

    const data: ServerFormErrors | NewsItemType = yield call([
      response,
      'json',
    ]);

    if (!response.ok) {
      const formErrors: FormErrors = yield call(
        getServerFormErrors,
        data as ServerFormErrors,
      );
      reject(formErrors);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(newsActions.stopFetching());
  }
}
