import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    width: '447px',
    boxSizing: 'content-box',
    minHeight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
};
