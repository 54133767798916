import { PaletteOptions } from '@mui/material/styles/createPalette';
import { PaletteMode } from '@mui/material';

export const commonColors = {
  white: '#ffffff',
  effects: {
    primary: '#9B24AE',
    secondary: '#37E6FF',
    tertiary: 'linear-gradient(74.23deg, #0035BE 0.49%, #DB28DF 82.74%)',
    pinkAndPurple: 'linear-gradient(91.91deg, #2E3DF9 13.57%, #F11EAA 90.37%)',
    green: 'linear-gradient(76.75deg, #01A4FF 15.01%, #27FF97 90.12%)',
    violet: 'linear-gradient(74.3deg, #0041E4 -27.49%, #DB28DF 79.89%)',
  },
  birchBlack: '#000000',
  trueBlack: '#000000',
  inversText: '#000000',
  inversText05: 'rgba(0,0,0,0.5)',
  mainShadow: '0px 2px 24px rgba(0, 0, 0, 0.2)',
  buttons: {
    violet: 'linear-gradient(74.3deg, #0041E4 -27.49%, #DB28DF 79.89%)',
    standardColor: '#070119',
    violetHover: 'linear-gradient(0deg, #DB28DF, #DB28DF)',
    violetPressed: 'linear-gradient(66.43deg, #3F3AE3 -8.12%, #DB28DF 126.94%)',
    green: 'linear-gradient(76.75deg, #01A4FF 15.01%, #27FF97 90.12%)',
    whiteOpacity:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0))',
    birchOpacity:
      'linear-gradient(to bottom, rgba(55, 228, 255, 0.7), rgba(55, 228, 255, 0.1))',
    activePoolBg: 'rgba(55, 230, 255, 0.1)',
    todoBackground: 'rgba(116, 107, 155, 0.1)',
    todoBackground05: 'rgba(116, 107, 155, 0.05)',
    todoBackground1: '#181630',
    birchBackground: 'rgba(87, 147, 181, 0.2)',
    greenHover: 'linear-gradient(0deg, #37E6FF, #37E6FF)',
    greenPressed: 'linear-gradient(66.43deg, #01A4FF 26.9%, #27FF97 200.08%)',
    pink: '#F13DD8',
    white: 'rgba(255, 255, 255, 0.14)',
    whiteHover: 'rgba(0, 0, 0, 1)',
    whitePressed: 'rgba(141, 125, 125, 1)',
    disabled: '#070119',
    textDisabled: 'rgba(255,255,255,0.6)',
    systemGreen: '#94FF9E',
    systemGreenHover: '#35FF49',
    systemGreenFocus: '#00FF66',
    systemPink: '#F13DD8',
    systemPinkHover: '#FF76EC',
    systemPinkFocus: '#FF73EC',
    systemBlue: '#37E6FF',
    systemBlueHover: '#37FFCF',
    systemBlueFocus: '#00FFC2',
    squareDark: '#28224A',
    squareDarkHover: '#342A6D',
    backBackground: 'rgba(255, 255, 255, 0.1)',
    hoverHeader: 'rgba(255, 255, 255, 0.08)',
  },
  percentage: {
    background: 'linear-gradient(286.1deg, #2D88EE 5.26%, #A351BF 86.43%)',
    backgroundBefore: 'rgba(255, 255, 255, 0.1)',
    backgroundAfter: 'rgba(255, 255, 255, 0.1)',
  },
  generalCardHolder: {
    background: '#FFFFFF',
    borderShadow: '0px 2px 24px rgba(0, 0, 0, 0.07)',
    border: 'none',
  },
  apexChart: {
    name: '#040C3F',
    value: '#040C3F',
    radialbarHollowBg: 'rgba(116, 107, 155, 0.1)',
    tabBackground: '#F5F7FA',
    activeTabBackground: '#CBC7E7',
    labelColor: '#7A778F',
    activeLabelColor: '#4B5876',
    chartColorFirst: '#5275EF',
    chartColorSecond: '#FF78D9',
  },
  earningPools: {
    mainText: '#5275EF',
    chartValue: '#040C3F',
    chartSubText: '#7A778F',
    chartFillingColor: '#376BFF',
  },
  ventureCapitalFund: {
    remainingTimeBackground: '#FFFFFF',
    text: '#040018',
    checkBox: '#5275EF',
  },
  countdown: {
    border: '#70A2FF',
    background: '#FFFFFF',
    dataFirst: '#5275EF',
    dataSecond: '#FF78D9',
  },
  recentActivity: {
    buttonText: '#070119',
  },
  map: {
    filledCountries: '#FF78D9',
    datalessRegionColor: '#ecebef',
  },
  todo: {
    button: '#040018',
    todoText: '#040018',
    todoTextDone: '#A8A9B1',
  },
  calendar: {
    monthYear: '#040018',
    days: '#040018',
    dateRegular: '#040018',
    dateOutsideMonth: '#7A778F',
    dateSelectedBackground: '#5275EF',
    dateSelected: '#FFFFFF',
  },
  calendarPopup: {
    bg: '#ffffff',
    shadow: '0px 2px 24px rgba(0, 0, 0, 0.5)',
    mainColor: '#5275EF',
    text: 'rgba(0,0,0,0.5)',
  },
  tooltipPopup: {
    text: '#7A778F',
    bg: '#FFFFFF',
    shadow: '0px 2px 24px rgba(0, 0, 0, 0.5)',
    green: '#3bc963',
    red: '#f13d89',
  },
  switch: {
    switchedPink: 'linear-gradient(102.8deg, #F13DD8 5.91%, #FE5FE8 83.27%)',
    defaultSwitch:
      'linear-gradient(90deg, rgba(117, 121, 231, 0.71) 0%, rgba(255, 255, 255, 0.22) 100.48%)',
  },
  inputs: {
    gray: 'rgba(116, 107, 155, 0.05)',
    defaultBorder: '#C4CCE3',
    selectListsBackground: '#16132D',
    iconColor: '#5275EF',
    mainColor: '#5275EF',
  },
  navbar: {
    denseActiveBg: 'rgba(116, 107, 155, 0.1)',
    hover:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 86.21%)',
    selected:
      'linear-gradient(90deg, rgba(219, 214, 255, 0.6) 0%, rgba(247, 245, 255, 0) 47.41%)',
  },
  header: {
    background: 'linear-gradient(286.1deg, #2D88EE 5.26%, #A351BF 86.43%)',
    iconColor: '#FFFFFF',
    themeSwitcherBackground: 'rgba(255, 255, 255, 0.15)',
    themeSwitcherIconRegular: '#C6D7FF',
    themeSwitcherIconActive: '#FFFFFF',
    themeSwitcherIconActiveBackground: 'rgba(255, 255, 255, 0.24)',
    dropdownBackground: 'rgba(255, 255, 255, 1)',
    dropdownIconColor: '#040018',
    titleColor: '#FFFFFF',
  },
  sidebar: {
    background: '#FFFFFF',
    border: '#FFFFFF',
    itemIcon: '#2D88EE',
    itemColor: '#040C3F',
    collapseButton: '#FFFFFF',
    collapseButtonBorder: '#EDEDED',
  },
  settings: {
    settingTabsText: 'rgba(4, 0, 24, 0.5)',
    settingTabsTextActive: 'rgba(4, 0, 24, 1)',
    companyBg: 'rgba(255, 255, 255, 1)',
  },
  wallet: {
    buttonText: '#040018',
    diagramBackground: '#E4E4F2',
    firstColorVariant: '#FF78D9',
    secondColorVariant: '#5275EF',
    comparePositive: '#3BC963',
    compareNegative: '#EB5757',
  },
  table: {
    amountPositive: '#3BC963',
    amountNegative: '#EB5757',
    statusCellPaid: '#94fc9e',
    statusCellAproved: '#94fc9e',
    statusCellPending: '#FFA722',
    statusCellLeader: '#FFEE94',
    statusCellFail: '#EB5757',
    statusCellDecline: '#9097AC',
    statusCellCanceled: '#9097AC',
    statusCellComplete: '#3BC963',
    statusCellDef: '#838395',
    buttonIncreaseDecrease: '#040C3F',
    buttonIncreaseDecreaseBorder: '#5275EF',
    linkText: '#5275EF',
    filterText: '#000000',
  },
  controlTopBlock: {
    button: '#4B5876',
    regularTab: '#7A778F',
    activeTab: '#A351BF',
  },
  teamPage: {
    statisticsBackground: '#FFFFFF',
    statisticsShadow: '0px 2px 24px rgba(0, 0, 0, 0.07)',
    primeContentColor: '#5275EF',
    subContentColor: '#7A778F',
    circularBorder: '#C4CCE3',
    titleColor: '#4B5876',
    zoomButton: 'rgba(116, 107, 155, 0.1)',
    zoomButtonIcon: 'rgba(0, 0, 0, 0.3)',
    userModal: {
      idBackground: 'rgba(0, 0, 0, 0.1)',
      mainFirstColor: '#5275EF',
      mainSecondColor: '#FF78D9',
      subText: '#7A778F',
      inverseText: 'rgba(0, 0, 0, 1)',
      inverseText05: 'rgba(0, 0, 0, 0.5)',
      red: '#FF41D5',
      green: '#20F0A9',
    },
  },
  poolsPage: {
    mainColor: '#5275EF',
    textColor: '#4B5876',
    dropDown: '#5275EF',
    dropDownBackground: '#FFFFFF',
    dropDownListBackground: '#FFFFFF',
    timeDateBlockBackground: '#FFFFFF',
    timeDateBlockShadow: '0px 2px 24px rgba(0, 0, 0, 0.07)',
    timeDateBlockText: '#040C3F',
    contentText: '#4B5876',
    amountText: '#5275EF',
    buttonText: '#040C3F',
    additionalText: '#6f81ab',
    levelChartBackground: '#F5F7FA',
    buttonPoolsSwiper: '#E4E4F2',
    buttonPoolsSwiperHover: '#5275EF',
    earningInformationText: '#4B5876',
    earningInformationAmount: '#5275EF',
    earningChartTextColor: '#7A778F',
    earningChartAxis: 'rgba(0, 0, 0, 0.2)',
    earningChartGrid: 'rgba(0, 0, 0, 0.2)',
    borderChart: 'linear-gradient(74.3deg, #0041E4 -27.49%, #DB28DF 79.89%)',
  },
  ventureCapital: {
    shadow: '0px 2px 24px rgba(0, 0, 0, 0.07)',
    background: '#FFFFFF',
    subText: '#4B5876',
    conditionsIcon: '#5275EF',
    sliderBackground: '#FFFFFF',
    buttonSwiper: '#5275EF',
    buttonSwiperBg: '#E4E4F2',
    sliderItemText: '#7A778F',
  },
  products: {
    buttonColor: '#4B5876',
  },
  news: {
    text: '#4B5876',
    link: '#5275EF',
    cardBg: '#FFFFFF',
    cardShadow: '0px 2px 24px rgba(0, 0, 0, 0.07)',
    cardChoosen: 'rgba(116, 107, 155, 0)',
    cardChoosenBefore: 'rgba(55, 230, 255, 0)',
    cardChoosenBorder: '#5275EF',
    newsStatusNew: '#FF78D9',
    newsStatusSeen: '#E4E4F2',
  },
  resources: {
    cardBg: '#FFFFFF',
    cardShadow: '0px 2px 24px rgba(0, 0, 0, 0.07)',
    dateColor: '#4B5876',
    buttonText: '#FFFFFF',
    buttonIcon: '#FFFFFF',
  },
  faq: {
    icon: '#5275EF',
  },
  adminWithdrawalsFeePage: {
    titleText: '#4B5876',
    mainColor: '#FF78D9',
    deductButton: '#1e1731',
  },
  adminRoyaltyFeePage: {
    titleText: '#4B5876',
    mainColor: '#FF78D9',
    deductButton: '#1e1731',
  },
  adminFaq: {
    button: '#1e1731',
  },
  tabs: {
    background: 'rgba(68, 72, 107, 0.15)',
    border:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
    indicator: '#3C335D',
  },
  notifications: {
    dateColor: '#A8A9B1',
    background: '#FFFFFF',
    border: 'rgba(255, 255, 255, 0.12)',
    remainingBg: '#1E1659',
    remainingTextReg: '#FFFFFF',
    markAsRead: '#5275EF',
    whiteText: '#FFFFFF',
  },
  modals: {
    subText: '#4B5876',
    subText05: '#4B5876',
    mainColor: '#FF78D9',
  },
  neonPink: '#F13DD8',
  levelColor: {
    novice: '#50FFC3',
    casual: '#F7E11B',
    active: '#428EFF',
    heavy: '#FFA722',
    absolute: '#FF78D9',
  },
  achievements: {
    sliderTitle: '#FFFFFF',
    backgroundOfLevel: 'rgba(255,255,255,0.3)',
    levelTextColor: '#FFFFFF',
    levelProgress: '#5275EF',
    levelNumber: 'linear-gradient(286.1deg, #2D88EE 5.26%, #A351BF 86.43%)',
    arrowColor: '#FFFFFF',
    progressBG: '#706b9e',
    activeSlideBackground: 'rgba(255,255,255,1)',
    activeSlideBackgroundDecorate: 'rgba(255,255,255,0)',
    activeSlideBorderDecorate: '#5275EF',
    sliderTitleBackground: 'rgba(255,255,255,0.4)',
    sliderTitleBorder: 'none',
    sliderWrapperBackground: 'rgba(255,255,255,0.4)',
    borderRadius: '16px 16px 0 0',
    titleOffset: '0px',
    titlePadding: '0px 20px',
  },
  mainColorFirst: '#5275EF',
  red: '#FF6565',
  chosenDate: '#F13DD8',
  birch: '#37E6FF',
  birchOpacity: 'rgba(55, 230, 255, 0.1)',
  birchOpacity30: 'rgba(55, 230, 255, 0.3)',
  birchOpacity50: 'rgba(55, 230, 255, 0.5)',
  hoverInfoBackground: 'rgba(255, 255, 255, 0.8)',
  pink: '#EA3AD9',
  lavender: '#DB28DF',
  razzleDazzleRose: '#FF25BE',
  failChip: '#FF7171',
  pendingChip: '#FFEE94',
  profileBackground: '#16132D',
  backgroundDark: '#1E1659',
  rank: '#3BAAE2',
  rankShadow: '#4BD5F8',
  purple200: '#C1B5E3',
  line: '#514F61',
  gray100: '#7A778F',
  gray50: '#FAFAFA',
  gray90: '#A0A5B0',
  declineChip: 'rgba(255, 255, 255, 0.5)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white09: 'rgba(255, 255, 255, 0.09)',
  white05: 'rgba(255, 255, 255, 0.05)',
  white03: 'rgba(255, 255, 255, 0.03)',
  white0: 'rgba(255, 255, 255, 0)',
  pink02: 'rgba(181, 87, 171, 0.2)',
  pink07: 'rgba(251, 63, 215, 0.7)',
  backgroundFuturePools: 'rgba(68, 72, 107, 0.03)',
  notActiveCard: 'rgba(56, 56, 62, 0.7)',
  currentPoolChartShareholder: '#22213C',
  shareholderName: 'rgba(8, 4, 28, 0.6)',
  shareholderNumber: 'rgba(8, 4, 28, 0.8)',
  gray70: '#7E7DA2',
  darkLink: 'linear-gradient(286.1deg, #2D88EE 5.26%, #A351BF 86.43%)',
  poolBackgroundDefault:
    'linear-gradient(180deg, rgba(68, 72, 107, 0.15) 0%, rgba(68, 72, 107, 0) 100%)',
  avatarSquareBackground: '#F5F7FA',
  loaderGreen: '#51BB25',
  lightGreen: '#20F0A9',
  green: '#65FF74',
  bananaYellow: '#F8FF94',
  darknessGreen: '#33cf4d',
  grayBackground: 'rgba(19, 19, 19, 0.59)',
  grayAwards: 'rgba(60, 51, 93, 0.3)',
  blackOpacity15: 'rgba(0, 0, 0, 0.15)',
  filterTab: 'linear-gradient(74.23deg, #0035BE 0.49%, #DB28DF 82.74%)',
  filterCrossClose: 'rgba(255, 255, 255, 0.5)',
  rangeDate: '#E4E4F2',
  lightPink: '#FF41D5',
  borderColor: '#2F2B45',
  aquamarine: '#5AF1FF',
  greenAquamarine: '#50FFC3',
  yellowOrange: '#FFB342',
  cornflowerblue: '#5A9FFF',
  regentGray: '#78909C',
  richBlack: '#0a072a',
  blueberry: '#7366FF',
  shadeOfBlue: '#A351BF',
  shadeOfGreen: '#2eca45',
  blueMagenta: '#8f8e9a',
  blueMagentaBg30: 'rgba(61, 52, 93, 0.3)',
  paleGreen: '#94fc9e',
  manatee: '#838395',
  darkByzantineBlue: '#3e3c52',
  byzantineBlue: '#3D345D',
  rhythm: '#706A87',
  mulberryMauveBlack: '#433D59',
  mildBlueYonder: '#A3AED0',
  hipsterSalmon: '#fd7c6e',
  tristesse: '#0c0c1f',
  forgottenPurple: '#9878f8',
  scarabaeusSacer: '#414040',
  throat: '#201937',
  whiteSmoke: '#f5f5f5',
  blueDacnis: '#46d1eb',
  purple: '#a26cf8',
  vanillaIce: '#E4E4F2',
  blueBell01: 'rgba(185, 178, 215, 0.1)',
  brightTurquoise06: 'rgba(65, 255, 232, 0.6)',
  lightYellow06: 'rgba(255, 199, 134, 0.6)',
  mainBg0: 'rgba(22, 12, 47, 0)',
  mainBg09: 'rgba(22, 12, 47, 0.9)',
  mainBg1: 'rgba(22, 12, 47, 1)',
  diagramColor01: 'rgba(236, 226, 254, .1)',
  slateGreen: 'rgba(92,36,140,1)',
  cyanBlue: 'rgba(36,76,123,1)',
  gray80: '#9097AC',
  gray60: '#3B3665',
};

export const palette: PaletteOptions = {
  mode: 'light' as PaletteMode,
  primary: {
    main: '#37E6FF',
  },
  secondary: {
    main: '#65FF74',
  },
  text: {
    primary: '#040018',
  },
  error: {
    main: '#F13D89',
  },
  background: {
    default: '#F5F7FA',
  },
  common: commonColors,
};
