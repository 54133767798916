import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextField } from '@mui/material';
import { getFieldError } from '../../../helpers/getFieldError';

const RenderAmountField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <TextField
      {...input}
      fullWidth
      label={'Amount'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
    />
  );
};

export default RenderAmountField;
