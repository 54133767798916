import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& > div:nth-of-type(2)': {
      marginTop: '20px',
    },
    '& button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
      },
    }),
  },
  button: {
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  },
};
