import { Components, Theme } from '@mui/material';

export const MuiSwitch: Partial<Components<Theme>> = {
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 44,
        height: 24,
        padding: 0,
      },
      switchBase: ({ theme }) => ({
        padding: 0,
        margin: '2px',
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(20px)',
          color: theme.palette.common.white,
          '& + .MuiSwitch-track': {
            background: theme.palette.common.switch.switchedPink,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.3,
        },
      }),
      thumb: {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
        '&.Mui-focusVisible': {
          color: (theme: Theme) => theme.palette.common.darknessGreen,
          border: (theme: Theme) => `6px solid ${theme.palette.common.white}`,
        },
        '&.Mui-disabled': {
          color: (theme: Theme) => theme.palette.common.shadeOfGreen,
        },
      },
      track: ({ theme }) => ({
        borderRadius: 26 / 2,
        background: theme.palette.common.switch.defaultSwitch,
        opacity: 1,
      }),
    },
  },
};
