import { apply, call, put } from 'redux-saga/effects';
import { notificationsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { NotificationsActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchNotifications() {
  try {
    yield put(notificationsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `notifications/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: NotificationsActionPayload = yield call([response, 'json']);
    yield put(notificationsActions.notifications(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(notificationsActions.stopFetching());
  }
}
