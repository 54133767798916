import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

type MobileTitleProps = {
  title: string;
};

export const MobileTitle: React.FC<MobileTitleProps> = ({ title }) => {
  return (
    <Box sx={styles.root}>
      <Typography variant={'Headlines/H4'}>{title}</Typography>
    </Box>
  );
};
