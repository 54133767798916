import React from 'react';
import { getFieldError } from '../../../helpers/getFieldError';
import { FieldRenderProps } from 'react-final-form';
import { TextField } from '@mui/material';

const RenderCodeField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <TextField
      {...input}
      fullWidth
      label={'Code'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
    />
  );
};

export default RenderCodeField;
