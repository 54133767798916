import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';

type ItemProps = {
  value: number | string;
  title: string;
};

export const Item: React.FC<ItemProps> = ({ value, title }) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={styles.root}>
      <Typography variant={isSM ? 'Headlines/H5' : 'Headlines/H4'}>
        {value}
      </Typography>
      <Typography variant={'Tag/ Uppercase 12'}>{title}</Typography>
    </Box>
  );
};
