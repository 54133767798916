import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const PoolsIconLT: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33301 24.4969C2.33301 23.8504 2.85534 23.3262 3.49967 23.3262H24.4997C25.144 23.3262 25.6663 23.8504 25.6663 24.4969C25.6663 25.1434 25.144 25.6676 24.4997 25.6676H3.49967C2.85535 25.6676 2.33301 25.1434 2.33301 24.4969Z"
        fill="url(#paint0_linear_14271_84436)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.83686 7.9745C7.3752 8.59537 6.99507 9.29287 6.71776 10.0574L21.9901 15.6352C22.0321 15.6504 22.0785 15.6287 22.0938 15.5866C22.2263 15.2211 22.3307 14.8522 22.4083 14.4821C22.4862 14.1102 22.5369 13.7371 22.5615 13.3653C22.5617 13.3611 22.5621 13.3568 22.5623 13.3526C22.7892 9.83719 20.685 6.43791 17.2233 5.17359C13.7469 3.90395 9.94329 5.1416 7.83686 7.9745ZM5.91971 12.2575C4.70876 11.8153 4.0844 10.4717 4.52514 9.2566C6.51365 3.77446 12.5863 0.988466 18.0213 2.97346C23.4144 4.94313 26.2596 10.9474 24.2863 16.3874C23.8304 17.6446 22.4449 18.2928 21.1921 17.8353L5.91971 12.2575Z"
        fill="url(#paint1_linear_14271_84436)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9562 9.34437C12.0244 7.55256 13.8536 5.2514 16.9153 3.09961L18.2539 5.01733C15.4833 6.96451 13.8726 9.01307 12.9586 10.5462C12.5007 11.3144 12.2162 11.956 12.0486 12.3944C11.9649 12.6135 11.9104 12.7816 11.8782 12.8889C11.8622 12.9427 11.8517 12.9811 11.8459 13.0031L11.8406 13.0238L11.841 13.0225L11.8413 13.0209C11.8413 13.0209 11.8418 13.0188 10.7044 12.7579C9.56711 12.497 9.56738 12.4958 9.56738 12.4958L9.56767 12.4946L9.56835 12.4917L9.57001 12.4847L9.57462 12.4654C9.57829 12.4504 9.58318 12.431 9.58939 12.4073C9.6018 12.3599 9.61952 12.2957 9.6435 12.2155C9.69145 12.0553 9.76444 11.832 9.87005 11.5557C10.0812 11.0033 10.4231 10.2387 10.9562 9.34437Z"
        fill="url(#paint2_linear_14271_84436)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2796 12.3832C19.6092 10.3216 19.682 7.37898 18.7151 3.75586L16.4612 4.36152C17.3362 7.64012 17.2578 10.2483 16.9758 12.0123C16.8345 12.8961 16.6414 13.5712 16.4891 14.015C16.4129 14.2369 16.3469 14.4008 16.3028 14.5038C16.2806 14.5553 16.264 14.5916 16.2543 14.6121L16.2451 14.6314L16.2458 14.6301L16.2465 14.6287C16.2465 14.6287 16.2475 14.6267 17.2858 15.1604C18.3242 15.6942 18.3247 15.6931 18.3247 15.6931L18.3267 15.6893L18.33 15.6829L18.3387 15.6651C18.3455 15.6512 18.3542 15.6333 18.3646 15.6111C18.3854 15.5669 18.413 15.5061 18.446 15.4292C18.512 15.2756 18.5991 15.0574 18.6951 14.7778C18.8872 14.2184 19.1151 13.4121 19.2796 12.3832Z"
        fill="url(#paint3_linear_14271_84436)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9935 13.9596C14.599 14.1807 14.9112 14.8525 14.6908 15.4601L11.4986 24.2606C11.2783 24.8682 10.6088 25.1814 10.0033 24.9602C9.39784 24.7392 9.08565 24.0674 9.30603 23.4598L12.4982 14.6593C12.7186 14.0517 13.388 13.7384 13.9935 13.9596Z"
        fill="url(#paint4_linear_14271_84436)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14271_84436"
          x1="25.6663"
          y1="25.1173"
          x2="23.0342"
          y2="17.5469"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14271_84436"
          x1="24.9134"
          y1="14.3047"
          x2="6.1328"
          y2="7.19359"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14271_84436"
          x1="18.2539"
          y1="10.6916"
          x2="9.71316"
          y2="8.53409"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14271_84436"
          x1="19.5018"
          y1="12.8879"
          x2="16.1164"
          y2="12.6213"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14271_84436"
          x1="14.7615"
          y1="22.4127"
          x2="9.09741"
          y2="21.602"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D88EE" />
          <stop offset="1" stopColor="#A351BF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default PoolsIconLT;
