import { Box } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

type CollapseButtonProps = {
  onClick: () => void;
  collapsed: boolean;
};

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  onClick,
  collapsed,
}) => {
  const isLight = useIsLightTheme();

  return (
    <Box
      sx={{
        ...styles.root,
        transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      onClick={onClick}>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        {isLight ? (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.08958 0.410091C6.76416 0.0846573 6.23649 0.0846573 5.91108 0.410091L0.911067 5.4101C0.585634 5.73552 0.585634 6.26318 0.911067 6.5886L5.91108 11.5886C6.23649 11.914 6.76416 11.914 7.08958 11.5886C7.41499 11.2632 7.41499 10.7355 7.08958 10.4101L2.67883 5.99935L7.08958 1.58861C7.41499 1.26317 7.41499 0.735532 7.08958 0.410091Z"
              fill="url(#paint0_linear_14271_84495)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_14271_84495"
                x1="7.33364"
                y1="9.091"
                x2="0.54522"
                y2="7.97147"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#2D88EE" />
                <stop offset="1" stopColor="#A351BF" />
              </linearGradient>
            </defs>
          </>
        ) : (
          <path
            d="M6.5 11L1.5 6L6.5 1"
            stroke="#0C0B1F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </Box>
  );
};
