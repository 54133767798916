import { apply, put } from 'redux-saga/effects';
import { resourcesActions } from '../../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../../../REST/api';
import { RemoveResourcesActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* deleteResources({
  payload,
}: PayloadAction<RemoveResourcesActionPayload>) {
  try {
    yield put(resourcesActions.startFetching());
    const response: Response = yield apply(api, api.delete, [
      { endpoint: `resources/${payload}/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    yield put(resourcesActions.removeResources(payload));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(resourcesActions.stopFetching());
  }
}
