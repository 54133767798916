import { apply, call, put } from 'redux-saga/effects';
import { adminActions } from '../../actions';
import { api } from '../../../../REST/api';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';

export function* approveWithdrawRequest({
  payload: { password, id },
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield put(adminActions.startFetching());

    const body: string = yield call(JSON.stringify, {
      password,
    });

    const response: Response = yield apply(api, api.post, [
      { endpoint: `transactions/withdraw/${id}/approve/`, body },
    ]);

    if (!response.ok) {
      const data: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, data);
      reject(formErrors);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(adminActions.stopFetching());
  }
}
