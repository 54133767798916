import { put, select, take } from 'redux-saga/effects';
import { appActions } from '../../actions';

export function* initializeDashboard() {
  let isInit = false;
  const deps = [
    'auth',
    'options',
    'dashboard',
    'notifications',
    'pools',
    'balance',
    'news',
    'todo',
  ];

  while (!isInit) {
    yield take('*');
    const state: { [key: string]: any } = yield select();
    const isAllInitialized = deps.every((dep: string) => {
      return state[dep].isInitialized === false;
    });

    if (isAllInitialized) {
      isInit = true;
      yield put(appActions.initializationDashboard());
    }
  }
}
