export const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: -1,
    '& video': {
      minWidth: '100%',
      minHeight: '100%',
    },
  },
};
