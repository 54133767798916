import React from 'react';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../../components/ui-kit/GeneralCardHolder';

const FormBackgroundHolder: React.FC<{
  children: React.ReactNode;
  effects?: EffectFunc[];
}> = ({ children, effects }) => {
  return (
    <GeneralCardHolder height={'100%'} sx={styles.root} effects={effects}>
      {children}
    </GeneralCardHolder>
  );
};

export default FormBackgroundHolder;
