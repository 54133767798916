import { createAction } from '@reduxjs/toolkit';
import adminSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const adminActions = {
  ...adminSlice.actions,
  editApplications: createAction(
    'admin/editApplications',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchApplications: createAction(
    'admin/fetchApplications',
    ({ order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  approveWithdrawRequest: createAction(
    'admin/approveWithdrawRequest',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  declineWithdrawRequest: createAction(
    'admin/declineWithdrawRequest',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchAdminWithdraw: createAction(
    'admin/fetchAdminWithdraw',
    ({ is_active, order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering:
                (order === 'desc' ? '-' : '') + value.ordering === 'initial'
                  ? 'amount'
                  : value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  fetchAdminWithdrawRequests: createAction(
    'admin/fetchAdminWithdrawRequests',
    ({ is_active, order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering:
                (order === 'desc' ? '-' : '') + value.ordering === 'initial'
                  ? 'amount'
                  : value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  fetchAdminTransactionHistory: createAction(
    'admin/fetchAdminTransactionHistory',
    ({ order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  adminSent: createAction('admin/adminSent', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: {
        resolve,
        reject,
      },
    };
  }),
};
