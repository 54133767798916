import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { PasswordInput } from '../../../components/ui-kit/inputs/PasswordInput';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderTransactionPassField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <PasswordInput
      fullWidth
      setShowPassword={setShowPassword}
      showPassword={showPassword}
      label={'Transaction password'}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
      {...input}
    />
  );
};
