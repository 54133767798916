import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
      },
    }),
  },
};
