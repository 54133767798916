import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import FinalForm from '../../../components/FinalForm';
import { authActions } from '../../../bus/auth/actions';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Link as UiLink } from '../../../components/ui-kit/typography/Link';
import ForgotPassword from '../../../Forms/ForgotPassword';
import { book } from '../../../routes/book';
import { schema } from '../../../Forms/ForgotPassword/schema';
import { styles } from './styles';
import { EffectFunc } from '../../../components/ui-kit/GeneralCardHolder';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.violet,
    position: [10, 84],
    size: 100,
    blur: 60,
  }),
  (colors) => ({
    color: colors.violet,
    position: [95, 55],
    size: 100,
    blur: 60,
  }),
];

const ForgotPasswordPage = () => {
  const theme = useTheme();

  return (
    <>
      <FormBackgroundHolder effects={effects}>
        <Box
          sx={styles.root}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}>
          <Typography sx={{ zIndex: 1 }} variant={'title'}>
            Forgot Password
          </Typography>
          <FinalForm
            component={ForgotPassword}
            sagaAction={authActions.forgotPassword}
            initialValues={{}}
            onSubmitSuccess={(showSnackbar) => {
              showSnackbar('Verify your email. Link has been sent');
            }}
            schema={schema}
          />
        </Box>
      </FormBackgroundHolder>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={4}
        mb={10}>
        <Typography
          mb={1}
          sx={{ opacity: 0.5 }}
          variant={'h8'}
          color={theme.palette.grey['50']}>
          Don`t have an account?
        </Typography>
        <UiLink to={book.signUp}>Sign Up</UiLink>
      </Box>
    </>
  );
};

export default ForgotPasswordPage;
