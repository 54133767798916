import { Box, TextField } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderCodeField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={1}>
      <TextField
        data-testid={'render-code-from-email-field'}
        fullWidth
        label={'Reset code'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
    </Box>
  );
};
