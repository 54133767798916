import { Box, FormHelperText } from '@mui/material';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Button } from '../../components/ui-kit/Button';
import RenderCodeField from './fields/RenderCodeField';
import { styles } from './styles';

export const TwoFactorQrCodeForm = (props: FormRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Box
        maxWidth={'447px'}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}>
        <Field name={'verify_code'} render={RenderCodeField} />
        <Box mt={'30px'}>
          <Button
            sx={styles.button}
            type={'submit'}
            variant={'secondary'}
            color={'violet'}
            role={'submit'}>
            Done
          </Button>
          {props.hasSubmitErrors && (
            <Box>
              <FormHelperText error>{props.submitError}</FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
