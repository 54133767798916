import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

import React from 'react';
import { ButtonColors, ButtonVariants } from '../../../themes/dark/typetefs';

export interface ButtonProps extends MuiButtonProps {
  variant?: ButtonVariants;
  color?: ButtonColors;
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <MuiButton {...props}>
      <span style={{ position: 'relative', zIndex: 1 }}>{children}</span>
    </MuiButton>
  );
};
