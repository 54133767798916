import CompanyIcon from '../../../../components/ui-kit/Icons/CompanyIcon';
import { book } from '../../../../routes/book';
import VerifyIcon from '../../../../components/ui-kit/Icons/VerifyIcon';
import ProfileIcon from '../../../../components/ui-kit/Icons/ProfileIcon';
import PaymentIcon from '../../../../components/ui-kit/Icons/PaymentIcon';
import SecurityIcon from '../../../../components/ui-kit/Icons/SecurityIcon';
import { NavMenuItem } from '../../../../components/ui-kit/Sidebar/menu';

export const MENUITEMS: NavMenuItem[] = [
  {
    label: 'My Profile',
    icon: ProfileIcon,
    link: book.settingsProfile,
    active: false,
  },
  {
    label: 'My Company',
    icon: CompanyIcon,
    link: book.settingsCompany,
    active: false,
  },
  {
    label: 'Payment details',
    icon: PaymentIcon,
    link: book.settingsPayment,
    active: false,
  },
  {
    label: 'Security & Password',
    icon: SecurityIcon,
    link: book.settingsSecurity,
    active: false,
  },
  {
    label: 'Verify account',
    icon: VerifyIcon,
    link: book.settingsVerify,
    active: false,
  },
];
