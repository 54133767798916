import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';
import SeenNotificationIcon from '../../../ui-kit/Icons/SeenNotificationIcon';
import { Avatar } from '../../../ui-kit/Avatar';
import logoSmall from '../../../../assets/logo-small.png';

type NotificationItemProps = {
  id: string | number;
  icon?: string;
  seen: boolean;
  notificationText: string;
  date: string;
};

const style = {
  '& *': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '270px',
    whiteSpace: 'nowrap',
  },
};

export const NotificationItem: React.FC<NotificationItemProps> = ({
  icon,
  seen,
  notificationText,
  date,
}) => {
  return (
    <ListItem disablePadding>
      <ListItemButton sx={styles.button}>
        <Box sx={styles.container}>
          <ListItemIcon>
            <Avatar variant={'rounded'} icon={icon || logoSmall} />
          </ListItemIcon>
          <Box sx={styles.textWrapper}>
            {seen ? (
              <ListItemText secondary={notificationText} sx={style} />
            ) : (
              <ListItemText primary={notificationText} sx={style} />
            )}
            <ListItemText primary={date} />
          </Box>
        </Box>
        <SeenNotificationIcon seen={seen} />
      </ListItemButton>
    </ListItem>
  );
};
