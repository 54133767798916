import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    padding: '0 0 0 0',
    maxHeight: '1666px',
    background: theme.palette.common.tabs.background,
    '& .MuiFormControl-root': {
      boxSizing: 'border-box',
      padding: '40px 40px 0 40px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        padding: '36px 24px 0 24px',
      },
      '& .MuiOutlinedInput-root': {
        height: '42px',
      },
    },
  }),
  list: (theme: Theme) => ({
    padding: '0 0 40px 40px',
    marginRight: '14px',
    maxHeight: '1450px',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: (theme: Theme) => theme.palette.common.mainColorFirst,
      borderRadius: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 10px 0',
      margin: '0 24px 26px 24px',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
    },
    '& .MuiListItem-root': {
      '&:not(:last-child)': {
        marginBottom: '20px',
      },
    },
  }),
  listWrapper: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '10px',
    },
  }),
};
