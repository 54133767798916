import { Theme } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsOptions } from '../../../../themes/dark/breakpoints';

const breakpoints = createBreakpoints(breakpointsOptions);

export const styles = {
  root: (theme: Theme) => ({
    ...theme.mixins.card,
    maxWidth: '527px',
    width: '100%',
    padding: '0 0 48px 0',
    [breakpoints.down('xxl')]: {
      padding: '0 0 48px 0',
    },
    '&:before': {
      ...theme.mixins.cardBefore,
    },
  }),
  textWrap: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    textAlign: 'center',
    maxWidth: '527px',
    width: '100%',
    padding: '0 54px',
    margin: '44px auto 48px auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '0 17px',
    },
  }),
  headline: (theme: Theme) => ({
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      fontSize: '20px',
    },
  }),
  button: {
    height: '50px',
  },
};
