import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Button } from '../../../ui-kit/Button';
import { notificationsActions } from '../../../../bus/notifications/actions';
import { useDispatch } from 'react-redux';

type NotificationTitleProps = {};

export const NotificationTitle: React.FC<NotificationTitleProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box sx={styles.root}>
      <Typography variant={'Headlines/H6'}>Notifications</Typography>
      <Button
        onClick={() => dispatch(notificationsActions.setSeen({}))}
        variant={'square'}
        color={'birch'}
        sx={{ color: theme.palette.common.notifications.markAsRead }}>
        Mark all as read
      </Button>
    </Box>
  );
};
