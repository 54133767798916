import { Components, Theme } from '@mui/material';
import { experimental_sx as sx } from '@mui/material/styles';

export const MuiInputLabel: Partial<Components<Theme>> = {
  MuiInputLabel: {
    styleOverrides: {
      root: sx({
        fontFamily: 'Poppins, sans-serif',
        color: 'common.buttons.standardColor',
        opacity: 0.6,
        fontWeight: 500,
        fontSize: 15,
        left: '2px',
        transform: 'translate(14px, 15.5px) scale(1)',
        maxWidth: 'calc(100% - 24px)',
        '&.Mui-focused': {
          color: 'common.buttons.standardColor',
          opacity: 0.6,
          transform: 'translate(14px, 8px)',
          fontSize: 12,
        },
      }),
      outlined: {
        '&.MuiFormLabel-filled': {
          transform: 'translate(14px, 8px)',
          fontSize: 12,
        },
      },
    },
  },
};
