import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Item } from './Item';
import TimeCountdown from 'react-countdown';
import { NotificationItem } from './NotificationItem';

type CountdownProps = {
  times: number;
  padding?: string | number;
  isNotification?: boolean;
};

type RendererProps = {
  days: number;
  hours: number;
  minutes: number;
};

export const Countdown: React.FC<CountdownProps> = ({
  times,
  padding,
  isNotification = false,
}) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const renderer = ({ days, hours, minutes }: RendererProps) => {
    return (
      <Box
        sx={styles.root}
        p={padding ? padding : '0 20px 81px 20px'}
        width={isNotification ? 'initial' : '100%'}
        gap={isNotification ? 0 : '7px'}>
        {isNotification ? (
          <>
            <NotificationItem value={days} title={'d'} />
            {isSM ? ':' : <>&nbsp;</>}
            <NotificationItem value={hours} title={'h'} />
            {isSM ? ':' : <>&nbsp;</>}
            <NotificationItem value={minutes} title={'m'} />
          </>
        ) : (
          <>
            <Item value={'T'} title={'day'} />
            <Item value={'B'} title={'hour'} />
            <Item value={'A'} title={'minute'} />
          </>
        )}
      </Box>
    );
  };

  return <TimeCountdown date={times} renderer={renderer} />;
};
