import React from 'react';
import FinalForm from '../../../FinalForm';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';
import EnterCodeForResetTransactionPasswordForm from '../../../../Forms/EnterCodeForResetTransactionPasswordForm';
import { transactionsActions } from '../../../../bus/transactions/actions';
import { useDispatch } from 'react-redux';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { schema } from '../../../../Forms/EnterCodeForResetTransactionPasswordForm/schema';
import { uiActions } from '../../../../bus/ui/actions';
import { useSnackbar } from 'notistack';

type ModalProps = {};

const effects: EffectFunc[] = [
  (colors, isSM) => ({
    color: colors.secondary,
    position: isSM ? [-7, 50] : [30, 105],
  }),
  (colors, isSM) => ({
    color: colors.violet,
    position: isSM ? [103, 50] : [65, -5],
    blur: 50,
  }),
];

export const EnterCodeForResetTransactionPassword: React.FC<
  ModalProps
> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    return new Promise((resolve, reject) => {
      dispatch(
        transactionsActions.changeTransactionPasswordWithCode({
          values: payload,
          resolve,
          reject,
        }),
      );
    })
      .then(() => {
        enqueueSnackbar('Successfully', { variant: 'infoSnackbar' });
        dispatch(uiActions.closeModal());
      })
      .catch((_) => {
        enqueueSnackbar('Something go wrong', { variant: 'infoSnackbar' });
      });
  };

  return (
    <GeneralCardHolder effects={effects} sx={styles.root}>
      <FinalForm
        initialValues={{}}
        component={EnterCodeForResetTransactionPasswordForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
