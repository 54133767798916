import { combineReducers } from 'redux';
import adminSlice from '../bus/admin/slice';
import authSlice from '../bus/auth/slice';
import balanceSlice from '../bus/balance/slice';
import dashboardSlice from '../bus/dashboard/slice';
import faqSlice from '../bus/faq/slice';
import merchantsSlice from '../bus/merchants/slice';
import newsSlice from '../bus/news/slice';
import notificationsSlice from '../bus/notifications/slice';
import optionsSlice from '../bus/options/slice';
import poolsSlice from '../bus/pools/slice';
import productsSlice from '../bus/products/slice';
import resourcesSlice from '../bus/resources/slice';
import royaltyFeesSlice from '../bus/royaltyFees/slice';
import teamSlice from '../bus/team/slice';
import todoSlice from '../bus/todo/slice';
import transactionsSlice from '../bus/transactions/slice';
import uiSlice from '../bus/ui/slice';
import usersSlice from '../bus/users/slice';
import ventureSlice from '../bus/venture/slice';
import walletsSlice from '../bus/wallets/slice';
import appSlice from '../bus/app/slice';
import paymentSlice from '../bus/payment/slice';
// imports

export const rootReducer = combineReducers({
  app: appSlice.reducer,
  payment: paymentSlice.reducer,
  auth: authSlice.reducer,
  wallets: walletsSlice.reducer,
  transactions: transactionsSlice.reducer,
  royaltyFees: royaltyFeesSlice.reducer,
  venture: ventureSlice.reducer,
  admin: adminSlice.reducer,
  resources: resourcesSlice.reducer,
  faq: faqSlice.reducer,
  news: newsSlice.reducer,
  users: usersSlice.reducer,
  merchants: merchantsSlice.reducer,
  products: productsSlice.reducer,
  pools: poolsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  team: teamSlice.reducer,
  todo: todoSlice.reducer,
  notifications: notificationsSlice.reducer,
  options: optionsSlice.reducer,
  ui: uiSlice.reducer,
  balance: balanceSlice.reducer,
});
