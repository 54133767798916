import React from 'react';
import { Box, TextField } from '@mui/material';
import { getFieldError } from '../../../helpers/getFieldError';
import { FieldRenderProps } from 'react-final-form';

const RenderDescriptionField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={2.5}>
      <TextField
        {...input}
        fullWidth
        label={'Description'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};

export default RenderDescriptionField;
