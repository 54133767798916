import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminApplicationsIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 28 28'} width={'28'} height={'28'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83203 12.834C3.89904 12.834 2.33203 11.267 2.33203 9.33398C2.33203 7.40099 3.89904 5.83398 5.83203 5.83398C7.76502 5.83398 9.33203 7.40099 9.33203 9.33398C9.33203 11.267 7.76502 12.834 5.83203 12.834ZM4.66536 9.33398C4.66536 9.97831 5.1877 10.5007 5.83203 10.5007C6.47636 10.5007 6.9987 9.97831 6.9987 9.33398C6.9987 8.68966 6.47636 8.16732 5.83203 8.16732C5.1877 8.16732 4.66536 8.68966 4.66536 9.33398ZM12.832 8.16732C12.1877 8.16732 11.6654 8.68966 11.6654 9.33398C11.6654 9.97831 12.1877 10.5007 12.832 10.5007H24.4987C25.143 10.5007 25.6654 9.97831 25.6654 9.33398C25.6654 8.68966 25.143 8.16732 24.4987 8.16732H12.832ZM2.33203 18.6673C2.33203 20.6004 3.89904 22.1673 5.83203 22.1673C7.76502 22.1673 9.33203 20.6004 9.33203 18.6673C9.33203 16.7343 7.76502 15.1673 5.83203 15.1673C3.89904 15.1673 2.33203 16.7343 2.33203 18.6673ZM5.83203 19.834C5.1877 19.834 4.66536 19.3117 4.66536 18.6673C4.66536 18.023 5.1877 17.5007 5.83203 17.5007C6.47636 17.5007 6.9987 18.023 6.9987 18.6673C6.9987 19.3117 6.47636 19.834 5.83203 19.834ZM12.832 17.5007C12.1877 17.5007 11.6654 18.023 11.6654 18.6673C11.6654 19.3117 12.1877 19.834 12.832 19.834H24.4987C25.143 19.834 25.6654 19.3117 25.6654 18.6673C25.6654 18.023 25.143 17.5007 24.4987 17.5007H12.832Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminApplicationsIcon;
