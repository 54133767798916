// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {
  FillThemeActionPayload,
  ModalDataActionPayload,
  AddTableFilterActionPayload,
  ThemeVariant,
  UiState,
  InitTableFilterActionPayload,
} from './typedefs';

const xlScreenDown = window.innerWidth < 1690;

const initialState: UiState = {
  isFetching: false,
  isInitialized: true,
  theme: ThemeVariant.dark,
  isSidebarOpen: !xlScreenDown,
  isMobileSidebarOpen: false,
  isModalOpen: false,
  modalData: null,
  tableFilter: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTableFilter(state, action: PayloadAction<AddTableFilterActionPayload>) {
      state.tableFilter = {
        ...state.tableFilter,
        [action.payload.key]: action.payload.value,
      };
    },
    initTableFilter(
      state,
      action: PayloadAction<InitTableFilterActionPayload>,
    ) {
      state.tableFilter = action.payload.reduce((prev, current) => {
        return {
          ...prev,
          [current]: [],
        };
      }, {});
    },
    clearTableFilter(state) {
      state.tableFilter = null;
    },
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
      state.isInitialized = false;
    },
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleMobileSidebar(state) {
      state.isMobileSidebarOpen = !state.isMobileSidebarOpen;
    },
    fillTheme(state, action: PayloadAction<FillThemeActionPayload>) {
      state.theme = action.payload;
    },
    showModal(state) {
      state.isModalOpen = true;
    },
    hideModal(state) {
      state.isModalOpen = false;
    },
    fillModalData(state, action: PayloadAction<ModalDataActionPayload>) {
      state.modalData = action.payload;
    },
    clearModal(state) {
      state.modalData = null;
    },
    // inject
  },
});

export default uiSlice;
