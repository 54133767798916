import { List } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { INotification } from '../../../../bus/notifications/typedefs';
import { NotificationItem } from './NotificationItem';
import { styles } from './styles';
import VisibilitySensor from 'react-visibility-sensor';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../../../bus/notifications/actions';
import {
  getIsNotificationsFetching,
  newsActivitiesNextUrl,
} from '../../../../bus/notifications/selectors';

type NotificationListProps = {
  notifications: INotification[];
};

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
}) => {
  const [containment, setContainment] = useState<any>();
  const dispatch = useDispatch();
  const isActivitiesFetching = useSelector(getIsNotificationsFetching);
  const nextActivitiesUrl = useSelector(newsActivitiesNextUrl);

  useLayoutEffect(() => {
    setContainment(document.getElementById('notification-list'));
  }, []);

  const inView = (id: number) => {
    dispatch(notificationsActions.setSeen({ id }));
  };

  const nextActivitiesItems = () => {
    !isActivitiesFetching &&
      nextActivitiesUrl &&
      dispatch(notificationsActions.loadMoreNotifications(nextActivitiesUrl));
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      nextActivitiesItems();
    }
  };

  return (
    <List
      onScroll={handleScroll}
      id={'notification-list'}
      sx={[
        styles.list,
        {
          overflowY: notifications?.length > 4 ? 'scroll' : '',
          overflowX: 'hidden',
        },
      ]}>
      {notifications?.map((notification) => {
        return (
          <VisibilitySensor
            key={notification.id}
            containment={containment}
            onChange={(isVisible: boolean) =>
              isVisible && notification.is_new && inView(notification.id)
            }>
            {() => {
              return containment ? (
                <NotificationItem
                  id={notification.id}
                  icon={notification.user?.rank?.image}
                  notificationText={notification.title}
                  date={notification.created_at}
                  seen={!notification.is_new}
                />
              ) : null;
            }}
          </VisibilitySensor>
        );
      })}
    </List>
  );
};
