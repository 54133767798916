import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdvcashIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      sx={{ fontSize: '30px' }}
      viewBox={'0 0 30 26'}
      width={'30'}
      height={'26'}>
      <path
        d="M26.0816 15.1084H17.9614L19.3964 18.3759H23.6289L26.0816 15.1084ZM29.9999 8.97656H15.2695L16.7045 12.2441H27.5499L29.9999 8.97656Z"
        fill="#009A61"
      />
      <path
        d="M15.2307 0L3.79297 25.0029H8.14783L15.2252 8.87658L22.3136 25.0279H26.6713L15.2307 0Z"
        fill="white"
      />
      <path
        d="M0 8.97656L2.39434 12.2441H16.7048L15.2698 8.97656H0ZM4.35486 15.1084L6.75198 18.3759H19.3967L17.9617 15.1084H4.35486Z"
        fill="#009A61"
      />
    </SvgIcon>
  );
};

export default AdvcashIcon;
