import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { styles } from './styles';

type MobileTitleProps = {
  title: string;
};

export const MobileTitle: React.FC<MobileTitleProps> = ({ title }) => {
  const themes = useTheme();

  return (
    <Box sx={styles.root}>
      <Typography
        sx={{ color: themes.palette.common.header.titleColor }}
        variant={'Headlines/H4'}>
        {title}
      </Typography>
    </Box>
  );
};
