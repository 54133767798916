import { apply, put } from 'redux-saga/effects';
import { todoActions } from '../../actions';
import { api } from '../../../../REST/api';
import { DeleteTodoActionPayload } from '../../typedefs';
import { PayloadAction } from '@reduxjs/toolkit';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* deleteTodo({
  payload,
}: PayloadAction<DeleteTodoActionPayload>) {
  try {
    yield put(todoActions.startFetching());
    const response: Response = yield apply(api, api.delete, [
      { endpoint: `todo/${payload.id}/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    yield put(todoActions.removeTodo({ id: payload.id }));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(todoActions.stopFetching());
  }
}
