import { call, put } from 'redux-saga/effects';
import { optionsActions } from '../../actions';
import { Country } from 'country-state-city';
import { handleError } from '../../../ui/saga/workers/handleError';
import { ICountry } from 'country-state-city/lib/interface';

export function* fetchCountries() {
  try {
    yield put(optionsActions.startFetching());
    const counties: ICountry[] = yield call(Country.getAllCountries);
    yield put(optionsActions.fillCountries(counties));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(optionsActions.stopFetching());
  }
}
