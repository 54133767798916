import React, { SVGProps } from 'react';
import { SvgIcon, useTheme } from '@mui/material';

const LeftArrowCalendarIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      sx={{ fontSize: '26px' }}
      viewBox={'0 0 26 16'}
      width={'26'}
      height={'16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.25 7.9989C25.25 8.23096 25.1578 8.45352 24.9937 8.61761C24.8296 8.78171 24.6071 8.8739 24.375 8.8739H3.73725L9.2445 14.3794C9.4088 14.5437 9.5011 14.7665 9.5011 14.9989C9.5011 15.2313 9.4088 15.4541 9.2445 15.6184C9.0802 15.7827 8.85736 15.875 8.625 15.875C8.39264 15.875 8.1698 15.7827 8.0055 15.6184L1.0055 8.6184C0.924011 8.53712 0.859362 8.44056 0.81525 8.33425C0.771139 8.22795 0.748432 8.11399 0.748432 7.9989C0.748432 7.8838 0.771139 7.76984 0.81525 7.66354C0.859362 7.55723 0.924011 7.46068 1.0055 7.3794L8.0055 0.379396C8.1698 0.215095 8.39264 0.122791 8.625 0.122791C8.85736 0.122791 9.0802 0.215095 9.2445 0.379396C9.4088 0.543697 9.5011 0.766539 9.5011 0.998897C9.5011 1.23125 9.4088 1.45409 9.2445 1.6184L3.73725 7.1239H24.375C24.6071 7.1239 24.8296 7.21608 24.9937 7.38018C25.1578 7.54427 25.25 7.76683 25.25 7.9989Z"
        fill={theme.palette.common.calendar.monthYear}
      />
    </SvgIcon>
  );
};

export default LeftArrowCalendarIcon;
