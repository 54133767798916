import { all, call, takeEvery } from 'redux-saga/effects';
import { walletsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchWalletsWithStats,
} from './workers';
// imports
function* watchFetchWalletsWithStats() {
  yield takeEvery(
    walletsActions.fetchWalletsWithStats.type,
    fetchWalletsWithStats,
  );
}
// watchers
export function* watchWallets() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchWalletsWithStats),
  ]);
}
