import { apply, call, put } from 'redux-saga/effects';
import { newsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadMoreNewsActionPayload,
  PushMoreNewsActionPayload,
} from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* loadMoreNews({
  payload,
}: PayloadAction<LoadMoreNewsActionPayload>) {
  try {
    yield put(newsActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: payload.nextPageUrl, direct: true },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: PushMoreNewsActionPayload = yield call([response, 'json']);
    yield put(newsActions.pushMoreNews(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(newsActions.stopFetching());
  }
}
