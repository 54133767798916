import { all, call, takeEvery } from 'redux-saga/effects';
import { faqActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchFaqs,
  sortFaqs,
  fetchFaq,
  updateFaq,
  deleteFaq,
  createFaq,
} from './workers';
// imports
function* watchFetchFaqs() {
  yield takeEvery(faqActions.fetchFaqs.type, fetchFaqs);
}
function* watchSortFaqs() {
  yield takeEvery(faqActions.sortFaqs.type, sortFaqs);
}
function* watchFetchFaq() {
  yield takeEvery(faqActions.fetchFaq.type, fetchFaq);
}
function* watchUpdateFaq() {
  yield takeEvery(faqActions.updateFaq.type, updateFaq);
}
function* watchDeleteFaq() {
  yield takeEvery(faqActions.deleteFaq.type, deleteFaq);
}
function* watchCreateFaq() {
  yield takeEvery(faqActions.createFaq.type, createFaq);
}
// watchers
export function* watchFaq() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchCreateFaq),
    call(watchDeleteFaq),
    call(watchUpdateFaq),
    call(watchFetchFaq),
    call(watchSortFaqs),
    call(watchFetchFaqs),
  ]);
}
