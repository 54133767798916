import {
  Box,
  ClickAwayListener,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styles } from './styles';

import { MENUITEMS } from './menu';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../../components/ui-kit/Button';
import ProfileArrow from '../../../../components/ui-kit/Button/ProfileArrow';
import { MenuItem } from '../../../../components/ui-kit/Sidebar/MenuItem';

type SidebarProps = {};

export const Navbar: React.FC<SidebarProps> = () => {
  const { pathname } = useLocation();
  const themes = useTheme();
  const isLG = useMediaQuery(themes.breakpoints.down('lg'));
  const [isActive, setIsActive] = useState(false);
  const [activePage, setActivePage] = useState<any>();

  useEffect(() => {
    setActivePage(MENUITEMS.find((item) => pathname === item.link));
  }, [pathname]);

  return isLG ? (
    <ClickAwayListener
      onClickAway={() => {
        setIsActive(false);
      }}>
      <Box sx={styles.drop} onClick={() => setIsActive(!isActive)}>
        <Box sx={styles.items}>
          {isActive &&
            MENUITEMS.map((item) => (
              <MenuItem
                dense
                key={item.label}
                item={item}
                activeLink={pathname === item.link}
              />
            ))}
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'21px'}>
          {activePage && React.createElement(activePage.icon)}
          <Typography variant={'Button/Button 16'}>
            {activePage && activePage.label}
          </Typography>
        </Box>
        <Button
          variant={'square'}
          color={'none'}
          startIcon={
            <ProfileArrow
              direction={isActive}
              color={themes.palette.common.mainColorFirst}
            />
          }
        />
      </Box>
    </ClickAwayListener>
  ) : (
    <Box sx={styles.root} height={'initial'}>
      <List>
        {MENUITEMS.map((item) => (
          <MenuItem
            dense
            key={item.label}
            item={item}
            activeLink={pathname === item.link}
          />
        ))}
      </List>
    </Box>
  );
};
