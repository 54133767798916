import { all, call, takeEvery } from 'redux-saga/effects';
import { ventureActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchVenture,
} from './workers';
// imports
function* watchFetchVenture() {
  yield takeEvery(ventureActions.fetchVenture.type, fetchVenture);
}
// watchers
export function* watchVenture() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchVenture),
  ]);
}
