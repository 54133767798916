import { all, call } from 'redux-saga/effects';
// eslint-disable-next-line prettier/prettier
import {
  initializeDashboard,
  initializeBalance,
  initializeTeam,
  initializeMerchants,
  initializePools,
  initializeVenture,
  initializeAchievements,
  initializeProducts,
  initializeNews,
  initializeResources,
  initializeFaq,
  initializeAdminApplications,
  initializeAdminUsers,
  initializeAdminMerchants,
  initializeAdminTransactions,
  initializeAdminWithdrawals,
  initializeAdminFees,
  initializeRoyaltyFees,
  initializeAdminFaq,
  initializeAdminResources,
  initializeAdminNews,
  initializeAdminProducts,
  initializeSettings,
} from './workers';
// imports
// watchers
export function* watchApp() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(initializeBalance),
    call(initializeDashboard),
    call(initializeTeam),
    call(initializeMerchants),
    call(initializePools),
    call(initializeVenture),
    call(initializeAchievements),
    call(initializeProducts),
    call(initializeNews),
    call(initializeResources),
    call(initializeFaq),
    call(initializeAdminApplications),
    call(initializeAdminUsers),
    call(initializeAdminMerchants),
    call(initializeAdminTransactions),
    call(initializeAdminWithdrawals),
    call(initializeAdminFees),
    call(initializeRoyaltyFees),
    call(initializeAdminFaq),
    call(initializeAdminResources),
    call(initializeAdminNews),
    call(initializeAdminProducts),
    call(initializeSettings),
  ]);
}
