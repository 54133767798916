import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '447px',
    gap: '29px',
    background: 'none',
    backdropFilter: 'none',
    padding: '20px',
    boxSizing: 'content-box',
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: 'calc(100% - 40px)',
    },
    '& .MuiButton-root': {
      maxWidth: '148px',
      minWidth: '140px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  }),
  check: (theme: Theme) => ({
    ...theme.mixins.card,
    width: '100px',
    height: '100px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xxl')]: {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
    '&:before': {
      ...theme.mixins.cardBefore,
    },
  }),
  checkLT: (theme: Theme) => ({
    position: 'relative',
    borderRadius: '16px',
    boxSizing: 'border-box',
    mixBlendMode: 'normal',
    backdropFilter: 'blur(130px)',
    width: '100px',
    height: '100px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xxl')]: {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      borderRadius: 'inherit',
      background: 'rgba(116, 107, 155, 0.1)',
      lineHeight: 0,
      padding: '1px',
      boxSizing: 'border-box',
    },
  }),
};
