import AdapterMoment from '@date-io/moment';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import CookieBannerSnackbar from './components/common/snackbars/CookieBannerSnackbar';
import InfoSnackbar from './components/common/snackbars/InfoSnackbar';
import Routers from './routes';
import { createStore } from './setup/configureStore';
import styles from './styles';
import { themes } from './themes';
import { getTheme } from './bus/ui/selectors';

export const store = createStore();
function App() {
  const theme = useSelector(getTheme);

  return (
    <ThemeProvider theme={themes[theme]}>
      <CssBaseline />
      <Box className="App" sx={styles.root}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            Components={{
              infoSnackbar: InfoSnackbar,
              cookieBannerSnackbar: CookieBannerSnackbar,
            }}>
            <Routers />
          </SnackbarProvider>
        </LocalizationProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
