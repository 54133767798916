import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { AdminState } from '../bus/admin/typedefs';
import { AuthState } from '../bus/auth/typedefs';
import { BalanceState } from '../bus/balance/typedefs';
import { DashboardState } from '../bus/dashboard/typedefs';
import { FaqState } from '../bus/faq/typedefs';
import { MerchantsState } from '../bus/merchants/typedefs';
import { NewsState } from '../bus/news/typedefs';
import { NotificationsState } from '../bus/notifications/typedefs';
import { OptionsState } from '../bus/options/typedefs';
import { PoolsState } from '../bus/pools/typedefs';
import { ProductsState } from '../bus/products/typedefs';
import { ResourcesState } from '../bus/resources/typedefs';
import { RoyaltyFeesState } from '../bus/royaltyFees/typedefs';
import { TeamState } from '../bus/team/typedefs';
import { TodoState } from '../bus/todo/typedefs';
import { TransactionsState } from '../bus/transactions/typedefs';
import { UiState } from '../bus/ui/typedefs';
import { UsersState } from '../bus/users/typedefs';
import { VentureState } from '../bus/venture/typedefs';
import { WalletsState } from '../bus/wallets/typedefs';
import { AppState } from '../bus/app/typedefs';
import { PaymentState } from '../bus/payment/typedefs';

export type PromiseResolve = (value?: unknown) => void;
export type PromiseReject = (reason?: any) => void;

export type ResolveReject = {
  resolve: PromiseResolve;
  reject: PromiseReject;
};

export type OnFormSubmitPayload = {
  resolve: PromiseResolve;
  reject: PromiseReject;
  values: any;
};

export enum UserRole {
  admin = 'admin',
  user = 'user',
}

export type AppRoute<P> = {
  path: string;
  Component: React.ReactElement;
  Layout: React.FC<P>;
  layoutProps: P;
  forRole?: string;
  title?: string;
  exact?: boolean;
};

export type OnFormSubmit = (payload: OnFormSubmitPayload) => void;

export type PayloadActionWithPromiseMeta<T> = PayloadAction<
  T,
  string,
  ResolveReject
>;

export type PayloadActionWithNavigate<T> = PayloadAction<
  T,
  string,
  { navigate: (path: string) => void }
>;
export type RootState = {
  ui: UiState;
  users: UsersState;
  options: OptionsState;
  notifications: NotificationsState;
  auth: AuthState;
  todo: TodoState;
  dashboard: DashboardState;
  team: TeamState;
  pools: PoolsState;
  balance: BalanceState;
  merchants: MerchantsState;
  products: ProductsState;
  resources: ResourcesState;
  news: NewsState;
  faq: FaqState;
  admin: AdminState;
  venture: VentureState;
  royaltyFees: RoyaltyFeesState;
  transactions: TransactionsState;
  wallets: WalletsState;
  payment: PaymentState;
  app: AppState;
};

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};

export type Nullable<T> = T | null;

export type OptionItem = {
  value: string | number;
  text: string | number;
};

export type WithPagination<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T;
};

export type FetchListPayload = {
  page?: string;
  page_size?: string;
  search?: string;
  ordering?: string;
  filter?: any;
};

/////////////////////////

export const emptyPaginationState: unknown = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

export type FetchListWorkers = {
  query: string;
  isActive?: boolean;
  myOwn?: boolean;
  status?: boolean;
  categories?: number;
  filter?: string;
};
