import { createAction } from '@reduxjs/toolkit';
import newsSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const newsActions = {
  ...newsSlice.actions,
  fetchNewsByString: createAction('news/fetchNewsByString', (payload) => {
    return {
      payload,
    };
  }),
  postNews: createAction('news/postNews', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: { resolve, reject },
    };
  }),
  editNews: createAction('news/editNews', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: { resolve, reject },
    };
  }),
  fetchOneNews: createAction(
    'news/fetchOneNews',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  deleteNews: createAction('news/deleteNews', ({ values, resolve, reject }) => {
    return {
      payload: values,
      meta: { resolve, reject },
    };
  }),
  readNews: createAction('news/readNews', (slug) => {
    return {
      payload: slug,
    };
  }),

  loadMoreNews: createAction('news/loadMoreNews', (nextPageUrl) => {
    return {
      payload: { nextPageUrl },
    };
  }),

  fetchNews: createAction(
    'news/fetchNews',
    ({ is_active, order, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
              is_active: is_active?.toString(),
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
};
