import { Box, FormHelperText, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Field, FormRenderProps } from 'react-final-form';
import { RenderTitleField } from './fields/RenderTitleField';
import { RenderDescriptionField } from './fields/RenderDescriptionField';
import { RenderLinkField } from './fields/RenderLinkField';
import { Button } from '../../components/ui-kit/Button';
import { uiActions } from '../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import { RenderUploadFileField } from './fields/RenderUploadFileField';

export const CreateNewProductForm = (props: FormRenderProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(uiActions.closeModal());
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Box sx={styles.root} width={'100%'}>
        <Box display={'flex'} flexDirection={'column'} gap={'12px'} mt={'23px'}>
          <Field name={'title'} render={RenderTitleField} />
          <Field name={'content'} render={RenderDescriptionField} />
          <Field name={'external_url'} render={RenderLinkField} />
          <Box sx={styles.upload}>
            <Typography variant={'Input/Title 12'}>Preview images</Typography>
            <Field name={'thumbnail'} render={RenderUploadFileField} />
          </Box>
        </Box>
        <Box
          sx={styles.buttons}
          display={'flex'}
          gap={'17px'}
          justifyContent={'space-between'}
          mt={'45.5px'}>
          <Button variant={'secondary'} onClick={closeModal} sx={styles.button}>
            Close
          </Button>
          <Button
            variant={'secondary'}
            color={'violet'}
            type={'submit'}
            role={'submit'}
            sx={styles.button}>
            Add
          </Button>
        </Box>
        {props.hasSubmitErrors && (
          <Box mx={2}>
            <FormHelperText error>{props.submitError}</FormHelperText>
          </Box>
        )}
      </Box>
    </form>
  );
};
