import React from 'react';
import FinalForm from '../../../FinalForm';
import { uiActions } from '../../../../bus/ui/actions';
import { useDispatch } from 'react-redux';
import UserDeactivationForm from '../../../../Forms/UserDeactivationForm';
import { schema } from '../../../../Forms/UserDeactivationForm/schema';
import { usersActions } from '../../../../bus/users/actions';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type UserDeactivationProps = {
  userId: number;
  onSuccess: any;
};

const effects: EffectFunc[] = [
  (colors, isSM) => ({
    color: colors.tertiary,
    position: isSM ? [105, 55] : [110, 88],
    size: isSM ? 130 : 100,
    blur: isSM ? 50 : 40,
  }),
  (colors, isSM) => ({
    color: colors.secondary,
    position: isSM ? [-10, 55] : [0, 12],
    size: 100,
    blur: 50,
  }),
];

export const UserDeactivation: React.FC<UserDeactivationProps> = ({
  userId,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const onSubmitHandler = ({ values, ...payload }: OnFormSubmitPayload) => {
    const data = {
      userId: userId,
      password: values.password,
    };

    dispatch(
      usersActions.deactivateUser({
        values: data,
        ...payload,
      }),
    );
  };

  const onSuccessHandler = () => {
    onSuccess();
    dispatch(uiActions.closeModal());
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={{}}
        component={UserDeactivationForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          onSuccessHandler();
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
