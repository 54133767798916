import { apply, call } from 'redux-saga/effects';
import { api } from '../../../../REST/api';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { ApplyMembershipPayload } from '../../typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '../../../../helpers/getServerErrors';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* applyMembership({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ApplyMembershipPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `applications/`, body, unsafe: true },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      // throw new Error();
      yield throwError(response);
    }

    if (!response.ok) {
      //TODO: add tooltips
      alert('Apply Membership - fail');
      reject();
      // throw new Error('Apply Membership - fail');
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
