import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    paddingTop: '320px',
    '@media (max-width: 1920px)': {
      paddingTop: '90px',
    },
    [`@media (max-width: ${theme.breakpoints.values!.sm}px)`]: {
      width: '100%',
      paddingBottom: '30px',
    },
  }),
  bgImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'blur(40px) brightness(30%)',
    '& video': {
      width: '100%',
    },
  },
  bgImg1: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '& video': {
      position: 'relative',
      transform: 'translate(-50%, 0)',
      left: '50%',
      minWidth: '100%',
      minHeight: '100%',
    },
  },
  bgImg2: {
    width: '100%',
    height: '100%',
    // boxShadow: 'inset 0px -100px 300px 200px rgb(5 2 7)',
    // borderRadius: '20% 20% 50% 50%',
    position: 'absolute',
    top: '-1%',
    zIndex: 1000,
  },
};
