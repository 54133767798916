import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const DeleteIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <SvgIcon
      sx={{ fontSize: '20px', width: '18px', height: '20px' }}
      viewBox={'0 0 18 20'}
      width={'18'}
      height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2597 3H13C13 1.34315 11.6569 0 10 0H8C6.34314 0 5 1.34315 5 3H4.34328H4H3.99882H1C0.44772 3 0 3.44772 0 4C0 4.55229 0.44772 5 1 5H2V17C2 18.6569 3.34315 20 5 20H13C14.6569 20 16 18.6569 16 17V5H17C17.5523 5 18 4.55228 18 4C18 3.44771 17.5523 3 17 3H14.0018H14H13.2597ZM14 5H12H11H7H6H4V7V17C4 17.5523 4.44772 18 5 18H13C13.5523 18 14 17.5523 14 17V7V5ZM7 3H8H9H10H11C11 2.44772 10.5523 2 10 2H8C7.4477 2 7 2.44772 7 3ZM7 7C7.5523 7 8 7.44772 8 8V15C8 15.5523 7.5523 16 7 16C6.44772 16 6 15.5523 6 15V8C6 7.44772 6.44772 7 7 7ZM12 8C12 7.44772 11.5523 7 11 7C10.4477 7 10 7.44772 10 8V15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15V8Z"
        fill="#F13DD8"
      />
    </SvgIcon>
  );
};

export default DeleteIcon;
