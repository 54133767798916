import { apply } from 'redux-saga/effects';
import { api } from '../../../../REST/api';
import { ConfirmEmailPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '../../../../setup/typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* confirmEmail({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ConfirmEmailPayload>) {
  try {
    const { user_id, activation_token } = payload;

    const response: Response = yield apply(api, api.get, [
      {
        endpoint: `auth/confirm-email/${user_id}/${activation_token}`,
        unsafe: true,
      },
    ]);

    if (!response.ok) {
      //TODO: add tooltips
      alert('confirm email - fail');
      reject('confirm email - fail');
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
