import { all, call, takeEvery } from 'redux-saga/effects';
import { paymentActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchMercurio,
  fetchAdvcash,
  fetchPaymentCallback,
} from './workers';
// imports
function* watchFetchMercurio() {
  yield takeEvery(paymentActions.fetchMercurio.type, fetchMercurio);
}
function* watchFetchAdvcash() {
  yield takeEvery(paymentActions.fetchAdvcash.type, fetchAdvcash);
}
function* watchFetchPaymentCallback() {
  yield takeEvery(
    paymentActions.fetchPaymentCallback.type,
    fetchPaymentCallback,
  );
}
// watchers
export function* watchPayment() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    // inject
    call(watchFetchPaymentCallback),
    call(watchFetchAdvcash),
    call(watchFetchMercurio),
  ]);
}
