import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnFormSubmitPayload } from '../../../../setup/typedefs';
import { uiActions } from '../../../../bus/ui/actions';
import { schema } from '../../../../Forms/EditProductForm/schema';
import FinalForm from '../../../FinalForm';
import { productsActions } from '../../../../bus/products/actions';
import { getOneProduct } from '../../../../bus/products/selectors';
import { EditProductForm } from '../../../../Forms/EditProductForm';
import { styles } from './styles';
import {
  EffectFunc,
  GeneralCardHolder,
} from '../../../ui-kit/GeneralCardHolder';

type EditProductProps = {
  slug: string;
};

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [-3, 23],
  }),
  (colors) => ({
    color: colors.primary,
    position: [92, 80],
    blur: 50,
  }),
];

export const EditProduct: React.FC<EditProductProps> = ({ slug }) => {
  const dispatch = useDispatch();

  const product = useSelector(getOneProduct(slug));

  const onSubmitHandler = (payload: OnFormSubmitPayload) => {
    dispatch(
      productsActions.editProduct({
        ...payload,
        values: {
          ...payload.values,
          attachment: 1,
          featured_image: product?.thumbnail,
        },
      }),
    );
  };

  return (
    <GeneralCardHolder sx={styles.root} effects={effects}>
      <FinalForm
        initialValues={product ? product : {}}
        component={EditProductForm}
        onSubmit={onSubmitHandler}
        onSubmitSuccess={(showSnackbar) => {
          showSnackbar('Success');
          dispatch(uiActions.closeModal());
        }}
        schema={schema}
      />
    </GeneralCardHolder>
  );
};
