import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';
import { getActivities } from '../options/selectors';
import { getStringActivities } from '../../helpers/selectorHelpers';
import { getFullNameCountryByCode } from '../../helpers/formating';
import { IMerchant } from './typedefs';

const merchantsSelector = (state: RootState) => state.merchants;

export const getIsMerchantsFetching = createSelector(
  [merchantsSelector],
  (result) => {
    return { isFetching: result.isFetching };
  },
);

export const getMerchantsList = createSelector(
  [merchantsSelector, getActivities],
  ({ merchants }, activities) => {
    if (!merchants?.results.length) return [];

    return merchants.results.map((item: IMerchant) => {
      return {
        id: item.id,
        name: {
          label: item.name,
          icon: item.image,
        },
        address: item.address,
        country: getFullNameCountryByCode(item.country),
        city: item.city,
        activities: getStringActivities(item.activities, activities),
        url: item.url,
      };
    });
  },
);

export const getMerchant = (id: number) =>
  createSelector([merchantsSelector], ({ merchants }) => {
    if (!merchants?.results.length) return null;

    return merchants.results
      .map((item: IMerchant) => {
        return {
          merchantId: item.id,
          city: item.city,
          name: item.name,
          country: item.country,
          address: item.address,
          postal_code: item.postal_code,
          url: item.url,
          activities: item.activities,
        };
      })
      .find((i) => i.merchantId === id);
  });

export const getMerchantsCurrentPage = createSelector(
  [merchantsSelector],
  ({ merchants }) => {
    if (!merchants?.previous) return 1;

    return (
      Number(
        new URLSearchParams(merchants.previous.split('?')[1]).get('page'),
      ) + 1 || 1
    );
  },
);

export const getMerchantsPagination = (pageSize: number) =>
  createSelector(
    [merchantsSelector, getMerchantsCurrentPage],
    ({ merchants }, currentPage) => {
      if (!merchants) return null;

      return {
        totalRows: merchants.count,
        totalPage: Math.ceil(merchants.count / pageSize),
        currentPage: currentPage,
        rowsPerPage: pageSize,
        nextPage: merchants.next,
        prevPage: merchants.previous,
      };
    },
  );

export const getNotConfirmMerchantsList = createSelector(
  [merchantsSelector, getActivities],
  ({ notConfirmMerchants }, activities) => {
    if (!notConfirmMerchants?.results.length) return [];

    return notConfirmMerchants.results.map((item: IMerchant) => {
      return {
        id: item.id,
        name: {
          label: item.name,
          icon: item.image,
        },
        address: item.address,
        country: getFullNameCountryByCode(item.country),
        city: item.city,
        activities: getStringActivities(item.activities, activities),
        url: item.url,
      };
    });
  },
);

export const getNotConfirmMerchantsCurrentPage = createSelector(
  [merchantsSelector],
  ({ notConfirmMerchants }) => {
    if (!notConfirmMerchants?.previous) return 1;

    return (
      Number(
        new URLSearchParams(notConfirmMerchants.previous.split('?')[1]).get(
          'page',
        ),
      ) + 1 || 1
    );
  },
);

export const getNotConfirmMerchantsPagination = (pageSize: number) =>
  createSelector(
    [merchantsSelector, getNotConfirmMerchantsCurrentPage],
    ({ notConfirmMerchants }, currentPage) => {
      if (!notConfirmMerchants) return null;

      return {
        totalRows: notConfirmMerchants.count,
        totalPage: Math.ceil(notConfirmMerchants.count / pageSize),
        currentPage: currentPage,
        rowsPerPage: pageSize,
        nextPage: notConfirmMerchants.next,
        prevPage: notConfirmMerchants.previous,
      };
    },
  );

export const singleMerchant = createSelector(
  [merchantsSelector],
  ({ singleMerchant }) => {
    if (!singleMerchant) return null;

    return {
      merchantId: singleMerchant.id,
      name: singleMerchant.name,
      country: singleMerchant.country,
      address: singleMerchant.address,
      postal_code: singleMerchant.postal_code,
      url: singleMerchant.url,
      activities: singleMerchant.activities,
    };
  },
);
