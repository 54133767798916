import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';
import { Select } from '../../../components/ui-kit/inputs/Select';
import { optionsActions } from '../../../bus/options/actions';
import { getCurrencies } from '../../../bus/options/selectors';
import { useDispatch, useSelector } from 'react-redux';

const RenderCurrencyField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const dispatch = useDispatch();
  const optionList = useSelector(getCurrencies);
  useEffect(() => {
    dispatch(optionsActions.fetchCurrencies());
  }, []);

  return (
    <Select
      {...input}
      fullWidth
      testId={'amount-field'}
      options={optionList}
      label={''}
      error={!!getFieldError(meta)}
      helperText={getFieldError(meta)}
    />
  );
};

export default RenderCurrencyField;
