import { apply, call, put } from 'redux-saga/effects';
import { optionsActions } from '../../actions';
import { api } from '../../../../REST/api';
import { WithPagination } from '../../../../setup/typedefs';
import { FillActivitiesActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchActivities() {
  try {
    yield put(optionsActions.startFetching());

    const response: Response = yield apply(api, api.get, [
      { endpoint: `activities/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const { results }: WithPagination<FillActivitiesActionPayload> = yield call(
      [response, 'json'],
    );

    yield put(optionsActions.fillActivities(results));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(optionsActions.stopFetching());
  }
}
