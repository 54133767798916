import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const AdminFeesIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color = '#37E6FF',
}) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} width={'20'} height={'20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0114 1.55748C18.6948 2.2409 18.6948 3.34894 18.0114 4.03236L4.00697 18.0368C3.32355 18.7202 2.21551 18.7202 1.53209 18.0368C0.848677 17.3534 0.848677 16.2453 1.53209 15.5619L15.5365 1.55748C16.22 0.874068 17.328 0.874068 18.0114 1.55748Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7607 15.2963C19.695 16.261 19.6786 17.7979 18.7239 18.7424L18.1345 18.1466L18.7239 18.7424C17.7693 19.6869 16.2323 19.6869 15.2776 18.7424L16.2622 17.7473L15.2776 18.7424C14.323 17.7979 14.3066 16.261 15.2409 15.2963C15.7024 14.8198 16.3374 14.5508 17.0008 14.5508C17.6642 14.5508 18.2992 14.8198 18.7607 15.2963Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7607 1.29632C5.69495 2.26097 5.67855 3.79792 4.72393 4.74241C3.7693 5.6869 2.23226 5.6869 1.27764 4.74241C0.323012 3.79792 0.306612 2.26097 1.24087 1.29632C1.70238 0.819791 2.3374 0.550781 3.00078 0.550781C3.66416 0.550781 4.29919 0.819791 4.7607 1.29632Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AdminFeesIcon;
