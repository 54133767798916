import React, { useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import FinalForm from '../../../components/FinalForm';
import SignIn from '../../../Forms/SignIn';
import { schema } from '../../../Forms/SignIn/schema';
import { authActions } from '../../../bus/auth/actions';
import FormBackgroundHolder from '../../../layouts/private/AppLayout/FormBackgroundHolder';
import { Link as UiLink } from '../../../components/ui-kit/typography/Link';
import { book } from '../../../routes/book';
import { useSnackbar } from 'notistack';
import { styles } from './styles';
import { history } from '../../../setup/configureStore';
import { EffectFunc } from '../../../components/ui-kit/GeneralCardHolder';

const effects: EffectFunc[] = [
  (colors) => ({
    color: colors.secondary,
    position: [5, 94],
    size: 100,
    blur: 70,
  }),
  (colors) => ({
    color: colors.primary,
    position: [-5, 54],
    size: 180,
    blur: 120,
  }),
  (colors) => ({
    color: colors.violet,
    position: [95, 50],
    size: 100,
    blur: 60,
  }),
];

const SignInPage = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const snackbarMessage = localStorage.getItem('snackbar_message');
    if (snackbarMessage) {
      enqueueSnackbar(snackbarMessage, {
        variant: 'infoSnackbar',
      });
      localStorage.removeItem('snackbar_message');
    }
  }, [history.location.key]);

  return (
    <>
      <FormBackgroundHolder effects={effects}>
        <Box
          sx={styles.root}
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}>
          <Typography
            sx={{ zIndex: 1 }}
            variant={'title'}
            variantMapping={{ title: 'h2' }}>
            Welcome to the Future!
          </Typography>
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'h5'}
            color={theme.palette.grey['50']}>
            Everything will start after you Sign In
          </Typography>
          <FinalForm
            component={SignIn}
            sagaAction={authActions.signIn}
            initialValues={{}}
            schema={schema}
          />
        </Box>
      </FormBackgroundHolder>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={4}
        mb={10}>
        <Typography
          mb={1}
          sx={{ opacity: 0.5 }}
          variant={'h8'}
          color={theme.palette.grey['50']}>
          Don`t have an account?
        </Typography>
        <UiLink to={book.signUp}>Sign Up</UiLink>
      </Box>
    </>
  );
};

export default SignInPage;
