import { Box, TextField } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getFieldError } from '../../../helpers/getFieldError';

export const RenderFirstNameField: React.FC<FieldRenderProps<string>> = ({
  input: { onBlur, ...input },
  meta,
}) => {
  return (
    <Box my={1}>
      <TextField
        {...input}
        id={'firstname'}
        fullWidth
        onBlur={(e) => setTimeout(() => onBlur(e), 100)}
        label={'First Name'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};
