import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';
import SettingsIcon from './SettingsIcon';
import LogOutIcon from './LogOutIcon';
import { useNavigate } from 'react-router-dom';
import { book } from '../../../routes/book';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../bus/auth/actions';

type ProfileActionProps = {};

export const ProfileAction: React.FC<ProfileActionProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <List sx={styles.root}>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate(book.settings);
          }}>
          <ListItemIcon>
            <SettingsIcon
              color={theme.palette.common.header.dropdownIconColor}
            />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => dispatch(authActions.clearData())}>
          <ListItemIcon>
            <LogOutIcon color={theme.palette.common.header.dropdownIconColor} />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
