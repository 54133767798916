import React from 'react';
import ApplyMembershipPage from '../../../pages/public/ApplyMembershipPage';
import CheckEmailPage from '../../../pages/public/CheckEmailPage';
import ConfirmEmailPage from '../../../pages/public/ConfirmEmailPage';
import ForgotPasswordPage from '../../../pages/public/ForgotPasswordPage';
import SetPasswordPage from '../../../pages/public/SetPasswordPage';
import SignInPage from '../../../pages/public/SignInPage';
import SignUpPage from '../../../pages/public/SignUpPage';
import ThankYouPage from '../../../pages/public/ThankYouPage';
import { TechnicalPage } from '../../../pages/public/TechnicalPage';
import { book } from '../../../routes/book';
import { AppRoute } from '../../../setup/typedefs';
import AuthLayout, { AuthLayoutProps } from './index';
import AlmostReadyPage from '../../../pages/public/AlmostReadyPage';
import VerificationCodePage from '../../../pages/public/VerificationCodePage';
// imports

export const authRoutes: AppRoute<AuthLayoutProps>[] = [
  // inject
  {
    path: book.technicalPage,
    Component: <TechnicalPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: `${book.signIn}`,
    Component: <SignInPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: `${book.signUp}`,
    Component: <SignUpPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: `${book.signUp}/:referral`,
    Component: <SignUpPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.applyForMembership,
    Component: <ApplyMembershipPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.forgotPassword,
    Component: <ForgotPasswordPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: `${book.setPassword}/:user_id/:reset_token`,
    Component: <SetPasswordPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.checkYouEmail,
    Component: <CheckEmailPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: `${book.confirmEmail}/:user_id/:activation_token`,
    Component: <ConfirmEmailPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.thankYou,
    Component: <ThankYouPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.almostReady,
    Component: <AlmostReadyPage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
  {
    path: book.verificationCode,
    Component: <VerificationCodePage />,
    Layout: AuthLayout,
    layoutProps: {},
  },
];
