import { Components } from '@mui/material';

export const MuiListItemIcon: Partial<Components> = {
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 42,
      },
    },
  },
};
