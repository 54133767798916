import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const CompanyIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon
      sx={{ width: '24px', height: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9H8H10H14H16H18H20V11H16H8H4V9H6ZM15 13V14C15 14.5523 15.4477 15 16 15C16.5523 15 17 14.5523 17 14V13H20V17V19H18H6H4V17V13H7V14C7 14.5523 7.44772 15 8 15C8.55229 15 9 14.5523 9 14V13H15ZM20 7H16V6C16 4.34315 14.6569 3 13 3H11C9.34315 3 8 4.34315 8 6V7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7ZM13 7H14V6C14 5.44795 13.5527 5.00038 13.0007 5H13H11H10.9993C10.4473 5.00038 10 5.44795 10 6V7H11H12H13Z"
        fill={isLight ? '#5275EF' : '#37E6FF'}
      />
    </SvgIcon>
  );
};

export default CompanyIcon;
