import React, { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';
import useIsLightTheme from '../../../helpers/customHooks/useIsLightTheme';

const VerifyIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  const isLight = useIsLightTheme();

  return (
    <SvgIcon
      sx={{ width: '24px', height: '24px' }}
      viewBox={'0 0 24 24'}
      width={'24'}
      height={'24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0475 7.95032C12.9722 5.67556 15.956 4.61622 18.8611 5.13901C19.3838 8.04422 18.3246 11.0281 16.0499 12.9529L12.6345 15.843L11.8439 16.3102L7.68949 12.1556L8.15298 11.3712L11.0475 7.95032ZM6.65427 10.0344L9.51492 6.65347C9.53702 6.62735 9.55924 6.60136 9.58156 6.57549C8.4085 6.83665 6.85658 7.50755 5.04315 9.08231L6.65427 10.0344ZM12.0111 4.51384C14.3332 3.09513 17.1671 2.62681 19.8935 3.30842C20.2865 3.40668 20.5933 3.71356 20.6916 4.10659C21.3733 6.83354 20.9047 9.66803 19.4855 11.9906C19.5666 12.4566 19.6157 13.0691 19.5486 13.8043C19.3941 15.4998 18.6301 17.7658 16.376 20.3381C15.461 21.3823 13.8677 21.1277 13.2118 20.0177L12.385 18.6184C11.9494 18.8085 11.4276 18.7248 11.0724 18.3778L11.1869 18.4923L10.4771 19.2021C9.69306 19.9862 8.42192 19.9862 7.6379 19.2021L4.79873 16.3629C4.01471 15.5788 4.01471 14.3076 4.79873 13.5236L5.50852 12.8137L5.62285 12.9281C5.27573 12.5727 5.19208 12.0506 5.38242 11.6148L3.98226 10.7874C2.87231 10.1315 2.61769 8.53817 3.66183 7.62312C6.23405 5.36891 8.49994 4.6049 10.1954 4.45031C10.9316 4.38319 11.5447 4.43248 12.0111 4.51384ZM6.92805 14.2334L6.21832 14.9432L9.05749 17.7825L9.76722 17.0727L6.92805 14.2334ZM13.9655 17.3467L14.9168 18.9568C16.4906 17.1443 17.1616 15.5931 17.4231 14.4203C17.3977 14.4421 17.3723 14.4639 17.3467 14.4856L13.9655 17.3467Z"
        fill={isLight ? '#5275EF' : '#37E6FF'}
      />
    </SvgIcon>
  );
};

export default VerifyIcon;
