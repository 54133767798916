import { apply, call, put } from 'redux-saga/effects';
import { dashboardActions } from '../../actions';
import { api } from '../../../../REST/api';
import { FillVentureTimerActionPayload } from '../../typedefs';
import { throwError } from '../../../ui/saga/workers/throwError';
import { handleError } from '../../../ui/saga/workers/handleError';

export function* fetchVentureTimer() {
  try {
    yield put(dashboardActions.startFetching());
    const response: Response = yield apply(api, api.get, [
      { endpoint: `venture/timer/` },
    ]);
    const data: FillVentureTimerActionPayload[] = yield call([
      response,
      'json',
    ]);

    if (!response.ok) {
      yield throwError(response);
    }
    yield put(dashboardActions.fillVentureTimer(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put(dashboardActions.stopFetching());
  }
}
