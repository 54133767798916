import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Box } from '@mui/material';
import { Select } from '../../../components/ui-kit/inputs/Select';
import { getFieldError } from '../../../helpers/getFieldError';

const optionList = [
  {
    id: 'internet',
    label: 'Internet',
  },
  {
    id: 'social_media',
    label: 'Social Media',
  },
  {
    id: 'friends',
    label: 'Friends',
  },
];

const RenderSourceField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box my={1}>
      <Select
        {...input}
        fullWidth
        testId={'source-field'}
        options={optionList}
        opened={false}
        disabled={false}
        label={'How did you hear about us?'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
      />
    </Box>
  );
};

export default RenderSourceField;
