import { Theme } from '@mui/material';

export const styles = {
  root: {
    '& button': (theme: Theme) => ({
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        width: '100%',
      },
    }),
  },
  upload: (theme: Theme) => ({
    background: theme.palette.common.inputs.gray,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.common.buttons.backBackground}`,
    padding: '10px 14px 14.5px 16px',
    '& > .MuiBox-root': {
      marginTop: '10px',
    },
  }),
  buttons: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  }),
  button: {
    color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette.common.buttons.standardColor,
    },
  },
};
