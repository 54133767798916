import { createAction } from '@reduxjs/toolkit';
import merchantsSlice from './slice';
import { getEndpointParams2 } from '../../helpers/formating';

export const merchantsActions = {
  ...merchantsSlice.actions,
  deleteMerchant: createAction(
    'merchants/deleteMerchant',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  approveMerchants: createAction(
    'merchants/approveMerchants',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  declineMerchants: createAction(
    'merchants/declineMerchants',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  updateMerchant: createAction(
    'merchants/updateMerchant',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  getSingleMerchant: createAction('merchants/getSingleMerchant', (payload) => {
    return { payload };
  }),
  createNewMerchant: createAction(
    'merchants/createNewMerchant',
    ({ values, resolve, reject }) => {
      return {
        payload: values,
        meta: { resolve, reject },
      };
    },
  ),
  fetchMerchants: createAction(
    'merchants/fetchMerchants',
    ({ my_own, order, status, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
              my_own: my_own.toString(),
              status: status?.toString(),
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  fetchNotConfirmMerchants: createAction(
    'merchants/fetchNotConfirmMerchants',
    ({ order, status, ...value }) => {
      return {
        payload: {
          query: getEndpointParams2(
            {
              ...value,
              ordering: (order === 'desc' ? '-' : '') + value.ordering,
              status: status?.toString(),
            },
            new URLSearchParams(),
          ),
          status: status,
        },
      };
    },
  ),
};
