import { createSelector } from 'reselect';
import { RootState } from '../../setup/typedefs';

const appSelector = (state: RootState) => state.app;

export const getIsAppFetching = createSelector([appSelector], (result) => {
  return { isFetching: result.isFetching };
});

export const getIsDashboardInitialize = createSelector(
  [appSelector],
  ({ isInitializeDashboard }) => {
    return isInitializeDashboard;
  },
);

export const getIsBalanceInitialize = createSelector(
  [appSelector],
  ({ isInitializeBalance }) => {
    return isInitializeBalance;
  },
);

export const getIsTeamInitialize = createSelector(
  [appSelector],
  ({ isInitializeTeam }) => {
    return isInitializeTeam;
  },
);

export const getIsMerchantsInitialize = createSelector(
  [appSelector],
  ({ isInitializeMerchants }) => {
    return isInitializeMerchants;
  },
);

export const getIsPoolsInitialize = createSelector(
  [appSelector],
  ({ isInitializePools }) => {
    return isInitializePools;
  },
);

export const getIsVentureInitialize = createSelector(
  [appSelector],
  ({ isInitializeVentureCapital }) => {
    return isInitializeVentureCapital;
  },
);

export const getIsAchievementsInitialize = createSelector(
  [appSelector],
  ({ isInitializeAchievements }) => {
    return isInitializeAchievements;
  },
);

export const getIsProductsInitialize = createSelector(
  [appSelector],
  ({ isInitializeProducts }) => {
    return isInitializeProducts;
  },
);

export const getIsNewsInitialize = createSelector(
  [appSelector],
  ({ isInitializeNews }) => {
    return isInitializeNews;
  },
);

export const getIsResourcesInitialize = createSelector(
  [appSelector],
  ({ isInitializeResources }) => {
    return isInitializeResources;
  },
);

export const getIsFaqInitialize = createSelector(
  [appSelector],
  ({ isInitializeFaq }) => {
    return isInitializeFaq;
  },
);

export const getIsAdminApplicationsInitialize = createSelector(
  [appSelector],
  ({ isInitializeAdminApplication }) => {
    return isInitializeAdminApplication;
  },
);

export const getIsAdminUsersInitialize = createSelector(
  [appSelector],
  ({ isInitializeAdminUsers }) => {
    return isInitializeAdminUsers;
  },
);

export const getIsAdminMerchantsInitialize = createSelector(
  [appSelector],
  ({ isInitializeAdminMerchants }) => {
    return isInitializeAdminMerchants;
  },
);

export const getIsAdminTransactionInitialize = createSelector(
  [appSelector],
  ({ isInitializeAdminTransaction }) => {
    return isInitializeAdminTransaction;
  },
);

export const getInitializeAdminWithdrawals = createSelector(
  [appSelector],
  ({ isInitializeAdminWithdrawals }) => {
    return isInitializeAdminWithdrawals;
  },
);

export const getInitializeAdminFees = createSelector(
  [appSelector],
  ({ isInitializeAdminFees }) => {
    return isInitializeAdminFees;
  },
);

export const getInitializeAdminRoyaltyFees = createSelector(
  [appSelector],
  ({ isInitializeRoyaltyFees }) => {
    return isInitializeRoyaltyFees;
  },
);

export const getInitializeAdminFaq = createSelector(
  [appSelector],
  ({ isInitializeAdminFaq }) => {
    return isInitializeAdminFaq;
  },
);

export const getInitializeAdminResources = createSelector(
  [appSelector],
  ({ isInitializeAdminResources }) => {
    return isInitializeAdminResources;
  },
);

export const getInitializeAdminNews = createSelector(
  [appSelector],
  ({ isInitializeAdminNews }) => {
    return isInitializeAdminNews;
  },
);

export const getInitializeAdminProducts = createSelector(
  [appSelector],
  ({ isInitializeAdminProducts }) => {
    return isInitializeAdminProducts;
  },
);

export const getInitializeSettings = createSelector(
  [appSelector],
  ({ isInitializeSettings }) => {
    return isInitializeSettings;
  },
);
